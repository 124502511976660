import { useQuery } from '@tanstack/react-query';
import { Skeleton } from 'primereact/skeleton';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Status } from '../../../../components';
import { selectLiverOrder } from '../../../../store/slices/main/mainSlice';
import { PaymentStatus } from '../../../../types';
import PaymentService from '../../paymentService';

const withinFiveMinutes = (modifiedTime: string) => {
  const modified = new Date(modifiedTime).getTime();
  return Date.now() - modified <= 5 * 60 * 1000;
};

const PaymentTableStatus = ({ tableOrder }: { tableOrder: Order }) => {
  const liveOrder = useSelector(selectLiverOrder);
  const isActiveRow = liveOrder?.id == tableOrder.id && tableOrder?.status == PaymentStatus.CREATED;

  const [pollingInterval, setPollingInterval] = useState<number | false>(2000);

  const {
    data: orderData,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ['order', tableOrder.id],
    queryFn: () => PaymentService.getOrderById(tableOrder.id),
    enabled: !!isActiveRow,
    refetchInterval: pollingInterval,
    select: (res) => res,
  });

  useEffect(() => {
    if (!orderData) return;
    const shouldStop =
      orderData.status === PaymentStatus.PENDING_APPROVAL ||
      !withinFiveMinutes(orderData.lastModifiedAt);

    setPollingInterval(shouldStop ? false : 2000);
  }, [orderData]);

  const hasPollingStopped =
    !!orderData &&
    (orderData.status === PaymentStatus.PENDING_APPROVAL ||
      !withinFiveMinutes(orderData.lastModifiedAt));

  if (!isActiveRow) {
    return <Status status={tableOrder.status} />;
  }

  if (isLoading || isFetching || !hasPollingStopped) {
    return <Skeleton width='10rem' height='1.5rem' />;
  }

  return (
    <div>
      <Status status={orderData.status} />
    </div>
  );
};

export default PaymentTableStatus;
