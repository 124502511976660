import {
  ArrowRight,
  CheckmarkOutline,
  Close,
  Download as DownloadIcon,
  Filter,
  SendAlt,
  View,
} from '@carbon/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTablePageEvent,
  DataTableSelectionSingleChangeEvent,
  DataTableSortEvent,
  SortOrder,
} from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  Confirm,
  CurrencyFlag,
  FormatAmount,
  InheritedMenu,
  MoreActionMobile,
  PaginatorTemplate,
  TableSearch,
  WarningIcon,
} from '../../../../components';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { MoreAction } from '../../../../icons';
import NextButton from '../../../../icons/NextButton';
import PrevButton from '../../../../icons/PrevButton';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import paymentRole from '../../paymentRole';
import PaymentService from '../../paymentService';

import { ProgressSpinner } from 'primereact/progressspinner';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import CountryFlag from '../../../../components/CountryFlag';
import FilterModal from '../../../../components/FilterModal';
import {
  selectCurrentCustomer,
  selectFilters,
  sendToast,
  updateFilter,
} from '../../../../store/slices/main/mainSlice';
import { CustomerType, PAYMENT_CATEGORY, PaymentStatus } from '../../../../types';
import { convertDateFormat } from '../../../../utils/helper';
import {
  IN_PROCESSING_STATUS_OPTIONS,
  PAYMENT_CATEGORY_OPTIONS,
  PAYMENT_COMPLETED_STATUS_OPTIONS,
  PAYMENT_STATUS_OPTIONS,
  PENDING_STATUS_OPTIONS,
} from '../../paymentConstants';
import './Payments.scss';
import PaymentTableStatus from './PaymentTableStatus';

interface PaymentsTableProps {
  isDashboard?: boolean;
  filterCategory?: 'PENDING' | 'IN_PROCESSING' | 'PAYMENT_COMPLETED';
  totalRecords: number;
  setTotalRecords: (totalRecords: number) => void;
  selectable?: boolean;
  selectedPayment?: Order | null;
  setSelectedPayment?: (selectedPayment: Order | null) => void;
  onFetchingChange?: (isLoading: boolean) => void;
}

const PaymentsTable = ({
  isDashboard = false,
  filterCategory,
  totalRecords,
  setTotalRecords,
  selectable = false,
  selectedPayment,
  setSelectedPayment,
  onFetchingChange,
}: PaymentsTableProps) => {
  const [searchParams] = useSearchParams();

  const [globalSearch, setGlobalSearch] = useState('');
  const [debouncedSearch] = useDebounce(globalSearch, 300);
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState<Order['id'] | null>(null);
  const [actionItems, setActionItems] = useState<MenuItem[]>([]);
  const menu = useRef<Menu>(null);
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<SortOrder>();
  const navigate = useNavigate();
  const currencies = useSelector(selectCustomerCurrencies);
  const filters =
    useSelector(selectFilters)[
      isDashboard ? `payment_filter_${filterCategory}` : 'payments_filter'
    ];
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const [showCancelPaymentConfirmPopup, setShowCancelPaymentConfirmPopup] = useState(false);
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useDispatch();
  const statusCategoryMap = {
    [PAYMENT_CATEGORY.PENDING]: PENDING_STATUS_OPTIONS,
    [PAYMENT_CATEGORY.IN_PROCESSING]: IN_PROCESSING_STATUS_OPTIONS,
    [PAYMENT_CATEGORY.PAYMENT_COMPLETED]: PAYMENT_COMPLETED_STATUS_OPTIONS,
  };
  const [filterValues, setFilterValues] = useState<any>([
    {
      header: 'By Status',
      fields: [
        {
          name: 'statuses',
          placeholder: 'Select Status',
          value: filters?.statuses || [],
          options:
            (isDashboard || selectable) && filterCategory
              ? statusCategoryMap[filterCategory]
              : PAYMENT_STATUS_OPTIONS,
        },
      ],
    },
    {
      header: 'By Buy Currency',
      fields: [
        {
          name: 'currency',
          type: 'currency',
          placeholder: 'Select Currency',
          value: filters?.currency || [],
        },
      ],
    },
    ...(!(isDashboard || selectable)
      ? [
          {
            header: 'By Category',
            fields: [
              {
                name: 'categories',
                placeholder: 'Select Category',
                value: filters?.category || '',
                type: 'single',
                options: PAYMENT_CATEGORY_OPTIONS,
              },
            ],
          },
        ]
      : []),
  ]);

  const rateRequestQuery = useQuery({
    queryKey: [
      'rate_requests_query',
      pageParams,
      sortField,
      sortOrder,
      filters,
      filterCategory,
      selectedCustomer?.id,
      debouncedSearch,
    ],
    queryFn: async () => {
      const { page, rows } = pageParams;
      const payload: {
        statuses: (typeof filters)['statuses'];
        buyCurrency: (typeof filters)['currency'];
        filterCategory?: typeof filterCategory;
        searchText?: string;
      } = {
        statuses: filters?.statuses || [],
        buyCurrency: filters?.currency || [],
        filterCategory: filters?.categories as typeof filterCategory,
      };
      if (debouncedSearch) {
        payload.searchText = debouncedSearch;
      }
      if (filterCategory) {
        payload.filterCategory = filterCategory;
      }
      const data = await PaymentService.getRateRequests(page, rows, payload, sortField, sortOrder);
      setTotalPages(data?.totalPages ?? 0);
      setTotalRecords(data?.totalElements ?? 0);
      return data?.content;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    onFetchingChange?.(rateRequestQuery.isFetching);
  }, [rateRequestQuery.isFetching]);

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const onSort = (event: DataTableSortEvent) => {
    if (event.sortField && event.sortOrder) {
      setSortField(event.sortField);
      setSortOrder(event.sortOrder);
    }
  };

  const getFormattedDate = (date: string) => {
    if (date == '') return 'N/A';

    return convertDateFormat(date);
  };

  const downloadMutation = useMutation({
    mutationFn: async ({ orderId, quoteId }: { orderId: number; quoteId: string }) => {
      const response = await PaymentService.downloadDisclosureStatement(orderId, quoteId);
      return response;
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'download_failed',
          detail: 'disclosure_statement_download_failed',
        }),
      );
    },
  });

  const sendDisclosureEmailMutation = useMutation({
    mutationFn: async (orderId: number) => {
      const response = await PaymentService.sendDisclosureEmail(orderId);
      return response;
    },
    onSuccess: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: 'disclosure_email_sent',
        }),
      );
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'failed',
          detail: 'disclosure_email_failed',
        }),
      );
    },
  });

  const actionBodyTemplate = (order: Order) => {
    const actions: MenuItem[] = [
      {
        roles: paymentRole.viewPayments,
        label: 'viewDetails',
        icon: <View />,
        onClick: () => order.id && navigate(`/payment/rate-requests/view?orderId=${order.id}`),
      },
      // {
      //   roles: paymentRole.editPayments,
      //   label: 'edit_payment',
      //   icon: <Edit />,
      //   onClick: () =>
      //     rateRequest.payments[0].quote.quoteId &&
      //     navigate('/payment/rate-requests/edit/' + rateRequest.payments[0].quote.quoteId),
      // },
    ];

    if (order.status !== PaymentStatus.CREATED) {
      actions.push(
        ...[
          {
            label: 'download_payment',
            icon: <DownloadIcon />,
            onClick: () =>
              void downloadMutation.mutate({
                orderId: order.payments[0].orderId,
                quoteId: order.payments[0].quote.quoteId,
              }),
          },
          {
            label: 'send_via_email',
            icon: <SendAlt />,
            onClick: () => void sendDisclosureEmailMutation.mutate(order.payments[0].orderId),
          },
        ],
      );
    }

    if (
      order.canBeApprovedByUser &&
      (order.status === PaymentStatus.PENDING_APPROVAL || PaymentStatus.PENDING_PARENT_APPROVAL)
    ) {
      actions.unshift({
        roles: paymentRole.approvePayments,
        label: 'payments.table.approve.action',
        onClick: () => {
          navigate(`/payment/rate-requests/approve?orderId=${order.id}`);
        },
        icon: <CheckmarkOutline />,
      });
    }
    if (
      ![
        PaymentStatus.REJECTED,
        PaymentStatus.CANCELLED,
        PaymentStatus.ABANDONED,
        PaymentStatus.CREATED,
      ].includes(order.status)
    ) {
      actions.push({
        label: 'payments.table.cancel_payment',
        icon: <Close />,
        className: 'delete-menu-item',
        onClick: () => setShowCancelPaymentConfirmPopup(true),
      });
    }

    return (
      <>
        <div>
          <div
            className='hidden sm:block'
            onClick={(event) => {
              setActionItems(actions);
              setSelectedOrder(order.id);
              menu.current?.toggle(event);
            }}
          >
            <MoreAction />
          </div>
          <InheritedMenu items={actionItems} ref={menu} popupAlign='left' />
        </div>

        <div
          onClick={() => {
            setActionItems(actions);
            setSelectedOrder(order.id);
          }}
        >
          <MoreActionMobile actions={actions} />
        </div>
      </>
    );
  };

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div className='text-neutral-1 text-lg-semibold'>
        <Translate value='payment_requests' />
        <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
          {totalRecords}
        </span>
      </div>
      <div className='flex sm:justify-around gap-2 items-center'>
        <TableSearch
          globalFilterValue={globalSearch}
          onGlobalFilterChange={(e) => {
            setGlobalSearch(e.target.value);
          }}
        />
        <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
          <Button
            severity='secondary'
            className='p-button-text h-12'
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <Filter size={20} />
            <Translate value='filterLabel' className='ml-1 hidden lg:block' />
          </Button>
          {!!activeFilterCount && (
            <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
          )}
        </div>
      </div>
    </div>
  );

  const handleApplyFilters = (formdata: { [key: string]: string[] | string }) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    dispatch(
      updateFilter({
        filterKey: isDashboard ? `payment_filter_${filterCategory}` : 'payments_filter',
        value: formdata,
      }),
    );
  };

  const cancelPaymentMutation = useMutation({
    mutationFn: async () => {
      if (!selectedOrder) {
        return;
      }
      const response = await PaymentService.cancelPayment(selectedOrder);
      return response;
    },
    onSuccess: () => {
      setShowCancelPaymentConfirmPopup(false);
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: 'payment.cancel.success.toast.title',
        }),
      );
      rateRequestQuery.refetch();
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'payment.cancel.error.toast.title',
          detail: error.message,
        }),
      );
    },
  });

  useEffect(() => {
    if (searchParams.get('pending')) {
      const defaultFilter = {
        statuses: [PaymentStatus.PENDING_APPROVAL],
      };
      const filterCount = Object.values(defaultFilter).filter((arr) => arr?.length).length;
      setActiveFilterCount(filterCount);
      setFilterValues((prevFilters: any) =>
        prevFilters.map((filter: any) => {
          const field = filter.fields[0];
          if (field.name === 'statuses') {
            return {
              ...filter,
              fields: [
                {
                  ...field,
                  value: [PaymentStatus.PENDING_APPROVAL],
                },
              ],
            };
          }
          return filter;
        }),
      );
      dispatch(
        updateFilter({
          filterKey: isDashboard ? `payment_filter_${filterCategory}` : 'payments_filter',
          value: defaultFilter,
        }),
      );
    }
  }, []);

  return (
    <>
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='paymentFilter'
        onApply={handleApplyFilters}
      />
      <DataTable
        className={`mt-5 ${!selectable ? 'hidden sm:block' : ''} `}
        header={header}
        selectionMode={'single'}
        dataKey='id'
        first={pageParams.first}
        rows={pageParams.rows}
        loading={rateRequestQuery.isFetching}
        onPage={onPage}
        onSort={onSort}
        sortField={sortField}
        sortOrder={sortOrder}
        value={rateRequestQuery.data}
        paginator={true}
        lazy
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        paginatorTemplate={PaginatorTemplate}
        totalRecords={totalRecords}
        {...(selectable
          ? {
              selection: selectedPayment,
              onSelectionChange: (e: DataTableSelectionSingleChangeEvent<any>) => {
                setSelectedPayment?.(e.value);
              },
            }
          : {})}
      >
        {selectable && <Column selectionMode='single' headerStyle={{ width: '1rem' }}></Column>}
        <Column field='id' sortable header={translateWithValues('orderId')} />
        <Column
          field='paymentId'
          header={translateWithValues('payment_id')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              {rateRequest.payments?.[0]?.quote?.fxId}
            </div>
          )}
        />
        {selectedCustomer &&
          (selectedCustomer.customerType == CustomerType.SERVICE_PROVIDER ||
            selectedCustomer.customerType == CustomerType.INSTRUCTING_INSTITUTION) && (
            <Column
              field='owningCustomer'
              header={translateWithValues('owning_customer')}
              body={(rateRequest: Order) => (
                <div className='text-sm-medium text-neutral-2'>
                  {rateRequest.owningCustomerShortName ?? rateRequest.owningCustomerShortName}
                </div>
              )}
            />
          )}
        <Column
          field='orderingCustomer'
          header={translateWithValues('ordering_customer')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              {rateRequest.payments?.[0]?.debitor?.customerShortName ??
                rateRequest.payments?.[0]?.debitor?.customerLegalName}
            </div>
          )}
        />
        <Column
          field='buyCurrency'
          header={translateWithValues('buy_currency')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              <CurrencyFlag currencyCode={rateRequest.payments[0]?.buyCurrency} />
            </div>
          )}
        />
        <Column
          field='buyAmount'
          header={translateWithValues('buy_amount')}
          className='text-right !pr-8'
          body={(rateRequest: Order) => {
            const buyCurrencyValue = rateRequest.payments[0]?.buyCurrency;
            const currency = currencies.find((val) => buyCurrencyValue === val.isocode);
            const precision = currency?.amountPrecision;
            return rateRequest.payments[0]?.buyAmount ? (
              <FormatAmount
                number={parseFloat(rateRequest.payments[0]?.buyAmount.toString())}
                maximumFractionDigits={precision}
              />
            ) : (
              <></>
            );
          }}
        />
        <Column
          field='sellCurrency'
          header={translateWithValues('sell_currency')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              <CurrencyFlag currencyCode={rateRequest.sellCurrency} />
            </div>
          )}
        />
        <Column
          field='sellAmount'
          className='text-right !pr-8'
          sortable
          header={translateWithValues('sell_amount')}
          body={(rateRequest: Order) => {
            const sellCurrencyValue = rateRequest.sellCurrency;
            const currency = currencies.find((val) => sellCurrencyValue === val.isocode);
            const precision = currency?.amountPrecision;
            return rateRequest.totalAmount ? (
              <FormatAmount
                number={parseFloat(rateRequest.totalAmount)}
                maximumFractionDigits={precision}
              />
            ) : (
              <></>
            );
          }}
        />
        <Column
          field='beneficiaryName'
          header={translateWithValues('beneficiary_name')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              {rateRequest.payments?.[0]?.creditor?.accountName}
            </div>
          )}
        />
        {selectedCustomer &&
          (selectedCustomer?.customerType == CustomerType.ORDERING_CUSTOMER ||
            selectedCustomer?.customerType == CustomerType.ORDERING_INSTITUTION) && (
            <Column
              field='beneficiaryCountry'
              header={translateWithValues('beneficiary_country')}
              body={(rateRequest: Order) => (
                <CountryFlag
                  countryCode={rateRequest.payments?.[0]?.creditor?.beneAddress?.country}
                />
              )}
            />
          )}

        <Column
          sortable
          field='createdAt'
          header={translateWithValues('created_date')}
          body={(rateRequest: Order) => {
            return getFormattedDate(rateRequest.createdAt);
          }}
        />
        <Column
          field='status'
          sortable
          header={translateWithValues('status')}
          body={(rateRequest: Order) => <PaymentTableStatus tableOrder={rateRequest} />}
        />
        <Column header='Actions' body={actionBodyTemplate}></Column>
      </DataTable>
      {!selectable && (
        <div className='sm:hidden'>
          <div className='relative'>
            <TableSearch
              globalFilterValue={globalSearch}
              onGlobalFilterChange={(e) => {
                setGlobalSearch(e.target.value);
              }}
            />
            <div className='absolute sm:relative right-1 top-2 sm:right-auto sm:top-auto'>
              <Button
                severity='secondary'
                className='p-button-text h-8 sm:h-12 border-none sm:border-unset !px-1 sm:px-4'
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                <Filter size={20} />
                <Translate value='filterLabel' className='ml-1 hidden lg:block' />
              </Button>
              {!!activeFilterCount && (
                <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
              )}
            </div>
          </div>
          {!rateRequestQuery.isFetching ? (
            rateRequestQuery.data?.length ? (
              <>
                {rateRequestQuery.data?.map((rateRequest) => {
                  return (
                    <div
                      key={rateRequest.id}
                      className='border-[1px] border-neutral-surface-dark rounded-[12px] p-4 mt-4 mb-4 shadow-c'
                    >
                      <div className='flex justify-between items-center	'>
                        <div>
                          {translateWithValues('orderId')}:{rateRequest.id}
                        </div>
                        <div>
                          <PaymentTableStatus tableOrder={rateRequest} />
                        </div>
                      </div>
                      <div className='border-b-[1px] border-neutral-border mt-4 mb-4'></div>
                      <div className='flex justify-between items-end'>
                        <div className='text-neutral-2 text-lg-semibold'>
                          {selectedCustomer &&
                          !(
                            selectedCustomer.customerType == CustomerType.SERVICE_PROVIDER ||
                            selectedCustomer.customerType == CustomerType.INSTRUCTING_INSTITUTION
                          )
                            ? (rateRequest.payments?.[0]?.debitor?.customerShortName ??
                              rateRequest.payments?.[0]?.debitor?.customerLegalName)
                            : (rateRequest.owningCustomerShortName ??
                              rateRequest.owningCustomerShortName)}
                        </div>
                        {actionBodyTemplate(rateRequest)}
                      </div>
                      {selectedCustomer &&
                        (selectedCustomer.customerType == CustomerType.SERVICE_PROVIDER ||
                          selectedCustomer.customerType ==
                            CustomerType.INSTRUCTING_INSTITUTION) && (
                          <div>
                            {rateRequest.payments?.[0]?.debitor?.customerShortName ??
                              rateRequest.payments?.[0]?.debitor?.customerLegalName}
                          </div>
                        )}
                      <div>{getFormattedDate(rateRequest.createdAt)}</div>
                      <div className='flex items-center justify-between mt-8'>
                        <div className='text-sm-medium text-neutral-2'>
                          <CurrencyFlag currencyCode={rateRequest.payments[0]?.buyCurrency} />
                        </div>
                        <div className='text-sm-medium text-neutral-2'>
                          {rateRequest.payments[0]?.buyAmount}
                        </div>
                        <ArrowRight />
                        <div className='text-sm-medium text-neutral-2'>
                          <CurrencyFlag currencyCode={rateRequest.sellCurrency} />
                        </div>
                        <div className='text-sm-medium text-neutral-2'>
                          {rateRequest.totalAmount}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <Translate value='no_available_options' className='pb-4 block' />
            )
          ) : (
            <div className='absolute z-10 inset-0 bg-white/50 grid place-content-center'>
              <ProgressSpinner className='w-10 h-10' />
            </div>
          )}

          <div className='flex justify-between items-center'>
            <Button
              disabled={pageParams.page === 0}
              className='cursor-pointer'
              onClick={() =>
                onPage({
                  first: pageParams.first - pageParams.rows,
                  rows: pageParams.rows,
                  page: pageParams.page - 1,
                })
              }
            >
              <PrevButton />
            </Button>

            <div>
              {translateWithValues('pageOf', {
                current: pageParams.page + 1,
                total: totalPages,
              })}
            </div>
            <Button
              disabled={pageParams.page >= totalPages - 1}
              className='cursor-pointer'
              onClick={() =>
                onPage({
                  first: pageParams.first + pageParams.rows,
                  rows: pageParams.rows,
                  page: pageParams.page + 1,
                })
              }
            >
              <NextButton />
            </Button>
          </div>
        </div>
      )}
      {showCancelPaymentConfirmPopup && (
        <Confirm
          confirmLabel='payments.table.cancel_payment'
          headerIcon={<WarningIcon />}
          title='payments.table.cancel_payment.confirm.title'
          message='payments.table.cancel_payment.confirm.message'
          confirmButtonSeverify='danger'
          onCancel={() => {
            setShowCancelPaymentConfirmPopup(false);
          }}
          onConfirm={() => cancelPaymentMutation.mutate()}
          visible={showCancelPaymentConfirmPopup}
          loading={cancelPaymentMutation.isPending}
        />
      )}
    </>
  );
};

export default PaymentsTable;
