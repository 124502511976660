import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

const ROOT = '/api/disclaimer-management';
const DISCLAIMERS = '/disclaimers';
const CUSTOMERS = '/customers';

const fetchDisclaimer = async (customerId: number): Promise<DiscalimerField[] | null> => {
  try {
    const response = await apiClient.get<DiscalimerField[]>(
      `${ROOT}${DISCLAIMERS}${CUSTOMERS}/${customerId}`,
    );
    return handleApiResponse(response);
  } catch (error) {
    console.error(`Error fetching disclaimer with id ${customerId}`);
    return null;
  }
};

const updateDisclaimer = (
  payload: Array<{ type: string; content?: string }>,
  customerId: number,
) => {
  return apiClient.put(`${ROOT}${DISCLAIMERS}${CUSTOMERS}/${customerId}`, payload);
};

export { fetchDisclaimer, updateDisclaimer };
