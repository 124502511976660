import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CustomStepper } from '../../../components';
import { PageHeadContext } from '../../../providers/PageHead';
import {
  selectCurrentCustomer,
  selectCustomerSettings,
} from '../../../store/slices/main/mainSlice';
import { AddOrEditOrView, ClientForms, CustomerSubType, CustomerType } from '../../../types';
import ClientService from '../clientService';
import CustomerDetails from '../CustomerDetails';
import { CustomerOverview } from '../CustomerOverview';
import Settings from '../Settings';
import SiteDetails from '../SiteDetails';
import UserDetails from '../UserDetails';

interface Step {
  id: string;
  content: React.ReactNode;
}

const AddClient = ({ mode }: { mode: AddOrEditOrView }) => {
  const [activeStep, setActiveStep] = useState<string>('');
  const [visited, setVisited] = useState<Set<string>>(new Set());
  const [visitPermitted, setVisitPermitted] = useState<Set<string>>(new Set());
  const [validationOnStepper, setValidationOnStepper] = useState<Set<string>>(new Set());
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const [customerType, setCustomerType] = useState<CustomerType>();
  const [currentEditiCustomer, setCurrentEditiCustomer] = useState<Client>();
  const [parentCustomer, setParentCustomer] = useState<Client>();
  const loggedInCustomer = useSelector(selectCurrentCustomer);
  const [isStonexCustomer, setIsStonexCustomer] = useState<boolean | undefined>(undefined);
  const customerSettings = useSelector(selectCustomerSettings);
  const { customerId } = useParams();

  useEffect(() => {
    if (mode === AddOrEditOrView.Edit && customerId) {
      getCutomerById();
    }
    if (mode === AddOrEditOrView.Add && customerId) {
      getParentCustomer();
    } else if (loggedInCustomer) {
      setParentCustomer(loggedInCustomer);
    }
  }, [mode, customerId, loggedInCustomer]);

  const getCutomerById = async () => {
    const client = await ClientService.getClientById(customerId!);
    if (client) {
      setCustomerType(client.customerType);
      setCurrentEditiCustomer(client);
    }
  };

  useEffect(() => {
    if (currentEditiCustomer) {
      setIsStonexCustomer(currentEditiCustomer.owningCustomerId == 1);
    } else if (parentCustomer) {
      setIsStonexCustomer(parentCustomer?.id == 1);
    }
  }, [currentEditiCustomer, parentCustomer]);

  const getParentCustomer = async () => {
    if (customerId) {
      const parentCustomer = await ClientService.getClientById(customerId);
      if (parentCustomer) {
        setParentCustomer(parentCustomer);
      }
    }
  };

  useEffect(() => {
    if (mode === AddOrEditOrView.Edit) {
      setVisitPermitted(
        new Set([
          ClientForms.CustomerOverview,
          ClientForms.CustomerDetails,
          ClientForms.UserDetails,
          ClientForms.SiteDetails,
          ClientForms.Settings,
        ]),
      );
      setVisited(
        new Set([
          ClientForms.CustomerOverview,
          ClientForms.CustomerDetails,
          ClientForms.UserDetails,
          ClientForms.SiteDetails,
          ClientForms.Settings,
        ]),
      );
    } else {
      setVisitPermitted(new Set([ClientForms.CustomerOverview]));
    }
  }, [mode]);

  useEffect(() => {
    if (mode === AddOrEditOrView.Edit) {
      setPageHeadData({
        title: currentEditiCustomer?.customerShortName ?? 'editClient.title',
        description: 'editClient.description',
      });
    } else {
      setPageHeadData({
        title: 'addclient.title',
        description: 'addclient.description',
      });
    }
  }, [setPageHeadData, currentEditiCustomer]);

  const jumpToEdit = (step: string) => {
    setActiveStep(step);
  };

  const handleStepperValidation = (formName: ClientForms, isValid: boolean) => {
    setValidationOnStepper((prev: Set<string>) => {
      const newValidations = new Set(prev);
      if (!isValid) {
        newValidations.add(formName);
      } else {
        newValidations.delete(formName);
      }
      return newValidations;
    });
  };

  const steps: Step[] = [
    {
      id: ClientForms.CustomerOverview,
      content: (
        <CustomerOverview
          activeStep={activeStep}
          onSaveAndProceed={(client?: Client) => {
            if (client) {
              setCurrentEditiCustomer(client);
            }
            setVisitPermitted(
              new Set([
                ...visitPermitted,
                ClientForms.CustomerDetails,
                ...(isStonexCustomer ? [] : [ClientForms.UserDetails]),
                ClientForms.SiteDetails,
                ClientForms.Settings,
              ]),
            );
            setVisited(new Set([...visited, ClientForms.CustomerOverview]));
            if (mode === AddOrEditOrView.Add && !visited.has(ClientForms.CustomerDetails)) {
              // setActiveStep(ClientForms.CustomerDetails);
              setActiveStep('');
            } else {
              setActiveStep('');
            }
          }}
          handleStepperValidation={handleStepperValidation}
          jumpToEdit={jumpToEdit}
          setCustomerType={setCustomerType}
          mode={visited?.has(ClientForms.CustomerOverview) ? AddOrEditOrView.Edit : mode}
          visited={visited}
          currentEditiCustomer={currentEditiCustomer}
          parentCustomer={parentCustomer}
          isStonexCustomer={isStonexCustomer}
        />
      ),
    },
    {
      id: ClientForms.CustomerDetails,
      content: (
        <CustomerDetails
          activeStep={activeStep}
          onSaveAndProceed={(client?: Client) => {
            if (client) {
              setCurrentEditiCustomer(client);
            }
            setVisitPermitted(new Set([...visitPermitted, ClientForms.UserDetails]));
            setVisited(new Set([...visited, ClientForms.CustomerDetails]));
            if (mode === AddOrEditOrView.Add && !visited.has(ClientForms.UserDetails)) {
              setActiveStep(ClientForms.UserDetails);
            } else {
              setActiveStep('');
            }
          }}
          handleStepperValidation={handleStepperValidation}
          jumpToEdit={jumpToEdit}
          mode={visited?.has(ClientForms.CustomerDetails) ? AddOrEditOrView.Edit : mode}
          visited={visited}
          visitPermitted={visitPermitted}
          customerType={customerType}
          currentEditiCustomer={currentEditiCustomer}
          isStonexCustomer={isStonexCustomer}
        />
      ),
    },
    {
      id: ClientForms.UserDetails,
      content: (
        <UserDetails
          activeStep={activeStep}
          jumpToEdit={jumpToEdit}
          handleStepperValidation={handleStepperValidation}
          inheritedClientId={currentEditiCustomer?.id}
          mode={mode}
          visitPermitted={visitPermitted}
          visited={visited}
          isStonexCustomer={isStonexCustomer}
          onSaveAndProceed={() => {
            if (mode === AddOrEditOrView.Add && !visited.has(ClientForms.SiteDetails)) {
              setActiveStep(ClientForms.SiteDetails);
            } else {
              setActiveStep('');
            }
            setVisitPermitted(new Set([...visitPermitted, ClientForms.SiteDetails]));
            setVisited(new Set([...visited, ClientForms.UserDetails]));
          }}
        />
      ),
    },
    {
      id: ClientForms.SiteDetails,
      content: (
        <SiteDetails
          inheritedClientId={currentEditiCustomer?.id}
          activeStep={activeStep}
          visited={visited}
          mode={mode}
          visitPermitted={visitPermitted}
          jumpToEdit={jumpToEdit}
          onSaveAndProceed={() => {
            setVisited(new Set([...visited, ClientForms.SiteDetails]));
            if (mode === AddOrEditOrView.Add && !visited.has(ClientForms.Settings)) {
              setActiveStep(ClientForms.Settings);
            } else {
              setActiveStep('');
            }
          }}
        />
      ),
    },
    {
      id: ClientForms.Settings,
      content: (
        <Settings
          activeStep={activeStep}
          inheritedClientId={currentEditiCustomer?.id ?? parentCustomer?.id}
          visited={visited}
          mode={mode}
          visitPermitted={visitPermitted}
          jumpToEdit={jumpToEdit}
          onSaveAndProceed={() => {
            setVisited(new Set([...visited, ClientForms.Settings]));
            setActiveStep('');
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className='pt-10 mx-auto rounded-md'>
        {(mode === AddOrEditOrView.Edit ? currentEditiCustomer : parentCustomer) && (
          <CustomStepper
            steps={(() => {
              let updatedSteps = steps;

              if (customerType === CustomerType.ORDERING_CUSTOMER) {
                if (currentEditiCustomer?.customerSubType === CustomerSubType.CONSUMER) {
                  updatedSteps = currentEditiCustomer?.enableForOnline
                    ? steps
                    : steps.filter((step) => step.id !== ClientForms.UserDetails);
                } else {
                  updatedSteps = currentEditiCustomer?.enableForOnline
                    ? steps.filter((step) => step.id !== ClientForms.SiteDetails)
                    : steps.filter(
                        (step) =>
                          step.id !== ClientForms.UserDetails &&
                          step.id !== ClientForms.SiteDetails,
                      );
                }
              }

              if (!customerSettings?.brandingConfigEnabled) {
                updatedSteps = updatedSteps.filter((step) => step.id !== ClientForms.SiteDetails);
              }

              return updatedSteps;
            })()}
            activeStep={activeStep}
            visited={visited}
            validations={validationOnStepper}
          />
        )}
      </div>
    </>
  );
};

export default AddClient;
