import PaymentSelect from './PaymentSelect';
import { CreateCaseSteps, TicketType } from '../../../types';
import { useState } from 'react';
import CaseForm from './CaseForm';
import CaseSummary from './CaseSummary';
import PaymentService from '../../Payment/paymentService';
import { useQuery } from '@tanstack/react-query';

const CreateCase = () => {
  const [activeStep, setActiveStep] = useState(CreateCaseSteps.SELECT_PAYMENT);
  const [orderId, setOrderId] = useState<number | null>(null);
  const [caseData, setCaseData] = useState<CaseDetailsFormData | null>(null);
  const [ticketType, setTicketType] = useState(TicketType.QUERY);

  const { data: payment } = useQuery({
    queryKey: ['payment_details_in_case', orderId],
    queryFn: async () => {
      const data = PaymentService.getOrderById(orderId as number);
      return data;
    },
    enabled: !!orderId,
  });

  const onPaymentSelectProceed = (order: Order | null) => {
    setOrderId(order?.payments?.[0]?.orderId ?? null);
    setActiveStep(CreateCaseSteps.CASE_FORM);
  };

  const onCaseFormProceed = (formData: CaseDetailsFormData) => {
    setCaseData(formData);
    setActiveStep(CreateCaseSteps.CASE_SUMMARY);
  };

  return (
    <div className='border-t border-neutral-border'>
      {activeStep === CreateCaseSteps.SELECT_PAYMENT && (
        <PaymentSelect
          onProceed={onPaymentSelectProceed}
          ticketType={ticketType}
          setTicketType={setTicketType}
        />
      )}
      {activeStep === CreateCaseSteps.CASE_FORM && (
        <CaseForm onProceed={onCaseFormProceed} payment={payment} ticketType={ticketType} />
      )}
      {activeStep === CreateCaseSteps.CASE_SUMMARY && (
        <CaseSummary caseData={caseData} payment={payment} ticketType={ticketType} />
      )}
    </div>
  );
};

export default CreateCase;
