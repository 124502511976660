import { RoleGuard } from '../../components';
import BulkPaymentOutlet from './BulkPayment';
import bulkPaymentRoutes from './BulkPayment/routes';
import paymentRole from './paymentRole';
import RateRequestOutlet from './RateRequest';
import rateRequestRoutes from './RateRequest/routes';

const paymentRoutes = [
  {
    id: 'rate-requests',
    path: 'rate-requests',
    handle: {
      breadcrumb: 'Rate Requests',
    },
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <RateRequestOutlet />
      </RoleGuard>
    ),
    children: rateRequestRoutes,
  },
  {
    id: 'bulk-payments',
    path: 'bulk-payments',
    handle: {
      breadcrumb: 'Bulk Payments',
    },
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <BulkPaymentOutlet />
      </RoleGuard>
    ),
    children: bulkPaymentRoutes,
  },
];

export default paymentRoutes;
