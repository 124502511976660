const settingsConfig: SettingsConfig = {
  administration: [
    {
      name: 'clientManagementEnabled',
      disabledAtParentProperty: 'clientManagementDisabledAtParent',
      header: 'customer_management',
      subtitle: 'setup_child_customers',
    },
  ],
  branding: [
    {
      name: 'brandingConfigEnabled',
      disabledAtParentProperty: 'brandingConfigDisabledAtParent',
      header: 'branding',
      subtitle: 'adjust_branding',
    },
    {
      name: 'emailSettingsEnabled',
      disabledAtParentProperty: 'emailSettingsDisabledAtParent',
      header: 'email_settings',
      subtitle: 'configure_email_server',
    },
    {
      name: 'disclaimersEnabled',
      disabledAtParentProperty: 'disclaimersDisabledAtParent',
      header: 'disclaimers',
      subtitle: 'edit_disclaimers',
    },
  ],
  managed_pricing: [
    {
      name: 'marginDefinitionEnabled',
      disabledAtParentProperty: 'marginDefinitionDisabledAtParent',
      header: 'margin',
      subtitle: 'configure_margins',
    },
    {
      name: 'feeDefinitionEnabled',
      disabledAtParentProperty: 'feeDefinitionDisabledAtParent',
      header: 'fees',
      subtitle: 'configure_fees',
    },
    {
      name: 'lockInBeforeApproval',
      disabledAtParentProperty: 'lockInBeforeApprovalDisabledAtParent',
      header: 'seperate_rate_lock_in',
      subtitle: 'configure_seperate_rate_lock_in',
    },
  ],
  compliance: [
    {
      name: 'complianceEnabled',
      disabledAtParentProperty: 'complianceDisabledAtParent',
      header: 'sanction_screening',
      subtitle: 'enable_sanction_screening_module',
    },
  ],
  payments: [
    {
      name: 'fundsCheckEnabled',
      disabledAtParentProperty: 'fundsCheckDisabledAtParent',
      header: 'funds_check',
      subtitle: 'enable_funds_check',
    },
  ],
};

export default settingsConfig;
