export const RouteRoles = {
  client:
    'MANAGE_CUSTOMER_DELETE,MANAGE_CUSTOMER_CREATE,MANAGE_CUSTOMER_UPDATE, MANAGE_CUSTOMER_APPROVE,MANAGE_CUSTOMER_VIEW',
  manage:
    'MANAGE_USER_DELETE, MANAGE_USER_CREATE, MANAGE_USER_UPDATE, MANAGE_USER_APPROVE, MANAGE_USER_VIEW, MANAGE_APPROVAL_WORKFLOW_DELETE, MANAGE_APPROVAL_WORKFLOW_CREATE, MANAGE_APPROVAL_WORKFLOW_UPDATE, MANAGE_APPROVAL_WORKFLOW_APPROVE, MANAGE_APPROVAL_WORKFLOW_VIEW,MANAGE_ROLE_DELETE, MANAGE_ROLE_CREATE, MANAGE_ROLE_UPDATE, MANAGE_ROLE_APPROVE, MANAGE_ROLE_VIEW',
  configuration:
    'MANAGE_CONFIGURATION_DELETE,MANAGE_CONFIGURATION_CREATE,MANAGE_CONFIGURATION_UPDATE,MANAGE_CONFIGURATION_APPROVE,MANAGE_CONFIGURATION_VIEW,MANAGE_PRICING_DELETE,MANAGE_PRICING_CREATE,MANAGE_PRICING',
  Currency:
    'MANAGE_CURRENCY_DELETE,MANAGE_CURRENCY_CREATE,MANAGE_CURRENCY_UPDATE,MANAGE_CURRENCY_APPROVE,MANAGE_CURRENCY_VIEW',
  ManagedPricing:
    'MANAGE_PRICING_DELETE,MANAGE_PRICING_CREATE,MANAGE_PRICING_UPDATE,MANAGE_PRICING_APPROVE,MANAGE_PRICING_VIEW',
  User: 'MANAGE_USER_DELETE,MANAGE_USER_CREATE,MANAGE_USER_UPDATE,MANAGE_USER_APPROVE,MANAGE_USER_VIEW',
  Roles:
    'MANAGE_ROLE_DELETE,MANAGE_ROLE_CREATE,MANAGE_ROLE_UPDATE,MANAGE_ROLE_APPROVE,MANAGE_ROLE_VIEW',
  approvals:
    'MANAGE_APPROVAL_WORKFLOW_CREATE,MANAGE_APPROVAL_WORKFLOW_VIEW,MANAGE_APPROVAL_WORKFLOW_UPDATE,MANAGE_APPROVAL_WORKFLOW_DELETE,MANAGE_APPROVAL_WORKFLOW_APPROVE',
  payment: 'PAYMENT_CREATE,PAYMENT_UPDATE,PAYMENT_VIEW',
  hasCurrencyPermission:
    'MANAGE_CURRENCY_CREATE,MANAGE_CURRENCY_VIEW,PAYMENT_CREATE,PAYMENT_UPDATE,PAYMENT_VIEW',
  branding: 'MANAGE_BRANDING_UPDATE',
  report: 'REPORTING_VIEW,REPORTING_CREATE,REPORTING_UPDATE,REPORTING_APPROVE',
  benificiary:
    'BENEFICIARY_MANAGEMENT_VIEW,BENEFICIARY_MANAGEMENT_CREATE,BENEFICIARY_MANAGEMENT_UPDATE,BENEARY_MANAGEMENT_APPROVE',
  investigations:
    'INVESTIGATIONS_CREATE,INVESTIGATIONS_UPDATE,INVESTIGATIONS_APPROVE,INVESTIGATIONS_VIEW',
};
