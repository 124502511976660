import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DropdownInput } from '../../../../components';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { AddAlt } from '../../../../icons';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { CustomerSubType, CustomerType, Status } from '../../../../types';
import ClientService from '../../../Client/clientService';
import PaymentService from '../../paymentService';
import CustomerCreationModal from './CustomerCreationModal';
import { useMutation } from '@tanstack/react-query';
import { selectCountries } from '../../../../store/slices/refdata/refdataSlice';

interface CustomerDetailsProps {
  onSaveAndProceed?: (client: CustomerDetails) => void;
  quoteId: string;
  orderId: number | undefined;
  paymentId: number | undefined;
}

interface Option {
  value: string;
  label: string;
}

export const CustomerDetails = ({
  onSaveAndProceed,
  quoteId,
  orderId,
  paymentId,
}: CustomerDetailsProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors },
    watch,
  } = useForm<CustomerDetails>();
  const [options, setOptions] = useState<Option[]>([]);
  const dispatch = useAppDispatch();
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const countries = useSelector(selectCountries);

  const getCustomers = async () => {
    setIsLoading(true);
    const customerId = selectedCustomer?.id;
    if (customerId) {
      const payload = {
        customerTypes: [CustomerType.ORDERING_CUSTOMER],
      };
      const response = await ClientService.getChildClients(
        customerId,
        0,
        0,
        payload,
        '',
        null,
        true,
      );
      if (response?.content) {
        const filterCustomers = response.content.filter(
          (c) =>
            (c.status == Status.ACTIVE ||
              c.approvalStatus == Status.PENDING_APPROVAL ||
              c.approvalStatus == Status.PENDING_CREATION) &&
            c.bankInfos[0]?.accountNumber != null,
        );
        const customerOptions = filterCustomers
          .map(({ id, customerLegalName, customerShortName, customerIdentifier }) => ({
            value: `${id}`,
            label: customerShortName
              ? customerIdentifier
                ? `${customerShortName} - ${customerIdentifier}`
                : customerShortName
              : customerLegalName,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setOptions(customerOptions);
        setIsLoading(false);
      }
    }
  };

  const handleNewCustomerCreation = (customer: Client) => {
    const { customerLegalName, customerShortName, customerIdentifier } = customer;
    const newCustomerOption = {
      value: `${customer.id}`,
      label: customerShortName
        ? customerIdentifier
          ? `${customerShortName} - ${customerIdentifier}`
          : customerShortName
        : customerLegalName,
    };
    setOptions([...options, newCustomerOption]);
    reset({ selectedCustomer: `${customer.id}` });
    //    setIsNewCustomer(true);
  };

  const handleDeleteEntry = () => {
    if (isNewCustomer) {
      reset();
    }
    setIsNewCustomer(false);
  };

  useEffect(() => {
    if (quoteId) getCustomers();
  }, [selectedCustomer, quoteId]);

  const saveCustomerDetailsMutation = useMutation({
    mutationFn: async (formData: CustomerDetails) => {
      const payload = {
        remitterId: formData.selectedCustomer,
        orderId: orderId,
        paymentId: paymentId,
      };
      const response = await PaymentService.updateRemitter(payload);
      return response;
    },
    onSuccess: (_, formData) => {
      showToast('success', 'success', 'quote_saved.message.success');
      if (onSaveAndProceed) {
        onSaveAndProceed(formData);
      }
    },
    onError: () => {
      showToast('error', 'error', 'quote.remitter.update.error');
    },
  });

  function saveCustomerDetails(data: CustomerDetails) {
    saveCustomerDetailsMutation.mutate(data);
  }

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const showErrorToast = () => {
    showToast('error', 'client.field.validation.error', 'client.field.validation.error.detail');
  };

  const {
    mutate: fetchCustomer,
    data: selectedCustomerDetails,
    isPending,
  } = useMutation({
    mutationFn: async (customerId: string) => {
      const data = await PaymentService.getClientById(customerId);
      if (data) {
        return {
          customerIdentifier: data.customerIdentifier,
          customerLegalName: data.customerLegalName,
          customerShortName: data.customerShortName,
          customerSubType: CustomerSubType.CONSUMER as CustomerSubType.CONSUMER,
          address: {
            street1: data.address?.street1 || '',
            street2: data.address?.street2 || '',
            city: data.address?.city || '',
            state: data.address?.state || '',
            postalCode: data.address?.postalCode || '',
            country: countries.find((country) => country.code === data.address?.country),
          },
          bankInfos: [
            {
              routingCode: data.bankInfos?.[0]?.routingCode || '',
              codeType: data.bankInfos?.[0]?.codeType || '',
              accountNumber: data.bankInfos?.[0]?.accountNumber || '',
            },
          ],
          customerType: data.customerType,
          phone: data.phone,
          email: data.email,
          enableForOnline: false,
          kycStatus: '',
        };
      }
    },
    onSuccess: () => {
      setIsViewModalVisible(true);
    },
  });

  const selectedCustomerId = watch('selectedCustomer');

  const viewSelectedCustomer = () => {
    fetchCustomer(selectedCustomerId);
  };

  return (
    <div className='relative'>
      {!quoteId ? (
        <div className='absolute inset-0 bg-white opacity-70 cursor-not-allowed z-10' />
      ) : (
        ''
      )}
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <CustomerCreationModal
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          handleNewCustomer={handleNewCustomerCreation}
          handleDeleteEntry={handleDeleteEntry}
        />
        <CustomerCreationModal
          visible={isViewModalVisible}
          setVisible={(value) => setIsViewModalVisible(value)}
          isViewMode
          {...(selectedCustomerDetails ? { defaultValues: selectedCustomerDetails } : {})}
        />

        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='customer.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='customer.description' />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className='mt-4'>{translateWithValues('loadingCustomer')}</div>
        ) : (
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          <form onSubmit={handleSubmit(saveCustomerDetails)}>
            <div className='mt-8 flex flex-wrap items-start gap-10 text-neutral-2 text-sm-medium'>
              <div>
                <DropdownInput
                  disabled={isNewCustomer || !quoteId}
                  className='lg:min-w-[250px]'
                  placeholder='change_customer'
                  options={options}
                  label='select_existing_customer'
                  isRequired
                  name='selectedCustomer'
                  filter
                  control={control}
                  error={errors['selectedCustomer']}
                  rules={{ required: true }}
                />
                {selectedCustomerId && (
                  <Button
                    link
                    className='!pl-0 text-primary'
                    loading={isPending}
                    type='button'
                    onClick={() => {
                      viewSelectedCustomer();
                    }}
                  >
                    <Translate value='view_customer' />
                  </Button>
                )}
              </div>
              <div className='mt-6'>
                <Button
                  severity='contrast'
                  className='h-[43px] w-60'
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                  type='button'
                >
                  <AddAlt />
                  <Translate value='add_new_customer.header' className='ml-1' />
                </Button>
              </div>
            </div>
            <div className='flex items-center justify-center mt-10 gap-5'>
              <Button
                type='submit'
                severity='info'
                className='w-52 inline-flex gap-2 items-center justify-center'
                loading={saveCustomerDetailsMutation.isPending}
                disabled={!quoteId || isLoading || saveCustomerDetailsMutation.isPending}
                onClick={() => {
                  if (!isValid) {
                    showErrorToast();
                    return;
                  }
                }}
              >
                <Translate value='save_and_proceed' />
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
