import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CustomTabView } from '../../../../components';
import { PageHeadContext } from '../../../../providers/PageHead';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer, selectLoading } from '../../../../store/slices/main/mainSlice';
import { fetchRoles } from '../../../../store/slices/role/roleSlice';
import { AddOrEditOrView } from '../../../../types';
import UserService from '../../userService';
import RolesAssignedContent from './RolesAssigned';
import Settings from './Settings';
import UserDetails from './UserDetails';

const AddUser = ({ mode }: { mode: AddOrEditOrView }) => {
  const { userId: paramUserId } = useParams<{ userId?: string }>();
  const { customerId: paramCustomerId } = useParams<{ customerId?: string }>();
  const isEdit = !!paramUserId;
  const [activeTab, setActiveTab] = useState(0);
  const context = useContext(PageHeadContext);
  const isLoading = useSelector(selectLoading);
  const { setPageHeadData } = context;
  const [userData, setUserData] = useState<User>();
  const currentCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useAppDispatch();

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  const handleNext = (user: User, changeTab = true) => {
    setUserData((prev) => ({
      ...prev,
      ...user,
    }));
    if (changeTab) {
      handleTabChange((activeTab + 1) % 3);
    }
  };

  useEffect(() => {
    setPageHeadData({
      title: 'user.list.title',
      description: 'user.list.description',
      className: 'pb-[16px] pr-4',
    });
  }, []);

  useEffect(() => {
    if (paramCustomerId) dispatch(fetchRoles(paramCustomerId));
    else if (currentCustomer) {
      dispatch(fetchRoles(currentCustomer?.id as number));
    }
    const owningCusotmerId = paramCustomerId || currentCustomer?.id;
    if (owningCusotmerId) {
      setUserData((prev) => ({
        ...(prev as User),
        owningCustomerId: owningCusotmerId as number,
      }));
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (paramUserId) {
      const fetchUserData = async () => {
        const user = await UserService.getUserById(paramUserId);
        if (user) {
          setUserData(user);
        }
      };
      fetchUserData();
    }
  }, [paramUserId]);

  const tabs = [
    {
      header: 'user_details.title',
      content: <UserDetails onNext={handleNext} mode={mode} userObjet={userData} />,
      loading: isLoading,
    },
    {
      header: 'add_user.roles_assigned',
      content: (
        <RolesAssignedContent
          mode={mode}
          userObject={userData}
          roleType='primary'
          onNext={handleNext}
        />
      ),
      loading: isLoading,
      isDisabled: !userData,
    },
    {
      header: 'aoboRoles',
      content: (
        <RolesAssignedContent
          mode={mode}
          userObject={userData}
          roleType='aobo'
          onNext={handleNext}
        />
      ),
      loading: isLoading,
      isDisabled: !userData,
    },
    { header: 'settings', content: <Settings />, loading: isLoading, isDisabled: !userData },
  ];

  return (
    <CustomTabView
      tabs={isEdit ? tabs : tabs.slice(0, 3)}
      initialIndex={activeTab}
      updateActiveIndex={setActiveTab}
    />
  );
};

export default AddUser;
