import { useMutation, UseQueryResult } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { type SubmitHandler, type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  ApprovalConfirm,
  CountrySelect,
  CurrencySelect,
  TextInput,
  WarningIcon,
} from '../../components';
import { Translate, TranslateWithValues } from '../../i18n/translate';
import { selectCountries } from '../../store/slices/refdata/refdataSlice';
import { AddOrEditOrView, Status } from '../../types';
import BeneficiaryService from './beneficiaryService';

type Props = {
  form: UseFormReturn<BeneficiaryDetailsFormType, any, undefined>;
  onCountryChange: () => void;
  onProceed?: () => void;
  onError?: () => void;
} & (
  | {
      mode: AddOrEditOrView.Approve;
      beneficiaryDetailsQuery: UseQueryResult<BeneficiaryTemplateType, Error>;
    }
  | {
      mode: Exclude<AddOrEditOrView, AddOrEditOrView.Approve>;
    }
);

export default function BeneficiaryDetails(props: Props) {
  const { form, mode, onCountryChange, onProceed, onError } = props;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasNickNameUniqueError, setHasNickNameUniqueError] = useState(false);
  const [showCurrencyMismatchWarningPopup, setShowCurrencyMismatchWarningPopup] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors, touchedFields },
    setValue,
    trigger,
  } = form;

  const selectedCustomerCountries = useSelector(selectCountries);
  const homeCurrency =
    selectedCustomerCountries.find((x) => x.code === watch('country')?.code)?.currencyCode || '';

  const isFormDisabled = mode !== AddOrEditOrView.Add;

  const validateNickNameMutation = useMutation({
    mutationFn: async (data: BeneficiaryDetailsFormType) => {
      const response = await BeneficiaryService.validateNickName(data.nickName);
      return response;
    },
    onSuccess: (data) => {
      if (!data) {
        setHasNickNameUniqueError(true);
        return;
      }
      setHasNickNameUniqueError(false);
      setIsSubmitted(true);
      onProceed?.();
    },
    onError: () => onError?.(),
  });

  const onSubmit: SubmitHandler<BeneficiaryDetailsFormType> = (data) => {
    validateNickNameMutation.mutate(data);
  };

  return (
    <div className='grid gap-8 card'>
      <div className='flex justify-between'>
        <div>
          <h2 className='text-xs-bold text-neutral-1'>
            <Translate
              value={
                mode === AddOrEditOrView.Add ? 'beneficiary_details.title' : 'beneficiary.title'
              }
            />
          </h2>
          {mode === AddOrEditOrView.Add ? (
            <div className='text-sm-regular text-neutral-3'>
              <Translate value='beneficiary_details.description' />
            </div>
          ) : null}
        </div>
        {mode === AddOrEditOrView.Approve &&
          [Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(
            props.beneficiaryDetailsQuery.data?.approvalStatus ?? Status.DEFAULT,
          ) &&
          props.beneficiaryDetailsQuery.data?.canBeApprovedByCurrentUser && (
            <ApproveRejectBeneficiary beneficiaryDetailsQuery={props.beneficiaryDetailsQuery} />
          )}
      </div>
      <fieldset disabled={isFormDisabled || isSubmitted} className='grid place-items-center gap-8'>
        <div className='grid gap-4 w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
          <div className='sm:col-span-2 md:col-span-3'>
            <TextInput
              label='beneficiary_nick_name'
              isRequired
              placeholder='beneficiary_nick_name'
              error={errors['nickName']}
              formRegister={register('nickName', {
                required: true,
                onChange: (e) => {
                  if (!touchedFields.name) {
                    setValue('name', e.target.value);
                    if (Object.keys(errors).length) {
                      trigger('name');
                    }
                  }
                },
              })}
            />
            {hasNickNameUniqueError ? (
              <Translate
                value='beneficiary_nickname_unique_error'
                className='text-sm-regular text-warning-content-default'
              />
            ) : null}
          </div>
          <TextInput
            label='beneficiary_name'
            isRequired
            placeholder='beneficiary_name'
            error={errors['name']}
            className='sm:col-span-2 md:col-span-3'
            formRegister={register('name', { required: true })}
          />
          <TextInput
            label='email'
            placeholder='email'
            error={errors['email']}
            className='sm:col-span-2 md:col-span-3'
            formRegister={register('email', {
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
            })}
          />
          <CountrySelect
            label='beneficiary_country'
            placeholder='beneficiary_country'
            name='country'
            isRequired
            control={control}
            rules={{ required: true }}
            error={errors['country']}
            onChange={(value: Country) => {
              setValue('buyCurrency', value.currencyCode || '');
              onCountryChange();
            }}
            disabled={isFormDisabled}
          />
          <CurrencySelect
            label='buy_currency'
            placeholder='buy_currency'
            name='buyCurrency'
            error={errors['buyCurrency']}
            isRequired
            control={control}
            rules={{ required: true }}
            onChange={onCountryChange}
            currencySource='buy'
            disabled={isFormDisabled}
          />
          {homeCurrency ? (
            <div className='grid gap-1'>
              <div className='label whitespace-nowrap overflow-hidden text-ellipsis inline-block'>
                <Translate value='home_currency' />
              </div>
              <div className='flex align-items-center'>
                <img
                  alt={homeCurrency}
                  src={`/images/c_flags_svg/${homeCurrency.toLocaleLowerCase()}.svg`}
                  className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
                />
                <span>{homeCurrency}</span>
              </div>
            </div>
          ) : null}
        </div>
        {mode === AddOrEditOrView.Add ? (
          <Button
            severity='info'
            loading={validateNickNameMutation.isPending}
            className='gap-2'
            onClick={(e) => {
              e.preventDefault();
              if (homeCurrency !== (watch('buyCurrency') ?? '')) {
                setShowCurrencyMismatchWarningPopup(true);
                return;
              }
              void handleSubmit(onSubmit)();
            }}
          >
            <Translate value='proceed' />
          </Button>
        ) : null}
      </fieldset>
      <Dialog
        onHide={() => setShowCurrencyMismatchWarningPopup(false)}
        draggable={false}
        visible={showCurrencyMismatchWarningPopup}
        className='rounded-xl confirm-dialog w-full max-w-[400px]'
        header={
          <div className='grid gap-4 px-5 py-5'>
            <div className='w-fit'>
              <WarningIcon />
            </div>
            <div className='grid gap-2'>
              <h2 className='text-midnightBlue text-mobile-header-bold'>
                <TranslateWithValues value='beneficiary_currency_mismatch_error' />
              </h2>
              <p className='text-sm-regular text-midnightBlue'>
                <TranslateWithValues
                  value='beneficiary_currency_mismatch_error_message'
                  params={{ currency: watch('buyCurrency') }}
                />
              </p>
            </div>
          </div>
        }
      >
        <div className='grid gap-4'>
          <Button
            severity='info'
            className='flex justify-center'
            onClick={() => {
              void handleSubmit(onSubmit)();
              setShowCurrencyMismatchWarningPopup(false);
            }}
          >
            <Translate value='Yes, select and proceed' />
          </Button>
          <Button
            severity='secondary'
            className='flex justify-center'
            onClick={() => setShowCurrencyMismatchWarningPopup(false)}
          >
            <Translate value='No, cancel' />
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

function ApproveRejectBeneficiary({
  beneficiaryDetailsQuery,
}: {
  beneficiaryDetailsQuery: UseQueryResult<BeneficiaryTemplateType, Error>;
}) {
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [apporveOrject, setApporveOrject] = useState<'approve' | 'reject'>();

  return (
    <>
      <div>
        <Button
          severity='secondary'
          onClick={(event) => {
            event.preventDefault();
            setApporveOrject('reject');
            setShowApproveConfirm(true);
          }}
        >
          <Translate value='reject' className='ml-1' />
        </Button>
        <Button
          severity='info'
          className='ml-2'
          onClick={(event) => {
            event.preventDefault();
            setApporveOrject('approve');
            setShowApproveConfirm(true);
          }}
        >
          <Translate value='approve' className='ml-1' />
        </Button>
      </div>

      <ApprovalConfirm
        showApproveConfirm={showApproveConfirm}
        setShowApproveConfirm={(v) => {
          setShowApproveConfirm(v);
          beneficiaryDetailsQuery.refetch();
        }}
        apporveOrject={apporveOrject}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        approveFn={BeneficiaryService.approveBeneficiary}
        id={beneficiaryDetailsQuery.data?.id}
        type='approval'
      />
    </>
  );
}
