import { Download, SendAlt } from '@carbon/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  ApprovalConfirm,
  Confirm,
  FormatAmount,
  Status,
  WarningIcon,
} from '../../../../components';
import CountryFlag from '../../../../components/CountryFlag';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { PageHeadContext } from '../../../../providers/PageHead';
import { useAppDispatch } from '../../../../store';
import {
  selectCurrentCustomer,
  sendToast,
  setLiverOrder,
} from '../../../../store/slices/main/mainSlice';
import {
  selectCountries,
  selectCustomerCurrencies,
} from '../../../../store/slices/refdata/refdataSlice';
import { AddOrEditOrView } from '../../../../types';
import { CustomerSubType, PaymentStatus } from '../../../../types/enum';
import { getCountryISO2, getCountryISO3 } from '../../../../utils/isoncCodeMapping';
import PaymentService from '../../paymentService';
import CountdownTimer from '../CountdownTimer';
import CustomerCreationModal from '../CustomerDetails/CustomerCreationModal';
import PaymentHistory from '../PaymentHistory';

export default function QuickQuoteSummary({ mode }: { mode: AddOrEditOrView }) {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  const [quoteId, setQuoteId] = useState<string | null>(null);
  const [quoteExpired, setQuoteExpired] = useState(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const orderId = searchParams.get('orderId') as number | null;
  const orderDetailsQuery = useQuery({
    queryKey: ['order_details', orderId],
    queryFn: async () => {
      const response = await PaymentService.getOrderById(orderId!);
      setQuoteId(response.payments[0].quote.quoteId);
      return response;
    },
    enabled: !!orderId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    setPageHeadData({
      title: 'rate_requests.title',
      description: 'rate_requests.description',
      button: (
        <>
          {mode && [AddOrEditOrView.Approve, AddOrEditOrView.View].includes(mode) && (
            <Button
              severity='secondary'
              className='h-[45px]'
              onClick={() => {
                setShowHistory(true);
              }}
            >
              <Translate value='payment.history' />
            </Button>
          )}
        </>
      ),
    });
  }, [setPageHeadData]);

  const downloadMutation = useMutation({
    mutationFn: async () => {
      const response = await PaymentService.downloadDisclosureStatement(orderId!, quoteId!);
      return response;
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'download_failed',
          detail: 'disclosure_statement_download_failed',
        }),
      );
    },
  });

  const sendDisclosureEmailMutation = useMutation({
    mutationFn: async () => {
      const response = await PaymentService.sendDisclosureEmail(orderId!);
      return response;
    },
    onSuccess: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: 'disclosure_email_sent',
        }),
      );
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'failed',
          detail: 'disclosure_email_failed',
        }),
      );
    },
  });

  if (orderDetailsQuery.isLoading) {
    return <div className='grid place-content-center'>Loading...</div>;
  }

  if (orderDetailsQuery.isError) {
    return <div className='grid place-content-center'>Error fetching order summary</div>;
  }

  if (!quoteId) {
    return <div className='grid place-content-center'>No quote id</div>;
  }

  return (
    <div className='grid gap-6 py-6'>
      <PaymentHistory showHistory={showHistory} setShowHistory={setShowHistory} orderId={orderId} />
      {mode == AddOrEditOrView.Add && (
        <div className='flex ml-auto gap-4'>
          <Button
            type='button'
            severity='contrast'
            className='gap-2'
            onClick={() => void downloadMutation.mutate()}
          >
            <Download color='var(--primary-gradient-from)' />
            <Translate value='downloadLabel' />
          </Button>
          <Button
            type='button'
            severity='contrast'
            className='gap-2'
            onClick={() => sendDisclosureEmailMutation.mutate()}
          >
            <SendAlt color='var(--primary-gradient-from)' />
            <Translate value='send_via_email' />
          </Button>
        </div>
      )}
      <div className='flex flex-col gap-5'>
        <div className='flex flex-col md:flex-row justify-between gap-5'>
          <AcceptedRate
            quoteId={quoteId}
            updateQuoteId={setQuoteId}
            quoteExpired={quoteExpired}
            setQuoteExpired={(value) => setQuoteExpired(value)}
            mode={mode}
            isMobile={true}
          />
          <CustomerDetails />
          <AcceptedRate
            quoteId={quoteId}
            updateQuoteId={setQuoteId}
            quoteExpired={quoteExpired}
            setQuoteExpired={(value) => setQuoteExpired(value)}
            mode={mode}
          />
        </div>
        <div className='col-span-2 card p-8'>
          <h2 className='text-lg-semibold text-neutral-1 pb-6'>
            <Translate value='beneficiary.title' />
          </h2>
          <BeneficiarySummary orderId={orderId!} />
        </div>
      </div>
      {mode == AddOrEditOrView.Add && orderDetailsQuery.data?.status != PaymentStatus.CANCELLED && (
        <SubmitButtons quoteId={quoteId} quoteExpired={quoteExpired} />
      )}
      {mode == AddOrEditOrView.Approve &&
        orderDetailsQuery.data?.canBeApprovedByUser &&
        orderDetailsQuery.data?.status != PaymentStatus.CANCELLED && (
          <div className='hidden md:block'>
            <ApprovalButtons quoteExpired={quoteExpired} />
          </div>
        )}
    </div>
  );
}

function ApprovalButtons({ quoteExpired }: { quoteExpired: boolean }) {
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [apporveOrject, setApporveOrject] = useState<'approve' | 'reject'>();
  const [approved, setApproved] = useState(false);
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId') as number | null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (approved) {
    return <></>;
  }

  return (
    <>
      <ApprovalConfirm
        showApproveConfirm={showApproveConfirm}
        setShowApproveConfirm={(v, _approved) => {
          setShowApproveConfirm(v);
          setApproved(_approved);
          navigate('/payment/rate-requests');
        }}
        apporveOrject={apporveOrject}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        approveFn={PaymentService.approveOrRject}
        id={orderId}
        type='payment'
      />

      <div className='flex justify-center gap-6'>
        <Button
          severity='secondary'
          className={`!px-12 disabled ${quoteExpired ? 'opacity-50' : ''}`}
          onClick={() => {
            if (quoteExpired) {
              dispatch(
                sendToast({
                  severity: 'error',
                  summary: 'error',
                  detail: 'approve.quote_expired',
                }),
              );
              return;
            }
            setApporveOrject('reject');
            setShowApproveConfirm(true);
          }}
        >
          <Translate value='reject' />
        </Button>

        <Button
          severity='info'
          className={`ml-[5px] !px-12 ${quoteExpired ? 'opacity-50' : ''}`}
          onClick={() => {
            if (quoteExpired) {
              dispatch(
                sendToast({
                  severity: 'error',
                  summary: 'error',
                  detail: 'approve.quote_expired',
                }),
              );
              return;
            }
            setApporveOrject('approve');
            setShowApproveConfirm(true);
          }}
        >
          <Translate value='approve' />
        </Button>
      </div>
    </>
  );
}

function SubmitButtons({ quoteId, quoteExpired }: { quoteId: string; quoteExpired: boolean }) {
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get('orderId') as number | null;

  const [customerOptIn, setCustomerOptIn] = useState(true);
  const [requesterOptIn, setRequesterOptIn] = useState(true);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const quickQuoteByIdQuery = useQuery<
    Awaited<ReturnType<typeof PaymentService.getQuickQuoteById>>
  >({
    queryKey: ['quick_quote', quoteId],
    retry: false,
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const initiateOrderApprovalMutation = useMutation({
    mutationFn: async () => {
      const response = await PaymentService.initiateOrderApproval(
        orderId!,
        customerOptIn,
        requesterOptIn,
      );
      return response;
    },
    onSuccess: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'order_approval.success',
        }),
      );
      dispatch(setLiverOrder({ id: orderId } as Order));
      navigate('/payment/rate-requests');
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'order_approval.failed',
          detail: error.message,
        }),
      );
    },
  });

  const abandonOrderMutation = useMutation({
    mutationFn: async () => {
      const response = await PaymentService.abandonOrder(orderId!);
      return response;
    },
    onSuccess: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: 'payment.order.success.toast',
        }),
      );
      navigate('/payment/rate-requests');
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'payment.order.error.toast',
          detail: error.message,
        }),
      );
    },
  });

  return (
    <div className='grid gap-6 mt-6 place-items-center'>
      <div className='border border-primary-light rounded-lg bg-primary-surface-light p-5 flex items-center gap-4 w-fit'>
        <Checkbox onChange={(e) => setCustomerOptIn(Boolean(e.checked))} checked={customerOptIn} />
        <div className='text-sm-semibold text-primary-dark-content'>
          <Translate value='order_approval.customer_opt_in' />
        </div>
      </div>
      <div className='border border-primary-light rounded-lg bg-primary-surface-light p-5 flex items-center gap-4 w-fit'>
        <Checkbox
          onChange={(e) => setRequesterOptIn(Boolean(e.checked))}
          checked={requesterOptIn}
        />
        <div className='text-sm-semibold text-primary-dark-content'>
          <Translate value='order_approval.requester_opt_in' />
        </div>
      </div>
      <div className='flex justify-center gap-6'>
        <Button
          type='button'
          severity='contrast'
          className='gap-2'
          loading={abandonOrderMutation.isPending}
          disabled={abandonOrderMutation.isPending}
          onClick={() => abandonOrderMutation.mutate()}
        >
          <Translate value={'return'} />
        </Button>
        <Button
          severity='info'
          className={`w-80 inline-flex gap-2 justify-center items-center ${quoteExpired ? 'opacity-50' : ''}`}
          disabled={
            quickQuoteByIdQuery.isLoading ||
            quickQuoteByIdQuery.isError ||
            initiateOrderApprovalMutation.isPending
          }
          loading={initiateOrderApprovalMutation.isPending}
          onClick={() => {
            if (quoteExpired) {
              dispatch(
                sendToast({
                  severity: 'error',
                  summary: 'error',
                  detail: 'approve.quote_expired',
                }),
              );
              return;
            }
            initiateOrderApprovalMutation.mutate();
          }}
        >
          <Translate value={'submit_for_approval'} />
        </Button>
      </div>
    </div>
  );
}

function CustomerDetails() {
  const [modalOpen, setModalOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const orderId = searchParams.get('orderId') as number | null;

  const queryClient = useQueryClient();

  const orderDetailsData = queryClient.getQueryData<
    Awaited<ReturnType<typeof PaymentService.getOrderById>>
  >(['order_details', orderId]);

  const customerDetails = orderDetailsData?.payments[0].debitor;

  if (!customerDetails) {
    return <div>No customer details</div>;
  }
  const countries = useSelector(selectCountries);

  return (
    <div className='md:min-w-[30%] card  p-8'>
      <div className='flex-initial grid gap-4 md:place-content-start'>
        <h2 className='text-lg-semibold text-neutral-1 pb-4 border-b border-neutral-5 md:border-0'>
          <Translate value='order.customer.summary.title' />
        </h2>
        {customerDetails.stoneXId && (
          <div>
            <div className='text-unselected text-sm-medium'>
              <Translate value='order.customer.summary.associate.stonx.id' />
            </div>
            <div className='text-sm-semibold text-neutral-2'>{customerDetails.stoneXId}</div>
          </div>
        )}

        <div className='flex justify-between md:flex-col md:justify-start'>
          <div className='text-unselected text-sm-medium'>
            <Translate value='order.customer.summary.customer.type' />
          </div>
          <div className='text-sm-semibold text-neutral-2'>
            <Translate value={customerDetails.customerType} />
          </div>
        </div>
        <div className='flex justify-between md:flex-col md:justify-start'>
          <div className='text-unselected text-sm-medium'>
            <Translate value='order.customer.summary.customer.name' />
          </div>
          <div className='text-sm-semibold text-neutral-2'>
            {customerDetails.customerShortName || customerDetails.customerLegalName}
          </div>
        </div>

        <div className='flex justify-between md:flex-col md:justify-start'>
          <div className='text-unselected text-sm-medium'>
            <Translate value='order.customer.summary.customer.city' />
          </div>
          <div className='text-sm-semibold text-neutral-2'>
            {customerDetails.ordCustAddressId?.city}
          </div>
        </div>
        <div className='flex justify-between md:flex-col md:justify-start'>
          <div className='text-unselected text-sm-medium'>
            <Translate value='order.customer.summary.customer.country' />
          </div>
          <div className='flex gap-2 items-center'>
            {customerDetails.ordCustAddressId?.country && (
              <>
                <img
                  alt={customerDetails.ordCustAddressId?.country ?? ''}
                  src={`/images/country_flags/${getCountryISO2(customerDetails.ordCustAddressId.country)?.toLocaleLowerCase()}.svg`}
                  className='w-[20px] h-[20px] rounded-full object-cover'
                />
                <div className='text-sm-semibold text-neutral-2'>
                  {customerDetails.ordCustAddressId.country}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='flex justify-end w-full'>
        <Button severity='help' className='!px-0 pt-0 pb-0' onClick={() => setModalOpen(true)}>
          <Translate value='view_more' className='text-primary-gradient-from' />
        </Button>
      </div>
      <CustomerCreationModal
        visible={modalOpen}
        setVisible={(value) => setModalOpen(value)}
        isViewMode
        defaultValues={{
          customerIdentifier: '',
          customerLegalName: customerDetails.customerLegalName,
          customerShortName: customerDetails.customerShortName,
          customerSubType: CustomerSubType.CONSUMER,
          address: {
            street1: customerDetails.ordCustAddressId?.street1 ?? '',
            street2: customerDetails.ordCustAddressId?.street2 ?? '',
            city: customerDetails.ordCustAddressId?.city ?? '',
            state: customerDetails.ordCustAddressId?.state ?? '',
            postalCode: customerDetails.ordCustAddressId?.postCode ?? '',
            country: countries.find(
              (country) => country.code === customerDetails.ordCustAddressId.country,
            ),
          },
          bankInfos: [
            {
              routingCode: customerDetails.bankCode,
              codeType: customerDetails.bankCodeType,
              accountNumber: customerDetails.accountNumber,
            },
          ],
          customerType: customerDetails.customerType,
          phone: customerDetails.phone,
          email: customerDetails.email,
          enableForOnline: false,
        }}
      />
    </div>
  );
}

function AcceptedRate({
  quoteId,
  updateQuoteId,
  quoteExpired,
  setQuoteExpired,
  mode,
  isMobile,
}: {
  quoteId: string;
  updateQuoteId: (quoteId: string) => void;
  quoteExpired: boolean;
  setQuoteExpired: (value: boolean) => void;
  mode?: AddOrEditOrView;
  isMobile?: boolean;
}) {
  const [searchParams] = useSearchParams();

  const [showCancelPaymentConfirmPopup, setShowCancelPaymentConfirmPopup] = useState(false);

  const orderId = searchParams.get('orderId') as number | null;

  const dispatch = useAppDispatch();

  const currencies = useSelector(selectCustomerCurrencies);
  const selectedCustomer = useSelector(selectCurrentCustomer);

  const orderDetailsQuery = useQuery<Awaited<ReturnType<typeof PaymentService.getOrderById>>>({
    queryKey: ['order_details', orderId],
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const initialQuoteDetails = orderDetailsQuery.data!.payments[0].quote;

  const quickQuoteByIdQuery = useQuery({
    queryKey: ['quick_quote', quoteId],
    queryFn: async () => {
      const response = await PaymentService.getQuickQuoteById(quoteId);
      const createdTimeWithZ = response.quote.createdTime.endsWith('Z')
        ? response.quote.createdTime
        : `${response.quote.createdTime}Z`;
      const now = new Date().getTime();
      const expiry = new Date(createdTimeWithZ).getTime() + 5 * 60 * 1000; // 5 minutes in milliseconds
      const timeLeft = Math.max(0, Math.floor((expiry - now) / 1000)); // time left in seconds
      setQuoteExpired(timeLeft <= 0);
      return response;
    },
    refetchOnWindowFocus: false,
    enabled: quoteId !== initialQuoteDetails.quoteId,
    retry: false,
  });

  const recalculateMutation = useMutation({
    mutationFn: async () => {
      const response = await PaymentService.reQuote(quoteId);
      return response;
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'recalculateQuoteError',
          detail: error.message,
        }),
      );
    },
    onSuccess: (data) => {
      updateQuoteId(data.quoteId || '');
    },
  });

  const cancelPaymentMutation = useMutation({
    mutationFn: async () => {
      const response = await PaymentService.cancelPayment(orderId!);
      return response;
    },
    onSuccess: () => {
      setShowCancelPaymentConfirmPopup(false);
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: 'payment.cancel.success.toast.title',
        }),
      );
      orderDetailsQuery.refetch();
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'payment.cancel.error.toast.title',
          detail: error.message,
        }),
      );
    },
  });

  if (quickQuoteByIdQuery.isFetching) {
    return (
      <div className='grid place-content-center'>
        <Translate value='rate_requests.requote' />
      </div>
    );
  }

  if (quickQuoteByIdQuery.isError) {
    return (
      <div className='grid place-content-center'>
        <Translate value='rate_requests.fetch_error' />
      </div>
    );
  }

  const quickQuote = quickQuoteByIdQuery.data?.quote || initialQuoteDetails;

  const payment = orderDetailsQuery.data?.payments[0];

  return (
    <div className={isMobile ? 'md:hidden ' : '' + 'flex-1 card p-8 grid gap-4'}>
      {!isMobile && (
        <>
          <div className='flex flex-col pb-4 md:pb-0 md:flex-row md:justify-between gap-4 border-b border-neutral-5 md:border-0'>
            <h2 className='text-lg-semibold text-neutral-1'>
              <Translate value='rate_requests.accepted_rate' />
            </h2>
            <div className={'flex md:justify-around items-center gap-2 min-w-fit	sm:min-w-max'}>
              {orderDetailsQuery.data?.status && <Status status={orderDetailsQuery.data?.status} />}
              {mode !== AddOrEditOrView.View &&
                (mode !== AddOrEditOrView.Approve || orderDetailsQuery.data?.canBeApprovedByUser) &&
                orderDetailsQuery.data?.status != PaymentStatus.CANCELLED && (
                  <Button
                    severity='contrast'
                    disabled={!quoteExpired || recalculateMutation.isPending}
                    loading={recalculateMutation.isPending}
                    onClick={() => recalculateMutation.mutate()}
                    className='hidden md:flex'
                  >
                    <Translate value='rate_requests.refresh_quote' />
                  </Button>
                )}
              {orderDetailsQuery.data?.status &&
                ![
                  PaymentStatus.REJECTED,
                  PaymentStatus.CANCELLED,
                  PaymentStatus.ABANDONED,
                  PaymentStatus.CREATED,
                ].includes(orderDetailsQuery.data?.status) && (
                  <Button
                    severity='danger'
                    disabled={cancelPaymentMutation.isPending}
                    onClick={() => setShowCancelPaymentConfirmPopup(true)}
                  >
                    <Translate value='payments.table.cancel_payment' />
                  </Button>
                )}
            </div>
          </div>
          <div className='flex flex-col md:flex-row gap-4'>
            <div className='flex justify-between md:flex-col md:justify-start'>
              <div className='text-sm-medium text-unselected'>
                <Translate value='rate_requests.buy_currency_and_amount' />
              </div>
              <div className='flex gap-2 items-center text-sm-semibold'>
                <img
                  alt={quickQuote.buyCurrency ?? ''}
                  src={`/images/c_flags_svg/${quickQuote.buyCurrency.toLocaleLowerCase()}.svg`}
                  className='w-[20px] h-[20px] rounded-full object-cover'
                />
                <span>{quickQuote.buyCurrency || ''}</span>
                <FormatAmount
                  number={parseFloat(quickQuote.buyAmount || '0')}
                  maximumFractionDigits={
                    currencies.find((currency) => quickQuote.buyCurrency == currency.isocode)
                      ?.amountPrecision
                  }
                />
              </div>
            </div>
            <div className='flex justify-between md:flex-col md:justify-start'>
              <div className='text-sm-medium text-unselected'>
                <Translate value='rate_requests.sell_currency_and_amount' />
              </div>
              <div className='flex gap-2 items-center text-sm-semibold'>
                <img
                  alt={quickQuote.sellCurrency ?? ''}
                  src={`/images/c_flags_svg/${quickQuote.sellCurrency.toLocaleLowerCase()}.svg`}
                  className='w-[20px] h-[20px] rounded-full object-cover'
                />
                <span>{quickQuote.sellCurrency || ''}</span>
                <FormatAmount
                  number={parseFloat((orderDetailsQuery.data || quickQuote).sellAmount || '0')}
                  maximumFractionDigits={
                    currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                      ?.amountPrecision
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
      {mode !== AddOrEditOrView.View && quoteExpired && !isMobile && (
        <div className='ml-auto w-fit'>
          <Status status='QuoteExpired' />
        </div>
      )}
      <div className='block lg:flex lg:justify-between'>
        {!isMobile && (
          <div className='grid grid-cols-[auto_1fr] gap-x-16 gap-y-4'>
            <div>
              <Translate value='orderId' />
            </div>
            <div className='text-neutral-2 text-right md:text-left'> {quickQuote.orderId}</div>
            <div>
              <Translate value='buy_amount' />
            </div>
            <div className='text-neutral-2 text-right md:text-left'>
              <FormatAmount
                number={parseFloat(quickQuote.buyAmount || '0')}
                maximumFractionDigits={
                  currencies.find((currency) => quickQuote.buyCurrency == currency.isocode)
                    ?.amountPrecision
                }
              />{' '}
              {quickQuote.buyCurrency}
            </div>
            <div>
              <Translate value='rate_requests.exchange_rate' />
            </div>
            <div className='text-neutral-2 text-right md:text-left'>
              1 {quickQuote.sellCurrency} ={' '}
              <FormatAmount
                number={parseFloat(
                  (quickQuote.direction == 'IN' ? quickQuote.inverseRate : quickQuote?.rate) ?? '0',
                )}
                maximumFractionDigits={
                  currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                    ?.ratePrecision
                }
              />{' '}
              {quickQuote.buyCurrency}
            </div>
            <div>
              <Translate value='inverse' />
            </div>
            <div className='text-neutral-2 text-right md:text-left'>
              {quickQuote.inverseRate ?? '-'}
            </div>
            <div>
              <Translate value='sellAmount' />
            </div>
            <div className='text-neutral-2 text-right md:text-left'>
              <FormatAmount
                number={parseFloat((orderDetailsQuery.data || quickQuote).sellAmount || '0')}
                maximumFractionDigits={
                  currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                    ?.amountPrecision
                }
              />{' '}
              {quickQuote.sellCurrency}
            </div>
            <div>
              <Translate value='rate_requests.fee' />
            </div>
            <div className='text-neutral-2 text-right md:text-left'>
              <FormatAmount
                number={quickQuote.fee || 0}
                maximumFractionDigits={
                  currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                    ?.amountPrecision
                }
              />{' '}
              {quickQuote.feeCurrency}
            </div>
            <div>
              <Translate value='rate_requests.total_amount_payable' />
            </div>
            <div className='text-neutral-2 text-right md:text-left'>
              <FormatAmount
                number={quickQuote.totalAmountWithFee || 0}
                maximumFractionDigits={
                  currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                    ?.amountPrecision
                }
              />{' '}
              {quickQuote.sellCurrency}
              {(quickQuote?.fee ?? 0) > 0 && quickQuote.feeCurrency != quickQuote.sellCurrency && (
                <>
                  <div>
                    {' '}
                    <FormatAmount
                      number={quickQuote.fee || 0}
                      maximumFractionDigits={
                        currencies.find((currency) => quickQuote.feeCurrency == currency.isocode)
                          ?.amountPrecision
                      }
                    />
                    {quickQuote.fee} <span>{quickQuote.feeCurrency}</span>
                  </div>
                </>
              )}
            </div>
            {(payment?.uetr || payment?.gpiStatus || payment?.gpiCode) && (
              <div className='flex justify-between col-span-2'>
                {payment.uetr && (
                  <div className='grid'>
                    <Translate value='uetr' />
                    <div className='text-neutral-2'>{payment.uetr}</div>
                  </div>
                )}
                {payment.gpiStatus && (
                  <div className='grid'>
                    <Translate value='gpi_status' />
                    <div className='text-neutral-2'>{payment.gpiStatus}</div>
                  </div>
                )}
                {payment.gpiCode && (
                  <div className='grid'>
                    <Translate value='gpi_code' />
                    <div className='text-neutral-2'>{payment.gpiCode}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div
          className={
            (!isMobile ? 'hidden md:block' : 'block') +
            ' items-center justify-between gap-2 bg-primary-surface-light rounded-[8px] px-4 py-5'
          }
        >
          <div
            className={
              (!isMobile ? 'hidden md:flex' : 'flex') +
              ' items-center justify-between gap-2 lg:gap-14'
            }
          >
            <div className='grid'>
              <Translate value='quote_received' className='text-primary-dark-content' />
              <span className='text-primary-blue-surface-dark text-2xl-bold'>
                <FormatAmount
                  number={parseFloat((orderDetailsQuery.data || quickQuote).sellAmount || '0')}
                  maximumFractionDigits={
                    currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                      ?.amountPrecision
                  }
                />
                {' ' + quickQuote.sellCurrency}
              </span>
              <div className={'flex flex-col gap-2'}>
                <div className='bg-primary-blue text-primary-dark-content text-sm-medium w-fit h-fit px-2 py-1 sm:p-2 rounded-2xl sm:rounded-lg'>
                  At 1 {quickQuote.sellCurrency + ' = '}
                  <FormatAmount
                    number={parseFloat(
                      (quickQuote.direction == 'IN' ? quickQuote.inverseRate : quickQuote?.rate) ??
                        '0',
                    )}
                    maximumFractionDigits={
                      currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                        ?.ratePrecision
                    }
                  />
                  {' ' + quickQuote.buyCurrency}
                </div>
                {quickQuote.profit != undefined &&
                  selectedCustomer?.customerType !== 'ORDERING_CUSTOMER' && (
                    <div
                      className={
                        (quickQuote.profit >= 0
                          ? 'border-success-content-default text-success-content-default '
                          : 'border-error-content-default text-error-content-default ') +
                        'rounded-3xl sm:rounded-lg border-[1px] text-sm-medium sm:text-lg-semibold flex items-center px-2 sm:px-2 py-1 text-nowrap h-fit self-start'
                      }
                    >
                      <span>
                        {quickQuote.profit >= 0
                          ? translateWithValues('profit')
                          : translateWithValues('loss')}{' '}
                        <FormatAmount
                          number={parseFloat(String(quickQuote?.profit) || '0')}
                          maximumFractionDigits={
                            currencies.find(
                              (currency) => quickQuote.sellCurrency == currency.isocode,
                            )?.amountPrecision
                          }
                        />
                        {' ' + quickQuote.sellCurrency}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            {mode !== AddOrEditOrView.View &&
              quickQuote?.createdTime &&
              (mode !== AddOrEditOrView.Approve || orderDetailsQuery.data?.canBeApprovedByUser) &&
              orderDetailsQuery.data?.status != PaymentStatus.CANCELLED && (
                <CountdownTimer
                  createdTime={quickQuote?.createdTime}
                  onTimeOut={() => {
                    dispatch(
                      sendToast({
                        severity: 'error',
                        summary: 'alert',
                        detail: 'quoteExpired',
                      }),
                    );
                    setQuoteExpired(true);
                  }}
                />
              )}
          </div>
          {mode !== AddOrEditOrView.View &&
            (mode !== AddOrEditOrView.Approve || orderDetailsQuery.data?.canBeApprovedByUser) &&
            orderDetailsQuery.data?.status != PaymentStatus.CANCELLED && (
              <Button
                severity='contrast'
                disabled={!quoteExpired || recalculateMutation.isPending}
                loading={recalculateMutation.isPending}
                onClick={() => recalculateMutation.mutate()}
                className='flex justify-center w-full mt-2 md:hidden'
              >
                <Translate value='rate_requests.refresh_quote' />
              </Button>
            )}
        </div>
        {mode == AddOrEditOrView.Approve &&
          isMobile &&
          (mode !== AddOrEditOrView.Approve || orderDetailsQuery.data?.canBeApprovedByUser) &&
          orderDetailsQuery.data?.status != PaymentStatus.CANCELLED && (
            <div className='mt-2 md:hidden'>
              <ApprovalButtons quoteExpired={quoteExpired} />
            </div>
          )}
      </div>
      {showCancelPaymentConfirmPopup && (
        <Confirm
          confirmLabel='payments.table.cancel_payment'
          headerIcon={<WarningIcon />}
          title='payments.table.cancel_payment.confirm.title'
          message='payments.table.cancel_payment.confirm.message'
          confirmButtonSeverify='danger'
          onCancel={() => {
            setShowCancelPaymentConfirmPopup(false);
          }}
          onConfirm={() => cancelPaymentMutation.mutate()}
          visible={showCancelPaymentConfirmPopup}
          loading={cancelPaymentMutation.isPending}
        />
      )}
    </div>
  );
}

export function BeneficiarySummary({ orderId }: { orderId: number }) {
  const queryClient = useQueryClient();

  const orderDetailsData = queryClient.getQueryData<
    Awaited<ReturnType<typeof PaymentService.getOrderById>>
  >(['order_details', orderId]);

  const orderDetailsQuery = useQuery({
    queryKey: ['order_details', orderId],
    queryFn: async () => {
      const response = await PaymentService.getOrderById(orderId);
      return response;
    },
    enabled: !!orderId && !orderDetailsData,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const beneficiaryDetails = orderDetailsQuery.data?.payments[0]?.creditor;

  const beneficiaryDataQuery = useQuery({
    queryKey: [
      'request_quote_beneficiary',
      beneficiaryDetails?.beneAddress.country,
      orderDetailsQuery.data?.payments[0].buyCurrency,
    ],
    queryFn: async () => {
      const data = await PaymentService.getBeneficiaryFormByCountry(
        getCountryISO2(beneficiaryDetails!.beneAddress.country),
        orderDetailsQuery.data!.payments[0].buyCurrency,
      );
      return data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!beneficiaryDetails,
  });

  if (orderDetailsQuery.isLoading || beneficiaryDataQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (orderDetailsQuery.isError) {
    return <div>Error loading order details</div>;
  }

  if (!orderDetailsQuery.data) {
    return <div>No order details</div>;
  }

  if (!beneficiaryDetails) {
    return <div>No beneficiary details</div>;
  }

  if (beneficiaryDataQuery.isError) {
    return <div>Error loading beneficiary data</div>;
  }

  if (!beneficiaryDataQuery.data || beneficiaryDataQuery.data.length === 0) {
    return <div>No beneficiary data</div>;
  }

  const buyCurrency = orderDetailsQuery.data.payments[0].buyCurrency;

  function groupByOrder(arr: Array<BeneficiaryType>) {
    const grouped = arr.reduce<Record<number, Array<string>>>((acc, obj) => {
      if (!acc[obj.order]) {
        acc[obj.order] = [];
      }
      acc[obj.order].push(obj.fieldIdentifier);
      return acc;
    }, {});

    return Object.entries(grouped).map(([key, value]) => {
      return { [key]: value };
    });
  }

  const mainFieldElements: Array<BeneficiaryType> = [];
  const additionalFieldElements: Array<BeneficiaryType> = [];

  beneficiaryDataQuery.data?.forEach((beneficiary) => {
    if (
      !Object.keys(beneficiaryDetails.additionalFieldsMap).includes(beneficiary.fieldIdentifier)
    ) {
      return;
    }

    if (beneficiary.displaySection === 'Beneficiary Details') {
      mainFieldElements.push(beneficiary);
    } else {
      additionalFieldElements.push(beneficiary);
    }
  });

  const fields = [...groupByOrder(mainFieldElements), ...groupByOrder(additionalFieldElements)];

  return (
    <div>
      <div className='py-6 border-y border-neutral-border'>
        <div className='text-md-semibold text-unselected mb-5'>
          <Translate value='rate_requests.beneficiary_currency_and_country' />
        </div>
        <div className='grid gap-5 grid-cols-1 md:grid-cols-3'>
          <div className='flex justify-between md:flex-col md:justify-start'>
            <div className='text-sm-medium text-unselected'>
              <Translate value='currency' />
            </div>
            <div className='flex gap-2 items-center'>
              <img
                alt={buyCurrency ?? ''}
                src={`/images/c_flags_svg/${buyCurrency.toLocaleLowerCase()}.svg`}
                className='w-[20px] h-[20px] rounded-full object-cover'
              />
              <span>{buyCurrency || ''}</span>
            </div>
          </div>
          {beneficiaryDetails.beneAddress.country && (
            <div>
              <div className='text-sm-medium text-unselected'>
                <Translate value='beneficiary_country' />
              </div>
              <CountryFlag countryCode={beneficiaryDetails.beneAddress?.country} />
            </div>
          )}
        </div>
      </div>
      <div className='py-6'>
        <div className='text-md-semibold text-unselected mb-5'>
          <Translate value='rate_requests.beneficiary_bank_details' />
        </div>
        {beneficiaryDetails.additionalFieldsMap ? (
          <div className='grid gap-5 grid-cols-1 md:grid-cols-3'>
            {fields.map((order, index) => (
              <React.Fragment key={index}>
                {Object.values(order)[0].map((beneficiary, i) => (
                  <div key={i} className='flex justify-between md:flex-col md:justify-start'>
                    <Translate className='text-sm-medium text-unselected' value={beneficiary} />
                    {beneficiary === 'BeneficiaryAddressCountry' ? (
                      <div>
                        <div className='flex gap-2 items-center'>
                          <img
                            alt={beneficiaryDetails.additionalFieldsMap[beneficiary] ?? ''}
                            src={`/images/country_flags/${beneficiaryDetails.additionalFieldsMap[beneficiary]?.toLocaleLowerCase()}.svg`}
                            className='w-[20px] h-[20px] rounded-full object-cover'
                          />
                          <span>
                            {getCountryISO3(beneficiaryDetails.additionalFieldsMap[beneficiary])}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div>{beneficiaryDetails.additionalFieldsMap[beneficiary]}</div>
                    )}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
