import { DropdownChangeEvent } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ClientService from '../../modules/Client/clientService';
import { useAppDispatch } from '../../store';
import { fethCurrentUser } from '../../store/slices/main/actions';
import {
  selectAoboClient,
  selectAoboClientFilterType,
  selectAoboSOurce,
  selectCurrentCustomer,
  selectHierarchyChildren,
  selectUpdateAoboList,
  sendToast,
  setAoboClient,
  setAoboClientFilterType,
  setAoboSource,
  setCurrentCustomer,
  setHieirarchyChildren,
} from '../../store/slices/main/mainSlice';
import { CustomerType } from '../../types';
import { injectAoboCustoemrID } from '../../utils/apiClient';
import Confirm from '../Confirm';
import DropdownInput from '../DropdownInput';
import WarningIcon from '../WarningIcon';

interface Option {
  value: string;
  label: string;
}

export const AoboDropdown = ({ fromAoboHeader }: { fromAoboHeader?: boolean }) => {
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [customers, setCustomers] = useState<Option[]>([]);
  const [customerTypeOptions, setCustomerTypeOptions] = useState<Option[]>([]);
  const [hierarchyCustomers, setHierarchyCustomers] = useState<Client[] | undefined>([]);
  const [parentClient] = useState<Client | null>(selectedCustomer);
  const selectedAoboClient = useSelector(selectAoboClient);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const aoboListUpdate = useSelector(selectUpdateAoboList);
  const hierarchyChildren = useSelector(selectHierarchyChildren);
  const aoboSource = useSelector(selectAoboSOurce);
  const selectedAoboFilterType = useSelector(selectAoboClientFilterType);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownSelect, setDropdownSelect] = useState<DropdownChangeEvent | undefined>();

  useEffect(() => {
    if (parentClient || (fromAoboHeader && aoboSource)) {
      const parent = parentClient || aoboSource;
      if (parent?.customerType == CustomerType.SERVICE_PROVIDER) {
        dispatch(setAoboClientFilterType(CustomerType.INSTRUCTING_INSTITUTION));
        setCustomerTypeOptions(CUSTOMER_TYPE_OPTIONS);
      } else if (parent?.customerType === CustomerType.INSTRUCTING_INSTITUTION) {
        const filterered = CUSTOMER_TYPE_OPTIONS.filter((i) =>
          [
            'All',
            CustomerType.ORDERING_INSTITUTION.toString(),
            CustomerType.ORDERING_CUSTOMER.toString(),
          ].includes(i.value),
        );
        setCustomerTypeOptions(filterered);
      } else {
        setCustomerTypeOptions([]);
      }
    }
  }, [parentClient, aoboSource]);

  useEffect(() => {
    if (!fromAoboHeader && !selectedAoboClient) {
      getChildrenCustomers();
    }
    if (fromAoboHeader) {
      let options = hierarchyChildren
        ?.filter((c) => {
          if (selectedAoboFilterType == 'All' || !selectedAoboFilterType) {
            return true;
          }
          return c.customerType == selectedAoboFilterType;
        })
        .map((child) => ({
          value: child.id?.toString() || '',
          label: child.customerShortName ?? child.customerLegalName,
        }));
      options = options.sort((a, b) => a.label?.localeCompare(b.label));
      setCustomers(options || []);
    }
  }, [aoboListUpdate, selectedAoboClient]);

  const getAllChildren = (node: Client): Client[] => {
    const children = node.children || [];
    return children.reduce((acc: Client[], child: Client) => {
      acc.push({ ...child, parent: node });
      return acc.concat(getAllChildren(child));
    }, []);
  };

  const getChildrenCustomers = async () => {
    if (selectedCustomer && selectedCustomer.id) {
      setIsLoading(true);
      const hierarchy = await ClientService.getCustomerHierarchy(selectedCustomer.id);
      setIsLoading(false);
      if (hierarchy) {
        const allChildren = hierarchy ? getAllChildren(hierarchy) : [];
        dispatch(setHieirarchyChildren(allChildren));
        setHierarchyCustomers(allChildren);
        setAoboSource(selectedCustomer);
        let options = allChildren?.map((child) => ({
          value: child.id?.toString() || '',
          label: child.customerShortName ?? child.customerLegalName,
        }));
        options = options.sort((a, b) => a.label?.localeCompare(b.label));
        setCustomers(options || []);
      }
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const CUSTOMER_TYPE_OPTIONS = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Instructing Institution',
      value: CustomerType.INSTRUCTING_INSTITUTION,
    },
    {
      label: 'Ordering Institution',
      value: CustomerType.ORDERING_INSTITUTION,
    },
    {
      label: 'Ordering Customer',
      value: CustomerType.ORDERING_CUSTOMER,
    },
  ];

  const exitAobo = () => {
    if (!selectedAoboClient) {
      return;
    }
    injectAoboCustoemrID(null);
    showToast('success', 'aobo.remove.title', 'aobo.remove.description');
    dispatch(fethCurrentUser());
    dispatch(setCurrentCustomer(parentClient));
    dispatch(setAoboClient(undefined));
    dispatch(setAoboClientFilterType('All'));
    setDropdownSelect(undefined);
    navigate('/');
  };

  if (isLoading) {
    return <></>;
  }

  const handleDropdownChange = (e: DropdownChangeEvent) => {
    dispatch(setAoboClient(customers.find((customer) => customer.value === e.value)));
    const label = customers.find((customer) => customer.value === e.value)?.label;
    showToast('success', 'aobo.title', 'aobo.description', {
      clientName: label || '',
    });
    injectAoboCustoemrID(parseInt(e.value || ''));
    dispatch(fethCurrentUser());
    const aoboClient = hierarchyChildren?.find((client) => client.id == e.value);
    if (aoboClient) {
      dispatch(setCurrentCustomer({ ...aoboClient, aobo: true }));
    }
    navigate('/');
  };

  return (
    <>
      <Confirm
        headerIcon={<WarningIcon />}
        title='aobo.change.title'
        message='aobo.change.description'
        visible={!!dropdownSelect}
        i18nParameters={{
          currentClientName: selectedAoboClient?.label || '',
          newClientName:
            customers.find((customer) => customer.value === dropdownSelect?.value)?.label || '',
        }}
        onConfirm={() => {
          setDropdownSelect(undefined);
          if (dropdownSelect) {
            handleDropdownChange(dropdownSelect);
          }
        }}
        onCancel={() => setDropdownSelect(undefined)}
      />
      {((parentClient && hierarchyCustomers?.length != 0) || hierarchyChildren.length != 0) && (
        <>
          {customerTypeOptions.length != 0 && !fromAoboHeader && (
            <DropdownInput
              className='mr-1 min-w-[200px] aobo-dropdown hidden lg:flex'
              options={customerTypeOptions}
              placeholder='select.aobo.customer.type'
              onChange={(e) => {
                exitAobo();
                dispatch(setAoboClientFilterType(e.value));
                if (e.value === 'All') {
                  let options = (fromAoboHeader ? hierarchyChildren : hierarchyCustomers)?.map(
                    (child) => ({
                      value: child.id?.toString() || '',
                      label: child.customerShortName ?? child.customerLegalName,
                    }),
                  );
                  options = options?.sort((a, b) => a.label?.localeCompare(b.label));
                  setCustomers(options || []);
                  return;
                }
                const filteredCustomers = (
                  fromAoboHeader ? hierarchyChildren : hierarchyCustomers
                )?.filter((client) => client.customerType == e.value);
                let options = filteredCustomers?.map((child) => ({
                  value: child.id?.toString() || '',
                  label: child.customerShortName ?? child.customerLegalName,
                }));
                options = options?.sort((a, b) => a.label?.localeCompare(b.label));
                setCustomers(options || []);
              }}
              value={selectedAoboFilterType?.toString()}
            ></DropdownInput>
          )}

          <DropdownInput
            onChange={(e) => {
              if (fromAoboHeader) {
                setDropdownSelect(e);
              } else {
                handleDropdownChange(e);
              }
            }}
            className='min-w-[250px] aobo-dropdown hidden sm:flex'
            placeholder='select.aobo.customer'
            options={customers}
            value={selectedAoboClient?.value}
            filter={true}
          ></DropdownInput>
        </>
      )}
    </>
  );
};
