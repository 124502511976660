let countries: Country[] = [];

export function injectCoutries(_countries: Country[]) {
  countries = _countries;
}

export function getCountryISO2(countryCode: string) {
  const country = countries.find((c) => c.code === countryCode);
  return country ? country.isoCode : '';
}

export function getCountryISO3(countryCode: string) {
  const country = countries.find((c) => c.isoCode === countryCode);
  return country ? country.code : '';
}
