import { Menu } from 'primereact/menu';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import useRole from '../../hooks/useRoles';
import { Translate } from '../../i18n/translate';
import { useAppDispatch } from '../../store';
import { selectCustomerSettings, sendToast } from '../../store/slices/main/mainSlice';
import './Menu.css';

interface MenuProps {
  items: MenuItem[];
  popupAlign?: 'left' | 'right';
}
// eslint-disable-next-line react/display-name
const InheritedMenu = forwardRef<Menu | null, MenuProps>(({ items, popupAlign = 'left' }, ref) => {
  const { hasRole } = useRole();
  const dispatch = useAppDispatch();
  const customerSettings = useSelector(selectCustomerSettings);

  const updatedItems = items.map((item) => ({
    ...item,
    template: (
      <_Template
        disabled={item.disabled ?? false}
        label={item.label}
        icon={item.icon}
        className={item.className ?? ''}
        template={item.template}
        onClick={(event: React.MouseEvent) => {
          if (item.disabled) {
            dispatch(
              sendToast({
                severity: 'error',
                summary: 'Warning',
                detail: 'item.disabled.action',
              }),
            );
            return;
          }
          if (item.onClick) {
            item.onClick(event);
          }
          if (ref && 'current' in ref && ref.current) {
            ref.current.toggle(event);
          }
          event.stopPropagation();
        }}
      />
    ),
  }));

  const filterRoles = updatedItems.filter((i) => (i.roles ? hasRole(i.roles) : true));
  const filterByCustomerSettings = filterRoles.filter(
    (item) => !item.configKey || customerSettings?.[item.configKey as keyof SettingsData],
  );
  return filterByCustomerSettings.length > 0 ? (
    <Menu model={filterByCustomerSettings} popup ref={ref} popupAlignment={popupAlign} />
  ) : null;
});

const _Template = ({
  disabled,
  label,
  icon,
  className,
  template,
  onClick,
}: {
  disabled: boolean;
  label: string;
  icon: React.ReactNode;
  className: string;
  template: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
}) => {
  if (template) {
    return <>{template}</>;
  }

  return (
    <div className='p-menuitem-content' onClick={onClick}>
      <span
        className={`p-menuitem-link ${className} ${disabled ? 'opacity-[.4] !pointer-events-none' : ''}`}
      >
        {icon} <span className={`${icon ? 'ml-2' : ''}`}>{<Translate value={label} />}</span>
      </span>
    </div>
  );
};

export default InheritedMenu;
