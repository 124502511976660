import { createAsyncThunk } from '@reduxjs/toolkit';
import CurrencyService from '../../../modules/Configuration/Currency/Dashboard/currencyService';
import { RefdataService } from '../../../utils/refdataService';

// Define the async action to fetch currency data
export const fetchCurrency = createAsyncThunk<CurrencyConfiguration[], number>(
  'refData/fetchCurrency',
  async (customerId) => {
    const response = await CurrencyService.getCurrencies(customerId);
    const processedResponse = response.map((currency) => ({
      ...currency,
      status: currency.disabled ? 'INACTIVE' : 'ACTIVE',
    }));
    return processedResponse;
  },
);

export const fetchAppRefdata = createAsyncThunk<MetaData | null>(
  'refData/fetchAppRefdata',
  async (): Promise<MetaData | null> => {
    const response = await RefdataService.getMetaData();
    return response;
  },
);

export const fetchSellCurrency = createAsyncThunk<string[], number>(
  'refData/fetchSellCurrency',
  async (customerId) => {
    const response = await CurrencyService.getSellCurrencies(customerId);
    return response;
  },
);

export const fetchBuyCurrency = createAsyncThunk<string[], number>(
  'refData/fetchBuyCurrency',
  async (customerId) => {
    const response = await CurrencyService.getBuyCurrencies(customerId);
    return response;
  },
);

export const fetchCountryList = createAsyncThunk<StonexCountry[]>(
  'country-management/countries',
  async () => {
    const response = await RefdataService.getCountryList();
    return response;
  },
);