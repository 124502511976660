import { RoleGuard } from '../../components';
import { RouteRoles } from '../../RouteRoles';
import ApprovalsOutlet from './Approvals';
import approvalsRoutes from './Approvals/routes';
import RoleOutlet from './Role';
import roleRoutes from './Role/routes';
import UserOutlet from './User';
import userRoutes from './User/routes';

const manageRoutes = [
  {
    id: 'user',
    path: 'user',
    handle: {
      breadcrumb: 'User',
    },

    element: (
      <RoleGuard roles={RouteRoles.User} checkUserMenuCondition>
        <UserOutlet />
      </RoleGuard>
    ),
    children: userRoutes,
  },
  {
    id: 'role',
    path: 'role',
    handle: {
      breadcrumb: 'Role',
    },
    element: (
      <RoleGuard roles={RouteRoles.Roles}>
        <RoleOutlet />
      </RoleGuard>
    ),
    children: roleRoutes,
  },
  {
    id: 'approvals',
    path: 'approvals',
    handle: {
      breadcrumb: 'Approvals',
    },
    element: (
      <RoleGuard roles={RouteRoles.approvals}>
        <ApprovalsOutlet />
      </RoleGuard>
    ),
    children: approvalsRoutes,
  },
];

export default manageRoutes;
