import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useRole from '../../../../hooks/useRoles';
import { Translate } from '../../../../i18n/translate';
import { PageHeadContext } from '../../../../providers/PageHead';
import paymentRole from '../../paymentRole';
import PaymentsTable from './PaymentsTable';

const Dashboard = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = useState(0);
  const { hasRole } = useRole();
  useEffect(() => {
    setPageHeadData({
      className: 'py-[8px]',
      title: 'payment_requests.title',
      description: 'payment_requests.description',
      button: hasRole(paymentRole.createPayments) && (
        <div>
          <Button
            severity='info'
            onClick={() => {
              navigate('/payment/rate-requests/add');
            }}
          >
            <Translate value='request_new_quote' />
          </Button>
        </div>
      ),
    });
  }, []);

  return (
    <>
      <PaymentsTable totalRecords={totalRecords} setTotalRecords={setTotalRecords} />
    </>
  );
};

export default Dashboard;
