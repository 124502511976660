import { useContext, useEffect, useState } from 'react';
import { PageHeadContext } from '../../../providers/PageHead';
import PaymentBar from './PaymentBar';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { useForm } from 'react-hook-form';
import { CustomFileUpload, DropdownInput } from '../../../components';
import TextArea from '../../../components/TextArea';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../store';
import { sendToast } from '../../../store/slices/main/mainSlice';
import FileList from './FileList';
import { useQuery } from '@tanstack/react-query';
import CaseService from '../caseService';
import { TicketType } from '../../../types';

interface CaseFormProps {
  onProceed: (formData: CaseDetailsFormData) => void;
  payment?: Order;
  ticketType: TicketType;
}

const CaseForm = ({ onProceed, payment, ticketType }: CaseFormProps) => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    setPageHeadData({
      title: 'create_new_case',
      description: 'create_new_case.form_description',
    });
  }, [setPageHeadData]);

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    unregister,
  } = useForm<CaseDetailsFormData>();

  const topicCode = watch('topicCode');

  useEffect(() => {
    unregister('reasonCode');
  }, [topicCode]);

  const { data, isLoading } = useQuery({
    queryKey: ['case_topics', ticketType],
    queryFn: async () => {
      const data = await CaseService.getTopics(ticketType);
      if (data) {
        const topics: TopicItem[] = data.map((topic) => ({
          label: topic.topic_Names.enus,
          value: topic.topic_Code,
        }));

        const reasonsMap: Record<string, ReasonItem[]> = data.reduce(
          (acc: Record<string, ReasonItem[]>, topic) => {
            acc[topic.topic_Code] = topic.reasons.map((reason) => ({
              label: reason.reason_Names.enus,
              value: reason.reason_Code,
            }));
            return acc;
          },
          {} as Record<string, ReasonItem[]>,
        );
        return {
          topics,
          reasonsMap,
        };
      }
    },
  });

  const topics = data?.topics || [];
  const reasonsMap = data?.reasonsMap || {};

  const saveCaseDetails = (formData: CaseDetailsFormData) => {
    if (!isValid) {
      return;
    }
    const caseData = {
      ...formData,
      files,
      topic: topics?.find((topic) => topic.value === formData.topicCode)?.label,
      reason: reasonsMap[formData.topicCode]?.find((reason) => reason.value === formData.reasonCode)
        ?.label,
      fxid: payment?.payments[0].quote.fxId,
    };
    onProceed(caseData);
  };

  const showErrorToast = () => {
    showToast('error', 'case.field.validation.error', '');
  };

  const removeFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div>
      {ticketType === TicketType.INVESTIGATION && <PaymentBar payment={payment} />}
      {/*eslint-disable-next-line @typescript-eslint/no-misused-promises*/}
      <form onSubmit={handleSubmit(saveCaseDetails)}>
        <div className='dashboard-padding mt-4'>
          <div className='card'>
            <div className='text-neutral-1 text-sm-bold'>
              <Translate value='please_select_case_topic_and_reason' />
              <span className='text-error-1'> *</span>
            </div>
            <div className='text-neutral-3 text-md-regular'>
              <Translate value='please_make_sure_you_select_right_topic' />
            </div>
            {isLoading ? (
              <div className='mt-4'>{translateWithValues('loading_topics_and_reasons')}</div>
            ) : (
              <div className='grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-10 py-6'>
                <div className='col-span-1'>
                  <DropdownInput
                    label='select_case_topic'
                    placeholder='select_case_topic'
                    name='topicCode'
                    isRequired
                    control={control}
                    rules={{ required: true }}
                    error={errors['topicCode']}
                    options={topics}
                  />
                </div>
                <div className='col-span-1'>
                  <DropdownInput
                    disabled={!topicCode}
                    label='select_case_reason'
                    placeholder='select_case_reason'
                    name='reasonCode'
                    isRequired
                    control={control}
                    rules={{ required: true }}
                    error={errors['reasonCode']}
                    options={reasonsMap[topicCode]}
                  />
                </div>
              </div>
            )}
            <div className='md:w-5/6 mt-2'>
              <TextArea
                label='description'
                placeholder='enter_a_description'
                id={'description'}
                name={'description'}
                control={control}
                rules={{ required: true }}
                isRequired
                error={errors['description']}
              />
            </div>
          </div>
          <div className='card mt-8'>
            <div className='text-neutral-1 text-sm-bold'>
              <Translate value='attach_supporting_documents' />
            </div>
            <div className='text-neutral-3 text-md-regular'>
              <Translate value='attach_supporting_documents.subtitle' />
            </div>
            <div className='h-56'>
              <CustomFileUpload
                onFilesSelected={(newFiles: FileList) => {
                  if (files.length + newFiles.length > 5) {
                    showToast('error', 'cannot_upload_more_than_5_files', '');
                  } else {
                    setFiles([...files, ...newFiles]);
                  }
                }}
                accept='.doc,.docx,.xls,.xlsx,.jpg,.pdf,.png'
                multiple
              />
            </div>
            <FileList files={files} removeFile={removeFile} />
          </div>
          <div className='flex gap-4 justify-center mt-8'>
            <Button
              severity='contrast'
              onClick={() => {
                navigate('/get-help');
              }}
            >
              <Translate value='cancel' />
            </Button>
            <Button
              severity='info'
              type='submit'
              onClick={() => {
                if (!isValid) {
                  showErrorToast();
                }
              }}
            >
              <Translate value='proceed' />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CaseForm;
