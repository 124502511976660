import { Dropdown } from 'primereact/dropdown';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Translate, translateWithValues } from '../../i18n/translate';
import {
  selectBuyCurrencies,
  selectCustomerCurrencies,
  selectSellCurrencies,
} from '../../store/slices/refdata/refdataSlice';
import ErrorIcon from '../ErrorIcon';
import MultiSelectInput from '../MultiSelect';

interface Currency {
  label: string;
  value: string;
}

interface CurrencyDropdownProps {
  id?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  className?: string;
  value?: string;
  name?: string;
  control?: any;
  error?: any;
  rules?: any;
  includeAll?: boolean;
  currencySource?: 'default' | 'sell' | 'buy';
  isMulti?: boolean;
  multiSelectValue?: string[];
  onChange?: any;
  isEditable?: boolean;
  interSectSellCurrencies?: string[];
}

const CurrencySelect = ({
  label,
  disabled,
  id,
  isRequired,
  ariaDescribedBy,
  placeholder,
  className,
  value,
  rules,
  name,
  error,
  control,
  includeAll = false,
  currencySource = 'default',
  isMulti = false,
  multiSelectValue,
  onChange,
  isEditable = true,
  interSectSellCurrencies,
}: CurrencyDropdownProps) => {
  const [currencies, setCurrencies] = useState<any>([]);
  const customerCurrencies = useSelector(selectCustomerCurrencies);
  const sellCurrencies = useSelector(selectSellCurrencies);
  const buyCurrencies = useSelector(selectBuyCurrencies);

  useEffect(() => {
    if (currencySource === 'default' && customerCurrencies) {
      configureCustomerCurrencies();
    } else if (currencySource === 'sell' && sellCurrencies) {
      configureSellCurrencies();
    } else if (currencySource === 'buy' && buyCurrencies) {
      configureBuyerCurrencies();
    }
  }, [customerCurrencies, currencySource, sellCurrencies, buyCurrencies, interSectSellCurrencies]);

  const configureCustomerCurrencies = () => {
    const _currencies: Currency[] = Array.from(
      new Set(
        customerCurrencies
          .filter((c) => {
            return !c.disabled;
          })
          .map((currency) => currency.isocode),
      ),
    ).map((isocode) => ({
      label: isocode,
      value: isocode,
    }));

    _currencies.sort((a, b) => {
      if (a.value && b.value) {
        return a.value.localeCompare(b.value);
      }
      return 0;
    });

    if (includeAll && _currencies.length > 0) {
      _currencies.unshift({
        label: 'All currencies',
        value: '*',
      });
    }
    setCurrencies(_currencies);
  };

  const configureSellCurrencies = () => {
    let _currencies: Currency[] = sellCurrencies.map((isocode) => ({
      label: isocode,
      value: isocode,
    }));
    if (includeAll && _currencies.length > 0) {
      _currencies.unshift({
        label: 'All currencies',
        value: '*',
      });
    }
    if (interSectSellCurrencies && interSectSellCurrencies.length > 0) {
      _currencies = _currencies.filter((currency) =>
        interSectSellCurrencies.includes(currency.value),
      );
    }
    setCurrencies(_currencies);
  };

  const configureBuyerCurrencies = () => {
    const _currencies: Currency[] = buyCurrencies.map((isocode) => ({
      label: isocode,
      value: isocode,
    }));
    if (includeAll && _currencies.length > 0) {
      _currencies.unshift({
        label: 'All currencies',
        value: '*',
      });
    }
    setCurrencies(_currencies);
  };

  const selectedCurrencyTemplate = (option: Currency) => {
    if (option) {
      return (
        <div className='flex align-items-center'>
          {option.value != '*' && (
            <img
              alt={option.value ?? ''}
              src={`/images/c_flags_svg/${option.value?.toLocaleLowerCase()}.svg`}
              className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
            />
          )}
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{<Translate value={placeholder ?? ''} />}</span>;
  };

  const currencyOptionTemplate = (option: Currency) => {
    return (
      <div className='flex align-items-center'>
        {option.value != '*' && (
          <img
            alt={option.value ?? ''}
            src={`/images/c_flags_svg/${option.value?.toLocaleLowerCase()}.svg`}
            className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
          />
        )}
        <div className='overflow-hidden overflow-ellipsis'>{option.label}</div>
      </div>
    );
  };

  const gettErrorMessage = () => {
    if (currencySource == 'buy' && buyCurrencies.length === 0) {
      return 'no_buy_currency_configured';
    } else if (currencySource == 'sell' && sellCurrencies.length === 0) {
      return 'no_sell_currency_configured';
    }
    return '';
  };

  const errorMessage =
    error &&
    (error.type === 'required'
      ? `${translateWithValues(label)} ${translateWithValues('is_required')}`
      : error.message);

  return (
    <div className='w-full'>
      {!isMulti && (
        <div className={`flex flex-col gap-1 ${className || ''}`}>
          <label
            htmlFor={id}
            className='label whitespace-nowrap overflow-hidden text-ellipsis inline-block'
          >
            {translateWithValues(label)} {isRequired ? <span className='text-error-1'>*</span> : ''}
          </label>
          {control && name ? (
            <IconField iconPosition='right'>
              <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => {
                  const selectedCurrency = currencies.find(
                    (c: Currency) => c.value === field.value,
                  );
                  return isEditable ? (
                    <Dropdown
                      className='w-full'
                      id={id}
                      tooltip={error && errorMessage}
                      tooltipOptions={{ position: 'bottom' }}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                        onChange?.(e.value);
                      }}
                      options={currencies}
                      disabled={disabled}
                      optionLabel='label'
                      placeholder={placeholder && translateWithValues(placeholder)}
                      filter
                      valueTemplate={(option: Currency) => selectedCurrencyTemplate(option)}
                      itemTemplate={currencyOptionTemplate}
                      invalid={!!error}
                    />
                  ) : (
                    <div className='flex align-items-center mt-[10px]'>
                      {selectedCurrency && (
                        <img
                          alt={selectedCurrency.value ?? ''}
                          src={`/images/c_flags_svg/${selectedCurrency.value?.toLocaleLowerCase()}.svg`}
                          className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
                        />
                      )}
                      <span>{selectedCurrency?.value || ''}</span>
                    </div>
                  );
                }}
              />
              {!!error && (
                <InputIcon className='mr-[23px]'>
                  <ErrorIcon isFilled={false} size={16} withBackground={false} />
                </InputIcon>
              )}
            </IconField>
          ) : (
            <IconField iconPosition='right'>
              <Dropdown
                id={id}
                name={name}
                value={value}
                disabled={disabled}
                aria-describedby={ariaDescribedBy}
                placeholder={placeholder && translateWithValues(placeholder)}
                options={currencies}
                optionLabel='label'
                filter
                valueTemplate={selectedCurrencyTemplate}
                itemTemplate={currencyOptionTemplate}
                className='w-full md:w-14rem'
                invalid={!!error}
                onChange={(e) => (onChange ? onChange(e.value) : undefined)}
              />
              {!!error && (
                <InputIcon className='mr-[23px]'>
                  <ErrorIcon isFilled={false} size={16} withBackground={false} />
                </InputIcon>
              )}
            </IconField>
          )}
        </div>
      )}
      {isMulti &&
        (control ? (
          <MultiSelectInput
            label={label}
            control={control}
            disabled={disabled}
            className={`${className}`}
            name={name}
            placeholder={placeholder && translateWithValues(placeholder)}
            rules={{ required: true }}
            error={error}
            isRequired
            options={currencies}
            itemTemplate={currencyOptionTemplate}
          />
        ) : (
          <MultiSelectInput
            value={multiSelectValue}
            options={currencies}
            disabled={disabled}
            itemTemplate={currencyOptionTemplate}
            placeholder={placeholder && translateWithValues(placeholder)}
            className='w-full'
            onChange={onChange}
          />
        ))}
      {gettErrorMessage() && (
        <span className='text-error-1 text-sm'>
          <Translate value={gettErrorMessage()} />{' '}
        </span>
      )}
    </div>
  );
};

export default CurrencySelect;
