import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  CountrySelect,
  CurrencySelect,
  DropdownInput,
  ErrorIcon,
  MultiSelectInput,
  TextInput,
} from '../../../../components';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import {
  selectCurrentCustomer,
  selectOwningCustomerId,
  sendToast,
} from '../../../../store/slices/main/mainSlice';
import { selectCountries, selectTimeZones } from '../../../../store/slices/refdata/refdataSlice';
import { Status } from '../../../../types';
import { enumToCamelCase } from '../../../../utils/helper';
import ClientService from '../../../Client/clientService';
import UserService from '../../../Manage/userService';
import {
  DATE_OPTIONS,
  DAYS_OF_WEEK,
  DELIVERY_OPTIONS,
  FREQUENCY_OPTIONS,
  REPORT_FORMAT_OPTIONS,
  TIME_OPTIONS,
} from '../../report';
import ReportsService from '../../reportsService';
import { recipientTemplate } from '../../reportsUtils';

const AddSchedule = ({
  visible,
  setAddScheduleToggle,
  schedule,
  isViewMode = false,
}: {
  visible: boolean;
  setAddScheduleToggle: (visible: boolean) => void;
  schedule?: Schedule | null;
  isViewMode?: boolean;
}) => {
  const {
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    // trigger,
    // unregister,
    formState: { errors, isValid },
  } = useForm<any>();
  const formRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const timeZones = useSelector(selectTimeZones);
  const owningCustomerId = useSelector(selectOwningCustomerId);
  const customer = useSelector(selectCurrentCustomer);
  const countries = useSelector(selectCountries);

  const [frequency, setFrequency] = useState<string | null>(schedule?.recurringPeriod || null);
  const formData = watch();

  const showToast = (severity: 'success' | 'error', summary: string, detail: string) => {
    dispatch(sendToast({ severity, summary, detail }));
  };

  const addScheduleMutation = useMutation({
    mutationFn: async (newData: Schedule[]) => {
      try {
        const response = await ReportsService.addSchedule(newData);
        return response;
      } catch (error) {
        showToast('error', 'Error', 'schedule_failure_toast');
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['schedules'] });
      showToast('success', 'Success', 'schedule_success_toast');
      setAddScheduleToggle(false);
      navigate('/reports/schedule');
    },
    onError: () => {
      showToast('error', 'Error', 'schedule_failure_toast');
    },
  });

  const reportNames = useQuery<ReportType[], Error>({
    queryKey: ['reports'],
    queryFn: async () => {
      const data: ReportType[] = await ReportsService.getReportNames();
      return data;
    },
  });

  useEffect(() => {
    if (schedule) {
      const date = new Date();
      date.setHours(parseInt(schedule.hourOfDay, 10));
      setValue('time', date);
      setValue('timeZone', schedule.timeZone);
      setValue('reportName', schedule.reportName);
      setValue('recurringPeriod', schedule.recurringPeriod);
      setValue('minutesOfHour', schedule.minutesOfHour);
      setValue('dayOfWeek', schedule.dayOfWeek);
      setValue('dayOfMonth', schedule.dayOfMonth);
      setValue('reportFormat', schedule.reportTypes);
      setValue('deliveryOptions', schedule.deliveryChannels);
      if (recipient.data) {
        const user = recipient.data.find((user: User) =>
          schedule?.reportRecipients.some((recipient) => recipient.userId === user.userIdentity.id),
        );
        if (user) {
          setValue('recipient', [
            {
              userId: user.userIdentity.id,
              userEmail: user.userIdentity.email,
              userFullName: user.userIdentity.firstName + ' ' + user.userIdentity.lastName,
            },
          ]);
        }
      }
      const reportParams = JSON.parse(schedule.reportParam);
      setValue('customerIds', reportParams.customerIds);
      setValue(
        'country',
        countries.find((country) => country.isoCode === reportParams.country),
      );
      setValue('status', reportParams.status);
      setValue('buyCurrency', reportParams.buyCurrency);
      setValue('name', reportParams.name);
      setValue('accountNumber', reportParams.accountNumber);
      setValue('nickName', reportParams.nickName);
      setValue('beneficiaryId', reportParams.beneficiaryId);
    }
    setValue('reportFormat', ['CSV']);
  }, [visible, schedule, reportNames.data]);

  const recipient = useQuery({
    queryKey: ['recipients'],
    queryFn: async () => {
      const data: UsersResponse | null = await UserService.getCustomerUser(
        customer?.id as number,
        0,
        10,
      );

      const user = data?.content.find((user: User) =>
        schedule?.reportRecipients.some((recipient) => recipient.userId === user.userIdentity.id),
      );
      if (user) {
        setValue('recipient', [
          {
            userId: user.userIdentity.id,
            userEmail: user.userIdentity.email,
            userFullName: user.userIdentity.firstName + ' ' + user.userIdentity.lastName,
          },
        ]);
      }

      return data?.content;
    },
  });

  const customerUsers = useQuery({
    queryKey: ['customerUsers'],
    queryFn: async () => {
      const data: ClientResponse | null = await ClientService.getChildClients(
        customer?.id as number,
        0,
        0,
        {
          searchText: '',
        },
        '',
        null,
        true,
      );
      return data?.content;
    },
  });

  const onSave = (data: any) => {
    const time = new Date(data.time);
    const hours = time.getHours();
    const reportParams: any = {};
    if (formData['customerIds']) reportParams['customerIds'] = formData['customerIds'];
    if (formData['country']) reportParams['country'] = formData['country'].isoCode;
    if (formData['status']) reportParams['status'] = formData['status'];
    if (formData['buyCurrency']) reportParams['buyCurrency'] = formData['buyCurrency'];
    if (formData['name']) reportParams['name'] = formData['name'];
    if (formData['accountNumber']) reportParams['accountNumber'] = formData['accountNumber'];
    if (formData['nickName']) reportParams['nickName'] = formData['nickName'];
    if (formData['beneficiaryId']) reportParams['beneficiaryId'] = formData['beneficiaryId'];
    const payload: Schedule[] = [
      {
        ...data,
        customerIds: undefined,
        deliveryChannels: data.deliveryOptions,
        recipient: undefined,
        hourOfDay: hours,
        minutesOfHour: data.minutesOfHour || time?.getMinutes() || undefined,
        owningCustomerId,
        reportName: data.reportName,
        reportTypes: data.reportFormat,
        reportRecipients: data.recipient,
        timeZone: data.timeZone,
        time: undefined,
        reportFormat: undefined,
        deliveryOptions: undefined,
        reportParam: JSON.stringify(reportParams),
        status: undefined,
      },
    ];

    if (schedule) {
      payload[0].scheduleId = schedule.scheduleId;
      addScheduleMutation.mutate(payload);
    } else {
      addScheduleMutation.mutate(payload);
    }
  };

  const headerElement = () => (
    <div className='flex justify-between items-center w-full'>
      <div className='flex flex-col gap-1'>
        <div className='text-lg font-semibold text-neutral-1'>
          <Translate value={isViewMode ? 'viewScheduleTitle' : 'createScheduleTitle'} />
        </div>
        <div className='text-sm font-regular text-neutral-3'>
          <Translate value='createScheduleDescription' className='!text-sm-regular' />
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className='flex justify-end mt-5 gap-2'>
      <Button severity='secondary' onClick={() => setAddScheduleToggle(false)}>
        <Translate value='cancel' />
      </Button>
      {!isViewMode && (
        <Button
          severity='info'
          onClick={() => {
            if (formRef.current) {
              (formRef.current as HTMLFormElement).dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true }),
              );
            }
            if (!isValid) {
              showToast('error', 'error', 'client.field.validation.error');
            }
          }}
        >
          <Translate value='save' />
        </Button>
      )}
    </div>
  );

  return (
    <Dialog
      draggable={false}
      className='w-[50%] min-w-[] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      header={headerElement}
      visible={visible}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0, minWidth: '400px' }}
      onHide={() => setAddScheduleToggle(false)}
      footer={footerContent}
    >
      {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(onSave)} ref={formRef}>
          <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-y-7 mb-4'>
            <DropdownInput
              label='timeZone'
              name='timeZone'
              options={timeZones}
              placeholder={translateWithValues('selectTimeZone')}
              className='w-full'
              disabled={isViewMode}
              isRequired
              filter
              rules={{ required: true }}
              control={control}
              error={errors['timeZone']}
            />

            <DropdownInput
              label='reportName'
              name='reportName'
              options={
                reportNames.data?.map((reportName) => {
                  return {
                    label: enumToCamelCase(reportName.reportName),
                    value: reportName.reportName,
                  };
                }) || []
              }
              placeholder={translateWithValues('selectReportName')}
              className='w-full'
              disabled={isViewMode || !!schedule}
              isRequired
              rules={{ required: true }}
              control={control}
              error={errors['reportName']}
            />

            <DropdownInput
              label='recurringPeriod'
              name='recurringPeriod'
              options={FREQUENCY_OPTIONS}
              onChange={(e) => {
                setFrequency(e.value);
              }}
              placeholder={translateWithValues('selectFrequency')}
              className='w-full'
              disabled={isViewMode}
              isRequired
              rules={{ required: true }}
              control={control}
              error={errors['recurringPeriod']}
            />

            {frequency === 'HOURLY' && (
              <DropdownInput
                name='minutesOfHour'
                label='minute'
                options={TIME_OPTIONS}
                placeholder={translateWithValues('selectMinute')}
                className='w-full'
                disabled={isViewMode}
                isRequired
                rules={{ required: true }}
                control={control}
                error={errors['minutesOfHour']}
              />
            )}

            {frequency === 'WEEKLY' && (
              <DropdownInput
                label='dayOfWeek'
                name='dayOfWeek'
                options={DAYS_OF_WEEK}
                placeholder={translateWithValues('selectDay')}
                className='w-full'
                disabled={isViewMode}
                isRequired
                rules={{ required: true }}
                control={control}
                error={errors['dayOfWeek']}
              />
            )}

            {frequency === 'MONTHLY' && (
              <DropdownInput
                name='dayOfMonth'
                label='dayOfMonth'
                options={DATE_OPTIONS}
                placeholder={translateWithValues('selectDate')}
                className='w-full'
                disabled={isViewMode}
                isRequired
                rules={{ required: true }}
                control={control}
                error={errors['dayOfMonth']}
              />
            )}

            {frequency !== 'HOURLY' && (
              <IconField iconPosition='right'>
                <Controller
                  name='time'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div className='flex flex-col gap-1'>
                      <label className='whitespace-nowrap overflow-hidden text-ellipsis inline-block'>
                        {translateWithValues('time')}
                      </label>
                      <Calendar
                        {...field}
                        hourFormat='12'
                        placeholder={translateWithValues('selectTime')}
                        className='w-full'
                        timeOnly
                        tooltip={errors.time && 'Error'}
                        disabled={isViewMode}
                        invalid={!!errors.time}
                        pt={{
                          input: {
                            root: { className: errors.time ? 'border !border-error-2' : '' },
                          },
                        }}
                      />
                    </div>
                  )}
                />
                {errors.time && (
                  <InputIcon className='mr-[23px] mt-[3px]'>
                    <ErrorIcon isFilled={false} size={16} withBackground={false} />
                  </InputIcon>
                )}
              </IconField>
            )}

            <MultiSelectInput
              name='reportFormat'
              label='reportFormat'
              options={REPORT_FORMAT_OPTIONS}
              placeholder={translateWithValues('selectReportFormat')}
              className='w-full'
              disabled={isViewMode}
              control={control}
              isRequired
              rules={{ required: true }}
              error={errors.reportFormat}
            />

            <MultiSelectInput
              name='deliveryOptions'
              label='deliveryOptions'
              options={DELIVERY_OPTIONS}
              placeholder={translateWithValues('selectDeliveryOptions')}
              className='w-full'
              disabled={isViewMode}
              control={control}
              isRequired
              rules={{ required: true }}
              error={errors.deliveryOptions}
            />

            {formData['deliveryOptions']?.includes('EMAIL') && (
              <MultiSelectInput
                name='recipient'
                label='recipient'
                options={
                  Array.isArray(recipient.data)
                    ? recipient.data.map((recipient) => ({
                        label: recipient.userIdentity.email,
                        value: {
                          userId: recipient.userIdentity.id,
                          userEmail: recipient.userIdentity.email,
                          userFullName:
                            recipient.userIdentity.firstName +
                            ' ' +
                            recipient.userIdentity.lastName,
                        },
                      }))
                    : []
                }
                placeholder={translateWithValues('selectRecipient')}
                className='w-full p-0 p-inputtext p-component'
                disabled={isViewMode || recipient.isLoading}
                isRequired
                rules={{ required: true }}
                control={control}
                error={errors['recipient']}
                itemTemplate={recipientTemplate}
                handleFilter={(e) => {
                  UserService.getCustomerUser(customer?.id as number, 0, 10, {
                    searchTerm: e.filter,
                  }).then((data) => {
                    queryClient.setQueryData(['recipients'], data?.content);
                  });
                }}
              />
            )}

            {reportNames.data
              ?.find((r) => r.reportName == formData['reportName'])
              ?.reportUIParams.map((p) => {
                switch (p.parameterName) {
                  case 'customerIds':
                    return (
                      <MultiSelectInput
                        key={p.parameterName}
                        name='customerIds'
                        label='customerId'
                        options={
                          customerUsers.data?.map((customer: any) => ({
                            label: customer.customerShortName || customer.id,
                            value: customer.id,
                          })) || []
                        }
                        placeholder={translateWithValues('customerId')}
                        className='w-full p-0 p-inputtext p-component'
                        disabled={isViewMode}
                        control={control}
                        error={errors['customerIds']}
                      />
                    );
                  case 'country':
                    return (
                      <CountrySelect
                        key={p.parameterName}
                        label={`${formData['reportName']}_country`}
                        disabled={isViewMode}
                        placeholder='country'
                        name='country'
                        control={control}
                        error={errors['country']}
                      />
                    );
                  case 'status':
                    return (
                      <MultiSelectInput
                        key={p.parameterName}
                        name='status'
                        label='status'
                        options={[
                          { label: 'Active', value: Status.ACTIVE },
                          { label: 'Inactive', value: Status.INACTIVE },
                          { label: 'Pending', value: Status.PENDING },
                        ]}
                        placeholder={translateWithValues('status')}
                        className='w-full p-0 p-inputtext p-component'
                        disabled={isViewMode}
                        control={control}
                        error={errors[p.parameterName]}
                      />
                    );

                  case 'buyCurrency':
                    return (
                      <CurrencySelect
                        name={p.parameterName}
                        label='buy_currency'
                        key={p.parameterName}
                        placeholder='buyCurrency'
                        currencySource='buy'
                        disabled={isViewMode}
                        control={control}
                        error={errors[p.parameterName]}
                        isMulti
                      />
                    );
                  default:
                    if (
                      ['name', 'accountNumber', 'nickName', 'beneficiaryId'].includes(
                        p.parameterName,
                      )
                    ) {
                      return (
                        <TextInput
                          formRegister={register(p.parameterName)}
                          key={p.parameterName}
                          name={p.parameterName}
                          label={`${formData['reportName']}_${p.parameterName}`}
                          className='w-full'
                          disabled={isViewMode}
                          placeholder={`${formData['reportName']}_${p.parameterName}`}
                          error={errors[p.parameterName]}
                        />
                      );
                    }
                }
              })}
          </div>
        </form>
      }
    </Dialog>
  );
};

export default AddSchedule;
