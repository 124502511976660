import { createSlice } from '@reduxjs/toolkit';
import { injectCoutries } from '../../../utils/isoncCodeMapping';
import { RootState } from '../../rootReducer';
import {
  fetchAppRefdata,
  fetchBuyCurrency,
  fetchCountryList,
  fetchCurrency,
  fetchSellCurrency,
} from './actions';

interface Currency {
  code: string;
  currencyCode: string | null;
}

interface RefDataState {
  countries: Country[];
  currencies: Currency[];
  languages: string[];
  provinceList: string[];
  customerCurrencies: CurrencyConfiguration[];
  selleCurrrencies: string[];
  buyCurrencies: string[];
  appMetaData: MetaData | null;
}

export const initialState: RefDataState = {
  countries: [],
  currencies: [],
  languages: [],
  provinceList: [],
  customerCurrencies: [],
  selleCurrrencies: [],
  buyCurrencies: [],
  appMetaData: null,
};

const refdataSlice = createSlice({
  name: 'refdata',
  initialState,
  reducers: {
    reset: (state: RefDataState) => {
      state.countries = [];
      state.currencies = [];
      state.customerCurrencies = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrency.fulfilled, (state, action) => {
      const cs = action.payload;
      state.customerCurrencies = cs;
    });
    builder.addCase(fetchAppRefdata.fulfilled, (state, action) => {
      state.appMetaData = action.payload;
    });
    builder.addCase(fetchSellCurrency.fulfilled, (state, action) => {
      const selleCurrrencies = action.payload;
      state.selleCurrrencies = selleCurrrencies.sort((a, b) => a.localeCompare(b));
    });
    builder.addCase(fetchBuyCurrency.fulfilled, (state, action) => {
      const buyCurrencies = action.payload;
      state.buyCurrencies = buyCurrencies.sort((a, b) => a.localeCompare(b));
    });
    builder.addCase(fetchCountryList.fulfilled, (state, action) => {
      const stonexCountries = action.payload;
      const updateList: Country[] = stonexCountries.map((country) => {
        return {
          name: country.DisplayName,
          dialCode: country.PhoneCode,
          code: country.Alpha3,
          currencyCode: country.LocalCurrencyCode,
          isoCode: country.isocode,
          allowpayment: country.allowpayment,
          allowselfregistration: country.allowselfregistration,
          spotPaymentEntities: country.SpotPaymentEntities,
          riskStatus: country.RiskStatus,
          fullName: country.FullName,
        };
      });
      state.countries = updateList;
      injectCoutries(updateList);
    });
  },
});

export const { reset } = refdataSlice.actions;
export const selectCountries = (state: RootState) => state.refdata.countries;
export const selectCurrencies = (state: RootState) => state.refdata.currencies;
export const seletIdpOptions = (state: RootState) =>
  state.refdata.appMetaData?.idpApplications.map((app) => ({
    label: app.applicationName,
    value: app.id,
  })) || [];
export const selectTimeZones = (state: RootState) =>
  state.refdata.appMetaData?.timeZones.map((timeZone) => ({
    label: timeZone,
    value: timeZone,
  })) || [];
export const selectCustomerCurrencies = (state: RootState) => state.refdata.customerCurrencies;
export const selectSellCurrencies = (state: RootState) => state.refdata.selleCurrrencies;
export const selectBuyCurrencies = (state: RootState) => state.refdata.buyCurrencies;
export const selectRefDataCache = (state: RootState) => state.refdata;
export default refdataSlice.reducer;