import { Button } from 'primereact/button';
import {
  PaginatorTemplate,
  TableSearch,
  Status as StatusComponent,
  InheritedMenu,
} from '../../../components';
import { useNavigate } from 'react-router';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { DataTable } from 'primereact/datatable';
import { useContext, useEffect, useRef, useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { Filter, View } from '@carbon/icons-react';
import { Badge } from 'primereact/badge';
import { useQuery } from '@tanstack/react-query';
import CaseService from '../caseService';
import { Column } from 'primereact/column';
import { CASE_STATUS_OPTIONS } from '../caseConstants';
import FilterModal from '../../../components/FilterModal';
import { useAppDispatch } from '../../../store';
import { selectFilters, updateFilter } from '../../../store/slices/main/mainSlice';
import { useSelector } from 'react-redux';
import { PageHeadContext } from '../../../providers/PageHead';
import { convertDateFormat } from '../../../utils/helper';
import { Menu } from 'primereact/menu';
import { MoreAction } from '../../../icons';
import useRole from '../../../hooks/useRoles';
import caseRoles from '../caseRoles';

const ViewCases = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const [totalRecords, setTotalRecords] = useState(0);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    status: { value: [], matchMode: FilterMatchMode.IN },
  });
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [isModalVisible, setModalVisible] = useState(false);
  const filterPersisted = useSelector(selectFilters)['view_cases'];
  const [actionItems, setActionItems] = useState<MenuItem[]>([]);
  const { hasRole } = useRole();

  useEffect(() => {
    if (filterPersisted) {
      handleApplyFilters(filterPersisted);
    }
  }, []);

  useEffect(() => {
    setPageHeadData({
      title: 'view_cases',
      description: 'view_cases.description',
      button: hasRole(caseRoles.CreateCase) && (
        <Button
          severity='info'
          className='h-[40px]'
          onClick={() => {
            navigate('/get-help/create');
          }}
        >
          <Translate value='create_new_case' />
        </Button>
      ),
    });
  }, [setPageHeadData]);

  const [filterValues, setFilerValues] = useState<any>([
    {
      header: 'By Status',
      fields: [
        {
          name: 'status',
          placeholder: 'Select Status',
          value: [],
          options: CASE_STATUS_OPTIONS,
        },
      ],
    },
  ]);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const _filters = { ...filters };
    _filters['global'].value = value.trim();
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleApplyFilters = (formdata: FilterFormData) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    const _filters: any = {
      global: filters.global,
    };
    Object.keys(formdata).forEach((filterName: string) => {
      _filters[filterName] = { value: formdata[filterName], matchMode: FilterMatchMode.IN };
    });
    setFilters(_filters);
    dispatch(updateFilter({ filterKey: 'view_cases', value: formdata }));
  };

  const { data: caseList, isLoading } = useQuery({
    queryKey: ['case_list'],
    queryFn: async () => {
      const data = await CaseService.getAllCases();
      setTotalRecords(data?.length ?? 0);
      return data?.map((row, index) => ({
        ...row,
        id: index + 1,
      }));
    },
  });

  const menu = useRef<Menu>(null);

  const actionBodyTemplate = (caseRow: Case) => {
    const actions: MenuItem[] = [
      {
        roles: caseRoles.ViewCase,
        label: 'viewDetails',
        disabled: !caseRow.supportTicketId,
        icon: <View />,
        onClick: () => {
          navigate(`/get-help/details/${caseRow.supportTicketId}`);
        },
      },
    ];
    return (
      <div>
        <div
          onClick={(event) => {
            setActionItems(actions);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={actionItems} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const header = (
    <div>
      <div className='flex flex-col md:flex-row gap-3 justify-between'>
        <div className='text-neutral-1 text-lg-semibold'>
          <Translate value='total_cases' />
          <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
            {totalRecords}
          </span>
        </div>
        <div className='flex lg:justify-around gap-2 items-center'>
          <TableSearch
            globalFilterValue={globalFilterValue}
            onGlobalFilterChange={onGlobalFilterChange}
          />
          <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
            <Button
              severity='secondary'
              className='p-button-text h-12'
              onClick={() => {
                setModalVisible(true);
              }}
            >
              <Filter size={20} />
              <Translate value='filterLabel' className='ml-1 hidden md:hidden lg:block' />{' '}
            </Button>
            {!!activeFilterCount && (
              <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className='dashboard-padding'>
        <FilterModal
          visible={isModalVisible}
          setFilterModalToggle={setModalVisible}
          filters={filterValues}
          setFilters={setFilerValues}
          title='cases_filter.title'
          onApply={handleApplyFilters}
        />
        <DataTable
          dataKey={'id'}
          loading={isLoading}
          value={caseList || []}
          paginator={true}
          paginatorTemplate={PaginatorTemplate}
          first={0}
          rows={10}
          size='large'
          className='mt-8'
          header={header}
          globalFilterFields={[
            'supportTicketId',
            'status',
            'topic',
            'reason',
            'createdBy',
            'createdDate',
            'lastUpdated',
            'assignedTo',
          ]}
          filters={filters}
          sortField='createdDate'
          sortOrder={-1}
        >
          <Column sortable field='supportTicketId' header={translateWithValues('case_id')} />
          <Column field='topic' header={translateWithValues('topic')} />
          <Column field='reason' header={translateWithValues('reason')} />
          <Column field='createdBy' header={translateWithValues('created_by')} />
          <Column
            field='createdDate'
            header={translateWithValues('created_date')}
            body={(caseRow: Case) => convertDateFormat(caseRow.createdDate)}
            sortable
          />
          <Column
            field='lastUpdated'
            header={translateWithValues('last_modified')}
            body={(caseRow: Case) => {
              if (
                caseRow.messages?.length &&
                caseRow.messages[caseRow.messages.length - 1].createdDate
              )
                return convertDateFormat(caseRow.messages[caseRow.messages.length - 1].createdDate);
            }}
          />
          <Column
            field='status'
            header={translateWithValues('status')}
            body={(caseRow: Case) => <StatusComponent status={caseRow.status} />}
          />
          <Column header={translateWithValues('actions')} body={actionBodyTemplate} />
        </DataTable>
      </div>
    </>
  );
};

export default ViewCases;
