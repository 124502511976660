import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import useRole, {
  useOrderingCustomerMenu,
  useSettingEnabled,
  useUserMenu,
} from '../../hooks/useRoles';
import { selectRoles } from '../../store/slices/main/mainSlice';

interface RoleGuardProps {
  children: React.ReactNode;
  roles: string;
  urlKey?: string;
  configKey?: string;
  checkOrdCustomerMenuCondition?: boolean;
  checkUserMenuCondition?: boolean;
}
const RoleGuard: React.FC<RoleGuardProps> = ({
  children,
  roles,
  urlKey,
  configKey,
  checkOrdCustomerMenuCondition,
  checkUserMenuCondition,
}) => {
  const rootRoles = useSelector(selectRoles);
  const { hasRole } = useRole();
  const { isSettingEnabled } = useSettingEnabled();
  const { shouldShowMenu } = useOrderingCustomerMenu();
  const { shouldShowUserMenu } = useUserMenu();

  if (rootRoles.length !== 0 && !hasRole(roles)) {
    return <Navigate to='/unauthorized' replace />;
  }

  if (configKey && !isSettingEnabled(configKey)) {
    return <Navigate to='/unauthorized' replace />;
  }

  if (checkOrdCustomerMenuCondition && urlKey && !shouldShowMenu(urlKey)) {
    return <Navigate to='/unauthorized' replace />;
  }
  if (checkUserMenuCondition && urlKey && !shouldShowUserMenu(urlKey)) {
    return <Navigate to='/unauthorized' replace />;
  }

  return <>{rootRoles.length > 0 ? children : <></>}</>;
};
export default RoleGuard;
