import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Translate } from '../../i18n/translate';
import { PageHeadContext } from '../../providers/PageHead';
import { sendToast } from '../../store/slices/main/mainSlice';
import { selectCountries } from '../../store/slices/refdata/refdataSlice';
import { AddOrEditOrView } from '../../types';
import { getCountryISO3 } from '../../utils/isoncCodeMapping';
import BeneficiaryBankDetails from './BeneficiaryBankDetails';
import BeneficiaryDetails from './BeneficiaryDetails';
import BeneficiaryService from './beneficiaryService';
import type PaymentService from '../Payment/paymentService';
import { ApprovalHistory } from '../../components';

export default function BeneficiaryDetailsContainer({ mode }: { mode: AddOrEditOrView }) {
  const { beneficiaryId } = useParams();

  if (!beneficiaryId) {
    return null;
  }

  return <BeneficiaryDetailsContainerComponent beneficiaryId={Number(beneficiaryId)} mode={mode} />;
}

function BeneficiaryDetailsContainerComponent({
  beneficiaryId,
  mode,
}: {
  beneficiaryId: number;
  mode: AddOrEditOrView;
}) {
  const [routingCodeDetails, setRoutingCodeDetails] = useState<{
    intermediary: any;
    credit: any;
  }>();
  const [isBic, setIsBic] = useState(false);
  const [iban, setIban] = useState('');
  const [swiftBicCode, setSwiftBicCode] = useState('');
  const [showHistory, setShowHistory] = useState(false);

  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  const navigate = useNavigate();

  const selectedCountries = useSelector(selectCountries);

  const beneficiaryDetailsQuery = useQuery({
    queryKey: ['beneficiary_details', beneficiaryId],
    queryFn: async () => {
      const response = await BeneficiaryService.getBeneficiaryTemplate(beneficiaryId);
      return response;
    },
    refetchOnWindowFocus: false,
  });

  const verifyIbanExistsQuery = useQuery<
    Awaited<ReturnType<typeof PaymentService.verifyIbanExists>> | undefined
  >({
    queryKey: ['verify_iban_exists', iban],
    enabled: false,
  });

  const form = useForm<any>({
    values: {
      ...beneficiaryDetailsQuery.data?.fieldMap,
      country: selectedCountries.find(
        (item) => item.code === getCountryISO3(beneficiaryDetailsQuery.data?.fieldMap.country),
      ),
      BeneficiaryAddressCountry: selectedCountries.find(
        (item) =>
          item.code ===
          getCountryISO3(beneficiaryDetailsQuery.data?.fieldMap.BeneficiaryAddressCountry),
      ),
    },
    disabled: mode !== AddOrEditOrView.Edit,
  });

  const { handleSubmit, watch } = form;

  const dispatch = useDispatch();

  const editBeneficiaryMutation = useMutation({
    mutationFn: async (data: BeneficiaryFormType) => {
      const response = await BeneficiaryService.editBeneficiaryTemplate(
        beneficiaryId,
        isBic ? 'BIC' : 'FW',
        data,
        routingCodeDetails,
      );
      return response;
    },
    onSuccess: () => {
      navigate('/beneficiaries/');
    },
    onError: (error) => {
      dispatch(
        sendToast({
          summary: 'error',
          severity: 'error',
          detail: error.message,
        }),
      );
    },
  });

  const onSubmit: SubmitHandler<BeneficiaryFormType> = (data) => {
    if (iban) {
      if (verifyIbanExistsQuery.status === 'pending') {
        dispatch(
          sendToast({
            severity: 'warn',
            summary: 'iban_check.inprogress',
          }),
        );
        return;
      }

      if (!verifyIbanExistsQuery.data?.ibanBic) {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'failed',
            detail: 'iban_check.warn',
          }),
        );
        return;
      }
    }

    editBeneficiaryMutation.mutate(data);
  };

  useEffect(() => {
    setPageHeadData(
      mode === AddOrEditOrView.Edit
        ? {
            title: 'edit_beneficiary.header',
            className: 'pb-[16px]',
          }
        : mode === AddOrEditOrView.Approve || mode === AddOrEditOrView.View
          ? {
              title: 'beneficiaries.title',
              className: 'pb-[16px]',
              button: (
                <Button
                  severity='secondary'
                  className='h-[45px]'
                  onClick={() => {
                    setShowHistory(true);
                  }}
                >
                  <Translate value='approval.history' />
                </Button>
              ),
            }
          :{ title: '' },
    );
  }, [setPageHeadData]);

  if (beneficiaryDetailsQuery.isLoading) {
    return <div className='grid gap-8 place-items-center'>Loading...</div>;
  }

  if (beneficiaryDetailsQuery.isError) {
    return <div className='grid gap-8 place-items-center'>Failed to load beneficiary</div>;
  }

  if (!beneficiaryDetailsQuery.data) {
    return <div className='grid gap-8 place-items-center'>Beneficiary not found</div>;
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
        className='p-8 grid gap-8 place-items-center'
      >
        <div className='w-full grid gap-6'>
          <BeneficiaryDetails
            form={form}
            mode={mode}
            onCountryChange={() => {
              setIban('');
              setSwiftBicCode('');
              setRoutingCodeDetails(undefined);
            }}
            beneficiaryDetailsQuery={beneficiaryDetailsQuery}
          />
          <fieldset disabled={mode !== AddOrEditOrView.Edit}>
            <BeneficiaryBankDetails
              buyCurrency={watch('buyCurrency')}
              beneficiaryCountry={watch('country')}
              showForm
              form={form}
              mode={mode}
              routingCodeDetails={routingCodeDetails}
              setRoutingCodeDetails={setRoutingCodeDetails}
              setIsBic={(isBic) => setIsBic(isBic)}
              iban={iban}
              setIban={setIban}
              swiftBicCode={swiftBicCode}
              setSwiftBicCode={setSwiftBicCode}
            />
          </fieldset>
        </div>
        {mode === AddOrEditOrView.Edit ? (
          <Button
            type='submit'
            severity='info'
            loading={editBeneficiaryMutation.isPending}
            className='gap-2'
          >
            <Translate value='save_and_submit_for_approval' />
          </Button>
        ) : null}
      </form>
      <ApprovalHistory
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        recodrdId={`${beneficiaryId ?? ''}`}
        feature='BENEFICIARY_MANAGEMENT'
      />
    </>
  );
}
