import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RoleCard } from '../../../../../components';
import InfoTemplate from '../../../../../components/InfoTemplate';
import PermissionModal from '../../../../../components/PermissionModal';
import { Translate } from '../../../../../i18n/translate';
import BlotterView from '../../../../../icons/BlotterView';
import WidgetView from '../../../../../icons/WidgetView';
import { useAppDispatch } from '../../../../../store';
import { sendToast } from '../../../../../store/slices/main/mainSlice';
import {
  selectCustomRoles,
  selectPrimaryRoles,
  selectRoleApiInprogress,
} from '../../../../../store/slices/role/roleSlice';
import { AddOrEditOrView } from '../../../../../types';
import CreateCustomRole from '../../../Role/Dashboard/CreateCustomRole';
import UserService from '../../../userService';
import userRole from '../../userRole';
import './index.css';
import Table from './table';

interface RolesAssignedProps {
  mode: AddOrEditOrView;
  userObject?: User;
  roleType: 'aobo' | 'primary';
  onNext?: (user: User, switchTab: boolean) => void;
}

const RolesAssigned = ({ mode, userObject, roleType, onNext }: RolesAssignedProps) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [createCustomRole, setCreateCustomRole] = useState(false);
  const [widgetView, setWidgetView] = useState(true);
  const [selectedPrimaryRoles, setSelectedPrimaryRoles] = useState<number[]>([]);
  const [datesAssigned, setDatesAssigned] = useState<{ [key: string]: string }>({});
  const [selectedCustomRoles, setSelectedCustomRoles] = useState<number[]>([]);
  const [role, setRole] = useState<Role | null>(null);
  const primaryRoles = useSelector(selectPrimaryRoles);
  const allCustomRoles = useSelector(selectCustomRoles);
  const roleAPIInprogress = useSelector(selectRoleApiInprogress);
  const [isEdit] = useState(mode == AddOrEditOrView.Edit);
  const customRoles = useMemo(() => {
    return allCustomRoles.filter((role: Role) => role.isActive);
  }, [allCustomRoles]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getRolesData = (updatedRoles: number[]) => [
    ...updatedRoles
      .map((id) => primaryRoles.find((role) => role.id === id))
      .filter((role): role is Role => role !== undefined),
    ...updatedRoles
      .map((id) => customRoles.find((role) => role.id === id))
      .filter((role): role is Role => role !== undefined),
  ];

  const _onNext = (updatedRole: number[], switchTab: boolean) => {
    if (userObject) {
      const data: User = {
        ...userObject,
        roles: roleType === 'primary' ? getRolesData(updatedRole) : userObject.roles,
        aoboRoles: roleType === 'aobo' ? getRolesData(updatedRole) : userObject.aoboRoles,
      };
      onNext?.(data, switchTab);
    }
  };

  const handleSelectedRole = (roleItem: Role, isCustom: boolean) => {
    const setSelected = isCustom ? setSelectedCustomRoles : setSelectedPrimaryRoles;

    setSelected((prevRoles) => {
      const updatedRoles = prevRoles.includes(roleItem.id)
        ? prevRoles.filter((role) => role !== roleItem.id)
        : [...prevRoles, roleItem.id];
      if (isCustom) {
        _onNext([...selectedPrimaryRoles, ...updatedRoles], false);
      } else {
        _onNext([...updatedRoles, ...selectedCustomRoles], false);
      }
      return updatedRoles;
    });
  };

  const handleOpenModal = (roleItem: Role) => {
    setRole(roleItem);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setRole(null);
    setModalVisible(false);
  };

  const getSelectedRoles = (primaryList: Role[], customList: Role[]) => {
    const data = roleType == 'primary' ? userObject?.roles : userObject?.aoboRoles;
    if (data) {
      const primaryRoleIds = primaryList
        .filter((role) => data.some((item: Role) => item.id == role.id))
        .map((role) => role.id);
      const customRoleIds = customList
        .filter((role) => data.some((item: Role) => item.id == role.id))
        .map((role) => role.id);
      const dates = data.reduce((acc: { [key: string]: string }, item: any) => {
        acc[item.id] = item.assignedDate;
        return acc;
      }, {});
      setDatesAssigned(dates);
      setSelectedPrimaryRoles(primaryRoleIds);
      setSelectedCustomRoles(customRoleIds);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleRoleCreationComplete = () => {
    setCreateCustomRole(false);
  };

  const onCustomRoleCancel = () => {
    setCreateCustomRole(false);
  };

  useEffect(() => {
    getSelectedRoles(primaryRoles, customRoles);
  }, [primaryRoles, customRoles]);

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const useSubmitUser = useMutation({
    mutationFn: async () => {
      if (!userObject) throw new Error('User object is missing');
      const response = await UserService.createUser([userObject]);
      return response;
    },
    onSuccess: (response) => {
      const { status, data } = response;
      if (status !== 200) {
        showToast('error', 'error', 'user.save.error');
        return;
      }

      const { result } = data[0];
      if (result.success) {
        showToast(
          'success',
          'success',
          isEdit ? 'userUpdatedSuccessfully' : 'userCreatedSuccessfully',
          {
            email: data[0].data.userIdentity.email,
          },
        );
        navigate(-1);
      } else {
        const { errors } = result;
        showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
          ...errors[0],
          email: userObject?.userIdentity.email,
        });
      }
    },
    onError: () => {
      showToast('error', 'error', 'user.save.error');
    },
  });

  if (roleAPIInprogress) {
    return <Translate value='loading' />;
  }

  return (
    <>
      {role && isModalVisible && (
        <PermissionModal visible={isModalVisible} onHide={handleCloseModal} role={role} />
      )}
      {createCustomRole ? (
        <CreateCustomRole
          onComplete={handleRoleCreationComplete}
          onCancel={onCustomRoleCancel}
          parentCustomerId={userObject?.owningCustomerId}
        />
      ) : (
        <>
          <div className='!p-8 card'>
            <div className='grid grid-cols-2'>
              <div>
                <div className='text-sm-bold text-secondary-text'>
                  {mode === AddOrEditOrView.Edit && (
                    <Translate value='editUser.rolesAssigned.title' />
                  )}
                  {mode === AddOrEditOrView.Add && (
                    <Translate value='add_user.roles_assigned.title' />
                  )}

                  {[AddOrEditOrView.Approve, AddOrEditOrView.View].includes(mode) && (
                    <Translate
                      value={`${roleType == 'primary' ? 'primary.rolesAssigned.title' : 'aobo.rolesAssigned.title'}`}
                    />
                  )}
                </div>
                {![AddOrEditOrView.Approve, AddOrEditOrView.View].includes(mode) && (
                  <div className='text-neutral-3'>
                    <Translate
                      value={
                        isEdit
                          ? 'editUser.rolesAssigned.description'
                          : 'add_user.rolesAssigned.description'
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            {isEdit && (
              <div className='flex justify-end'>
                <div>
                  <Button
                    className={
                      (widgetView ? '!text-selected' : '!text-unselected') +
                      ' !p-0 mr-2 !border-none'
                    }
                    onClick={() => setWidgetView(true)}
                  >
                    <WidgetView />
                  </Button>

                  <Button
                    className={
                      (!widgetView ? '!text-selected' : '!text-unselected') + ' !p-0 !border-none'
                    }
                    onClick={() => setWidgetView(false)}
                  >
                    <BlotterView />
                  </Button>
                </div>
              </div>
            )}

            {widgetView ? (
              <div className='flex flex-wrap gap-8 justify-center mt-8 max-w-[1000px] mx-auto'>
                {primaryRoles.map((role) => (
                  <RoleCard
                    key={role.id}
                    title={role.roleName}
                    description={role.description}
                    selected={selectedPrimaryRoles.includes(role.id)}
                    onSelect={() => handleSelectedRole(role, false)}
                    handleOpenModal={() => handleOpenModal(role)}
                    disabled={mode === AddOrEditOrView.View || mode === AddOrEditOrView.Approve}
                  />
                ))}
              </div>
            ) : (
              <div className='mt-8'>
                <Table
                  roles={primaryRoles.filter((role) => selectedPrimaryRoles.includes(role.id))}
                  datesAssigned={datesAssigned}
                />
              </div>
            )}
          </div>

          {customRoles.length > 0 && (
            <div className='!p-8 mt-8 card'>
              <div className='grid grid-cols-2'>
                <div>
                  {![AddOrEditOrView.Approve, AddOrEditOrView.View].includes(mode) ? (
                    <>
                      <div className='text-sm-bold text-secondary-text'>
                        <Translate
                          value={
                            isEdit
                              ? 'editUser.rolesAssigned.customRolesAssigned.title'
                              : 'add_user.roles_assigned.custom_roles_assigned.title'
                          }
                        />
                      </div>
                      <div className='text-neutral-3'>
                        <Translate
                          value={
                            isEdit
                              ? 'editUser.rolesAssigned.customRolesAssigned.description'
                              : 'add_user.roles_assigned.custom_roles_assigned.description'
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <Translate
                      value={`${roleType == 'primary' ? 'custom.rolesAssigned.title' : 'aobo.custom.rolesAssigned.title'}`}
                      className='text-sm-bold text-secondary-text'
                    />
                  )}
                </div>
              </div>
              {widgetView ? (
                <div className='flex flex-wrap gap-8 justify-center mt-8 max-w-[1000px] mx-auto'>
                  {customRoles.map((role) => (
                    <RoleCard
                      key={role.id}
                      title={role.roleName}
                      description={role.description}
                      selected={selectedCustomRoles.includes(role.id)}
                      onSelect={() => handleSelectedRole(role, true)}
                      handleOpenModal={() => handleOpenModal(role)}
                      disabled={mode === AddOrEditOrView.View || mode === AddOrEditOrView.Approve}
                    />
                  ))}
                </div>
              ) : (
                <div className='mt-8'>
                  <Table
                    roles={customRoles.filter((role) => selectedCustomRoles.includes(role.id))}
                    datesAssigned={datesAssigned}
                  />
                </div>
              )}
            </div>
          )}

          {mode !== AddOrEditOrView.View && mode != AddOrEditOrView.Approve && (
            <div className='mt-5'>
              <InfoTemplate
                titleKey='add_user.roles_assigned.custom_role.title'
                descriptionKey='add_user.roles_assigned.custom_role.description'
                buttonKey='add_user.roles_assigned.create_custom_role'
                btnAction={() => setCreateCustomRole(true)}
                roles={userRole.AddRole}
              />
            </div>
          )}

          {mode !== AddOrEditOrView.View && mode != AddOrEditOrView.Approve && (
            <div className='flex items-center justify-center mt-10 gap-5'>
              <Button severity='contrast' className='!px-7' onClick={handleCancel}>
                <Translate value='cancel' />
              </Button>
              <Button
                loading={useSubmitUser.isPending}
                severity='info'
                className='!px-7'
                onClick={() => {
                  if (userObject) {
                    if (roleType == 'primary') onNext?.(userObject, true);
                    else useSubmitUser.mutate();
                  }
                }}
              >
                <Translate
                  value={roleType == 'primary' ? 'user.create.next' : 'user.create.submit'}
                />
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RolesAssigned;
