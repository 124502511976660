import { ArrowLeft, ArrowRight } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { MouseEventHandler } from 'react';
import DropdownInput from '../DropdownInput';
import './Paginator.css';

const PaginatorTemplate = {
  layout: 'PrevPageLink PageLinks RowsPerPageDropdown NextPageLink',
  PrevPageLink: (options: {
    className: any;
    onClick: MouseEventHandler<HTMLButtonElement> | undefined;
    disabled: boolean | undefined;
  }) => {
    return (
      <Button
        severity='secondary'
        className={classNames(options.className, 'border-round')}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <ArrowLeft />
        <span className='p-3 text-sm-semibold text-neutral-text-1'>Previous</span>
        <Ripple />
      </Button>
    );
  },
  NextPageLink: (options: {
    className: any;
    onClick: MouseEventHandler<HTMLButtonElement> | undefined;
    disabled: boolean | undefined;
  }) => {
    return (
      <Button
        severity='secondary'
        className={classNames(options.className, 'border-round')}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <span className='p-3 text-sm-semibold text-neutral-text-1'>Next</span> <ArrowRight />
        <Ripple />
      </Button>
    );
  },
  PageLinks: (options: {
    view: { startPage: number; endPage: any };
    page: number;
    totalPages: any;
    className: string | undefined;
    onClick: any;
  }) => {
    return (
      <div
        className={`${options.className} w-[40px] h-[40px] rounded-c8 text-neutral-2`}
        onClick={options.onClick}
      >
        {options.page + 1}
        <Ripple />
      </div>
    );
  },
  RowsPerPageDropdown: (options: { value: any; options: any; onChange: any }) => {
    if (!options.options || options.options.length === 0) return null;

    return (
      <DropdownInput options={options.options} value={options.value} onChange={options.onChange} />
    );
  },
};

export default PaginatorTemplate;
