import { RoleGuard } from '../../components';
import Dashboard from './Dashboard';
import GenerateReports from './GenerateReports';
import reportRoles from './reportRoles';
import ScheduleReports from './ScheduleReports';

const reportsRoutes = [
  {
    id: 'reports-dashboard',
    index: true,
    path: 'view',
    handle: {
      breadcrumb: 'view',
    },
    element: (
      <RoleGuard roles={reportRoles.VIEW_REPORT}>
        <Dashboard />
      </RoleGuard>
    ),
  },
  {
    id: 'schedule-reports',
    path: 'schedule',
    index: true,
    handle: {
      breadcrumb: 'Schedule',
    },
    element: (
      <RoleGuard roles={reportRoles.CREARE_REPORT}>
        <ScheduleReports />
      </RoleGuard>
    ),
  },
  {
    id: 'generate-reports',
    path: 'generate',
    index: true,
    handle: {
      breadcrumb: 'Generate',
    },
    element: (
      <RoleGuard roles={reportRoles.CREARE_REPORT}>
        <GenerateReports />
      </RoleGuard>
    ),
  },
];

export default reportsRoutes;
