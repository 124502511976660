import {
  ArrowDown,
  CheckmarkOutline,
  CloseOutline,
  Help,
  TrashCan,
  View,
} from '@carbon/icons-react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { InheritedMenu, Status, WarningIcon } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { MoreAction } from '../../../../icons';
import { useAppDispatch } from '../../../../store';
import { hideModal, showModal } from '../../../../store/slices/main/confirmModalSlice';
import {
  selectCurrentCustomer,
  selectLoading,
  sendToast,
} from '../../../../store/slices/main/mainSlice';
import { fetchRoles, setSelectedRole } from '../../../../store/slices/role/roleSlice';
import { convertDateFormat, formatDate } from '../../../../utils/helper';
import rolePermission from '../rolePermission';
import RoleService from '../roleService';

interface RolesTableProps {
  title: string;
  description: string;
  data: Role[];
}

const RolesTable: React.FC<RolesTableProps> = ({ title, description, data }) => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const currentCustomer = useSelector(selectCurrentCustomer);
  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const loading = useSelector(selectLoading);

  const menu = useRef<Menu>(null);
  const navigate = useNavigate();

  const actionBodyTemplate = (role: Role) => {
    // Build actions array dynamically based on role properties
    const roleActions: MenuItem[] = [
      {
        label: 'viewDetails',
        onClick: () => {
          navigate('users', { state: { role: role } });
        },
        icon: <View />,
        roles: rolePermission.ViewRole,
      },
      {
        label: 'deactivateRole',
        onClick: () => {
          deActivateRole(role.id);
        },
        icon: <CloseOutline />,
        roles: rolePermission.UpdateRole,
      },
      {
        label: 'activateRole',
        onClick: () => {
          activateRole(role.id);
        },
        icon: <CheckmarkOutline />,
        roles: rolePermission.UpdateRole,
      },
    ];
    // Only add the Delete action for non-primary roles
    if (!role.isPrimary) {
      roleActions.push({
        label: 'delete',
        onClick: () => showDeleteConfirmation(role),
        icon: <TrashCan />,
        className: 'delete-menu-item',
        roles: rolePermission.DeleteRole,
      });
    }

    const actionFilter: MenuItem[] = roleActions.filter((item) => {
      if (role.isActive && item.label === 'activateRole') {
        return false;
      }
      if (!role.isActive && item.label === 'deactivateRole') {
        return false;
      }
      if (!role.isActive && item.label === 'assignUsers') {
        return false;
      }
      if (role.isPrimary && ['deactivateRole', 'activateRole'].includes(item.label)) {
        return false;
      }
      return true;
    });

    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActions(actionFilter);
            dispatch(setSelectedRole(role));
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const showDeleteConfirmation = (role: Role) => {
    dispatch(
      showModal({
        visible: true,
        title: 'role.delete.confirm.title',
        message: 'role.delete.confirm.desc',
        confirmLabel: 'role.delete.confirm.btn',
        cancelLabel: 'button_cancel',
        onConfirm: () => void confirmRoleDelete(role),
        onCancel: () => cancelRoleDelete(),
        headerIcon: <WarningIcon />,
      }),
    );
  };

  const confirmRoleDelete = async (role: Role) => {
    const { data } = await RoleService.deleteRole(role.id);

    const toastSummary = data.result.success
      ? 'role.delete.success.title'
      : 'role.delete.fail.title';
    const toastDetail = data.result.success ? 'role.delete.success.desc' : 'role.delete.fail.desc';
    dispatch(
      sendToast({
        severity: data.result.success ? 'success' : 'error',
        summary: toastSummary,
        detail: toastDetail,
        params: { roleName: role.roleName },
      }),
    );

    if (data?.result?.success) {
      //refresh primary & custom roles data in store
      currentCustomer?.id && appDispatch(fetchRoles(currentCustomer.id.toString()));
    }
  };

  const cancelRoleDelete = () => {
    dispatch(hideModal());
  };

  const getUsersText = (e: Role) => {
    let usersText = '';
    if (e.firstUserName) {
      usersText += e.firstUserName;
    }

    if (e.firstUserName && e.numberOfUsers > 1)
      usersText = `${usersText} + ${e.numberOfUsers - 1} more`;

    return usersText ? usersText : 'N/A';
  };

  const getFormattedDate = (e: Role) => {
    if (!e.createdDate) return 'N/A';

    return formatDate(e.createdDate);
  };

  const deActivateRole = async (roleId: number) => {
    const { status, data } = await RoleService.deactivateRoles({
      roleIds: [roleId],
    });

    if (status !== 200) {
      console.log('error');
      return;
    }

    const toastSummary = data.result.success ? 'roleDeactivated' : 'roleDeactivationFailed';
    const toastDetail = data.result.success ? 'roleDeactivationSuccess' : 'roleDeactivationError';
    dispatch(
      sendToast({
        severity: data.result.success ? 'success' : 'error',
        summary: toastSummary,
        detail: toastDetail,
      }),
    );

    //refresh primary & custom roles data in store
    currentCustomer?.id && appDispatch(fetchRoles(currentCustomer.id.toString()));
  };

  const activateRole = async (roleId: number) => {
    const { status, data } = await RoleService.activateRoles({
      roleIds: [roleId],
    });

    if (status !== 200) {
      console.log('error');
      return;
    }

    const toastSummary = data.result.success ? 'activatedSuccessfully' : 'roleActivationFailed';
    const toastDetail = data.result.success ? 'roleActivationSuccessfull' : 'roleActivationError';
    dispatch(
      sendToast({
        severity: data.result.success ? 'success' : 'error',
        summary: toastSummary,
        detail: toastDetail,
      }),
    );

    //refresh primary & custom roles data in store
    currentCustomer?.id && appDispatch(fetchRoles(currentCustomer.id.toString()));
  };

  return (
    <div className='mt-5 mb-5 card'>
      <div className='text-xs-bold text-neutral-1 mb-2'>
        <Translate value={title} />
      </div>
      <div className='text-neutral-3 text-md-regular mb-5'>
        <Translate value={description} />
      </div>

      <div className='inline-block space-x-2 p-3 rounded-full bg-primary-surface-lighter mb-5'>
        <span className='text-primary font-bold'>
          <Translate value='totalRoles' />:
        </span>
        <span className='text-primary font-bold'>{data?.length}</span>
      </div>

      <DataTable
        value={data}
        className='p-datatable-gridlines'
        loading={loading && data.length == 0}
      >
        <Column
          field='roleName'
          header={
            <span className='text-xs-medium'>
              <Translate value='role' />
              <Help className='ml-1 inline text-neutral-4' />
            </span>
          }
        ></Column>
        <Column
          field='usersAssigned'
          header={
            <span className='text-xs-medium'>
              <Translate value='usersAssigned' />
            </span>
          }
          headerStyle={{ borderTopWidth: 0 }}
          body={(e) => getUsersText(e)}
        ></Column>
        <Column
          field='dateAssigned'
          header={
            <span className='text-xs-medium'>
              <Translate value='dateAssigned' />
              <Help className='ml-1 inline text-neutral-4' />
            </span>
          }
          headerStyle={{ borderTopWidth: 0 }}
          body={(e) => getFormattedDate(e)} // Static content
        ></Column>
        <Column
          field='isActive'
          header={
            <span className='text-xs-medium'>
              <Translate value='status' />
              <ArrowDown className='ml-1 inline text-neutral-4' />
            </span>
          }
          headerStyle={{ borderTopWidth: 0 }}
          body={(role: Role) => <Status status={!role.isActive ? 'INACTIVE' : 'ACTIVE'} />}
        ></Column>
        <Column
          field='createdDate'
          header={<Translate value='created_date' />}
          body={(role: Role) => {
            if (role.createdDate) {
              return convertDateFormat(role.createdDate);
            }
          }}
        />
        <Column header='Actions' body={actionBodyTemplate}></Column>
      </DataTable>
    </div>
  );
};

export default RolesTable;
