import { PageHead, RoleGuard } from '../../components';
import { RouteRoles } from '../../RouteRoles';
import { AddOrEditOrView, ClientForms } from '../../types';
import clientRole from '../Client/clientRole';
import SiteDetails from '../Client/SiteDetails';
import CurrencyOutlet from './Currency';
import currencyRoutes from './Currency/routes';
import DisclaimerOutlet from './Disclaimer';
import disclaimerRoutes from './Disclaimer/routes';
import EmailSettingsOutlet from './EmailSettings';
import emailSettingsRoutes from './EmailSettings/routes';
import ManagedPricingOutlet from './ManagePricing';
import managedPricingRoutes from './ManagePricing/routes';

const configurationRoutes = [
  {
    id: 'currency',
    path: 'currency',
    handle: {
      breadcrumb: 'Currency',
    },
    element: (
      <RoleGuard roles={RouteRoles.Currency}>
        <CurrencyOutlet />
      </RoleGuard>
    ),
    children: currencyRoutes,
  },
  {
    id: 'managed-pricing',
    path: 'managed-pricing',
    handle: {
      breadcrumb: 'Managed Pricing',
    },
    element: (
      <RoleGuard
        roles={RouteRoles.ManagedPricing}
        configKey='marginDefinitionEnabled,feeDefinitionEnabled'
      >
        <ManagedPricingOutlet />
      </RoleGuard>
    ),
    children: managedPricingRoutes,
  },
  {
    id: 'disclaimer',
    path: 'disclaimer',
    handle: {
      breadcrumb: 'Disclaimer',
    },
    element: (
      <RoleGuard roles={RouteRoles.configuration} configKey='disclaimersEnabled'>
        <DisclaimerOutlet />
      </RoleGuard>
    ),
    children: disclaimerRoutes,
  },
  {
    id: 'emailSettings',
    path: 'email-settings',
    handle: {
      breadcrumb: 'Email Settings',
    },
    element: (
      <RoleGuard roles={RouteRoles.configuration} configKey='emailSettingsEnabled'>
        <EmailSettingsOutlet />
      </RoleGuard>
    ),
    children: emailSettingsRoutes,
  },
  {
    id: 'client-branding',
    path: 'branding',
    handle: {
      breadcrumb: 'Branding Settings',
    },
    element: (
      <RoleGuard roles={clientRole.MangeBaranding} configKey='brandingConfigEnabled'>
        <div className='pl-[20px] lg:pl-[49px] py-[16px] bg-white border-b border-neutral-border'>
          <PageHead
            title='configuration.site_details.title'
            description='site_details.description'
          />
        </div>
        <div className='dashboard-padding mt-10'>
          <SiteDetails
            mode={AddOrEditOrView.Add}
            activeStep={ClientForms.SiteDetails}
            fromConfiguration={true}
          />
        </div>
      </RoleGuard>
    ),
  },
];

export default configurationRoutes;
