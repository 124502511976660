import {
  CustomerSubType,
  CustomerType,
  KycStatus,
  SettlementMethod,
  SsoProvider,
  Status,
} from '../../types';

const CUSTOMER_TYPE_OPTIONS = [
  {
    label: 'Ordering Customer',
    value: CustomerType.ORDERING_CUSTOMER,
  },
  {
    label: 'Ordering Institution',
    value: CustomerType.ORDERING_INSTITUTION,
  },
  {
    label: 'Instructing Institution',
    value: CustomerType.INSTRUCTING_INSTITUTION,
  },
];

const SETTLEMENT_METHOD_OPTIONS = [
  {
    label: 'Direct debit',
    value: SettlementMethod.DIRECT_DEBIT,
  },
  {
    label: 'Wire',
    value: SettlementMethod.WIRE,
  },
  {
    label: 'Sweep',
    value: SettlementMethod.SWEEP,
  },
  {
    label: 'Auto Debit',
    value: SettlementMethod.AUTO_DEBIT,
  },
];

const USER_STATUS_OPTIONS = [
  { label: 'Active', value: Status.ACTIVE },
  { label: 'Inactive', value: Status.INACTIVE },
  { label: 'Pending approval', value: Status.PENDING_APPROVAL },
];

const CUSTOMER_STATUS_OPTIONS = [
  { label: 'Active', value: Status.ACTIVE },
  { label: 'Inactive', value: Status.INACTIVE },
  { label: 'Pending approval', value: Status.PENDING_APPROVAL },
  { label: 'Rejected', value: Status.REJECTED_CREATION },
];

const KYC_INDICATOR_OPTIONS = [
  { label: 'Inactive', value: KycStatus.INACTIVE },
  { label: 'Active', value: KycStatus.ACTIVE },
];

const ROLES_OPTIONS = [{ label: 'Test', value: 'Test' }];

const CUSTOMER_CURRENCY_OPTIONS = [
  { label: 'USD', value: 'USD' },
  { label: 'INR', value: 'INR' },
  { label: 'EUR', value: 'EUR' },
];

const CLIENT_OBJECT_MAPPING = {
  addressId: '',
};

const EMAIL_VALIDATION_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const SSO_PROVIDER_OPTIONS = [{ label: 'Admin', value: SsoProvider.ADMIN }];

const CUSTOMER_SUB_TYPE_OPTIONS = [
  {
    label: 'Consumer',
    value: CustomerSubType.CONSUMER,
  },
  {
    label: 'Corporate',
    value: CustomerSubType.CORPORATE,
  },
];

const BANK_TYPE_OPTIONS = [{ label: 'ABA', value: 'ABA' }];

const REVENUE_MODEL_OPTIONS = [
  {
    label: 'Revenue share',
    value: 'REVENUE_SHARE',
  },
  {
    label: 'Mark-up',
    value: 'MARK_UP',
  },
];

export {
  BANK_TYPE_OPTIONS,
  CLIENT_OBJECT_MAPPING,
  CUSTOMER_CURRENCY_OPTIONS,
  CUSTOMER_STATUS_OPTIONS,
  CUSTOMER_SUB_TYPE_OPTIONS,
  CUSTOMER_TYPE_OPTIONS,
  EMAIL_VALIDATION_REGEX,
  KYC_INDICATOR_OPTIONS,
  ROLES_OPTIONS,
  SETTLEMENT_METHOD_OPTIONS,
  SSO_PROVIDER_OPTIONS,
  USER_STATUS_OPTIONS,
  REVENUE_MODEL_OPTIONS,
};

