import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CountrySelect, CurrencySelect, MultiSelectInput, TextInput } from '../../../components';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { PageHeadContext } from '../../../providers/PageHead';
import { useAppDispatch } from '../../../store';
import { selectCurrentCustomer, sendToast } from '../../../store/slices/main/mainSlice';
import { Status } from '../../../types';
import { enumToCamelCase } from '../../../utils/helper';
import ClientService from '../../Client/clientService';
import UserService from '../../Manage/userService';
import { DELIVERY_OPTIONS, REPORT_FORMAT_OPTIONS } from '../report';
import ReportsService from '../reportsService';
import { recipientTemplate } from '../reportsUtils';
import './index.css';

const GenerateReports = () => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  const dispatch = useAppDispatch();
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const customer = useSelector(selectCurrentCustomer);
  const queryClient = useQueryClient();
  const formData = watch();

  useEffect(() => {
    reset({});
  }, [reset]);

  useEffect(() => {
    //Set default value for report format
    const reportNames = Array.isArray(formData['reportName']) ? formData['reportName'] : [];
    reportNames.forEach((report: ReportType) => {
      if (!formData[`reportFormat_${report.reportName}`]) {
        setValue(`reportFormat_${report.reportName}`, [REPORT_FORMAT_OPTIONS[0].value]);
        setValue(`deliveryOptions_${report.reportName}`, ['ONLINE']);
      }
      if (report.reportName === 'ORDER_DETAILS_REPORT') {
        setValue(
          `customerIds_${report.reportName}`,
          customerUsers.data?.map((customer: any) => customer.id) || [],
        );
      }
    });
  }, [formData['reportName']]);

  useEffect(() => {
    setPageHeadData({
      title: 'generateReports',
      description: 'generateReportsDesc',
      className: 'pb-4 pr-4',
    });
  }, [setPageHeadData, dispatch]);

  const showToast = (severity: 'success' | 'error', summary: string, detail: string) => {
    dispatch(sendToast({ severity, summary, detail }));
  };

  const genrateReportMutation = useMutation({
    mutationFn: async (newData: Schedule[]) => {
      try {
        const response = await ReportsService.generateSchedule(newData);
        return response;
      } catch (error) {
        showToast('error', 'Error', 'generate_failure_toast');
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['schedules'] });
      showToast('success', 'Success', 'generate_success_toast');
    },
    onError: () => {
      showToast('error', 'Error', 'generate_failure_toast');
    },
  });

  const onGenerate = () => {
    const reports = formData['reportName'].map((report: ReportType) => {
      const reportName = report.reportName;
      const reportParams: any = {};
      if (formData[`dateRange_${reportName}`]) {
        reportParams['startDate'] = formData[`dateRange_${reportName}`][0];
        reportParams['endDate'] = formData[`dateRange_${reportName}`][1];
        reportParams['startDate'] = new Date(formData[`dateRange_${reportName}`][0]);
        reportParams['startDate'].setDate(reportParams['startDate'].getDate() + 1);
        reportParams['endDate'] = new Date(formData[`dateRange_${reportName}`][1]);
        reportParams['endDate'].setDate(reportParams['endDate'].getDate() + 1);
        reportParams['startDate'] = reportParams['startDate'].toISOString().split('T')[0];
        reportParams['endDate'] = reportParams['endDate'].toISOString().split('T')[0];
      }

      if (formData[`customerIds_${reportName}`])
        reportParams['customerIds'] = formData[`customerIds_${reportName}`];
      if (formData[`country_${reportName}`])
        reportParams['country'] = formData[`country_${reportName}`].isocode;
      if (formData[`status_${reportName}`])
        reportParams['status'] = formData[`status_${reportName}`];
      if (formData[`buyCurrency_${reportName}`])
        reportParams['buyCurrency'] = formData[`buyCurrency_${reportName}`];
      if (formData[`name_${reportName}`]) reportParams['name'] = formData[`name_${reportName}`];
      if (formData[`accountNumber_${reportName}`])
        reportParams['accountNumber'] = formData[`accountNumber_${reportName}`];
      if (formData[`nickName_${reportName}`])
        reportParams['nickName'] = formData[`nickName_${reportName}`];
      if (formData[`beneficiaryId_${reportName}`])
        reportParams['beneficiaryId'] = formData[`beneficiaryId_${reportName}`];

      return {
        reportName,
        deliveryChannels: formData[`deliveryOptions_${reportName}`] || undefined,
        reportTypes: formData[`reportFormat_${reportName}`] || undefined,
        reportParam: JSON.stringify(reportParams) || undefined,
        reportRecipients:
          formData[`recipient_${reportName}`]?.map((recipient: any) => ({
            userId: recipient.userId,
            userEmail: recipient.userEmail,
          })) || undefined,
      };
    });

    genrateReportMutation.mutate(reports);
  };

  const reportNames = useQuery({
    queryKey: ['reports'],
    queryFn: async () => {
      const data: ReportType[] = await ReportsService.getReportNames();
      return data;
    },
  });

  const recipient = useQuery({
    queryKey: ['recipients'],
    queryFn: async () => {
      const data: UsersResponse | null = await UserService.getCustomerUser(
        customer?.id as number,
        0,
        10,
      );
      return data?.content;
    },
  });

  const customerUsers = useQuery({
    queryKey: ['customerUsers'],
    queryFn: async () => {
      const data: ClientResponse | null = await ClientService.getChildClients(
        customer?.id as number,
        0,
        0,
        {
          searchText: '',
        },
        '',
        null,
        true,
      );
      return data?.content;
    },
  });

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onGenerate)}>
        <div className='!p-8 card mt-8'>
          <p className='text-xs-semibold'>
            <Translate value='selectReportTypeTitle' />
          </p>
          <p className='text-md-regular text-neutral-3'>
            <Translate value='selectReportTypeDesc' />
          </p>
          <MultiSelectInput
            label='reportName'
            name='reportName'
            options={
              reportNames.data?.map((report) => {
                return {
                  label: enumToCamelCase(report.reportName),
                  value: report,
                };
              }) || []
            }
            placeholder={translateWithValues('selectReportName')}
            className='w-[40%] mt-4'
            isRequired
            rules={{ required: true }}
            control={control}
            error={errors.reportName}
          />
        </div>
        {formData['reportName']?.map((report: ReportType) => (
          <div key={report.reportName} className='!p-8 card mt-8'>
            <div>
              <p className='text-md-medium'>{enumToCamelCase(report.reportName)}</p>
            </div>
            <div className='mb-6 mt-4 grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
              <Controller
                name={`dateRange_${report.reportName}`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className='flex flex-col gap-1 mt-4'>
                    <label className='whitespace-nowrap overflow-hidden text-ellipsis inline-block'>
                      {translateWithValues('dateRange')}
                    </label>
                    <Calendar
                      {...field}
                      showTime={false}
                      selectionMode='range'
                      placeholder={translateWithValues('selectDateRange')}
                      className='w-full'
                      tooltip={errors[`dateRange_${report.reportName}`] && 'Error'}
                      invalid={!!errors?.[`dateRange_${report.reportName}`]}
                      panelClassName='custom-calendar-panel'
                      hideOnRangeSelection={true}
                      pt={{
                        input: {
                          root: {
                            className: errors[`dateRange_${report.reportName}`]
                              ? 'border !border-error-2'
                              : '',
                          },
                        },
                      }}
                    />
                  </div>
                )}
              />

              <MultiSelectInput
                name={`reportFormat_${report.reportName}`}
                label='reportFormat'
                options={REPORT_FORMAT_OPTIONS}
                placeholder={translateWithValues('selectReportFormat')}
                className='w-full mt-4'
                control={control}
                rules={{ required: true }}
                isRequired
                error={errors[`reportFormat_${report.reportName}`]}
              />

              <MultiSelectInput
                name={`deliveryOptions_${report.reportName}`}
                label='deliveryOptions'
                options={DELIVERY_OPTIONS}
                placeholder={translateWithValues('selectDeliveryOptions')}
                className='w-full mt-4'
                control={control}
                isRequired
                rules={{ required: true }}
                error={errors[`deliveryOptions_${report.reportName}`]}
              />

              {formData[`deliveryOptions_${report.reportName}`]?.includes('EMAIL') && (
                <MultiSelectInput
                  name={`recipient_${report.reportName}`}
                  label='recipient'
                  options={
                    Array.isArray(recipient.data)
                      ? recipient.data.map((recipient) => ({
                          label: recipient.userIdentity.email,
                          value: {
                            userId: recipient.userIdentity.id,
                            userEmail: recipient.userIdentity.email,
                            userFullName:
                              recipient.userIdentity.firstName +
                              ' ' +
                              recipient.userIdentity.lastName,
                          },
                        }))
                      : []
                  }
                  placeholder={translateWithValues('selectRecipient')}
                  className='w-full mt-4 p-0 p-inputtext p-component'
                  isRequired
                  rules={{ required: true }}
                  control={control}
                  itemTemplate={recipientTemplate}
                  error={errors[`recipient_${report.reportName}`]}
                  handleFilter={(e) => {
                    UserService.getCustomerUser(customer?.id as number, 0, 10, {
                      searchTerm: e.filter,
                    }).then((data) => {
                      queryClient.setQueryData(['recipients'], data?.content);
                    });
                  }}
                />
              )}

              {reportNames.data
                ?.find((r) => r.reportName == report.reportName)
                ?.reportUIParams.map((p) => {
                  switch (p.parameterName) {
                    case 'customerIds':
                      return (
                        <MultiSelectInput
                          key={p.parameterName}
                          name={`customerIds_${report.reportName}`}
                          label='customerId'
                          options={
                            customerUsers.data?.map((customer: any) => ({
                              label: customer.customerShortName || customer.id,
                              value: customer.id,
                            })) || []
                          }
                          placeholder={translateWithValues('customerId')}
                          className='w-full mt-4 p-0 p-inputtext p-component'
                          control={control}
                          error={errors[`customerIds_${report.reportName}`]}
                        />
                      );
                    case 'country':
                      return (
                        <CountrySelect
                          key={p.parameterName}
                          label={`${report.reportName}_${p.parameterName}`}
                          placeholder='country'
                          className='w-full mt-4'
                          name={`country_${report.reportName}`}
                          control={control}
                          error={errors[`country_${report.reportName}`]}
                        />
                      );
                    case 'status':
                      return (
                        <MultiSelectInput
                          key={p.parameterName}
                          name={`status_${report.reportName}`}
                          label='status'
                          options={[
                            { label: 'Active', value: Status.ACTIVE },
                            { label: 'Inactive', value: Status.INACTIVE },
                            { label: 'Pending', value: Status.PENDING },
                          ]}
                          placeholder={translateWithValues('status')}
                          className='w-full mt-4 p-0 p-inputtext p-component'
                          control={control}
                          error={errors[`status_${report.reportName}`]}
                        />
                      );
                    case 'buyCurrency':
                      return (
                        <CurrencySelect
                          name={`buyCurrency_${report.reportName}`}
                          label='buy_currency'
                          key={p.parameterName}
                          placeholder='buyCurrency'
                          currencySource='buy'
                          control={control}
                          className='w-full mt-4'
                          error={errors[`buyCurrency_${report.reportName}`]}
                        />
                      );
                    default:
                      if (
                        ['name', 'accountNumber', 'nickName', 'beneficiaryId'].includes(
                          p.parameterName,
                        )
                      ) {
                        return (
                          <TextInput
                            formRegister={register(`${p.parameterName}_${report.reportName}`)}
                            key={p.parameterName}
                            name={`${p.parameterName}_${report.reportName}`}
                            label={`${report.reportName}_${p.parameterName}`}
                            className='w-full mt-4'
                            placeholder={`${report.reportName}_${p.parameterName}`}
                            error={errors[`${p.parameterName}_${report.reportName}`]}
                          />
                        );
                      }
                  }
                })}
            </div>
          </div>
        ))}

        {formData['reportName']?.length > 0 && (
          <div className='flex justify-center mt-5 gap-2'>
            <Button severity='secondary' type='button'>
              <Translate value='cancel' />
            </Button>
            <Button
              severity='info'
              type='submit'
              onClick={() => {
                if (!isValid) {
                  showToast('error', 'error', 'client.field.validation.error');
                }
              }}
            >
              <Translate value='generate' />
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

export default GenerateReports;
