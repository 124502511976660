import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CountrySelect, ErrorIcon, TextInput } from '../../components';
import { Translate, TranslateWithValues, translateWithValues } from '../../i18n/translate';
import { useAppDispatch } from '../../store';
import { sendToast } from '../../store/slices/main/mainSlice';
import { selectCustomerCurrencies } from '../../store/slices/refdata/refdataSlice';
import { cn, createRegexFromString } from '../../utils/helper';
import { getCountryISO2, getCountryISO3 } from '../../utils/isoncCodeMapping';
import PaymentService from '../Payment/paymentService';
import BranchLookupModal from './BranchLookupModal';

type FormType = {
  [key: string]: any;
};

type Props = {
  buyCurrency: string;
  buyAmount?: number;
  beneficiaryCountry: Country;
  form: UseFormReturn<any, any, undefined>;
  isFormDisabled?: boolean;
  routingCodeDetails:
    | {
        intermediary: any;
        credit: any;
      }
    | undefined;
  setRoutingCodeDetails: (codes: any) => void;
  setIsBic?: (isBic: boolean) => void;
  swiftBicCode: string;
  setSwiftBicCode: (swiftBicCode: string) => void;
  iban: string;
  setIban: (iban: string) => void;
};

export default function BeneficiaryForm({
  buyCurrency,
  buyAmount,
  beneficiaryCountry,
  form,
  isFormDisabled,
  routingCodeDetails,
  setRoutingCodeDetails,
  setIsBic,
  swiftBicCode,
  setSwiftBicCode,
  iban,
  setIban,
}: Props) {
  const {
    register,
    formState: { errors },
    watch,
    trigger,
    setValue,
    control,
  } = form;

  const selectedCustomerCurrencies = useSelector(selectCustomerCurrencies);
  const dispatch = useAppDispatch();

  const homeCountries = selectedCustomerCurrencies.find(
    (item) => item.isocode === buyCurrency,
  )?.homeCountries;

  const beneficiaryDataQuery = useQuery({
    queryKey: ['request_quote_beneficiary', beneficiaryCountry.code, buyCurrency],
    queryFn: async () => {
      const data = await PaymentService.getBeneficiaryFormByCountry(
        getCountryISO2(beneficiaryCountry.code),
        buyCurrency,
      );
      return data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!beneficiaryCountry && !!buyCurrency,
  });

  const isBic = useMemo(
    () =>
      beneficiaryDataQuery.data?.find((beneficiary) =>
        beneficiary.mappings.includes('CreditorAgent.RoutingCode'),
      )?.fieldLabelIdentifier === 'SwiftBICCode',
    [beneficiaryDataQuery.data],
  );

  // const isInterMidateBic = useMemo(
  //   () =>
  //     beneficiaryDataQuery.data?.find((beneficiary) =>
  //       beneficiary.mappings.includes('CreditorAgent.RoutingCode'),
  //     )?.fieldLabelIdentifier === 'SwiftBICCode',
  //   [beneficiaryDataQuery.data],
  // )

  const branchDataQuery = useQuery({
    queryKey: ['branch_data', beneficiaryCountry.code, 'SwiftBICCode', swiftBicCode, '', ''],
    queryFn: async () => {
      const data = await PaymentService.getBICCode(
        getCountryISO2(beneficiaryCountry.code),
        swiftBicCode,
        '',
        '',
      );
      setIsBic?.(
        beneficiaryDataQuery.data?.find((beneficiary) =>
          beneficiary.mappings.includes('CreditorAgent.RoutingCode'),
        )?.fieldLabelIdentifier === 'SwiftBICCode',
      );
      const autoCompletes = beneficiaryDataQuery.data?.find((beneficiary) =>
        beneficiary.mappings.includes('CreditorAgent.RoutingCode'),
      )?.autoCompletes;
      if (autoCompletes) {
        if (data && data.length === 1) {
          autoCompletes.forEach((autoComplete) => {
            setValue(autoComplete.fieldIdReference, data[0][autoComplete.fieldId]);
            trigger(autoComplete.fieldIdReference);
          });
          setRoutingCodeDetails((prev: any) => {
            return {
              ...prev,
              credit: data[0],
            };
          });
        } else {
          autoCompletes.forEach((autoComplete) => {
            setValue(autoComplete.fieldIdReference, '');
          });
        }
      }
      return data;
    },
    enabled: !!swiftBicCode,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const verifyIbanExistsQuery = useQuery({
    queryKey: ['verify_iban_exists', iban],
    queryFn: async () => {
      try {
        const data = await PaymentService.verifyIbanExists(iban);
        if (data.ibanBic) {
          setValue('BankCode', data.ibanBic);
          trigger('BankCode');
          setSwiftBicCode(data.ibanBic);
        } else {
          dispatch(
            sendToast({
              severity: 'warn',
              summary: 'iban_check.warn',
            }),
          );
        }
        return data;
      } catch {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'failed',
            detail: 'iban_check.warn',
          }),
        );
        return null;
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!iban && !errors.IBAN && iban === watch('IBAN'),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (
      verifyIbanExistsQuery.data?.ibanBic &&
      verifyIbanExistsQuery.data?.ibanBic !== watch('BankCode')
    ) {
      setValue('BankCode', verifyIbanExistsQuery.data.ibanBic);
      setSwiftBicCode(verifyIbanExistsQuery.data.ibanBic);
    }
  }, [verifyIbanExistsQuery.data?.ibanBic]);

  if (beneficiaryDataQuery.isLoading) {
    return (
      <div className='text-neutral-3 h-full grid place-content-center'>
        <Translate value='loading' />
      </div>
    );
  }

  if (beneficiaryDataQuery.isError) {
    return (
      <div className='text-neutral-3 h-full grid place-content-center'>
        <Translate value='beneficiary_loading_error' className='text-neutral-3 text-center' />
      </div>
    );
  }

  if (beneficiaryDataQuery.data?.length === 0) {
    return (
      <div className='text-neutral-3 h-full grid place-content-center'>
        <Translate value='no_beneficiary_data' className='text-neutral-3 text-center' />
      </div>
    );
  }

  function groupByOrder(arr: Array<BeneficiaryType>) {
    const grouped = arr.reduce<Record<number, Array<BeneficiaryType>>>((acc, obj) => {
      if (!acc[obj.order]) {
        acc[obj.order] = [];
      }
      acc[obj.order].push(obj);
      return acc;
    }, {});

    return Object.entries(grouped).map(([key, value]) => {
      return { [key]: value };
    });
  }

  const mainFieldElements: Array<BeneficiaryType> = [];
  const additionalFieldElements: Array<BeneficiaryType> = [];
  beneficiaryDataQuery.data?.forEach((beneficiary) => {
    if (
      !beneficiary.rules.some((rule) => {
        if (buyAmount && rule.ruleName === 'CONDITIONAL MANDATORY ON AMOUNT') {
          const [operator, numberStr] = (rule.ruleValues[0] as string).split(',');
          const number = parseFloat(numberStr);
          if (!isNaN(number)) {
            switch (operator) {
              case 'lt':
                if (buyAmount < number) return true;
                break;
              case 'gt':
                if (buyAmount > number) return true;
                break;
              case 'lte':
                if (buyAmount <= number) return true;
                break;
              case 'gte':
                if (buyAmount >= number) return true;
                break;
              default:
                break;
            }
          }
        }
        return rule.ruleName === 'MANDATORY';
      })
    ) {
      return;
    }

    if (beneficiary.displaySection === 'Beneficiary Details') {
      mainFieldElements.push(beneficiary);
    } else {
      additionalFieldElements.push(beneficiary);
    }
  });

  const fields = [...groupByOrder(mainFieldElements), ...groupByOrder(additionalFieldElements)];

  return (
    <div className='grid gap-5 place-content-start'>
      {fields.map((order, index) => (
        <>
          {index === mainFieldElements.length ? (
            <p className='text-md-semibold text-neutral-3 -mb-2 mt-3'>
              <Translate value='additional_information' />
            </p>
          ) : null}
          <div key={`order-${index}`} className='grid gap-5 md:grid-cols-2 lg:grid-cols-3'>
            {Object.values(order)[0].map((beneficiary: BeneficiaryType, i) => {
              const isIntermediaryAgent = beneficiary.mappings.includes(
                'IntermediaryAgents.[0].RoutingCode',
              );
              const isRequired = beneficiary.rules.some((rule) => rule.ruleName === 'MANDATORY');
              const maxLength = beneficiary.rules.find((rule) => rule.ruleName === 'MAX LENGTH')
                ?.ruleValues[0] as number;
              const minLength = beneficiary.rules.find((rule) => rule.ruleName === 'MIN LENGTH')
                ?.ruleValues[0] as number;
              const fixLength = beneficiary.rules.find((rule) => rule.ruleName === 'FIX LENGTH')
                ?.ruleValues as Array<string>;
              const prefix = beneficiary.rules.find((rule) => rule.ruleName === 'PREFIX VALUE')
                ?.ruleValues as Array<string>;
              const emailFormat = beneficiary.rules.some((rule) => rule.ruleName === 'FORMAT EMAIL')
                ? RegExp(
                    beneficiary.rules.find((rule) => rule.ruleName === 'FORMAT EMAIL')
                      ?.ruleValues[0] as string,
                  )
                : undefined;
              const regexValidation = beneficiary.rules.some(
                (rule) => rule.ruleName === 'REGEX VALIDATION',
              )
                ? RegExp(
                    beneficiary.rules.find((rule) => rule.ruleName === 'REGEX VALIDATION')
                      ?.ruleValues[0] as string,
                  )
                : undefined;
              const pattern = beneficiary.rules.some(
                (rule) => rule.ruleName === 'ALLOWED CHARACTER SET',
              )
                ? createRegexFromString(
                    beneficiary.rules.find((rule) => rule.ruleName === 'ALLOWED CHARACTER SET')
                      ?.ruleValues[0] as string,
                  )
                : undefined;
              const mandatoryKeywords = beneficiary.rules.find(
                (rule) => rule.ruleName === 'MANDATORY KEYWORD',
              )?.ruleValues as Array<string>;
              const prohibitedKeywords = beneficiary.rules.find(
                (rule) => rule.ruleName === 'PROHIBITED KEYWORD',
              )?.ruleValues as Array<string>;

              if (beneficiary.fieldLabelIdentifier === 'BeneficiaryCountryofResidence') {
                return (
                  <CountrySelect
                    key={i}
                    label={beneficiary.fieldLabel}
                    placeholder={beneficiary.fieldName}
                    name={beneficiary.fieldIdentifier}
                    isRequired={isRequired}
                    control={control}
                    rules={{ required: isRequired }}
                    error={errors[beneficiary.fieldIdentifier]}
                    disabled={isFormDisabled}
                  />
                );
              }

              const restrictedList = beneficiary.rules.find(
                (rule) => rule.ruleName === 'RESTRICTED TO SET LIST OF VALUES',
              );
              if (restrictedList) {
                const error = errors[beneficiary.fieldIdentifier];

                const errorMessage = error
                  ? error.type === 'required'
                    ? `${beneficiary.fieldLabel} ${translateWithValues('is_required')}`
                    : error.message?.toString()
                  : '';

                return (
                  <div key={i} className='col-span-3'>
                    <label
                      htmlFor={beneficiary.fieldIdentifier}
                      className='w-fit overflow-hidden grid gap-1 grid-cols-[1fr_auto]'
                    >
                      <span className='truncate'>{beneficiary.fieldLabel}</span>
                      {isRequired ? <span className='text-error-1'>*</span> : null}
                    </label>
                    <IconField iconPosition='right'>
                      <Controller
                        name={beneficiary.fieldIdentifier}
                        control={control}
                        rules={{ required: isRequired }}
                        disabled={isFormDisabled}
                        render={({ field }) => (
                          <Dropdown
                            id={beneficiary.fieldIdentifier}
                            virtualScrollerOptions={{ itemSize: 40 }}
                            className='min-w-[200px]'
                            tooltip={errorMessage}
                            options={restrictedList.ruleValues.map((item) => ({
                              value: item,
                              label: `${item}-${translateWithValues(item)}`,
                            }))}
                            placeholder={beneficiary.fieldName}
                            value={field.value}
                            filter
                            onChange={(e) => field.onChange(e.value)}
                            invalid={!!error}
                            disabled={isFormDisabled}
                          />
                        )}
                      />
                      {!!error && (
                        <InputIcon className='mr-[23px]'>
                          <ErrorIcon isFilled={false} size={16} withBackground={false} />
                        </InputIcon>
                      )}
                    </IconField>
                  </div>
                );
              }

              const textInputComponent = (
                <TextInput
                  label={beneficiary.fieldLabel}
                  isRequired={isRequired}
                  placeholder={beneficiary.fieldName}
                  maxLength={maxLength}
                  minLength={minLength}
                  formRegister={register(beneficiary.fieldIdentifier, {
                    onChange: (e) => {
                      if (
                        beneficiary.mappings.includes('CreditorAgent.RoutingCode') ||
                        isIntermediaryAgent
                      ) {
                        setValue(beneficiary.fieldIdentifier, e.target.value.trim());
                        trigger(beneficiary.fieldIdentifier);
                      } else if (beneficiary.fieldIdentifier === 'IBAN') {
                        setValue(beneficiary.fieldIdentifier, e.target.value.replaceAll(' ', ''));
                      }
                    },
                    required: isRequired,
                    maxLength: maxLength
                      ? {
                          value: maxLength,
                          message: translateWithValues('max_length_error', { maxLength }),
                        }
                      : undefined,
                    minLength: minLength
                      ? {
                          value: minLength,
                          message: translateWithValues('min_length_error', { minLength }),
                        }
                      : undefined,
                    pattern: emailFormat
                      ? {
                          value: emailFormat,
                          message: emailFormat ? 'invalid_email' : 'invalid_characters',
                        }
                      : pattern
                        ? {
                            value: pattern,
                            message: 'invalid_characters',
                          }
                        : undefined,
                    validate: (value: string) => {
                      if (
                        mandatoryKeywords &&
                        mandatoryKeywords.length > 0 &&
                        !mandatoryKeywords.some(
                          (keyword) => value.toLocaleLowerCase() === keyword.toLocaleLowerCase(),
                        )
                      ) {
                        return `Must be ${mandatoryKeywords.join(' or ')}`;
                      }
                      if (
                        prohibitedKeywords &&
                        prohibitedKeywords.length > 0 &&
                        prohibitedKeywords.some((keyword) =>
                          value.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()),
                        )
                      ) {
                        return `Must not contain ${prohibitedKeywords.join(' or ')}`;
                      }
                      if (
                        fixLength &&
                        fixLength.length > 0 &&
                        !fixLength.includes(value.length.toString())
                      ) {
                        return `Must be ${fixLength.join(' or ')} characters long`;
                      }
                      if (
                        prefix &&
                        prefix.length > 0 &&
                        !prefix.some((prefixValue) => value.startsWith(prefixValue))
                      ) {
                        return `Must start with ${prefix.join(' or ')}`;
                      }
                      if (regexValidation) {
                        if (!regexValidation.test(value)) {
                          return 'input_validation_failed';
                        }
                      }
                      return true;
                    },
                  })}
                  error={errors[beneficiary.fieldIdentifier]}
                  className={cn(
                    beneficiary.fieldLabelIdentifier === 'BeneficiaryAccountName' && 'col-span-3',
                  )}
                  onBlurCapture={() => {
                    if (isBic && beneficiary.fieldIdentifier === 'IBAN' && watch('IBAN')) {
                      setIban(watch('IBAN'));
                    }
                  }}
                />
              );

              if (
                beneficiary.mappings.includes('CreditorAgent.RoutingCode') ||
                isIntermediaryAgent
              ) {
                return (
                  <div
                    key={`${beneficiary.fieldIdentifier}-${i}`}
                    className='grid md:col-span-2 lg:col-span-3 gap-5 mb-5'
                  >
                    <BankCode
                      form={form}
                      showLoader={
                        branchDataQuery.isFetching &&
                        beneficiary.fieldLabelIdentifier === 'SwiftBICCode'
                      }
                      fieldLabel={beneficiary.fieldLabel}
                      fieldValue={watch(beneficiary.fieldIdentifier)}
                      buyCurrency={buyCurrency}
                      countries={
                        isIntermediaryAgent && homeCountries?.length
                          ? homeCountries
                              .map((item) => getCountryISO3(item))
                              .filter((item) => item !== undefined)
                          : [beneficiaryCountry.code]
                      }
                      autoCompletes={beneficiary.autoCompletes}
                      updateBicCode={(bicCode) => {
                        setValue(beneficiary.fieldIdentifier, bicCode);
                        trigger(beneficiary.fieldIdentifier);
                        if (beneficiary.fieldLabelIdentifier === 'SwiftBICCode') {
                          setSwiftBicCode(bicCode);
                        }
                      }}
                      routingCodeDetails={routingCodeDetails}
                      setRoutingCodeDetails={setRoutingCodeDetails}
                      fieldLabelIdentifier={beneficiary.fieldLabelIdentifier}
                      swiftBicCode={swiftBicCode}
                      isButtonDisabled={
                        !!errors[beneficiary.fieldIdentifier] || !watch(beneficiary.fieldIdentifier)
                      }
                    >
                      {textInputComponent}
                    </BankCode>
                  </div>
                );
              }

              return (
                <React.Fragment key={`${beneficiary.fieldIdentifier}-${i}`}>
                  {textInputComponent}
                </React.Fragment>
              );
            })}
          </div>
        </>
      ))}
    </div>
  );
}

function BankCode({
  form,
  showLoader,
  fieldLabel,
  fieldValue,
  buyCurrency,
  countries,
  autoCompletes,
  updateBicCode,
  routingCodeDetails,
  setRoutingCodeDetails,
  fieldLabelIdentifier,
  swiftBicCode,
  isButtonDisabled,
  children,
}: {
  form: UseFormReturn<FormType, any, undefined>;
  showLoader: boolean;
  fieldLabel: string;
  fieldValue: string;
  buyCurrency: string;
  countries: Array<string>;
  autoCompletes: BeneficiaryType['autoCompletes'];
  updateBicCode: (bicCode: string) => void;
  routingCodeDetails:
    | {
        intermediary: any;
        credit: any;
      }
    | undefined;
  setRoutingCodeDetails: (codes: any) => void;
  fieldLabelIdentifier: string;
  swiftBicCode: string;
  isButtonDisabled: boolean;
  children: React.ReactNode;
}) {
  const [branchDialogVisible, setBranchDialogVisible] = useState(false);
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
  } = form;

  const dispatch = useAppDispatch();

  const isSwiftBicCode =
    fieldLabelIdentifier === 'SwiftBICCode' ||
    fieldLabelIdentifier === 'IntermediaryBankSwiftBICCode';

  function fillAutoCompleteFields(bicCodeData: Record<string, string>) {
    autoCompletes.forEach((autoComplete) => {
      if (isSwiftBicCode) {
        if (autoComplete.fieldId === 'OpCity') {
          setValue(autoComplete.fieldIdReference, bicCodeData.OpCity || bicCodeData.ReCity);
        } else if (autoComplete.fieldId === 'ReCity') {
          setValue(autoComplete.fieldIdReference, bicCodeData.ReCity || bicCodeData.OpCity);
        } else if (autoComplete.fieldId === 'OpStreetAddress1') {
          setValue(
            autoComplete.fieldIdReference,
            bicCodeData.OpStreetAddress1 ||
              bicCodeData.ReStreetAddress1 ||
              bicCodeData.OpCity ||
              bicCodeData.ReCity,
          );
        } else if (autoComplete.fieldId === 'ReStreetAddress1') {
          setValue(
            autoComplete.fieldIdReference,
            bicCodeData.ReStreetAddress1 ||
              bicCodeData.OpStreetAddress1 ||
              bicCodeData.ReCity ||
              bicCodeData.OpCity,
          );
        } else {
          setValue(autoComplete.fieldIdReference, bicCodeData[autoComplete.fieldId]);
        }
      } else if (autoComplete.fieldId === 'StreetAddress1') {
        setValue(autoComplete.fieldIdReference, bicCodeData.StreetAddress1 || bicCodeData.City);
      } else {
        setValue(autoComplete.fieldIdReference, bicCodeData[autoComplete.fieldId]);
      }

      trigger(autoComplete.fieldIdReference);
    });
  }

  const getInterMediariesDataQuery = useQuery({
    queryKey: ['getInterMediariesData', buyCurrency, swiftBicCode],
    queryFn: async () => {
      const data = await PaymentService.getInterMediariesData(buyCurrency, swiftBicCode);
      if (data && data.length > 0 && data[0].intermediary && data[0].intermediary.length === 1) {
        const { bankcode, localcode } = data[0].intermediary[0];
        setValue('IntermediaryBankCode', bankcode || localcode);
        setValue('IntermediaryBankName', data[0].intermediary[0].bankname);
        setValue('IntermediaryBankAddressCity', data[0].intermediary[0].address[1]);
        setValue('IntermediaryBankAddressStreet', data[0].intermediary[0].address[0]);
      }
      return data;
    },
    enabled:
      (fieldLabelIdentifier === 'IntermediaryBankABACode' ||
        fieldLabelIdentifier === 'IntermediaryBankSwiftBICCode') &&
      !!swiftBicCode &&
      !!buyCurrency,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const branchDataQuery = useQuery({
    queryKey: [
      'branch_data',
      countries[0],
      fieldLabelIdentifier,
      fieldValue?.toUpperCase(),
      '',
      '',
    ],
    queryFn: async () => {
      try {
        const data = isSwiftBicCode
          ? await PaymentService.getBICCode(
              getCountryISO2(countries[0]),
              fieldValue?.toUpperCase(),
              '',
              '',
            )
          : await PaymentService.getBankDirectory(
              getCountryISO2(countries[0]),
              fieldValue?.toUpperCase(),
              '',
              '',
            );
        if (data && data.length === 1) {
          updateBicCode(isSwiftBicCode ? data[0].Bic : data[0].NationalId);
          fillAutoCompleteFields(data[0]);
          setRoutingCodeDetails((prev: any) => {
            return {
              ...prev,
              [!fieldLabelIdentifier.includes('Intermediary') ? 'credit' : 'intermediary']: data[0],
            };
          });
        } else {
          autoCompletes.forEach((autoComplete) => {
            setValue(autoComplete.fieldIdReference, '');
          });
          if (data && data.length > 1) {
            dispatch(
              sendToast({
                severity: 'error',
                summary: 'more_than_one_entry',
                detail: 'country_code.more_than_one_entry',
              }),
            );
          } else {
            dispatch(
              sendToast({
                severity: 'error',
                summary: 'no_data',
                detail: 'country_code.no_data',
              }),
            );
          }
        }
        return data;
      } catch (error) {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'failed_to_fetch_data',
            detail: 'country_code.failed_to_fetch_data',
          }),
        );
        throw error;
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!routingCodeDetails && fieldValue) {
      branchDataQuery.refetch();
    }
  }, []);

  if (fieldLabelIdentifier === 'IntermediaryBankABACode' && getInterMediariesDataQuery.isPending) {
    return null;
  }

  return (
    <>
      <div className='relative grid gap-5 bg-primary-surface-light p-6 rounded-lg'>
        {showLoader && (
          <div className='absolute z-10 inset-0 bg-white/50 grid place-content-center'>
            <ProgressSpinner className='w-10 h-10' />
          </div>
        )}
        <div className='flex gap-6 h-fit'>
          {children}
          <Button
            type='button'
            severity='info'
            loading={branchDataQuery.isFetching}
            className='mt-7 w-28 flex justify-center gap-2'
            onClick={() => void branchDataQuery.refetch()}
            disabled={isButtonDisabled || branchDataQuery.isFetching}
          >
            <Translate value='done' />
          </Button>
        </div>
        <Button
          link
          type='button'
          className='text-selected w-fit text-sm-semibold'
          onClick={() => setBranchDialogVisible(true)}
        >
          <Translate value='search_code' />
        </Button>
      </div>
      <div className='grid gap-5 md:grid-cols-2 lg:grid-cols-3'>
        {autoCompletes.map((autoComplete) => (
          <TextInput
            key={autoComplete.fieldIdReference}
            label={autoComplete.fieldIdReference}
            isRequired
            placeholder={autoComplete.fieldIdReference}
            formRegister={register(autoComplete.fieldIdReference, {
              required: true,
            })}
            error={errors[autoComplete.fieldIdReference]}
            disabled
          />
        ))}
      </div>
      <Dialog
        className='w-full md:w-3/5 max-h-[100%] m-0 !rounded-none full-screen-dialog'
        header={
          <>
            <div className='text-lg font-semibold text-neutral-1'>
              <TranslateWithValues value='branch_lookup.header' params={{ label: fieldLabel }} />
            </div>
            <div className='text-sm-regular mt-1 text-neutral-3'>
              <Translate value='branch_lookup.subtitle' />
            </div>
          </>
        }
        visible={branchDialogVisible}
        style={{
          height: '100vh',
          position: 'fixed',
          top: 0,
          right: 0,
        }}
        onHide={() => setBranchDialogVisible(false)}
        draggable={false}
      >
        <BranchLookupModal
          buyCurrency={buyCurrency}
          countries={countries}
          fieldLabelIdentifier={fieldLabelIdentifier}
          fieldLabel={fieldLabel}
          onBicCodeSelect={(bicCodeData) => {
            setRoutingCodeDetails((prev: any) => {
              return {
                ...prev,
                [!fieldLabelIdentifier.includes('Intermediary') ? 'credit' : 'intermediary']:
                  bicCodeData,
              };
            });
            fillAutoCompleteFields(bicCodeData);
            setBranchDialogVisible(false);
            updateBicCode(isSwiftBicCode ? bicCodeData.Bic : bicCodeData.NationalId);
          }}
          closeModal={() => setBranchDialogVisible(false)}
        />
      </Dialog>
      <div className='mt-5 h-[1px] bg-neutral-5' />
    </>
  );
}
