import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import createOktaClient from './auth/Okta';
import { Toaster } from './components';
import ConfirmModal from './components/Confirm/confirmModal';
import useRole from './hooks/useRoles';
import { Translate } from './i18n/translate';
import CustomerSelectionDialog from './modules/UserPrinciple/CustomerSelectionDialog';
import { RouteRoles } from './RouteRoles';
import routes from './routes';
import store, { useAppDispatch } from './store';
import { fetchCurrentCustomerSettings, fethCurrentUser } from './store/slices/main/actions';
import {
  selecFethCurrentUserFulfilled,
  selectCurrentCustomer,
  selectToast,
} from './store/slices/main/mainSlice';
import {
  fetchAppRefdata,
  fetchBuyCurrency,
  fetchCountryList,
  fetchCurrency,
  fetchSellCurrency,
} from './store/slices/refdata/actions';
import { injectOktaClient, injectStore } from './utils/apiClient';

function App() {
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const fethCurrentUserFulfilled = useSelector(selecFethCurrentUserFulfilled);
  const dispatch = useAppDispatch();
  const toast = useSelector(selectToast);
  const toastRef = useRef<ToasterProps>(null);
  const { hasRole } = useRole();
  const [oktaClient, setOktaClient] = useState<OktaAuth | null>(null);
  const [oktaHasError, setOktaHasError] = useState<boolean>(false);

  useEffect(() => {
    createOktaClient()
      .then((client) => {
        setOktaClient(client);
        injectOktaClient(client);
      })
      .catch((error) => {
        setOktaHasError(true);
        console.error('Failed to create okta client', error);
      });
  }, []);

  useEffect(() => {
    if (toast) {
      toastRef.current?.showToast(
        toast.severity,
        toast.summary,
        '',
        toast.life,
        toast.detail,
        toast.params,
      );
    }
  }, [toast]);

  useEffect(() => {
    if (selectedCustomer) {
      dispatch(fethCurrentUser());
      dispatch(fetchAppRefdata());
      dispatch(fetchCountryList());
    }
  }, [selectedCustomer]);

  useEffect(() => {
    const customerId = selectedCustomer?.id;
    if (!customerId) {
      return;
    }
    dispatch(fetchCurrentCustomerSettings(customerId));

    if (hasRole(RouteRoles.hasCurrencyPermission)) {
      dispatch(fetchCurrency(customerId));
      dispatch(fetchSellCurrency(customerId));
      dispatch(fetchBuyCurrency(customerId));
    }
  }, [fethCurrentUserFulfilled, selectedCustomer]);

  injectStore(store);

  return (
    <>
      <CustomerSelectionDialog />
      <ConfirmModal />
      <Toaster ref={toastRef} />
      {oktaHasError && <Translate value='failed_to_initalize_okta_client' />}
      {oktaClient && (
        <Security
          oktaAuth={oktaClient}
          restoreOriginalUri={() => {
            window.location.href = '/';
          }}
        >
          <RouterProvider router={routes} />
        </Security>
      )}
    </>
  );
}

export default App;
