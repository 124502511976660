import { Button } from 'primereact/button';
import { useState } from 'react';
import { Translate } from '../../../../i18n/translate';
import { AddAlt } from '../../../../icons';
import BeneficiaryCreationModal from '../Dashboard/BenificieryCreationModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import BeneficiaryService from '../../../Beneficiaries/beneficiaryService';
import { DropdownInput } from '../../../../components';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../../store';
import { sendToast } from '../../../../store/slices/main/mainSlice';
import ViewBeneficiaryModal from './ViewBeneficiaryModal';

interface BeneficiaryDetailsProps {
  orderId: number;
  disabled: boolean;
  quoteId: string;
  paymentId: number;
  buyCurrency: string;
  buyAmount: number;
}

export const BeneficiaryDetails = ({
  orderId,
  disabled,
  quoteId,
  paymentId,
  buyCurrency,
  buyAmount,
}: BeneficiaryDetailsProps) => {
  const [visible, setVisible] = useState(false);
  const [beneficiaryId, setBeneficiaryId] = useState('');
  const [viewBeneficiaryModalOpen, setViewBeneficiaryModalOpen] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const beneficiaryListQuery = useQuery({
    queryKey: ['beneficiary_list'],
    queryFn: async () => {
      const data = await BeneficiaryService.getAllBeneficiaries({
        currency: [buyCurrency],
        status: ['ACTIVE'],
      });
      return data?.content || [];
    },
    enabled: !!buyCurrency,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const addPaymentCreditorMutation = useMutation({
    mutationFn: async () => {
      const data = await BeneficiaryService.addPaymentCreditor(orderId, paymentId, beneficiaryId);
      return data;
    },
    onSuccess: () => {
      navigate(`/payment/rate-requests/summary?orderId=${orderId}`);
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'Failed',
          detail: error.message,
        }),
      );
    },
  });

  return (
    <div className='relative'>
      {disabled && <div className='absolute inset-0 bg-white opacity-70 cursor-not-allowed z-10' />}
      <div className={(disabled ? 'opacity-50	' : '') + 'rounded-c8 shadow-c bg-white p-8'}>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='beneficiary.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='beneficiary.description' />
            </div>
          </div>
        </div>
        {beneficiaryListQuery.isLoading ? (
          <div className='mt-4'>
            <Translate value='loadingBeneficiary' />
          </div>
        ) : beneficiaryListQuery.error ? (
          <div className='mt-4'>
            <Translate value='errorLoadingBeneficiary' />
          </div>
        ) : (
          <div>
            <div className='mt-8 flex flex-wrap items-start gap-10 text-neutral-2 text-sm-medium'>
              <div>
                <DropdownInput
                  disabled={disabled}
                  className='lg:min-w-[250px]'
                  placeholder='change_beneficiary'
                  options={(beneficiaryListQuery.data || []).map((item) => ({
                    label: item.nickName,
                    value: item.id,
                  }))}
                  label='select_existing_beneficiary'
                  isRequired
                  filter
                  value={beneficiaryId}
                  onChange={(e) => setBeneficiaryId(e.value)}
                />
                {beneficiaryId && (
                  <Button
                    link
                    className='pl-0 text-primary'
                    onClick={() => {
                      setViewBeneficiaryModalOpen(true);
                    }}
                  >
                    <Translate value='view_beneficiary.header' />
                  </Button>
                )}
              </div>
              <div className='mt-6'>
                <Button
                  severity='contrast'
                  className='h-[43px]'
                  onClick={() => setVisible(true)}
                  type='button'
                >
                  <AddAlt />
                  <Translate value='add_new_beneficiary.header' className='ml-1' />
                </Button>
              </div>
            </div>
            <div className='flex items-center justify-center mt-10 gap-5'>
              <Button
                type='submit'
                severity='info'
                className='w-52 gap-2 inline-flex items-center justify-center'
                loading={addPaymentCreditorMutation.isPending}
                disabled={
                  beneficiaryListQuery.isLoading ||
                  !beneficiaryId ||
                  addPaymentCreditorMutation.isPending
                }
                onClick={() => addPaymentCreditorMutation.mutate()}
              >
                <Translate value='save_and_proceed' />
              </Button>
            </div>
          </div>
        )}
      </div>
      <BeneficiaryCreationModal
        visible={visible}
        setVisible={setVisible}
        quoteId={quoteId}
        paymentId={paymentId}
        orderId={orderId}
        buyCurrency={buyCurrency}
        buyAmount={buyAmount}
      />
      <ViewBeneficiaryModal
        visible={viewBeneficiaryModalOpen}
        setVisible={setViewBeneficiaryModalOpen}
        beneficiaryId={Number(beneficiaryId)}
      />
    </div>
  );
};
