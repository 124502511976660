import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { Translate } from '../../../i18n/translate';
import { CurrencyFlag, FormatAmount } from '../../../components';
import { useSelector } from 'react-redux';
import { selectCustomerCurrencies } from '../../../store/slices/refdata/refdataSlice';

const PaymentBar = ({ payment }: { payment?: Order }) => {
  const currencies = useSelector(selectCustomerCurrencies);
  return (
    <div className='bg-white dashboard-padding py-6'>
      {!payment ? (
        <div className='grid place-content-center'>
          <ProgressSpinner className='w-10 h-10' />
        </div>
      ) : (
        <div>
          <div className='text-neutral-1 text-sm-bold'>
            <Translate value='payment_id' />
            {': '}
            {payment.id}
          </div>
          <div className='grid grid-cols-1 md:grid-cols-5 gap-1 md:gap-10 mt-4'>
            <div className='flex flex-row justify-between md:flex-col'>
              <div className='text-neutral-3 text-sm-medium'>
                <Translate value='buy_currency' />
              </div>
              <div>
                <CurrencyFlag currencyCode={payment.payments[0]?.buyCurrency} />
              </div>
            </div>
            <div className='sm:border-r border-neutral-border flex flex-row justify-between md:flex-col '>
              <div className='text-neutral-3 text-sm-medium'>
                <Translate value='buy_amount' />
              </div>
              <div className='text-md-medium'>
                <FormatAmount
                  number={payment.payments[0]?.buyAmount}
                  maximumFractionDigits={
                    currencies.find(
                      (currency) => payment.payments[0]?.buyCurrency == currency.isocode,
                    )?.amountPrecision
                  }
                />
              </div>
            </div>
            <div className='flex flex-row justify-between md:flex-col'>
              <div className='text-neutral-3 text-sm-medium'>
                <Translate value='sell_currency' />
              </div>
              <div>
                <CurrencyFlag currencyCode={payment.sellCurrency} />
              </div>
            </div>
            <div className='border-r border-neutral-border flex flex-row justify-between md:flex-col'>
              <div className='text-neutral-3 text-sm-medium'>
                <Translate value='sell_amount' />
              </div>
              <div className='text-md-medium'>
                <FormatAmount
                  number={payment.payments[0]?.sellAmount}
                  maximumFractionDigits={
                    currencies.find((currency) => payment.sellCurrency == currency.isocode)
                      ?.amountPrecision
                  }
                />
              </div>
            </div>
            <div className='flex flex-row justify-between md:flex-col'>
              <div className='text-neutral-3 text-sm-medium'>
                <Translate value='beneficiary_name' />
              </div>
              <div className='text-md-medium'>{payment.payments[0]?.creditor?.accountName}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentBar;
