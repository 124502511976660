import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomTabView } from '../../components';
import useRole from '../../hooks/useRoles';
import { Translate } from '../../i18n/translate';
import { PageHeadContext } from '../../providers/PageHead';
import { selectOktaUser } from '../../store/slices/main/mainSlice';
import scrollEventEmitter from '../../utils/eventEmitter';
import PaymentsTable from '../Payment/RateRequest/Dashboard/PaymentsTable';
import PendingTaskWidget from './PendingTaskWidget';
import QuickQuoteForm from './QuickQuoteForm';
import { dashboardRole } from './role';

const Dashboard = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const oktaUser = useSelector(selectOktaUser);
  const { hasRole } = useRole();
  const paymentsTableRef = useRef<HTMLDivElement | null>(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const tabs = [
    {
      loading: isLoading,
      headerNode: (
        <div>
          <Translate value='pending_payment_tab' />
          {activeIndex === 0 && !isLoading ? (
            <span className='ml-2 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-white'>
              {totalRecords}
            </span>
          ) : (
            ''
          )}
        </div>
      ),
      content: (
        <PaymentsTable
          isDashboard={true}
          filterCategory='PENDING'
          totalRecords={totalRecords}
          setTotalRecords={setTotalRecords}
          onFetchingChange={setIsLoading}
        />
      ),
    },
    {
      loading: isLoading,
      headerNode: (
        <div>
          <Translate value='pending_execution' />
          {activeIndex === 1 && !isLoading ? (
            <span className='ml-2 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-white'>
              {totalRecords}
            </span>
          ) : (
            ''
          )}
        </div>
      ),
      content: (
        <PaymentsTable
          isDashboard={true}
          filterCategory='IN_PROCESSING'
          totalRecords={totalRecords}
          setTotalRecords={setTotalRecords}
          onFetchingChange={setIsLoading}
        />
      ),
    },
    {
      loading: isLoading,
      headerNode: (
        <div>
          <Translate value='completed' />
          {activeIndex === 2 && !isLoading ? (
            <span className='ml-2 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-white'>
              {totalRecords}
            </span>
          ) : (
            ''
          )}
        </div>
      ),
      content: (
        <PaymentsTable
          isDashboard={true}
          filterCategory='PAYMENT_COMPLETED'
          totalRecords={totalRecords}
          setTotalRecords={setTotalRecords}
          onFetchingChange={setIsLoading}
        />
      ),
    },
  ];

  useEffect(() => {
    setPageHeadData({
      title: 'welcome',
      description: 'dashboard.description',
      className: 'pb-[16px] pr-4',
      params: { userName: oktaUser?.name },
    });
  }, [setPageHeadData]);

  useEffect(() => {
    const handleScroll = (id: string) => {
      if (id === 'paymentsDiv' && paymentsTableRef.current) {
        paymentsTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    scrollEventEmitter.on('scrollTo', handleScroll);

    return () => {
      scrollEventEmitter.off('scrollTo', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className='px-[20px] sm:px-[49px]'>
        <div className='flex flex-col lg:flex-row gap-8 mt-8'>
          <QuickQuoteForm />
          {hasRole(dashboardRole.PendingWidgetRole) && <PendingTaskWidget />}
        </div>
        {hasRole(dashboardRole.payment) && (
          <div className='card mt-8' ref={paymentsTableRef}>
            <div className='text-lg-semibold'>
              <Translate value='all_payments' />
            </div>
            <CustomTabView tabs={tabs} initialIndex={0} updateActiveIndex={setActiveIndex} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
