import { useContext, useEffect, useState } from 'react';
import { Translate } from '../../../i18n/translate';
import { PageHeadContext } from '../../../providers/PageHead';
import PaymentsTable from '../../Payment/RateRequest/Dashboard/PaymentsTable';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../store';
import { sendToast } from '../../../store/slices/main/mainSlice';
import { TicketType } from '../../../types';

interface PaymentSelectProps {
  onProceed: (selectedPayment: Order | null) => void;
  ticketType: TicketType;
  setTicketType: (ticketType: TicketType) => void;
}

const PaymentSelect = ({ onProceed, ticketType, setTicketType }: PaymentSelectProps) => {
  const context = useContext(PageHeadContext);
  const dispatch = useAppDispatch();
  const { setPageHeadData } = context;
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [selectedPayment, setSelectedPayment] = useState<Order | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setPageHeadData({
      title: 'create_new_case',
      description: 'create_new_case.description',
    });
  }, [setPageHeadData]);

  const ReasonTypeOptions = [
    {
      value: TicketType.INVESTIGATION,
      label: 'select_existing_payment.yes',
      subLabel: 'select_existing_payment.yes.subtitle',
    },
    {
      value: TicketType.QUERY,
      label: 'select_existing_payment.no',
      subLabel: 'select_existing_payment.no.subtitle',
    },
  ];

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const handleProceed = () => {
    if (ticketType === TicketType.INVESTIGATION && !selectedPayment) {
      showToast('error', 'please_select_a_payment', '');
    } else {
      onProceed(selectedPayment);
    }
  };

  return (
    <div className='p-8'>
      <div className='card'>
        <div>
          <div className='text-neutral-1 text-sm-bold'>
            <Translate value='case_related_to_payment.query' />
          </div>
          <div className='text-neutral-3 text-md-regular'>
            <Translate value='select_answer_to_create_new_case' />
          </div>
        </div>
        <fieldset className='grid grid-cols-1 sm:grid-cols-2 gap-8 mt-8 lg:w-4/5'>
          {ReasonTypeOptions.map((option) => (
            <div key={option.label} className='flex gap-4'>
              <RadioButton
                checked={ticketType === option.value}
                inputId={option.label}
                value={option.value}
                onChange={(e) => {
                  if (!e.value) {
                    setSelectedPayment(null);
                  }
                  setTicketType(e.value);
                }}
              />
              <label htmlFor={option.label}>
                <div className='text-neutral-1 text-md-medium'>
                  <Translate value={option.label} />
                </div>
                <div className='text-neutral-3 text-md-regular'>
                  <Translate value={option.subLabel} />
                </div>
              </label>
            </div>
          ))}
        </fieldset>
      </div>
      {ticketType === TicketType.INVESTIGATION && (
        <div className='card mt-6'>
          <div className='py-4'>
            <div className='text-neutral-1 text-sm-bold'>
              <Translate value='payment_table_selectable.header' />
            </div>
            <div className='text-neutral-3 text-md-regular'>
              <Translate value='payment_table.selectable.subtitle' />
            </div>
          </div>
          <PaymentsTable
            totalRecords={totalRecords}
            setTotalRecords={setTotalRecords}
            selectable={true}
            selectedPayment={selectedPayment}
            setSelectedPayment={setSelectedPayment}
            filterCategory='IN_PROCESSING'
          />
        </div>
      )}
      <div className='flex gap-4 justify-center mt-8'>
        <Button
          severity='contrast'
          onClick={() => {
            navigate('/get-help');
          }}
        >
          <Translate value='cancel' />
        </Button>
        <Button severity='info' onClick={handleProceed}>
          <Translate value='proceed' />
        </Button>
      </div>
    </div>
  );
};

export default PaymentSelect;
