import { Button } from 'primereact/button';
import { Translate } from '../../../i18n/translate';
import FileList from './FileList';
import PaymentBar from './PaymentBar';
import { useNavigate } from 'react-router';
import PaymentDetails from './PaymentDetails';
import { TicketType } from '../../../types';
import { useMutation } from '@tanstack/react-query';
import CaseService from '../caseService';
import { useAppDispatch } from '../../../store';
import { sendToast } from '../../../store/slices/main/mainSlice';
import { ProgressSpinner } from 'primereact/progressspinner';

interface CaseSummaryProps {
  caseData: CaseDetailsFormData | null;
  payment?: Order;
  ticketType: TicketType;
}

const CaseSummary = ({ caseData, payment, ticketType }: CaseSummaryProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const createCaseMutation = useMutation({
    mutationFn: async (payload: CreateCasePayload): Promise<CreateCaseResponse> => {
      const response = await CaseService.createcase(payload);
      if (response) {
        return response;
      } else {
        throw new Error('Failed to create case: Response is null');
      }
    },
    onSuccess: (response: CreateCaseResponse) => {
      showToast('success', 'case_created', response.response);
      if (caseData?.files) {
        uploadFilesSequentially(caseData.files, response.supportTicketId);
      }
    },
    onError: () => {
      showToast('error', 'case_creation_failed', '');
    },
  });

  const uploadFileMutation = useMutation({
    mutationFn: async ({ file, caseId }: { file: File; caseId: string }) => {
      const response = await CaseService.uploadCaseFile(file, caseId);
      if (response.success) {
        return response;
      } else {
        throw new Error('Failed to create file');
      }
    },
    onSuccess: (data, variables) => {
      showToast('success', 'file_uploaded', `File ${variables.file.name} uploaded successfully.`);
    },
    onError: (error, variables) => {
      showToast('error', 'file_upload_failed', `Failed to upload file ${variables.file.name}.`);
      console.error('File upload error:', error);
    },
  });

  const uploadFilesSequentially = async (files: File[], caseId: string) => {
    for (const file of files) {
      try {
        await uploadFileMutation.mutateAsync({ file, caseId });
      } catch (error) {
        console.error('File upload failed:', error);
      }
    }
    navigate('/get-help');
  };

  const handleCaseSubmit = () => {
    const payload: CreateCasePayload = {
      ReasonCode: caseData?.reasonCode ?? '',
      TopicCode: caseData?.topicCode ?? '',
      Description: caseData?.description ?? '',
    };
    if (ticketType === TicketType.INVESTIGATION) {
      payload.fxid = caseData?.fxid;
    }

    createCaseMutation.mutate(payload);
  };

  const isLoading = createCaseMutation.isPending || uploadFileMutation.isPending;

  return (
    <div>
      {isLoading && (
        <div className='absolute z-10 inset-0 bg-white/50 grid place-content-center'>
          <ProgressSpinner className='w-10 h-10' />
        </div>
      )}
      {payment && <PaymentBar payment={payment} />}
      <div className='dashboard-padding mt-4'>
        <div className='card'>
          <div className='text-neutral-1 text-sm-bold'>
            <Translate value='case_details' />
          </div>
          <div className='py-6 border-b border-neutral-border'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 sm:w-5/6'>
              <div>
                <div className='text-sm-medium text-neutral-3'>
                  <Translate value='case_topic' /> <span className='text-error-1'>*</span>
                </div>
                <div className='text-md-medium text-neutral-1'>{caseData?.topic}</div>
              </div>
              <div>
                <div className='text-sm-medium text-neutral-3'>
                  <Translate value='case_reason' /> <span className='text-error-1'>*</span>
                </div>
                <div className='text-md-medium text-neutral-1'>{caseData?.reason}</div>
              </div>
            </div>
          </div>
          {caseData?.description && (
            <div className='py-6 border-b border-neutral-border'>
              <div className='text-sm-medium text-neutral-3'>
                <Translate value='description' /> <span className='text-error-1'>*</span>
              </div>
              <div className='text-md-medium text-neutral-1'>{caseData?.description}</div>
            </div>
          )}
          {caseData?.files && (
            <div className='py-6'>
              <div className='text-sm-medium text-neutral-3 mb-6'>
                <Translate value='attachments' />{' '}
                <div className='rounded-[16px] text-xs-medium inline-block py-[2px] px-[8px] w-max text-neutral-1 bg-neutral-surface-dark ml-4'>
                  <Translate value='total' />: {caseData?.files.length}
                </div>
              </div>
              <FileList files={caseData?.files} removeOption={false} />
            </div>
          )}
        </div>
        {payment && <PaymentDetails payment={payment} />}
        <div className='flex gap-4 justify-center mt-8'>
          <Button
            severity='contrast'
            onClick={() => {
              navigate('/get-help');
            }}
          >
            <Translate value='cancel' />
          </Button>
          <Button severity='info' onClick={handleCaseSubmit}>
            <Translate value='submit' />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CaseSummary;
