import type { UseFormReturn } from 'react-hook-form';
import { Translate } from '../../i18n/translate';
import BeneficiaryForm from '../CommonComponents/BeneficiaryForm';
import { AddOrEditOrView } from '../../types';

type Props = {
  buyCurrency: string;
  beneficiaryCountry: Country | undefined;
  form: UseFormReturn<BeneficiaryFormType, any, undefined>;
  mode: AddOrEditOrView;
  routingCodeDetails:
    | {
        intermediary: any;
        credit: any;
      }
    | undefined;
  setRoutingCodeDetails: (codes: any) => void;
  showForm: boolean;
  setIsBic: (isBic: boolean) => void;
  iban: string;
  setIban: (iban: string) => void;
  swiftBicCode: string;
  setSwiftBicCode: (swiftBicCode: string) => void;
};

export default function BeneficiaryBankDetails({
  buyCurrency,
  beneficiaryCountry,
  form,
  mode,
  routingCodeDetails,
  setRoutingCodeDetails,
  showForm,
  setIsBic,
  iban,
  setIban,
  swiftBicCode,
  setSwiftBicCode,
}: Props) {
  if (!beneficiaryCountry) {
    return <div className='grid gap-8 card w-full place-content-center'>No country selected</div>;
  }

  return (
    <div className='grid gap-8 card w-full'>
      <div>
        <h2 className='text-xs-bold text-neutral-1'>
          <Translate
            value={
              mode === AddOrEditOrView.Add || mode === AddOrEditOrView.Edit
                ? 'beneficiary_bank_details.title'
                : 'beneficiary_bank_details'
            }
          />
        </h2>
        {mode === AddOrEditOrView.Add || mode === AddOrEditOrView.Edit ? (
          <div className='text-sm-regular text-neutral-3'>
            <Translate value='beneficiary_bank_details.description' />
          </div>
        ) : null}
      </div>
      {showForm ? (
        <BeneficiaryForm
          key={beneficiaryCountry.code} // to clear the form when country changes
          buyCurrency={buyCurrency}
          beneficiaryCountry={beneficiaryCountry}
          form={form}
          isFormDisabled={mode !== AddOrEditOrView.Add && mode !== AddOrEditOrView.Edit}
          routingCodeDetails={routingCodeDetails}
          setRoutingCodeDetails={setRoutingCodeDetails}
          setIsBic={setIsBic}
          swiftBicCode={swiftBicCode}
          setSwiftBicCode={setSwiftBicCode}
          iban={iban}
          setIban={setIban}
        />
      ) : (
        <Translate value='beneficiary_bank_details.empty_error' />
      )}
    </div>
  );
}
