import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ApprovalHistory, CustomTabView } from '../../../components';
import { Translate } from '../../../i18n/translate';
import { PageHeadContext } from '../../../providers/PageHead';
import { selectLoading } from '../../../store/slices/main/mainSlice';
import { AddOrEditOrView, CustomerSubType, CustomerType } from '../../../types';
import ClientService from '../clientService';
import CustomerDetails from '../CustomerDetails';
import { CustomerOverview } from '../CustomerOverview';
import SiteDetails from '../SiteDetails';
import UserDetails from '../UserDetails';
import Settings from '../Settings';

const ViewClient = ({ mode }: { mode?: AddOrEditOrView }) => {
  const [customerType, setCustomerType] = useState<CustomerType>();
  const [currentEditiCustomer, setCurrentEditiCustomer] = useState<Client>();
  const [canBeApproved, setCanBeApproved] = useState(false);
  const context = useContext(PageHeadContext);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [isStonexCustomer, setIsStonexCustomer] = useState<boolean | undefined>(undefined);
  const isLoading = useSelector(selectLoading);

  const { setPageHeadData } = context;

  const { customerId } = useParams();

  useEffect(() => {
    getCutomerById();
  }, [customerId]);

  useEffect(() => {
    if (currentEditiCustomer) {
      setIsStonexCustomer(currentEditiCustomer.owningCustomerId == 1);
    }
  }, [currentEditiCustomer]);

  const getCutomerById = async () => {
    if (!customerId) {
      return;
    }
    const client = await ClientService.getClientById(customerId);
    if (client) {
      setCustomerType(client.customerType);
      setPageHeadData({
        title: client.customerShortName ?? 'ediClient.title',
        description: 'view.client.description',
        button: (
          <>
            {mode && [AddOrEditOrView.Approve, AddOrEditOrView.View].includes(mode) && (
              <Button
                severity='secondary'
                className='h-[45px]'
                onClick={() => {
                  setShowHistory(true);
                }}
              >
                <Translate value='approval.history' />
              </Button>
            )}
          </>
        ),
      });
      if (mode == AddOrEditOrView.Approve && customerId) {
        getPendingApprovalItem(customerId, client);
      } else {
        setCurrentEditiCustomer(client);
      }
    }
  };

  const getPendingApprovalItem = async (customerId: string, sourceClient: Client) => {
    try {
      const response = await ClientService.getApprovalItemDetails(customerId);
      const { status } = response;
      if (status === 200 && response?.data?.result?.success) {
        const {
          data: {
            data: { data: client },
          },
        } = response;
        setCurrentEditiCustomer(client);
        setCustomerType(client.customerType);
        setCanBeApproved(client.canBeApprovedByCurrentUser ?? false);
      } else {
        setCanBeApproved(false);
        setCurrentEditiCustomer(sourceClient);
      }
    } catch (error) {
      setCanBeApproved(false);
    }
  };

  const viewTabs = [
    {
      header: 'customer.details.tab.title',
      content: (
        <>
          <CustomerOverview
            currentEditiCustomer={currentEditiCustomer}
            setCustomerType={setCustomerType}
            mode={AddOrEditOrView.View}
            isApproveScreen={mode == AddOrEditOrView.Approve}
            isStonexCustomer={isStonexCustomer}
          />
          <div className='mt-2'></div>
          <CustomerDetails
            customerType={customerType}
            mode={AddOrEditOrView.View}
            currentEditiCustomer={currentEditiCustomer}
            isStonexCustomer={isStonexCustomer}
          />
        </>
      ),
      loading: isLoading,
    },
    {
      header: isStonexCustomer ? 'authorised_signatory' : 'user_details.title',
      content: <UserDetails mode={AddOrEditOrView.View} isStonexCustomer={isStonexCustomer} />,
      loading: isLoading,
    },
    {
      header: 'site_details.title',
      content: <SiteDetails mode={AddOrEditOrView.View} />,
      loading: isLoading,
    },
    {
      header: 'settings.title',
      content: <Settings mode={AddOrEditOrView.View} />,
    },
  ];

  const approveTabs = [
    {
      header: 'customer.details.tab.title',
      content: (
        <>
          <CustomerOverview
            currentEditiCustomer={currentEditiCustomer}
            setCustomerType={setCustomerType}
            mode={AddOrEditOrView.View}
            isApproveScreen={mode == AddOrEditOrView.Approve}
            canBeApproved={canBeApproved}
            isStonexCustomer={isStonexCustomer}
          />
          <div className='mt-2'></div>
          <CustomerDetails
            customerType={customerType}
            mode={AddOrEditOrView.View}
            currentEditiCustomer={currentEditiCustomer}
            isStonexCustomer={isStonexCustomer}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {mode && (
        <>
          <ApprovalHistory
            showHistory={showHistory}
            setShowHistory={setShowHistory}
            recodrdId={customerId ?? ''}
            feature='MANAGE_CUSTOMER'
          />
          <CustomTabView
            tabs={
              mode == AddOrEditOrView.View
                ? currentEditiCustomer?.customerType != CustomerType.ORDERING_CUSTOMER
                  ? viewTabs
                  : currentEditiCustomer.customerSubType != CustomerSubType.CONSUMER
                    ? !currentEditiCustomer.enableForOnline
                      ? viewTabs.slice(0, 1)
                      : viewTabs.slice(0, 2)
                    : !currentEditiCustomer.enableForOnline
                      ? viewTabs.slice(0, 1)
                      : viewTabs
                : approveTabs
            }
            initialIndex={0}
          />
        </>
      )}
    </>
  );
};

export default ViewClient;
