import { UserFollow } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { MultiSelectInput, TextInput, Toaster } from '../../../components';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { useAppDispatch } from '../../../store';
import { selectLoading, sendToast } from '../../../store/slices/main/mainSlice';
import {
  fetchRoles,
  selectCustomRoles,
  selectPrimaryRoles,
} from '../../../store/slices/role/roleSlice';
import { AddOrEditOrView, ClientForms, Status } from '../../../types';
import { EMAIL_VALIDATION_REGEX } from '../clientConstants';
import ClientService from '../clientService';

interface UserDetailsProps {
  activeStep?: string;
  inheritedClientId?: number;
  isStonexCustomer?: boolean;
  mode?: AddOrEditOrView;
  visitPermitted?: Set<string>;
  visited?: Set<string>;
  handleStepperValidation?: (formName: ClientForms, isValid: boolean) => void;
  onSaveAndProceed?: () => void;
  jumpToEdit?: (step: string) => void;
}

const UserDetails = ({
  activeStep,
  inheritedClientId,
  isStonexCustomer,
  mode,
  visitPermitted,
  visited,
  handleStepperValidation,
  onSaveAndProceed,
  jumpToEdit,
}: UserDetailsProps) => {
  const userDefaults: UserDetailsFormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    roles: [],
    aoboRoles: [],
  };
  const [users, setUsers] = useState<UserDetailsFormData[]>([userDefaults]);
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [usersIsInApproval, setUsersIsInApproval] = useState(false);
  const { customerId } = useParams();
  const toastRef = useRef<ToasterProps>(null);
  const [owningCustomerId, setOwningCustomerId] = useState<number>(0);
  const [customerHasUsers, setCustomerHasUsers] = useState<boolean>(false);
  const selectedPrimaryRoles = useSelector(selectPrimaryRoles);
  const selectedCustomroles = useSelector(selectCustomRoles);
  const [roles, setRoles] = useState<Option[]>([]);
  const isLoading = useSelector(selectLoading);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isValid },
    trigger,
  } = useForm<UserDetailsFormData>();

  const formValues = useWatch({ control });

  useEffect(() => {
    setUsers((prev) => {
      const updatedUsers = [...prev];
      updatedUsers[selectedUserIndex] = getValues();
      return updatedUsers;
    });
  }, [formValues, getValues, selectedUserIndex]);

  useEffect(() => {
    const _roles = selectedPrimaryRoles.concat(selectedCustomroles);
    const roleOptions = _roles.map((role) => {
      return { label: role.roleName.toString(), value: role.id.toString() } as Option;
    });
    setRoles(roleOptions);
  }, [selectedPrimaryRoles, selectedCustomroles]);

  const fillUsersDatafromServer = (response: User[]) => {
    let notDeletedUsers = response?.filter((user: User) => user.status !== Status.DELETED);
    if (isStonexCustomer) {
      // For Stonex customers, we need to show only signatory users
      notDeletedUsers = response.filter((user: User) => user.isSignatory);
    }
    setCustomerHasUsers(notDeletedUsers.length != 0);
    const _users = notDeletedUsers.map((user: User) => {
      return {
        ...user,
        id: user.id,
        firstName: user.userIdentity.firstName,
        lastName: user.userIdentity.lastName,
        email: user.userIdentity.email,
        phone: user.userIdentity.mobilePhone,
        roles: user.roles?.map((u) => u.id.toString()) ?? [],
        aoboRoles: user.aoboRoles?.map((u) => u.id.toString()) ?? [],
        status: user.approvalStatus,
      };
    });
    setUsers(_users);
    if (_users.length > 0) {
      const _user = _users[0];
      setUsersIsInApproval(
        _user.status === Status.PENDING_APPROVAL || _user.status === Status.PENDING_CREATION,
      );
      reset(_user);
    } else {
      reset(userDefaults);
    }
  };

  useEffect(() => {
    if (customerId && (mode == AddOrEditOrView.Edit || mode == AddOrEditOrView.View)) {
      setOwningCustomerId(parseInt(customerId));
      fetchUsers(customerId);
    }
  }, [customerId, isStonexCustomer]);

  useEffect(() => {
    if (inheritedClientId) {
      dispatch(fetchRoles(inheritedClientId));
      setOwningCustomerId(inheritedClientId);
      if (isStonexCustomer && mode == AddOrEditOrView.Add) {
        fetchUsers(inheritedClientId.toString());
      }
    }
  }, [inheritedClientId]);

  const fetchUsers = async (cId: string) => {
    if (!cId) {
      return;
    }
    const response = await ClientService.getUsersByCustomerId(cId);
    if (response) {
      fillUsersDatafromServer(response);
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const saveUserDetails = async () => {
    const _users: User[] = [];
    users.forEach((user) => {
      if (user.status === Status.PENDING_APPROVAL || user.status == Status.PENDING_CREATION) {
        return;
      }
      const _user: User = {
        owningCustomerId: owningCustomerId,
        id: user.id,
        userIdentity: {
          firstName: user.firstName,
          lastName: user.lastName,
          mobilePhone: user.phone,
          login: user.email,
          email: user.email,
          secondEmail: user.email,
        },
      };
      const _roles = selectedPrimaryRoles.concat(selectedCustomroles);
      if (!isStonexCustomer && user.roles) {
        _user.roles = _roles.filter((r) => user.roles.includes(r.id.toString()));
      }

      if (!isStonexCustomer && user.aoboRoles) {
        _user.aoboRoles = _roles.filter((r) => user.roles.includes(r.id.toString()));
      }
      _users.push(_user);
    });
    if (_users.length === 0) {
      showToast('error', 'error', 'no.changes.detected');
      return;
    }
    const { data, status } = await ClientService.createUsers(_users);
    if (status !== 200) {
      showToast('error', 'error', 'user.save.error');
      return;
    } else {
      let success = true;
      data.forEach((item: any) => {
        const { result, data } = item;
        if (result.success) {
          showToast('success', 'success', 'client.user.save.success', {
            email: data.userIdentity.email,
          });
        } else {
          success = false;
          const { errors } = result;
          showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
            ...errors[0],
          });
        }
      });
      if (success) {
        switchUser(0);
        fetchUsers(owningCustomerId.toString());
        if (onSaveAndProceed) {
          onSaveAndProceed();
        }
      }
    }
  };

  const addNewUser = async () => {
    const isValid = await trigger();
    if (isValid) {
      setUsers((prev) => {
        return [...prev, userDefaults];
      });
      setUsersIsInApproval(false);
      setSelectedUserIndex(users.length);
      reset(userDefaults);
    }
  };

  const switchUser = async (index: number) => {
    const isValid = await trigger();
    if (isValid) {
      const user = users[index];
      setSelectedUserIndex(index);
      setUsersIsInApproval(
        user.status === Status.PENDING_APPROVAL || user.status === Status.PENDING_CREATION,
      );
      reset(user);
    }
  };

  const deleteUser = () => {
    const usersLeft = [...users];
    usersLeft.splice(selectedUserIndex, 1);
    const currentUserIndex = selectedUserIndex === 0 ? selectedUserIndex : selectedUserIndex - 1;
    const user = usersLeft[currentUserIndex];
    setUsers(usersLeft);
    setSelectedUserIndex(currentUserIndex);
    setUsersIsInApproval(
      user.status === Status.PENDING_APPROVAL || user.status === Status.PENDING_CREATION,
    );
    reset(user);
  };

  const showErrorToast = () => {
    showToast('error', 'user.validation.error', 'user.validation.error.detail');
  };

  return (
    <>
      <Toaster ref={toastRef} />
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <div className='grid lg:grid-cols-2'>
          <div>
            <div className='text-sm-bold'>
              <Translate value={isStonexCustomer ? 'authorised_signatory' : 'user_details.title'} />
            </div>
            <div className='text-neutral-3'>
              <Translate
                value={
                  isStonexCustomer ? 'authorised_signatory.subtitle' : 'user_details.description'
                }
              />
            </div>
          </div>
        </div>
        {activeStep === ClientForms.UserDetails || mode === AddOrEditOrView.View ? (
          <div>
            {((mode === AddOrEditOrView.View && customerHasUsers) ||
              (mode !== AddOrEditOrView.View && !isStonexCustomer) ||
              (isStonexCustomer && customerHasUsers)) && (
              <div className='mt-6 flex gap-2'>
                {users.map((_, index) => (
                  <Tag
                    key={Math.random()}
                    onClick={() => {
                      switchUser(index);
                    }}
                    className={`border px-4 py-2.5 ${selectedUserIndex === index ? 'bg-primary-surface-light border-primary-dark-content text-primary-dark-content' : 'border-neutral-5 bg-neutral-surface-subtle'} text-neutral-2 py-1 px-3 rounded-[20px] cursor-pointer`}
                  >
                    {`${translateWithValues('user')} ${index + 1}`}
                  </Tag>
                ))}
                {mode !== AddOrEditOrView.View && !isStonexCustomer && (
                  <Button
                    severity='info'
                    type='button'
                    className='!px-2 !py-1'
                    onClick={() => {
                      addNewUser();
                    }}
                  >
                    <UserFollow />
                  </Button>
                )}
              </div>
            )}
            {(customerHasUsers && (mode === AddOrEditOrView.View || isStonexCustomer)) ||
            (mode !== AddOrEditOrView.View && !isStonexCustomer) ? (
              <>
                {/*eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <form onSubmit={handleSubmit(saveUserDetails)}>
                  <div className='mx-5 mt-12 mb-6 grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
                    <TextInput
                      formRegister={register('firstName', { required: true })}
                      disabled={
                        mode === AddOrEditOrView.View || isStonexCustomer || usersIsInApproval
                      }
                      error={errors.firstName}
                      label='first_name'
                      placeholder='first_name'
                      isRequired
                    />
                    <TextInput
                      disabled={
                        mode === AddOrEditOrView.View || isStonexCustomer || usersIsInApproval
                      }
                      formRegister={register('lastName', { required: true })}
                      error={errors.lastName}
                      label='last_name'
                      placeholder='last_name'
                      isRequired
                    />
                    <TextInput
                      formRegister={register('email', {
                        required: true,
                        pattern: {
                          value: EMAIL_VALIDATION_REGEX,
                          message: 'invalid_email_address',
                        },
                      })}
                      error={errors.email}
                      label='email_id'
                      placeholder='email_id'
                      disabled={
                        !!users[selectedUserIndex]?.id ||
                        mode === AddOrEditOrView.View ||
                        isStonexCustomer ||
                        usersIsInApproval
                      }
                      isRequired
                    />
                    <TextInput
                      disabled={
                        mode === AddOrEditOrView.View || isStonexCustomer || usersIsInApproval
                      }
                      formRegister={register('phone', { required: !isStonexCustomer })}
                      error={errors.phone}
                      label='phone'
                      placeholder='phone'
                      isRequired={!isStonexCustomer}
                    />
                    {!isStonexCustomer && (
                      <MultiSelectInput
                        label='roles'
                        control={control}
                        disabled={mode === AddOrEditOrView.View || usersIsInApproval}
                        name='roles'
                        placeholder='roles'
                        error={errors.roles}
                        options={roles}
                      />
                    )}

                    {!isStonexCustomer && (
                      <MultiSelectInput
                        label='aoboRoles'
                        control={control}
                        disabled={mode === AddOrEditOrView.View || usersIsInApproval}
                        name='aoboRoles'
                        placeholder='aoboRoles'
                        error={errors.aoboRoles}
                        options={roles}
                      />
                    )}
                  </div>
                  <div className='flex items-center justify-center'>
                    {!users[selectedUserIndex]?.id && users.length > 1 && (
                      <Button
                        type='button'
                        onClick={deleteUser}
                        severity='warning'
                        className='!px-7'
                      >
                        <Translate value='delete_user' />
                      </Button>
                    )}
                  </div>
                  {usersIsInApproval && (
                    <Translate value='users.in.pending.approval' className='text-error-1' />
                  )}

                  {mode !== AddOrEditOrView.View && !isStonexCustomer && (
                    <div className='flex items-center justify-center mt-10 gap-5'>
                      <Button
                        type='button'
                        onClick={() => {
                          addNewUser();
                        }}
                        severity='contrast'
                        className='!px-7'
                      >
                        <Translate value='add_another' />
                      </Button>

                      <Button
                        disabled={isLoading}
                        type='submit'
                        severity='info'
                        onClick={() => {
                          if (handleStepperValidation)
                            handleStepperValidation(ClientForms.UserDetails, isValid);
                          if (!isValid) {
                            showErrorToast();
                          }
                        }}
                      >
                        <Translate value='submit_for_approval' />
                      </Button>
                    </div>
                  )}
                </form>
              </>
            ) : (
              !isLoading && (
                <div className='mt-1 text-error-1'>
                  {isStonexCustomer && <Translate value='client.signatory.nousers.assigned' />}
                  {!isStonexCustomer && <Translate value='client.nousers.assigned' />}
                </div>
              )
            )}
          </div>
        ) : (
          <div className='flex justify-between mt-4'>
            <Button
              severity='contrast'
              className='!px-14'
              disabled={!visitPermitted?.has(ClientForms.UserDetails)}
              onClick={() => {
                if (jumpToEdit) jumpToEdit(ClientForms.UserDetails);
              }}
            >
              <Translate
                value={
                  mode === AddOrEditOrView.Edit || visited?.has(ClientForms.UserDetails)
                    ? 'edit'
                    : 'start'
                }
              />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default UserDetails;
