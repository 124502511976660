import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CustomFileUpload } from '../../../components';
import { Translate } from '../../../i18n/translate';
import { useAppDispatch } from '../../../store';
import { selectOwningCustomerId, sendToast } from '../../../store/slices/main/mainSlice';
import { AddOrEditOrView, ClientForms } from '../../../types';
import ClientService from '../clientService';
import ColorDetails from './ColorDetails';
import { FontDetails } from './FontDetails';

interface Images {
  logoExpanded?: File;
  logoCollapsed?: File;
  favIcon?: File;
}
interface SiteDetailsProps {
  activeStep?: string;
  inheritedClientId?: number;
  visited?: Set<string>;
  mode: AddOrEditOrView;
  visitPermitted?: Set<string>;
  fromConfiguration?: boolean;
  onSaveAndProceed?: () => void;
  jumpToEdit?: (step: ClientForms, client?: Client) => void;
}

const SiteDetails = ({
  activeStep,
  inheritedClientId,
  onSaveAndProceed,
  visited,
  mode,
  visitPermitted,
  fromConfiguration = false,
  jumpToEdit,
}: SiteDetailsProps) => {
  const [brandingData, setBrandingData] = useState<BrandingFormData>({});
  const [colors, setColors] = useState<ColorsFormData>({});
  const [owningCustomerId, setOwningCustomerId] = useState<number>(0);
  const [defaultFont, setdefaultFont] = useState<FontDetail>({
    name: 'Inter',
    font: 'Inter',
    weight: '400',
  });

  const [files, setFiles] = useState<Images>();
  const { customerId } = useParams();
  const selectedOwningCustomerId = useSelector(selectOwningCustomerId);
  const [previewWindow, setPreviewWindow] = useState<Window | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      if (previewWindow && !previewWindow.closed) {
        previewWindow.close();
      }
    };
  }, [previewWindow]);

  useEffect(() => {
    if ((mode == AddOrEditOrView.Edit || mode == AddOrEditOrView.View) && customerId) {
      setOwningCustomerId(parseInt(customerId));
    } else {
      if (inheritedClientId) {
        setOwningCustomerId(inheritedClientId);
      } else {
        if (fromConfiguration && selectedOwningCustomerId) {
          setOwningCustomerId(selectedOwningCustomerId);
        }
      }
    }
  }, [customerId, inheritedClientId]);

  useEffect(() => {
    if (owningCustomerId) {
      getBranding();
    }
  }, [owningCustomerId]);

  const getBranding = async () => {
    const branding = await ClientService.getCustomerBranding(owningCustomerId);
    const isClientExpandedLogoExist = branding?.find((b) => b.type === 'LOGOEXPANDED');
    const isClientCollapsedLogoExist = branding?.find((b) => b.type === 'LOGOCOLLAPSED');
    const isClientFavIconExist = branding?.find((b) => b.type === 'FAVICON');
    const iBrandingColorsExists = branding?.find((b) => b.type === 'BRANDINGCOLORS');
    if (isClientExpandedLogoExist) {
      const response = await ClientService.donwload(owningCustomerId, 'LOGOEXPANDED');
      if (response) {
        const imageUrl = URL.createObjectURL(response);
        setBrandingData((prev) => ({ ...prev, logoExpanded: imageUrl }));
      }
    }

    if (isClientCollapsedLogoExist) {
      const response = await ClientService.donwload(owningCustomerId, 'LOGOCOLLAPSED');
      if (response) {
        const imageUrl = URL.createObjectURL(response);
        setBrandingData((prev) => ({ ...prev, logoCollapsed: imageUrl }));
      }
    }
    if (isClientFavIconExist) {
      const response = await ClientService.donwload(owningCustomerId, 'FAVICON');
      if (response) {
        const imageUrl = URL.createObjectURL(response);
        setBrandingData((prev) => ({ ...prev, favIcon: imageUrl }));
      }
    }
    if (iBrandingColorsExists) {
      const content = JSON.parse(iBrandingColorsExists.textContent);
      if (content.colorConfig && content.colorFormData) {
        setColors(content.colorFormData);
      }
      if (content.defaultFont) {
        setdefaultFont(content.defaultFont);
      }
    }
  };

  const showToast = (severity: 'success' | 'error', summary: string, detail: string) => {
    dispatch(sendToast({ severity, summary, detail }));
  };

  const colorConfig: ColorConfig[] = [
    {
      name: 'primarySurfaceDark',
      var: '--primary-surface-dark',
      placeholder: 'color.primary_surface_dark',
      defaultValue: '#070d40',
    },
    {
      name: 'primarySurfaceDarkContent',
      var: '--primary-surface-dark-content',
      placeholder: 'color.primary_surface_dark_content',
      defaultValue: '#ffffff',
    },
    {
      name: 'primarySurfaceDarkHover',
      var: '--primary-surface-dark-hover',
      placeholder: 'color.primary_surface_hover',
      defaultValue: '#3d4591',
    },
    {
      name: 'primarySurfaceDarkSelected',
      var: '--primary-surface-dark-selected',
      placeholder: 'color.primary_surface_dark_selected',
      defaultValue: '#262d6e',
    },
    {
      name: 'primaryColor',
      var: '--primary-color',
      placeholder: 'color.primary_color',
      defaultValue: '#0d1775',
    },
    {
      name: 'primaryGradientFrom',
      var: '--primary-gradient-from',
      placeholder: 'color.primary_gradient_from',
      defaultValue: '#0d1775',
    },
    {
      name: 'primaryGradientTo',
      var: '--primary-gradient-to',
      placeholder: 'color.primary_gradient_to',
      defaultValue: '#1383f4',
    },
    {
      name: 'primaryColorLight',
      var: '--primary-color-light',
      placeholder: 'color.primary_color_light',
      defaultValue: '#92C6FA',
    },
    {
      name: 'primarySurfaceLight',
      var: '--primary-surface-light',
      placeholder: 'color.primary_surface_light',
      defaultValue: '#F0F8FE',
    },
    {
      name: 'primateDarkContent',
      var: '--primary-dark-content',
      placeholder: 'color.primary_dark_content',
      defaultValue: '#0D5DAD',
    },
    {
      name: 'primaryDisabled',
      var: '--primary-disabled-color',
      placeholder: 'color.primary_disabled',
      defaultValue: '#B4B7D4',
    },
    {
      name: 'primaryHover',
      var: '--primary-color-hover',
      placeholder: 'color.primary_hover',
      defaultValue: '#c1c1c5',
    },
  ];

  const onFileSelected = (file: File, name: string) => {
    setFiles((prev) => ({ ...prev, [name]: file }));
    const imageUrl = URL.createObjectURL(file);
    setBrandingData({ ...brandingData, [name]: imageUrl });
  };

  const handleChange = (e: any, name: string) => {
    setColors({ ...colors, [name]: e.target.value });
  };

  const preview = () => {
    localStorage.setItem(
      'branding',
      JSON.stringify({
        colorConfig: colorConfig,
        colorFormData: colors,
        images: brandingData,
        defaultFont: defaultFont,
      }),
    );
    if (previewWindow && !previewWindow.closed) {
      previewWindow.close();
    }

    const w = window.open('/preview', '_blank');
    if (w) {
      w.state = { preview: true }; // Set custom state
      setPreviewWindow(w);
    }
  };

  const saveBranding = async () => {
    if (files && files.logoExpanded) {
      const formData = new FormData();
      formData.append('file', files.logoExpanded);
      formData.append('type', 'logoExpanded');
      await ClientService.updateBranding(formData, owningCustomerId);
    }

    if (files && files.logoCollapsed) {
      const formData = new FormData();
      formData.append('type', 'logoCollapsed');
      formData.append('file', files.logoCollapsed);
      await ClientService.updateBranding(formData, owningCustomerId);
    }

    if (files && files.favIcon) {
      const formData = new FormData();
      formData.append('type', 'favIcon');
      formData.append('file', files.favIcon);
      await ClientService.updateBranding(formData, owningCustomerId);
    }
    if (Object.entries(colors).length > 0) {
      const colorsStringfied = JSON.stringify({
        colorConfig: colorConfig,
        colorFormData: colors,
        defaultFont: defaultFont,
      });
      const formData = new FormData();
      formData.append('type', 'brandingColors');
      formData.append('content', colorsStringfied);
      await ClientService.updateBranding(formData, owningCustomerId);
    } else if (defaultFont.name != 'Inter') {
      const colorsStringfied = JSON.stringify({
        defaultFont: defaultFont,
      });
      const formData = new FormData();
      formData.append('type', 'brandingColors');
      formData.append('content', colorsStringfied);
      await ClientService.updateBranding(formData, owningCustomerId);
    }
    showToast('success', 'success', 'branding.saves.success');
  };

  const _onSaveAndProceed = () => {
    saveBranding();
    if (onSaveAndProceed) {
      onSaveAndProceed();
    }
  };

  return (
    <div className='rounded-c8 shadow-c bg-white'>
      <div>
        <div className='grid lg:grid-cols-2 px-8 pt-8'>
          <div>
            <div className='text-sm-bold'>
              {!fromConfiguration && <Translate value='site_details.title' />}
            </div>
            {!fromConfiguration && (
              <div className='text-neutral-3'>
                <Translate value='site_details.description' />
              </div>
            )}
          </div>
          <div className='flex justify-end items-center'>
            {/* {activeStep === ClientForms.SiteDetails && (
              <Progress value={50} currentStep={3} stepLimit={3} />
            )} */}
            {/* <Checkmark className='ml-4' /> */}
          </div>
        </div>
        {activeStep !== ClientForms.SiteDetails && mode != AddOrEditOrView.View ? (
          <div className='flex justify-between mt-4 px-8 pb-8'>
            <Button
              severity='contrast'
              className='!px-14'
              disabled={
                mode === AddOrEditOrView.Add && !visitPermitted?.has(ClientForms.SiteDetails)
              }
              onClick={() => {
                if (jumpToEdit) {
                  jumpToEdit(ClientForms.SiteDetails);
                }
              }}
            >
              <Translate value={visited?.has(ClientForms.SiteDetails) ? 'edit' : 'start'} />
            </Button>
            {/* <Progress value={50} currentStep={3} stepLimit={3} /> */}
          </div>
        ) : (
          <>
            <div>
              <div className='border-b-2 border-banner'>
                <div className='p-8 flex gap-4 flex-col sm:flex-row'>
                  {mode !== AddOrEditOrView.View && (
                    <div className='shadow-c py-8 rounded-c8 w-full sm:w-1/2 h-[300px] px-4 lg:px-16'>
                      <div className='text-md-semibold'>
                        <Translate value='site_details.upload_client_logo' />
                      </div>
                      <CustomFileUpload
                        accept='.svg,.png,.jpg,.jpeg'
                        onFilesSelected={(files) => {
                          onFileSelected(files[0], 'logoExpanded');
                        }}
                      />
                    </div>
                  )}

                  <div className='shadow-c p-8 rounded-c8 w-full sm:w-1/2'>
                    <div className='flex gap-2 mt-2'>
                      <div>
                        {brandingData.logoExpanded && (
                          <img
                            src={brandingData.logoExpanded}
                            className='bg-contain w-full h-full'
                          />
                        )}
                      </div>
                    </div>
                    <div className='mt-10'></div>
                  </div>
                </div>
              </div>
              <div className='border-b-2 border-banner'>
                <div className='p-8 flex gap-4 flex-col sm:flex-row'>
                  {mode !== AddOrEditOrView.View && (
                    <div className='shadow-c py-8 rounded-c8 w-full sm:w-1/2 h-[300px] px-4 lg:px-16'>
                      <div className='text-md-semibold'>
                        <Translate value='site_details.upload_client_logo_minimized' />
                      </div>
                      <CustomFileUpload
                        accept='.svg,.png,.jpg,.jpeg'
                        onFilesSelected={(files) => {
                          onFileSelected(files[0], 'logoCollapsed');
                        }}
                      />
                    </div>
                  )}
                  <div className='shadow-c p-8 rounded-c8 w-full sm:w-1/2'>
                    <div className='flex gap-2 mt-2'>
                      <div>
                        {brandingData.logoCollapsed && (
                          <img
                            src={brandingData.logoCollapsed}
                            className='bg-contain w-full h-full'
                          />
                        )}
                      </div>
                    </div>
                    <div className='mt-10'></div>
                  </div>
                </div>
              </div>
              <div className='border-b-2 border-banner'>
                <div className='p-8 flex gap-4 flex-col sm:flex-row'>
                  {mode !== AddOrEditOrView.View && (
                    <div className='shadow-c py-8 rounded-c8 w-full sm:w-1/2 h-[300px] px-4 lg:px-16'>
                      <div className='text-md-semibold'>
                        <Translate value='site_details.upload_client_favicon' />
                      </div>
                      <CustomFileUpload
                        accept='.ico'
                        onFilesSelected={(files) => {
                          onFileSelected(files[0], 'favIcon');
                        }}
                      />
                    </div>
                  )}
                  <div className='shadow-c p-8 rounded-c8 w-full sm:w-1/2'>
                    <div className='flex gap-2 mt-2'>
                      <div>
                        {brandingData.favIcon && (
                          <img src={brandingData.favIcon} className='bg-contain w-full h-full' />
                        )}
                      </div>
                    </div>
                    <div className='mt-10'></div>
                  </div>
                </div>
              </div>
              <div className='pt-8 flex flex-col gap-10'>
                <ColorDetails
                  mode={mode}
                  colorConfig={colorConfig}
                  colors={colors}
                  handleChange={handleChange}
                />
              </div>

              <div className='pt-8 flex flex-col gap-10'>
                <FontDetails
                  mode={mode}
                  defaultFont={defaultFont}
                  setdefaultFont={setdefaultFont}
                />
              </div>
              <div className='flex items-center justify-center pb-8 pt-8'>
                <Button severity='contrast' onClick={preview}>
                  <Translate value='preview' />
                </Button>
                {mode !== AddOrEditOrView.View && (
                  <Button severity='info' onClick={_onSaveAndProceed} className='ml-5'>
                    <Translate value='save_and_proceed' />
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SiteDetails;
