import { DataTable } from 'primereact/datatable';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { Column } from 'primereact/column';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import { AddOrEditOrView } from '../../../types';

interface TableProps {
  title: string;
  settings: Setting[];
  settingsData?: SettingsData | null;
  handleSettingChange: (e: InputSwitchChangeEvent, name: string) => void;
  mode: AddOrEditOrView;
}

const Table = ({ title, settings, settingsData, handleSettingChange, mode }: TableProps) => {
  const header = (
    <div className='text-neutral-1 text-sm-semibold'>
      <Translate value={title} />
    </div>
  );

  return (
    <DataTable size='large' header={header} value={settings}>
      <Column
        field='feature'
        className='w-3/6'
        header={translateWithValues('feature')}
        body={(setting: Setting) => (
          <div>
            <div className='text-neutral-2 text-md-medium'>
              <Translate value={setting.header} />
            </div>
            <div className='text-neutral-3 text-md-regular'>
              <Translate value={setting.subtitle} />
            </div>
          </div>
        )}
      />
      <Column
        field='option'
        className='w-1/6'
        header={translateWithValues('enable_or_disable')}
        body={(setting: Setting) => {
          const idDisabled = settingsData?.[setting.disabledAtParentProperty];
          return (
            <>
              <InputSwitch
                id={`${settingsData?.[setting.name]}`}
                checked={settingsData?.[setting.name] as boolean}
                disabled={(idDisabled as boolean) || mode === AddOrEditOrView.View}
                tooltip={idDisabled ? translateWithValues('disabled_at_parent') : ''}
                tooltipOptions={{ position: 'top', showOnDisabled: true }}
                onChange={(e) => handleSettingChange(e, setting.name)}
              />
            </>
          );
        }}
      />
    </DataTable>
  );
};

export default Table;
