import { SortOrder } from 'primereact/datatable';
import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';
import { getCountryISO2 } from '../../utils/isoncCodeMapping';

const ROOT = '/api/beneficiary-management';
const PAYMENT_CREDITOR_MANAGEMENT = '/api/payment-creditor-management';

function getRequestBody(routingCodeType: 'BIC' | 'FW', formData: any, routingCodeData: any) {
  const requestData: any = {
    nickName: formData.nickName,
    firstName: formData.name,
    beneAddress: {
      addressType: 'BENE_ADDRESS',
      street1: formData.BeneficiaryAddressStreet,
      city: formData.BeneficiaryAddressCity,
      state: formData.BeneficiaryAddressRegion,
      postCode: formData.BeneficiaryAddressPostalCode,
      country: formData.country.code,
    },
    accountName: formData.AccountName,
    bankName: formData.BeneficiaryBankName,
    accountNumber: formData.AccountNumber,
    routingCode: formData.BankCode,
    routingCodeType,
    bankAddress: {
      addressType: 'BANK_ADDRESS',
      street1: formData.BeneficiaryBankAddressStreet,
      city: formData.BeneficiaryBankAddressCity,
      state: formData.BeneficiaryAddressRegion,
      postCode:
        routingCodeData['credit'][`${routingCodeType === 'BIC' ? 'ReZipCode' : 'ReZipCode'}`],
      country:
        routingCodeData['credit'][
          `${routingCodeType === 'BIC' ? 'IsoCountryCode' : 'IsoCountryCode'}`
        ],
    },
    taxId: formData.BeneficiaryTaxID,
    phoneNumber: formData.BeneficiaryPhoneNumber,
    email: formData.email,
    paymentPurpose: formData.paymentPurpose,
    contactName: formData.BeneficiaryContactName,
    buyCurrency: formData.buyCurrency,
    country: getCountryISO2(formData.country.code),
    fieldMap: {},
  };

  if (formData.BeneficiaryAddressCountry) {
    requestData.countryOfResidence = getCountryISO2(formData.BeneficiaryAddressCountry.code);
  }

  if (formData.PurposeofPayment) {
    requestData.paymentPurpose = formData.PurposeofPayment;
  }

  if (formData.OtherRoutingCode) {
    requestData.otherRoutingCode = formData.OtherRoutingCode;
  }

  if (formData.IntermediaryBankCode) {
    requestData.intermediaryBankName = formData.IntermediaryBankName;
    requestData.intermediaryBankCode = formData.IntermediaryBankCode;
    requestData.intermediaryBankCodeType = routingCodeType;
    requestData.intermediaryBankAdd = {
      addressType: 'INTERMEDIARY_BANK_ADDRESS',
      street1: formData.IntermediaryBankAddressStreet,
      city: formData.IntermediaryBankAddressCity,
      state: formData.IntermediaryAddressRegion,
      postCode:
        routingCodeData['credit'][`${routingCodeType === 'BIC' ? 'ReZipCode' : 'ReZipCode'}`],
      country:
        routingCodeData['intermediary']?.[
          `${routingCodeType === 'BIC' ? 'IsoCountryCode' : 'IsoCountryCode'}`
        ],
    };
  }

  Object.entries(formData).forEach(([key, value]) => {
    // If the key ends with 'Country' and value has a 'code' property,
    // transform it using getCountryISO2; otherwise assign as-is
    if ((key.endsWith('Country') || key.endsWith('country')) && (value as any)?.code) {
      requestData.fieldMap[key] = getCountryISO2((value as any).code);
    } else {
      requestData.fieldMap[key] = value;
    }
  });

  return requestData;
}

const BeneficiaryService = {
  async getAllBeneficiaries(
    payload: { [key: string]: string[] | string },
    page: number | undefined = undefined,
    rows: number | undefined = undefined,
    sortField = '',
    sortOrder: SortOrder = null,
  ): Promise<BenificiaryResponse | null> {
    const response = await apiClient.post(
      `${ROOT}/templates/search?sortBy=${sortField}&direction=${sortOrder === 1 ? 'asc' : 'desc'}${page !== undefined ? `&page=${page}` : ''}${rows !== undefined ? `&size=${rows}` : ''}`,
      payload,
    );
    return handleApiResponse(response);
  },

  async addPaymentCreditor(orderId: number, paymentId: number, beneficiaryId: string) {
    const response = await apiClient.post(
      `${PAYMENT_CREDITOR_MANAGEMENT}/payment-creditor-import`,
      {
        orderId,
        paymentId,
        beneficiaryId,
      },
    );
    return handleApiResponse(response);
  },

  async validateNickName(nickName: string) {
    const response = await apiClient.get(`${ROOT}/templates/nickname-validate?name=${nickName}`);
    return handleApiResponse<boolean>(response);
  },

  async addBeneficiary(routingCodeType: 'BIC' | 'FW', formData: any, routingCodeData: any) {
    const response = await apiClient.post(
      `${ROOT}/templates`,
      getRequestBody(routingCodeType, formData, routingCodeData),
    );
    return handleApiResponse(response);
  },

  async getBeneficiaryFormByCountry(countryCode: string, buyCurrency: string) {
    const response = await apiClient.get(
      `${ROOT}/payment-validation-rules?bankCountryIsoCode=${countryCode}&buyCurrency=${buyCurrency}`,
    );
    return handleApiResponse<Array<BeneficiaryType>>(response);
  },

  async getBeneficiaryTemplate(id: number) {
    const response = await apiClient.get(`${ROOT}/templates/bene/${id}`);
    return handleApiResponse<BeneficiaryTemplateType>(response);
  },

  async editBeneficiaryTemplate(
    id: number,
    routingCodeType: 'BIC' | 'FW',
    formData: any,
    routingCodeData: any,
  ) {
    const response = await apiClient.put(
      `${ROOT}/templates/bene/${id}`,
      getRequestBody(routingCodeType, formData, routingCodeData),
    );
    return handleApiResponse(response);
  },

  async approveBeneficiary(id: number, data: any) {
    return apiClient.post(`${ROOT}/benes/${id}/approve`, data);
  },

  async updateBeneficiaryStatus(id: number, status: Status) {
    const response = await apiClient.put(`${ROOT}/templates/${id}/status/${status}`);
    return handleApiResponse(response);
  },
};

export default BeneficiaryService;
