import { RoleGuard } from '../../../components';
import paymentRole from '../paymentRole';
import AddBulkPayment from './AddBulkPayment';
import Dashboard from './Dashboard';
import { ReviewBulkPayment } from './ReviewBulkPayment';

const bulkPaymentRoutes = [
  {
    id: 'bulkPaymentDashboard',
    path: '',
    index: true,
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <Dashboard />
      </RoleGuard>
    ),
  },
  {
    path: 'add',
    index: true,
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <AddBulkPayment />
      </RoleGuard>
    ),
  },
  {
    path: 'summary/:orderId',
    index: true,
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <ReviewBulkPayment />
      </RoleGuard>
    ),
  },
];

export default bulkPaymentRoutes;
