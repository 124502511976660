import { ProgressSpinner } from 'primereact/progressspinner';
import { Translate } from '../../../i18n/translate';
import { convertDateFormat } from '../../../utils/helper';
import { FormatAmount } from '../../../components';
import { useSelector } from 'react-redux';
import { selectCustomerCurrencies } from '../../../store/slices/refdata/refdataSlice';
import { BeneficiarySummary } from '../../Payment/RateRequest/QuickQuoteSummary';

const PaymentDetails = ({
  payment,
  detailsView = false,
}: {
  payment?: Order;
  detailsView?: boolean;
}) => {
  const currencies = useSelector(selectCustomerCurrencies);

  if (!payment) {
    return (
      <div className='absolute z-10 inset-0 bg-white/50 grid place-content-center'>
        <ProgressSpinner className='w-10 h-10' />
      </div>
    );
  }

  return (
    <div className='card mt-6'>
      <div className='border-b border-neutral-border py-6'>
        <div className='text-neutral-1 text-sm-bold'>
          <Translate value='payment_id' />
          {': '}
          {payment.id}
        </div>
        <div className=' mt-6 grid grid-cols-1 sm:grid-cols-2 gap-8 sm:w-5/6'>
          <div>
            <div className='text-sm-medium text-neutral-3'>
              <Translate value='date_created' />
            </div>
            <div className='text-md-medium text-neutral-1'>
              {convertDateFormat(payment.createdAt)}
            </div>
          </div>
          {!detailsView && (
            <>
              <div>
                <div className='text-sm-medium text-neutral-3'>
                  <Translate value='payment_table.header.valueDate' />
                </div>
                <div className='text-md-medium text-neutral-1'>
                  {convertDateFormat(payment.payments?.[0].quote.valueDate)}
                </div>
              </div>
              <div>
                <div className='text-sm-medium text-neutral-3'>
                  <Translate value='sell_amount' />
                </div>
                <div className='text-md-medium text-neutral-1'>
                  {payment.sellCurrency}{' '}
                  <FormatAmount
                    number={payment.payments[0]?.sellAmount}
                    maximumFractionDigits={
                      currencies.find((currency) => payment.sellCurrency == currency.isocode)
                        ?.amountPrecision
                    }
                  />
                </div>
              </div>
              <div>
                <div className='text-sm-medium text-neutral-3'>
                  <Translate value='sell_amount' />
                </div>
                <div className='text-md-medium text-neutral-1'>
                  {payment.payments[0].buyCurrency}{' '}
                  <FormatAmount
                    number={payment.payments[0]?.buyAmount}
                    maximumFractionDigits={
                      currencies.find(
                        (currency) => payment.payments[0].buyCurrency == currency.isocode,
                      )?.amountPrecision
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {payment.payments[0].purpose && (
          <div className='py-8'>
            <div className='text-sm-medium text-neutral-3'>
              <Translate value='paymentPurpose' />
            </div>
            <div className='text-md-medium text-neutral-1'>{payment.payments[0].purpose}</div>
          </div>
        )}
      </div>
      <BeneficiarySummary orderId={payment.payments[0].orderId} />
    </div>
  );
};

export default PaymentDetails;
