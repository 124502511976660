import { PageHead } from '../../../../components';
import { Translate } from '../../../../i18n/translate';

export const BulkOverview = ({ payment }: { payment?: Order }) => {
  return (
    <div className='card w-[40%] mt-8 p-8'>
      <PageHead title='bulk_overview' />
      <div className='text-unselected text-sm-medium mt-4'>
        <Translate value='bulkId' />
      </div>
      <div className='text-sm-semibold text-neutral-2 mt-2'>{payment?.id}</div>
      <div className='text-unselected text-sm-medium mt-4'>
        <Translate value='orderingCustomerId' />
      </div>
      <div className='text-sm-semibold text-neutral-2 mt-2'>{payment?.owningCustomerId}</div>
      <div className='text-unselected text-sm-medium mt-4'>
        <Translate value='payment_table.header.sellCurrency' />
      </div>
      <div className='flex text-sm-semibold text-neutral-2 mt-2'>
        <img
          alt={payment?.sellCurrency ?? ''}
          src={`/images/c_flags_svg/${payment?.sellCurrency?.toLocaleLowerCase()}.svg`}
          className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
        />
        <span>{payment?.sellCurrency || ''}</span>
      </div>
      <div className='text-unselected text-sm-medium mt-4'>
        <Translate value='sellAmount' />
      </div>
      <div className='text-sm-semibold text-neutral-2 mt-2'>{payment?.sellAmount}</div>
      <div className='text-unselected text-sm-medium mt-4'>
        <Translate value='fee' />
      </div>
      <div className='text-sm-semibold text-neutral-2 mt-2'>
        {payment?.totalAmount + ' ' + payment?.sellCurrency}
      </div>
      <div className='bg-primary-surface-light rounded-[8px] px-4 py-5 mt-2'>
        <div className='text-primary-blue-surface-dark text-sm-medium'>
          <Translate value='rate_requests.total_amount_payable' />
        </div>
        <div className='text-primary-blue-surface-dark text-mobile-header-bold mt-2'>
          {payment?.totalAmountWithFee + ' ' + payment?.sellCurrency}
        </div>
      </div>
    </div>
  );
};
