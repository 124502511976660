import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { CustomStepper } from '../../../components';
import { Translate } from '../../../i18n/translate';
import { PageHeadContext } from '../../../providers/PageHead';
import { sendToast } from '../../../store/slices/main/mainSlice';
import { AddOrEditOrView } from '../../../types';
import BeneficiaryBankDetails from '../BeneficiaryBankDetails';
import BeneficiaryDetails from '../BeneficiaryDetails';
import BeneficiaryService from '../beneficiaryService';
import PaymentService from '../../Payment/paymentService';

type Step = 'details' | 'bank-details';

export default function CreateBeneficiary() {
  const [visited, setVisited] = useState<Set<Step>>(new Set());
  const [validationOnStepper, setValidationOnStepper] = useState<Set<Step>>(new Set());
  const [routingCodeDetails, setRoutingCodeDetails] = useState<{
    intermediary: any;
    credit: any;
  }>();
  const [isBic, setIsBic] = useState(false);
  const [iban, setIban] = useState('');
  const [swiftBicCode, setSwiftBicCode] = useState('');

  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  const navigate = useNavigate();

  const form = useForm<BeneficiaryFormType>();

  const { handleSubmit, watch, setValue, reset } = form;

  const dispatch = useDispatch();

  const verifyIbanExistsQuery = useQuery<
    Awaited<ReturnType<typeof PaymentService.verifyIbanExists>> | undefined
  >({
    queryKey: ['verify_iban_exists', iban],
    enabled: false,
  });

  const submitFormMutation = useMutation({
    mutationFn: async (data: BeneficiaryFormType) => {
      const response = await BeneficiaryService.addBeneficiary(
        isBic ? 'BIC' : 'FW',
        data,
        routingCodeDetails,
      );
      return response;
    },
    onSuccess: () => {
      navigate('/beneficiaries/');
    },
    onError: () => {
      dispatch(
        sendToast({
          summary: 'error',
          severity: 'error',
          detail: 'beneficiary_details.add_beneficiary_error',
        }),
      );
    },
  });

  const onSubmit: SubmitHandler<BeneficiaryFormType> = (data) => {
    if (iban) {
      if (verifyIbanExistsQuery.status === 'pending') {
        dispatch(
          sendToast({
            severity: 'warn',
            summary: 'iban_check.inprogress',
          }),
        );
        return;
      }

      if (!verifyIbanExistsQuery.data?.ibanBic) {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'failed',
            detail: 'iban_check.warn',
          }),
        );
        return;
      }
    }

    submitFormMutation.mutate(data);
  };

  useEffect(() => {
    setPageHeadData({
      title: 'add_new_beneficiary.header',
      description: 'add_new_beneficiary.subtitle2',
    });
  }, [setPageHeadData]);

  const handleStepperValidation = (step: Step, isValid: boolean) => {
    setValidationOnStepper((prev) => {
      const newValidations = new Set(prev);
      if (!isValid) {
        newValidations.add(step);
      } else {
        newValidations.delete(step);
      }
      return newValidations;
    });
  };

  const steps: Array<{
    id: Step;
    content: React.ReactNode;
  }> = [
    {
      id: 'details',
      content: (
        <BeneficiaryDetails
          form={form}
          mode={AddOrEditOrView.Add}
          onCountryChange={() => {
            reset({
              nickName: watch('nickName'),
              name: watch('name'),
              email: watch('email'),
              country: watch('country'),
              buyCurrency: watch('buyCurrency'),
              AccountName: watch('name'),
            });
            setIban('');
            setSwiftBicCode('');
            setRoutingCodeDetails(undefined);
          }}
          onProceed={() => {
            setValue('AccountName', watch('name'));
            setVisited((prev) => new Set([...prev, 'details']));
          }}
          onError={() => handleStepperValidation('details', false)}
        />
      ),
    },
    {
      id: 'bank-details',
      content: (
        <div className='relative'>
          {!visited.has('details') ? (
            <div className='absolute inset-0 bg-white opacity-70 cursor-not-allowed z-10' />
          ) : null}
          <BeneficiaryBankDetails
            buyCurrency={watch('buyCurrency')}
            beneficiaryCountry={watch('country')}
            showForm={visited.has('details')}
            form={form}
            mode={AddOrEditOrView.Add}
            routingCodeDetails={routingCodeDetails}
            setRoutingCodeDetails={setRoutingCodeDetails}
            setIsBic={(isBic) => setIsBic(isBic)}
            iban={iban}
            setIban={setIban}
            swiftBicCode={swiftBicCode}
            setSwiftBicCode={setSwiftBicCode}
          />
        </div>
      ),
    },
  ];

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
      className='p-8 grid gap-8 place-items-center'
    >
      <div className='w-full'>
        <CustomStepper
          steps={steps}
          visited={visited}
          validations={validationOnStepper}
          hideIndicator
        />
      </div>
      <Button
        type='submit'
        severity='info'
        loading={submitFormMutation.isPending}
        disabled={!visited.has('details')}
        className='gap-2'
      >
        <Translate value='save_and_submit_for_approval' />
      </Button>
    </form>
  );
}
