import { AxiosResponse } from 'axios';
import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';
import ClientService from '../Client/clientService';

const ROOT = '/api/user-principal/current-user';

const UserprincipalService = {
  async getPricnipleCustomers(): Promise<UserPrinciple | null> {
    try {
      const response = await apiClient.get<UserPrinciple>(`${ROOT}/customers`);
      return handleApiResponse<UserPrinciple>(response);
    } catch (error) {
      console.error('Error fetching prinicple customers:', error);
      return null;
    }
  },

  async getCurrentUser(): Promise<RolesResponse | null> {
    try {
      const response = await apiClient.get<RolesResponse>(`${ROOT}`);
      return handleApiResponse<RolesResponse>(response);
    } catch (error) {
      console.error('Error fetching current user:', error);
      return null;
    }
  },

  async signOut(): Promise<AxiosResponse | void> {
    try {
      return await apiClient.post(`${ROOT}/sign-out`);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  },

  async getCustomerSettings(id?: number): Promise<SettingsData | null> {
    return ClientService.getClientSettings(id);
  },
};

export default UserprincipalService;
