import { PAYMENT_CATEGORY, PaymentStatus } from '../../types';

const PAYMENT_STATUS_OPTIONS = [
  {
    label: 'Created',
    value: PaymentStatus.CREATED,
  },
  {
    label: 'Pending Approval',
    value: PaymentStatus.PENDING_APPROVAL,
  },
  {
    label: 'Pending Parent Approval',
    value: PaymentStatus.PENDING_PARENT_APPROVAL,
  },
  {
    label: 'Approval',
    value: PaymentStatus.APPROVAL,
  },
  {
    label: 'Committed',
    value: PaymentStatus.COMMITTED,
  },
  {
    label: 'Deleted',
    value: PaymentStatus.DELETED,
  },
  {
    label: 'Compliance Failed',
    value: PaymentStatus.COMPLIANCE_FAILED,
  },
  {
    label: 'Pending Compliance',
    value: PaymentStatus.PENDING_COMPLIANCE,
  },
  {
    label: 'Abandoned',
    value: PaymentStatus.ABANDONED,
  },
  {
    label: 'Held',
    value: PaymentStatus.HELD,
  },
  {
    label: 'Ready For Release',
    value: PaymentStatus.READY_FOR_RELEASE,
  },
  {
    label: 'Submitted',
    value: PaymentStatus.SUBMITTED,
  },
  {
    label: 'Processing',
    value: PaymentStatus.PROCESSING,
  },
  {
    label: 'Released',
    value: PaymentStatus.RELEASED,
  },
  {
    label: 'InProgress',
    value: PaymentStatus.IN_PROGRESS,
  },
  {
    label: 'Completed',
    value: PaymentStatus.COMPLETED,
  },
  {
    label: 'Cancelled',
    value: PaymentStatus.CANCELLED,
  },
  {
    label: 'Rejected',
    value: PaymentStatus.APPROVAL_REJECTED,
  },
];

const PAYMENT_CATEGORY_OPTIONS = [
  {
    label: 'Pending',
    value: PAYMENT_CATEGORY.PENDING,
  },
  {
    label: 'In Progress',
    value: PAYMENT_CATEGORY.IN_PROCESSING,
  },
  {
    label: 'Payment Completed',
    value: PAYMENT_CATEGORY.PAYMENT_COMPLETED,
  },
  {
    label: 'Pending Parent Approval',
    value: PaymentStatus.PENDING_PARENT_APPROVAL,
  },
];

const PENDING_STATUS_OPTIONS = [
  {
    label: 'Created',
    value: PaymentStatus.CREATED,
  },
  {
    label: 'Pending Compliance',
    value: PaymentStatus.PENDING_COMPLIANCE,
  },
  {
    label: 'Pending Approval',
    value: PaymentStatus.PENDING_APPROVAL,
  },
  {
    label: 'Held',
    value: PaymentStatus.HELD,
  },
  {
    label: 'Ready For Release',
    value: PaymentStatus.READY_FOR_RELEASE,
  },
];

const IN_PROCESSING_STATUS_OPTIONS = [
  {
    label: 'Submitted',
    value: PaymentStatus.SUBMITTED,
  },
  {
    label: 'Processing',
    value: PaymentStatus.PROCESSING,
  },
  {
    label: 'Released',
    value: PaymentStatus.RELEASED,
  },
  {
    label: 'InProgress',
    value: PaymentStatus.IN_PROGRESS,
  },
];

const PAYMENT_COMPLETED_STATUS_OPTIONS = [
  {
    label: 'Completed',
    value: PaymentStatus.COMPLETED,
  },
];

export {
  IN_PROCESSING_STATUS_OPTIONS,
  PAYMENT_CATEGORY_OPTIONS,
  PAYMENT_COMPLETED_STATUS_OPTIONS,
  PAYMENT_STATUS_OPTIONS,
  PENDING_STATUS_OPTIONS,
};

