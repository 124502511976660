import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMemo, useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { CountrySelect, CurrencySelect } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { sendToast } from '../../../../store/slices/main/mainSlice';
import {
  selectCountries,
  selectCustomerCurrencies,
} from '../../../../store/slices/refdata/refdataSlice';
import { getCountryISO3 } from '../../../../utils/isoncCodeMapping';
import BeneficiaryForm from '../../../CommonComponents/BeneficiaryForm';
import PaymentService from '../../paymentService';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  quoteId: string;
  paymentId: number;
  orderId: number;
  buyCurrency: string;
  buyAmount: number;
};

const BeneficiaryCreationModal = ({
  visible,
  setVisible,
  quoteId,
  paymentId,
  orderId,
  buyCurrency,
  buyAmount,
}: Props) => {
  return (
    <>
      <Dialog
        className='w-full md:w-3/5 max-h-[100%] m-0 !rounded-none full-screen-dialog'
        header={
          <>
            <div className='text-lg font-semibold text-neutral-1'>
              <Translate value='add_new_beneficiary.header' />
            </div>
            <div className='text-sm-regular mt-1 text-neutral-3'>
              <Translate value='add_new_beneficiary.subtitle' />
            </div>
          </>
        }
        visible={visible}
        style={{
          height: '100vh',
          position: 'fixed',
          top: 0,
          right: 0,
        }}
        onHide={() => setVisible(false)}
        draggable={false}
      >
        <BeneficiaryFormLoader
          quoteId={quoteId}
          paymentId={paymentId}
          buyCurrency={buyCurrency}
          buyAmount={buyAmount}
          orderId={orderId}
        />
      </Dialog>
    </>
  );
};

type FormType = {
  [key: string]: any;
};

function BeneficiaryFormLoader({
  quoteId,
  paymentId,
  orderId,
  buyCurrency,
  buyAmount,
}: {
  quoteId: string;
  paymentId: number;
  orderId: number;
  buyCurrency: string;
  buyAmount: number;
}) {
  const selectedCustomerCurrencies = useSelector(selectCustomerCurrencies);
  const selectedCountries = useSelector(selectCountries);

  const [beneficiaryCountry, setBeneficiaryCountry] = useState(() => {
    const homeCountries = selectedCustomerCurrencies.find(
      (item) => item.isocode === buyCurrency,
    )?.homeCountries;
    return homeCountries && homeCountries.length >= 1
      ? selectedCountries.find((item) => item.code === getCountryISO3(homeCountries[0]))
      : undefined;
  });

  const countries = useMemo(() => {
    const item = selectedCustomerCurrencies.find((item) => item.isocode === buyCurrency);
    const homeCountries =
      selectedCustomerCurrencies
        .find((item) => item.isocode === buyCurrency)
        ?.homeCountries.map((item) => getCountryISO3(item)) || [];

    const countryCodeList = item?.intermediaries.map((item) => getCountryISO3(item)) || [];
    const mergedCountryCodeList = [...new Set([...homeCountries, ...countryCodeList])];
    return mergedCountryCodeList.reduce((acc, code) => {
      const country = selectedCountries.find((item) => item.code === code);
      return country ? [...acc, country] : acc;
    }, [] as Array<Country>);
  }, [buyCurrency, selectedCustomerCurrencies]);

  return (
    <div className='grid gap-4 grid-rows-[auto_1fr] h-full overflow-auto pr-2'>
      <div className='grid grid-cols-2 md:grid-cols-3 gap-5 mt-6 h-full'>
        <CurrencySelect
          label='currency'
          placeholder='currency'
          isRequired
          disabled
          value={buyCurrency}
        />
        <CountrySelect
          label='beneficiary_country'
          placeholder='beneficiary_country'
          isRequired
          countryList={countries}
          value={beneficiaryCountry}
          onChange={(value) => setBeneficiaryCountry(value)}
        />
      </div>
      {!beneficiaryCountry || !buyCurrency ? (
        <div className='text-neutral-3 h-full grid place-content-center'>
          <Translate value='select_country' className='text-neutral-3 text-center' />
        </div>
      ) : (
        <BeneficiaryFormWrapper
          key={beneficiaryCountry.code} // to clear the form when country changes
          quoteId={quoteId}
          paymentId={paymentId}
          orderId={orderId}
          buyCurrency={buyCurrency}
          buyAmount={buyAmount}
          beneficiaryCountry={beneficiaryCountry}
        />
      )}
    </div>
  );
}

function BeneficiaryFormWrapper({
  quoteId,
  paymentId,
  buyCurrency,
  buyAmount,
  orderId,
  beneficiaryCountry,
}: {
  quoteId: string;
  paymentId: number;
  orderId: number;
  buyCurrency: string;
  buyAmount: number;
  beneficiaryCountry: Country;
}) {
  const form = useForm<FormType>();

  const { reset, handleSubmit } = form;

  const [routingCodeDetails, setRoutingCodeDetails] = useState<{
    intermediary: any;
    credit: any;
  }>();
  const [isBic, setIsBic] = useState(false);
  const [swiftBicCode, setSwiftBicCode] = useState('');
  const [iban, setIban] = useState('');

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const verifyIbanExistsQuery = useQuery<
    Awaited<ReturnType<typeof PaymentService.verifyIbanExists>> | undefined
  >({
    queryKey: ['verify_iban_exists', iban],
    enabled: false,
  });

  const beneficiaryDataQuery = useQuery({
    queryKey: ['request_quote_beneficiary', beneficiaryCountry.code, buyCurrency],
  });

  const submitFormMutation = useMutation({
    mutationFn: async (data: FormType) => {
      const response = await PaymentService.addPaymentCreditor(
        quoteId,
        paymentId,
        orderId,
        isBic ? 'BIC' : 'FW',
        data,
        routingCodeDetails,
        beneficiaryCountry.code,
      );
      return response;
    },
    onSuccess: (data) => {
      navigate(`/payment/rate-requests/summary?orderId=${data.orderId}`);
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'Failed',
          detail: error.message,
        }),
      );
    },
  });

  const onSubmit: SubmitHandler<FormType> = (data) => {
    if (iban) {
      if (verifyIbanExistsQuery.status === 'pending') {
        dispatch(
          sendToast({
            severity: 'warn',
            summary: 'iban_check.inprogress',
          }),
        );
        return;
      }

      if (!verifyIbanExistsQuery.data?.ibanBic) {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'failed',
            detail: 'iban_check.warn',
          }),
        );
        return;
      }
    }

    submitFormMutation.mutate(data);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
      className='grid grid-rows-[1fr_auto] gap-6 h-full pb-6'
    >
      <BeneficiaryForm
        key={beneficiaryCountry.code} // to clear the form when country changes
        buyCurrency={buyCurrency}
        buyAmount={buyAmount}
        beneficiaryCountry={beneficiaryCountry}
        form={form}
        routingCodeDetails={routingCodeDetails}
        setRoutingCodeDetails={setRoutingCodeDetails}
        setIsBic={setIsBic}
        swiftBicCode={swiftBicCode}
        setSwiftBicCode={setSwiftBicCode}
        iban={iban}
        setIban={setIban}
      />
      <div className='flex justify-center gap-6'>
        <Button
          type='button'
          severity='contrast'
          onClick={() => {
            reset();
            setSwiftBicCode('');
          }}
        >
          <Translate value='delete_entry' />
        </Button>
        <Button
          severity='info'
          className='w-56 inline-flex gap-2 justify-center items-center'
          disabled={!beneficiaryDataQuery.isSuccess}
          loading={submitFormMutation.isPending}
        >
          <Translate value='quote.bene.save' />
        </Button>
      </div>
    </form>
  );
}

export default BeneficiaryCreationModal;
