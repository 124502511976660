import React, { useContext, useEffect, useState } from 'react';
import { CustomStepper } from '../../../../components';
import { PageHeadContext } from '../../../../providers/PageHead';
import { AddOrEditOrView, RequestQuickQuoteType } from '../../../../types';
import { BeneficiaryDetails } from '../BeneficiaryDetails';
import { CustomerDetails } from '../CustomerDetails';
import { QuoteAmount } from '../QuoteAmount';
import { useSelector } from 'react-redux';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { useMutation } from '@tanstack/react-query';
import PaymentService from '../../paymentService';
import { useAppDispatch } from '../../../../store';

interface Step {
  id: string;
  content: React.ReactNode;
}

const RequestQuickQuote = ({ mode }: { mode: AddOrEditOrView }) => {
  const [activeStep, setActiveStep] = useState<string>(RequestQuickQuoteType.QuoteAmounCurrency);
  const [visited, setVisited] = useState<Set<string>>(new Set());
  const [visitPermitted, setVisitPermitted] = useState<Set<string>>(new Set());
  const [validationOnStepper, setValidationOnStepper] = useState<Set<string>>(new Set());
  const [quoteId, setQuoteId] = useState<string>('');
  const [buyCurrency, setBuyCurrency] = useState<string>();
  const [buyAmount, setBuyAmount] = useState<number>();
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
  const [orderId, setOrderId] = useState<number | undefined>();
  const [paymentId, setPaymentId] = useState<number | undefined>();
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  const dispatch = useAppDispatch();

  const selectedCustomer = useSelector(selectCurrentCustomer);

  const isOrderingCustomer = selectedCustomer?.customerType === 'ORDERING_CUSTOMER';

  useEffect(() => {
    setVisitPermitted(new Set([RequestQuickQuoteType.QuoteAmounCurrency]));
    setPageHeadData({
      title: 'requestQuickQuote',
      description: 'requestQuickQuoteDescription',
    });
  }, [setPageHeadData]);

  const handleStepperValidation = (formName: RequestQuickQuoteType, isValid: boolean) => {
    setValidationOnStepper((prev: Set<string>) => {
      const newValidations = new Set(prev);
      if (!isValid) {
        newValidations.add(formName);
      } else {
        newValidations.delete(formName);
      }
      return newValidations;
    });
  };

  function completeCustomerStep() {
    setVisitPermitted(new Set([...visitPermitted, RequestQuickQuoteType.QuoteAmounCurrency]));
    setVisited(new Set([...visited, RequestQuickQuoteType.QuoteAmounCurrency]));
    if (mode === AddOrEditOrView.Add && !visited.has(RequestQuickQuoteType.QuoteAmounCurrency)) {
      setActiveStep(RequestQuickQuoteType.QuoteAmounCurrency);
    } else {
      setActiveStep('');
    }
  }

  const updateRemitterMutation = useMutation({
    mutationFn: async ({ orderId, paymentId }: { orderId: number; paymentId: number }) => {
      const response = await PaymentService.updateRemitter({
        remitterId: `${selectedCustomer!.id}`,
        orderId,
        paymentId,
      });
      return response;
    },
    onSuccess: () => {
      completeCustomerStep();
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'error',
          detail: 'quote.remitter.update.error',
        }),
      );
    },
  });

  const steps: Step[] = [
    {
      id: RequestQuickQuoteType.QuoteAmounCurrency,
      content: (
        <QuoteAmount
          onSaveAndProceed={(
            currency: string,
            id: string,
            orderId: number | undefined,
            paymentId: number | undefined,
            buyAmountValue: string,
          ) => {
            setQuoteId(id || '');
            setOrderId(orderId);
            setPaymentId(paymentId);
            setCustomerDetails(undefined);
            setVisited(new Set());
            if (id) {
              setBuyCurrency(currency);
              setBuyAmount(Number(buyAmountValue));
              if (isOrderingCustomer && orderId && paymentId) {
                updateRemitterMutation.mutate({ orderId, paymentId });
              } else {
                completeCustomerStep();
              }
            }
          }}
          handleStepperValidation={handleStepperValidation}
        />
      ),
    },
    {
      id: RequestQuickQuoteType.BeneficiaryDetails,
      content: (
        <BeneficiaryDetails
          orderId={orderId!}
          disabled={
            !quoteId ||
            !buyCurrency ||
            (isOrderingCustomer ? !updateRemitterMutation.isSuccess : !customerDetails)
          }
          quoteId={quoteId}
          paymentId={paymentId!}
          buyCurrency={buyCurrency!}
          buyAmount={buyAmount!}
        />
      ),
    },
  ];

  if (!isOrderingCustomer) {
    steps.splice(1, 0, {
      id: RequestQuickQuoteType.CustomerDetails,
      content: (
        <CustomerDetails
          quoteId={quoteId}
          orderId={orderId}
          paymentId={paymentId}
          onSaveAndProceed={(client: CustomerDetails) => {
            setCustomerDetails(client);
            setVisitPermitted(new Set([...visitPermitted, RequestQuickQuoteType.CustomerDetails]));
            setVisited(new Set([...visited, RequestQuickQuoteType.CustomerDetails]));
            if (
              mode === AddOrEditOrView.Add &&
              !visited.has(RequestQuickQuoteType.CustomerDetails)
            ) {
              setActiveStep(RequestQuickQuoteType.CustomerDetails);
            } else {
              setActiveStep('');
            }
          }}
        />
      ),
    });
  }

  return (
    <>
      <div className='pt-10 mx-auto rounded-md'>
        <CustomStepper
          steps={steps}
          activeStep={activeStep}
          visited={visited}
          validations={validationOnStepper}
        />
      </div>
    </>
  );
};

export default RequestQuickQuote;
