import { ChevronLeft, ChevronRight, DocumentBlank } from '@carbon/icons-react';
import React, { useRef } from 'react';
import { formatFileSize } from '../../../utils/helper';
import { Close } from '../../../icons';
import CaseService from '../caseService';

interface FileItem {
  name: string;
  size?: number;
  documentId?: string;
}

interface FileListProps {
  files: FileItem[];
  removeFile?: (index: number) => void;
  removeOption?: boolean;
  detailsView?: boolean;
  caseId?: string;
}

const FileList = ({
  files,
  removeFile,
  removeOption = true,
  detailsView = false,
  caseId,
}: FileListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const downloadFile = async (file: FileItem) => {
    const response = await CaseService.downloadFile(file.documentId, caseId);
    console.log(response);
  };

  return (
    <div>
      {!!files.length && (
        <div className='relative flex items-center gap-8 justify-between'>
          <div className='cursor-pointer hidden sm:block'>
            <ChevronLeft onClick={scrollLeft} size={24} className='text-primary' />
          </div>
          <div
            ref={containerRef}
            className='flex flex-col sm:flex-row gap-4 overflow-x-auto scrollbar-hide'
            style={{ scrollBehavior: 'smooth' }}
          >
            {files.map((file, index) => (
              <div
                key={file.name}
                className={`flex-shrink-0 flex gap-2 border border-neutral-5 rounded-c8 p-6 ${detailsView ? 'cursor-pointer' : ''}`}
                onClick={() => {
                  if (detailsView) {
                    downloadFile(file);
                  }
                }}
              >
                <div>
                  <div className='bg-document-surface inline-block rounded-full p-[5px]'>
                    <DocumentBlank className='text-primary-blue-solid' />
                  </div>
                </div>
                <div>
                  <div className='text-neutral-1 text-sm-medium'>{file.name}</div>
                  {file.size && (
                    <div className='text-neutral-3 text-sm-medium'>{formatFileSize(file.size)}</div>
                  )}
                </div>
                {removeOption && (
                  <div
                    className='ml-4 cursor-pointer'
                    onClick={() => {
                      removeFile?.(index);
                    }}
                  >
                    <Close />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='cursor-pointer hidden sm:block'>
            <ChevronRight onClick={scrollRight} size={24} className='text-primary' />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileList;
