import { useSelector } from 'react-redux';
import { FormatAmount } from '../../../components';
import { Translate } from '../../../i18n/translate';
import { convertDateFormat } from '../../../utils/helper';
import FileList from '../Create/FileList';
import { selectCustomerCurrencies } from '../../../store/slices/refdata/refdataSlice';

interface CaseDetailsProps {
  caseData?: Case;
  paymentData?: Order;
}

const CaseDetails = ({ caseData, paymentData }: CaseDetailsProps) => {
  const description = caseData?.messages[0].comment.split(' ').pop();
  const files = caseData?.messages[0].attachments.map((attachment) => ({
    name: attachment.fileName,
    documentId: attachment.documentId,
  }));
  const currencies = useSelector(selectCustomerCurrencies);

  return (
    <div className='card'>
      <div className='text-neutral-1 text-sm-bold'>
        <Translate value='case_details' />
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 sm:w-5/6 mt-6'>
        {paymentData && (
          <>
            <div>
              <div className='text-sm-medium text-neutral-3'>
                <Translate value='payment_id' />
              </div>
              <div className='text-md-medium text-neutral-1'>{paymentData?.id}</div>
            </div>
            <div>
              <div className='text-sm-medium text-neutral-3'>
                <Translate value='payment_table.header.valueDate' />
              </div>
              <div className='text-md-medium text-neutral-1'>
                {convertDateFormat(paymentData.payments?.[0].quote.valueDate)}
              </div>
            </div>
            <div>
              <div className='text-sm-medium text-neutral-3'>
                <Translate value='sell_amount' />
              </div>
              <div className='text-md-medium text-neutral-1'>
                {paymentData.payments[0].buyCurrency}{' '}
                <FormatAmount
                  number={paymentData.payments[0]?.buyAmount}
                  maximumFractionDigits={
                    currencies.find(
                      (currency) => paymentData.payments[0].buyCurrency == currency.isocode,
                    )?.amountPrecision
                  }
                />
              </div>
            </div>
            <div>
              <div className='text-sm-medium text-neutral-3'>
                <Translate value='sell_amount' />
              </div>
              <div className='text-md-medium text-neutral-1'>
                {paymentData.payments[0].buyCurrency}{' '}
                <FormatAmount
                  number={paymentData.payments[0]?.buyAmount}
                  maximumFractionDigits={
                    currencies.find(
                      (currency) => paymentData.payments[0].buyCurrency == currency.isocode,
                    )?.amountPrecision
                  }
                />
              </div>
            </div>
          </>
        )}
        <div>
          <div className='text-sm-medium text-neutral-3'>
            <Translate value='case_topic' /> <span className='text-error-1'>*</span>
          </div>
          <div className='text-md-medium text-neutral-1'>{caseData?.topic}</div>
        </div>
        <div>
          <div className='text-sm-medium text-neutral-3'>
            <Translate value='case_reason' /> <span className='text-error-1'>*</span>
          </div>
          <div className='text-md-medium text-neutral-1'>{caseData?.reason}</div>
        </div>
      </div>
      {description && (
        <div className='py-6'>
          <div className='text-sm-medium text-neutral-3'>
            <Translate value='description' /> <span className='text-error-1'>*</span>
          </div>
          <div className='text-md-medium text-neutral-1'>{description}</div>
        </div>
      )}
      <div className='text-sm-medium text-neutral-3 mb-6'>
        <Translate value='attachments' />{' '}
        <div className='rounded-[16px] text-xs-medium inline-block py-[2px] px-[8px] w-max text-neutral-1 bg-neutral-surface-dark ml-4'>
          <Translate value='total' />: {files?.length ?? 0}
        </div>
      </div>
      <FileList files={files ?? []} removeOption={false} detailsView caseId={caseData?.supportTicketId} />
    </div>
  );
};

export default CaseDetails;
