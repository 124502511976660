import { RoleGuard } from '../../components';
import { AddOrEditOrView } from '../../types';
import BeneficiaryDetailsContainer from './BeneficiaryDetailsContainer';
import beneficiaryRoles from './beneficiaryRoles';
import CreateBeneficiary from './Create';
import ManageBeneficiaries from './Manage';

const beneficiaryRoutes = [
  {
    id: 'manage_beneficiaries',
    path: '',
    handle: {
      breadcrumb: '',
    },
    element: (
      <RoleGuard roles={beneficiaryRoles.ViewBeneficiary}>
        <ManageBeneficiaries />
      </RoleGuard>
    ),
  },
  {
    id: 'create_beneficiary',
    path: 'create',
    handle: {
      breadcrumb: 'Add New Beneficiary',
    },
    element: (
      <RoleGuard roles={beneficiaryRoles.CreateBeneficiary}>
        <CreateBeneficiary />
      </RoleGuard>
    ),
  },
  {
    id: 'edit_beneficiary',
    path: ':beneficiaryId/edit',
    handle: {
      breadcrumb: 'Edit Beneficiary',
    },
    element: (
      <RoleGuard roles={beneficiaryRoles.UpdateBeneficiary}>
        <BeneficiaryDetailsContainer mode={AddOrEditOrView.Edit} />
      </RoleGuard>
    ),
  },
  {
    id: 'view_beneficiary',
    path: ':beneficiaryId',
    handle: {
      breadcrumb: 'View Beneficiary',
    },
    element: (
      <RoleGuard roles={beneficiaryRoles.ViewBeneficiary}>
        <BeneficiaryDetailsContainer mode={AddOrEditOrView.View} />
      </RoleGuard>
    ),
  },
  {
    id: 'approve_beneficiary',
    path: ':beneficiaryId/approve',
    handle: {
      breadcrumb: 'Approve Beneficiary',
    },
    element: (
      <RoleGuard roles={beneficiaryRoles.UpdateBeneficiary}>
        <BeneficiaryDetailsContainer mode={AddOrEditOrView.Approve} />
      </RoleGuard>
    ),
  },
];

export default beneficiaryRoutes;
