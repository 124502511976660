import { Status } from '../../types';

const BENEFICIARY_STATUS_OPTIONS = [
  { label: 'Active', value: Status.ACTIVE },
  { label: 'Inactive', value: Status.INACTIVE },
  { label: 'Pending', value: Status.PENDING },
  { label: 'Rejected', value: Status.REJECTED_CREATION },
] as const;

export { BENEFICIARY_STATUS_OPTIONS };
