import {
  CheckmarkOutline,
  CloseOutline,
  Filter,
  Help,
  PricingQuickProposal,
  TrashCan,
  UserFollow,
  UserIdentification,
  UserMultiple,
  UserProfile,
  View,
} from '@carbon/icons-react';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTablePageEvent, DataTableSortEvent, SortOrder } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
  Confirm,
  CurrencyFlag,
  InheritedMenu,
  PaginatorTemplate,
  Status as StatusComponent,
  TableSearch,
  WarningIcon,
} from '../../../components';
import FilterModal from '../../../components/FilterModal';
import InfoTemplate from '../../../components/InfoTemplate';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { Download, MoreAction } from '../../../icons';
import { PageHeadContext } from '../../../providers/PageHead';
import {
  removeFilter,
  selectBreadCrumbSearch,
  selectCurrentCustomer,
  selectFilters,
  sendToast,
  setBreadCrumbSearch,
  updateAoboList,
  updateFilter,
} from '../../../store/slices/main/mainSlice';
import { selectCustomerCurrencies } from '../../../store/slices/refdata/refdataSlice';
import { CustomerType, Status } from '../../../types';
import { convertDateFormat, enumToCamelCase } from '../../../utils/helper';
import { CUSTOMER_STATUS_OPTIONS, CUSTOMER_TYPE_OPTIONS } from '../clientConstants';
import clientRole from '../clientRole';
import ClientService from '../clientService';

const Table = ({ pending }: { pending: boolean }) => {
  const [selectedClients, setSelectedClients] = useState<Client[] | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentActionClient, setCurrentActionClient] = useState<Client | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const menu = useRef<Menu>(null);
  const navigate = useNavigate();
  const currencies = useSelector(selectCustomerCurrencies);
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<SortOrder>();
  const [filterValues, setFilterValues] = useState<any>();
  const { customerId } = useParams();
  const [searchParams] = useSearchParams();
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const filters = useSelector(selectFilters)[pending ? 'client_pending' : 'client_all'];
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [globalSearch, setGlobalSearch] = useState('');
  const [debouncedSearch] = useDebounce(globalSearch, 300);
  const [debouncedPage] = useDebounce(pageParams, 300);
  const [isLoading, setLoading] = useState(false);
  const [clientList, setCLientList] = useState<Client[]>([]);
  const dispatch = useDispatch();
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const [showClientAddOption, setShowClientAddOption] = useState(false);
  const breacrCrumbSearch = useSelector(selectBreadCrumbSearch);

  useEffect(() => {
    getClients();
  }, [customerId, selectedCustomer, debouncedPage, filters, debouncedSearch, sortField, sortOrder]);

  useEffect(() => {
    if (
      selectedCustomer?.id == 1 ||
      selectedCustomer?.customerType !== CustomerType.ORDERING_CUSTOMER
    ) {
      setShowClientAddOption(true);
    }
    if (customerId) {
      getCutomerById();
    }
  }, [selectedCustomer, customerId]);

  useEffect(() => {
    if (breacrCrumbSearch) {
      setGlobalSearch(breacrCrumbSearch);
      dispatch(setBreadCrumbSearch(''));
    }
  }, [breacrCrumbSearch]);

  const getCutomerById = async () => {
    const client = await ClientService.getClientById(customerId!);
    if (client) {
      setShowClientAddOption(
        client.owningCustomerId == 1 || client.customerType !== CustomerType.ORDERING_CUSTOMER,
      );
    }
  };

  const getClients = async () => {
    const { page, rows } = pageParams;
    const filtersFinal =
      pending && filters?.statuses
        ? {
            ...filters,
            statuses: [...filters.statuses],
          }
        : filters?.statuses && filters?.statuses.indexOf(Status.PENDING_APPROVAL) !== -1
          ? {
              ...filters,
              statuses: [...filters.statuses, Status.PENDING_CREATION],
            }
          : filters;
    const payload = {
      searchText: globalSearch.trim(),
      ...filtersFinal,
    };
    setLoading(true);
    try {
      const id = customerId ? parseInt(customerId) : selectedCustomer?.id;
      if (id) {
        const response = pending
          ? await ClientService.getApprovalList(
              customerId,
              page,
              rows,
              payload,
              sortField,
              sortOrder,
              globalSearch.trim(),
            )
          : await ClientService.getChildClients(id, page, rows, payload, sortField, sortOrder);
        if (response) {
          const { content, totalElements } = response;
          const filteredClients = content.filter((client) => !client.isDeleted);
          setCLientList(filteredClients);
          setTotalRecords(totalElements);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPageHeadData({
      title: 'client.list.title',
      description: 'client.list.description',
      className: 'pb-[16px] pr-4',
      actionUI:
        showClientAddOption && clientList.length != 0 ? (
          <InfoTemplate
            titleKey='client.addnew.title'
            descriptionKey='client.addnew.description'
            buttonKey='client.addnew.button'
            btnAction={() => {
              if (customerId) {
                navigate(`/client/${customerId}/add?name=${searchParams.get('name')}&sb=c`);
              } else {
                navigate('/client/add');
              }
            }}
            roles={clientRole.AddClient}
          />
        ) : (
          <></>
        ),
    });
  }, [clientList]);

  const downloadCustomer = async () => {
    const id = customerId ? parseInt(customerId) : selectedCustomer?.id;
    const filtersFinal =
      pending && filters?.statuses
        ? {
            ...filters,
            statuses: [...filters.statuses, 'PENDING'],
          }
        : filters;
    const payload = {
      searchText: globalSearch,
      ...filtersFinal,
    };
    if (id) {
      await ClientService.downloadCustomer(id, payload);
    }
  };

  const onSort = (event: DataTableSortEvent) => {
    if (event.sortField && event.sortOrder) {
      setSortField(event.sortField);
      setSortOrder(event.sortOrder);
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const getClientName = (client: Client | null) => {
    if (!client) return '';
    return client.customerShortName ?? client.customerLegalName ?? client.id?.toString() ?? '';
  };

  const deleteClient = async (client: Client) => {
    if (client.id) {
      const { data } = await ClientService.delete(client.id);
      const { result } = data;
      if (result.success) {
        showToast('success', 'client.delete.success.toast.title', '', {
          clientName: getClientName(client),
        });
        dispatch(updateAoboList(client));
      } else {
        showToast('success', 'client.delete.error.toast.title', '', {
          clientName: getClientName(client),
        });
      }
    }

    getClients();
  };

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const handleApplyFilters = (formdata: { [key: string]: string[] | string }) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    dispatch(
      updateFilter({ filterKey: pending ? 'client_pending' : 'client_all', value: formdata }),
    );
  };

  const handleGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    setGlobalSearch(e.target.value);
  };

  const generateFilterMetaData = () => {
    const _currencies: Currency[] = currencies.reduce((filteredCurrencies, currency) => {
      if (!filteredCurrencies.some((item) => item.value === currency.isocode)) {
        filteredCurrencies.push({
          label: currency.isocode,
          value: currency.isocode,
        });
      }
      return filteredCurrencies;
    }, [] as Currency[]);
    return [
      {
        header: 'By Status',
        fields: [
          {
            name: 'statuses',
            placeholder: 'Select Status',
            value: filters?.statuses || [],
            options: CUSTOMER_STATUS_OPTIONS,
          },
        ],
      },
      {
        header: 'By Currency',
        fields: [
          {
            name: 'currencies',
            type: 'currency',
            placeholder: 'Select Currency',
            value: filters?.currencies || [],
            options: _currencies,
          },
        ],
      },
      {
        header: 'By Customer Type',
        fields: [
          {
            name: 'customerTypes',
            placeholder: 'Select Customer Currency',
            value: filters?.customerTypes || [],
            options: CUSTOMER_TYPE_OPTIONS,
          },
        ],
      },
    ];
  };

  useEffect(() => {
    const filterMetaData = generateFilterMetaData();
    setFilterValues(filterMetaData);
  }, [currencies]);

  const activateOrDeactivate = async (client: Client, _status: Status) => {
    if (client && client.id) {
      const { status, data } = await ClientService.updateStatus(client.id, _status);
      if (status != 200 || data.result.success === false) {
        showToast(
          'success',
          _status == Status.ACTIVE
            ? 'client.activate.error.toast.title'
            : 'client.deactivate.error.toast.title',
          '',
          {
            clientName: getClientName(client),
          },
        );
        return;
      } else {
        showToast(
          'success',
          _status == Status.ACTIVE
            ? 'client.activate.success.toast.title'
            : 'client.deactivate.success.toast.title',
          '',
          {
            clientName: getClientName(client),
          },
        );
      }
      getClients();
    }
  };

  const actionBodyTemplate = (client: Client) => {
    const actions: MenuItem[] = [
      {
        roles: clientRole.ViewClient,
        label: 'client.view.title',
        disabled: [Status.PENDING_CREATION].includes(client.approvalStatus),
        onClick: () => {
          navigate(`/client/view/${client?.id}`);
        },
        icon: <View />,
      },
      {
        roles: clientRole.UpdateClient,
        label: 'editClient',
        disabled: [Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(
          client.approvalStatus,
        ),
        icon: <UserProfile />,
        onClick: () => {
          navigate(`/client/edit/${client?.id}`);
        },
      },
      {
        label: 'client.managed.pricing.menu',
        icon: <PricingQuickProposal />,
        disabled: [Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(
          client.approvalStatus,
        ),
        onClick: () => {
          navigate(
            `/configuration/managed-pricing/${client?.id}?sb=c&name=${getClientName(client)}`,
          );
        },
        configKey: 'marginDefinitionEnabled',
      },
      {
        roles: clientRole.AddClient,
        label: 'addNewClient',
        icon: <UserFollow />,
        disabled: [Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(
          client.approvalStatus,
        ),
        onClick: () => {
          navigate(`/client/${client?.id}/add?sb=c&name=${getClientName(client)}`);
        },
      },
      {
        label: 'viewUsers',
        icon: <UserMultiple />,
        disabled: [Status.PENDING_CREATION].includes(client.approvalStatus),
        onClick: () => {
          navigate(`/manage/user/${client?.id}?sb=c&name=${getClientName(client)}`);
        },
      },
      {
        roles: clientRole.ViewClient,
        label: 'view.child.clients',
        disabled: [Status.PENDING_CREATION].includes(client.approvalStatus),
        onClick: () => {
          dispatch(removeFilter('client_pending'));
          dispatch(removeFilter('client_all'));
          setGlobalSearch('');
          setActiveFilterCount(0);
          setPageParams({
            ...pageParams,
            page: 0,
            first: 0,
          });
          navigate(`/client/${client?.id}/view-child?sb=c&name=${getClientName(client)}`);
        },
        icon: <UserIdentification />,
      },
      {
        roles: clientRole.UpdateClient,
        label: 'deactivate',
        disabled: [Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(
          client.approvalStatus,
        ),
        icon: <CloseOutline />,
        onClick: () => {
          activateOrDeactivate(client, Status.INACTIVE);
        },
      },
      {
        roles: clientRole.UpdateClient,
        label: 'activate',
        disabled: [Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(
          client.approvalStatus,
        ),
        icon: <CheckmarkOutline />,
        onClick: () => {
          activateOrDeactivate(client, Status.ACTIVE);
        },
      },
      {
        roles: clientRole.DeleteClient,
        label: 'delete',
        disabled: [Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(
          client.approvalStatus,
        ),
        icon: <TrashCan />,
        onClick: () => {
          setCurrentActionClient(client);
          setShowDeleteDialog(true);
        },
        className: 'delete-menu-item',
      },
    ];
    let actionFilter = actions.filter((action) => {
      if (client.status === Status.PENDING) {
        return action.label !== 'activate' && action.label !== 'deactivate';
      } else if (client.status === Status.INACTIVE) {
        return action.label !== 'deactivate';
      } else if (client.status === Status.ACTIVE) {
        return action.label !== 'activate';
      } else if (client.status === Status.REJECTED_CREATION) {
        return (
          action.label !== 'activate' &&
          action.label !== 'deactivate' &&
          action.label !== 'viewUsers' &&
          action.label !== 'view.child.clients' &&
          action.label !== 'client.managed.pricing.menu' &&
          action.label !== 'addNewClient' &&
          action.label !== 'editClient'
        );
      }
      return true;
    });

    actionFilter = actionFilter.filter((action) => {
      if (client.customerType === CustomerType.ORDERING_CUSTOMER) {
        return action.label !== 'addNewClient' && action.label !== 'view.child.clients';
      }
      return true;
    });

    actionFilter = actionFilter.filter((action) => {
      if (client.customerType === CustomerType.ORDERING_CUSTOMER && !client.enableForOnline) {
        return action.label !== 'viewUsers';
      }
      return true;
    });

    if (
      (client.canBeApprovedByCurrentUser && client.approvalStatus === Status.PENDING_CREATION) ||
      client.approvalStatus === Status.PENDING_APPROVAL
    ) {
      actionFilter.unshift({
        roles: clientRole.ApproveClient,
        label: 'client.approve',
        onClick: () => {
          navigate(`/client/approve/${client?.id}`);
        },
        icon: <CheckmarkOutline />,
      });
    }

    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActions(actionFilter);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div>
        <div className='text-neutral-1 text-lg-semibold'>
          <Translate value='total' />
          <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
            {totalRecords}
          </span>
        </div>
        <div className='mt-1'>
          <Translate
            value='client_list_table_description'
            className='text-neutral-3 text-sm-regular'
          />
        </div>
      </div>

      <div className='flex justify-around'>
        <div>
          <TableSearch globalFilterValue={globalSearch} onGlobalFilterChange={handleGlobalSearch} />
        </div>

        {!pending && (
          <>
            <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
              <Button
                severity='secondary'
                className='p-button-text h-12'
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                <Filter size={20} />
                <Translate value='filterLabel' className='ml-1 hidden lg:block' />
              </Button>
              {!!activeFilterCount && (
                <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
              )}
            </div>
            <div className='ml-2'>
              <Button
                severity='contrast'
                onClick={() => {
                  downloadCustomer();
                }}
                className='h-12'
              >
                <Download /> <Translate value='downloadLabel' className='ml-1 hidden lg:block' />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
  return (
    <>
      <div>
        {!isLoading && clientList.length === 0 && !globalSearch && !activeFilterCount && (
          <div className='card'>
            <div className='text-center m-auto'>
              <img src='/images/study--skip.svg' className='m-auto pt-[60px]' />
              <div className='text-md-bold neutral-1 mt-5'>
                <Translate value='client.noclients.title' />
              </div>
              <div className='text-md-regular text-center m-auto text-neutral-3 mt-[15px] w-[60%]'>
                <Translate value='client.noclients.description' />
              </div>
            </div>
            {showClientAddOption && (
              <div className='mt-[30px]'>
                <InfoTemplate
                  titleKey='client.addnew.title'
                  descriptionKey='client.addnew.description'
                  buttonKey='client.addnew.button'
                  roles={clientRole.AddClient}
                  btnAction={() => {
                    if (customerId) {
                      navigate(`/client/${customerId}/add?name=${searchParams.get('name')}&sb=c`);
                    } else {
                      navigate('/client/add');
                    }
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div>
        <>
          <Confirm
            confirmLabel='Delete'
            headerIcon={<WarningIcon />}
            title='delete.client.confirm.title'
            message='delete.client.confirm.description'
            i18nParameters={{
              clientName: `'${getClientName(currentActionClient)}'`,
            }}
            confirmButtonSeverify='danger'
            onCancel={() => {
              setShowDeleteDialog(false);
            }}
            onConfirm={() => {
              if (currentActionClient) {
                deleteClient(currentActionClient);
              }
              setShowDeleteDialog(false);
            }}
            visible={showDeleteDialog}
          />
          <FilterModal
            visible={isModalVisible}
            setFilterModalToggle={setModalVisible}
            filters={filterValues}
            setFilters={setFilterValues}
            title='clientFilter'
            onApply={handleApplyFilters}
          />
          {(clientList.length !== 0 || globalSearch || activeFilterCount != 0) && (
            <div className='table-card'>
              <DataTable
                paginator={true}
                lazy
                paginatorTemplate={PaginatorTemplate}
                first={pageParams.first}
                rows={pageParams.rows}
                totalRecords={totalRecords}
                loading={isLoading}
                onPage={onPage}
                header={header}
                value={clientList}
                size={'large'}
                selectAll={true}
                selectionMode={'checkbox'}
                selection={selectedClients!}
                onSelectionChange={(e: { value: Client[] }) => setSelectedClients(e.value)}
                dataKey='id'
                onSort={onSort}
                sortField={sortField}
                sortOrder={sortOrder}
              >
                <Column selectionMode='multiple' headerStyle={{ width: '1rem' }}></Column>
                <Column
                  field='customerLegalName'
                  header='Client Name'
                  sortable
                  body={(client: Client) => (
                    <div className='text-sm-medium text-neutral-2'>
                      {client.customerLegalName}
                      <div className='text-sm-regular text-neutral-3'>{client.email}</div>
                    </div>
                  )}
                ></Column>
                <Column
                  sortable
                  field='customerShortName'
                  header={
                    <span>
                      Short Name <Help className='ml-1 inline text-neutral-4' />
                    </span>
                  }
                ></Column>
                <Column
                  sortable
                  field='status'
                  header='Status'
                  body={(client: Client) => (
                    <StatusComponent
                      status={
                        client.approvalStatus === Status.PENDING_CREATION ||
                        client.approvalStatus == Status.PENDING_APPROVAL
                          ? client.approvalStatus
                          : client.status
                      }
                    />
                  )}
                ></Column>
                <Column
                  filterField='currency'
                  header='Currency'
                  body={(client: Client) => <CurrencyFlag currencyCode={client.currency ?? ''} />}
                ></Column>
                <Column
                  sortable
                  field='customerType'
                  header='Customer Type'
                  body={(client: Client) => (
                    <div className='text-sm-medium text-neutral-2'>
                      {enumToCamelCase(client.customerType)}
                    </div>
                  )}
                ></Column>
                <Column field='createdBy' header={translateWithValues('created_by')} />
                <Column
                  field='createdDate'
                  header={translateWithValues('created_date')}
                  body={(client: Client) => convertDateFormat(client.createdOn ?? '')}
                />
                <Column field='stoneXId' header='Mnemonic ID'></Column>
                <Column header='Actions' body={actionBodyTemplate}></Column>
              </DataTable>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default Table;
