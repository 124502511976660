import { RoleGuard } from '../../components';
import caseRoles from './caseRoles';
import CreateCase from './Create';
import Details from './Details';
import ViewCases from './ViewCases';

const getHelpRoutes = [
  {
    id: 'view_cases',
    path: '',
    handle: {
      breadcrumb: '',
    },
    element: (
      <RoleGuard roles={caseRoles.ViewCase}>
        <ViewCases />
      </RoleGuard>
    ),
  },
  {
    id: 'create_case',
    path: 'create',
    handle: {
      breadcrumb: 'Add New Case',
    },
    element: (
      <RoleGuard roles={caseRoles.CreateCase}>
        <CreateCase />
      </RoleGuard>
    ),
  },
  {
    id: 'details',
    path: 'details/:caseId',
    handle: {
      breadcrumb: 'View Case',
    },
    element: (
      <RoleGuard roles={caseRoles.ViewCase}>
        <Details />
      </RoleGuard>
    ),
  },
];

export default getHelpRoutes;
