import { InputText } from 'primereact/inputtext';
import { Translate, TranslateWithValues, translateWithValues } from '../../../i18n/translate';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { SendAltFilled } from '@carbon/icons-react';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import CaseService from '../caseService';
import { formatDateRelativeTime } from '../../../utils/helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useSelector } from 'react-redux';
import { selectOktaUser } from '../../../store/slices/main/mainSlice';
import useRole from '../../../hooks/useRoles';
import caseRoles from '../caseRoles';

interface CommentBoxProps {
  caseId?: string;
  messages?: Message[];
  createdBy?: string;
}

const CommentBox = ({ caseId, messages, createdBy }: CommentBoxProps) => {
  const [messageList, setMessageList] = useState(messages);
  const [comment, setComment] = useState('');
  const scrollRef = useRef<HTMLDivElement>(null);
  const oktaUser = useSelector(selectOktaUser);
  const { hasRole } = useRole();
  const today = new Date().toLocaleDateString('en-CA');

  const groupedMessages: GroupedMessages = (messageList || []).reduce(
    (acc, message) => {
      const messageDate = new Date(message.createdDate).toLocaleDateString('en-CA');
      const group = messageDate === today ? 'Today' : 'Older';
      acc[group].push(message);
      return acc;
    },
    { Older: [], Today: [] } as GroupedMessages,
  );

  const handleCaseCommentMutation = useMutation({
    mutationFn: async () => {
      const commentWithUser = `[${oktaUser?.name}] ${comment}`;
      return await CaseService.commentOnCase(commentWithUser, caseId ?? '');
    },
    onSuccess: (response) => {
      if (response.status === 200) {
        const commentDetails: Message = {
          createdBy: createdBy ?? '',
          createdDate: new Date().toISOString(),
          comment: `[${oktaUser?.name}] ${comment}`,
          attachments: [],
        };
        setMessageList([...(messageList || []), commentDetails]);
        setComment('');
      }
    },
  });

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [handleCaseCommentMutation.isPending]);

  const renderMessage = (message: Message, index: number, messages: Message[]) => {
    if (message.comment === '{}') {
      return (
        <div
          key={`Case-Closed-${index}`}
          className='flex justify-center text-neutral-4 text-sm-medium border-b border-neutral-5 pb-4'
        >
          <TranslateWithValues
            value='case_closed_at'
            params={{ dateTime: formatDateRelativeTime(message.createdDate) }}
          />
        </div>
      );
    }

    const [user, commentText] = message.comment
      .split(']')
      .map((part) => part.replace('[', '').trim());
    const isCurrentUser = user === oktaUser?.name;

    return (
      <>
        {index > 0 && messages[index - 1].comment === '{}' && (
          <div className='flex justify-center text-warning-content-default text-sm-medium'>
            <TranslateWithValues
              value='case_reopened_at'
              params={{ dateTime: formatDateRelativeTime(message.createdDate) }}
            />
          </div>
        )}
        <div
          className={`flex ${isCurrentUser ? 'justify-end' : 'justify-start'}`}
          key={message.createdDate}
        >
          <div
            className={`${isCurrentUser ? 'bg-blue-surface-subtle' : 'bg-[#EFF2F5]'} w-2/3 p-3 rounded-c8 flex flex-col`}
          >
            <div className='text-neutral-1 text-lg-semibold'>{user}</div>
            <div className='text-neutral-2 text-md-regular break-words whitespace-normal'>
              {commentText}
            </div>
            <div className='text-right text-neutral-3 text-sm-regular'>
              {formatDateRelativeTime(message.createdDate)}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='card'>
      <div className='text-neutral-1 text-sm-bold'>
        <Translate value='case_comment_history' />
      </div>
      <div className='h-full flex flex-col mt-2'>
        <div className='flex-1 overflow-y-auto' style={{ maxHeight: '320px' }} ref={scrollRef}>
          {handleCaseCommentMutation.isPending ? (
            <div className='bg-white/50 flex justify-center items-center h-full'>
              <ProgressSpinner className='w-10 h-10' />
            </div>
          ) : (
            Object.entries(groupedMessages).map(([group, messages]) => (
              <div key={group}>
                {messages.length > 0 && (
                  <div className='text-neutral-3 text-center text-sm-semibold py-3'>
                    <Translate value={group} />
                  </div>
                )}
                <div className='flex flex-col gap-4'>
                  {messages.map((message: Message, index: number) =>
                    renderMessage(message, index, messages),
                  )}
                </div>
              </div>
            ))
          )}
        </div>
        {hasRole(caseRoles.UpdateCase) && (
          <div className='py-8'>
            <IconField iconPosition='right'>
              <InputText
                placeholder={translateWithValues('type_your_messages')}
                className='w-full'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && comment) {
                    handleCaseCommentMutation.mutate();
                  }
                }}
              />
              <InputIcon className='flex items-center justify-center h-full cursor-pointer'>
                <div
                  className='bg-selected p-1.5 rounded-c8 absolute bottom-6 right-0'
                  onClick={() => comment && handleCaseCommentMutation.mutate()}
                >
                  <SendAltFilled className='text-white' />
                </div>
              </InputIcon>
            </IconField>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentBox;