export const FREQUENCY_OPTIONS = [
  { label: 'Hourly', value: 'HOURLY' },
  { label: 'Daily', value: 'DAILY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Monthly', value: 'MONTHLY' },
];

export const DAYS_OF_WEEK = [
  { label: 'Monday', value: 'MONDAY' },
  { label: 'Tuesday', value: 'TUESDAY' },
  { label: 'Wednesday', value: 'WEDNESDAY' },
  { label: 'Thursday', value: 'THURSDAY' },
  { label: 'Friday', value: 'FRIDAY' },
  { label: 'Saturday', value: 'SATURDAY' },
  { label: 'Sunday', value: 'SUNDAY' },
];

export const TIMEZONE_OPTIONS = [
  { label: 'Monday', value: 'MONDAY' },
  { label: 'Tuesday', value: 'TUESDAY' },
  { label: 'Wednesday', value: 'WEDNESDAY' },
  { label: 'Thursday', value: 'THURSDAY' },
  { label: 'Friday', value: 'FRIDAY' },
  { label: 'Saturday', value: 'SATURDAY' },
  { label: 'Sunday', value: 'SUNDAY' },
];

export const DATE_OPTIONS = Array.from({ length: 28 }, (_, i) => ({
  label: `${i + 1}`,
  value: i + 1,
}));

export const REPORT_FORMAT_OPTIONS = [
  // { label: 'PDF', value: 'PDF' },
  // { label: 'XML', value: 'XML' },
  { label: 'CSV', value: 'CSV' },
];

export const DELIVERY_OPTIONS = [
  { label: 'Email', value: 'EMAIL' },
  { label: 'Online', value: 'ONLINE' },
  { label: 'SFTP', value: 'SFTP' },
];

export const TIME_OPTIONS = Array.from({ length: 60 }, (_, i) => ({
  label: `${i + 1}`,
  value: i + 1,
}));
