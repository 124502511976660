import apiClient from './apiClient';
import { handleApiResponse } from './helper';

const ROOT = '/api/refdata-management/refdata/all';
const COUNTRY_LIST = '/api/country-management/countries';
export const RefdataService = {
  getMetaData: async () => {
    try {
      const response = await apiClient.get<RefdataResponse>(ROOT);
      return handleApiResponse<MetaData>(response);
    } catch (error) {
      return null;
    }
  },

  getCountryList: async () => {
    try {
      const response = await apiClient.get<RefdataResponse>(COUNTRY_LIST);
      return handleApiResponse<StonexCountry[]>(response, 'value');
    } catch (error) {
      return [];
    }
  },
};
