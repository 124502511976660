import { useRef } from 'react';
import { Translate, TranslateWithValues } from '../../i18n/translate';
import { FetchUpload } from '../../icons';

interface CustomFileUploadProps {
  onFilesSelected: (files: FileList) => void;
  accept: string;
  multiple?: boolean;
}

const CustomFileUpload = ({ onFilesSelected, accept, multiple = false }: CustomFileUploadProps) => {
  const extensions = accept.replace(/\./g, ' ').toUpperCase();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: any) => {
    const files = event.target.files;
    if (files) {
      onFilesSelected(files);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      onFilesSelected(files);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div
      onClick={handleClick}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className='text-center justify-center h-4/5 mt-5 flex items-center flex-col bg-background border border-neutral-5 rounded-c8 cursor-pointer'
    >
      <input
        type='file'
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileUpload}
        accept={accept}
        multiple={multiple}
      />
      <div className='bg-primary-surface-light inline-block p-2 rounded-full'>
        <FetchUpload />
      </div>
      <div>
        <Translate
          value='click_to_upload'
          className='bg-gradient-to-b from-primary-gradient-from to-primary-gradient-to bg-clip-text text-transparent font-semibold inline-block'
        />{' '}
        <Translate value='or_drag_or_drop' className='text-neutral-3 font-medium' />
      </div>
      <div className='text-xs-regular text-neutral-3 mt-1'>
        <TranslateWithValues
          value='file_upload_supported_file_types'
          params={{ accept: extensions }}
        />
      </div>
    </div>
  );
};

export default CustomFileUpload;
