// import { useState } from 'react';
import { Translate } from '../../../../i18n/translate';
// import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { UserAvatar } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { useState } from 'react';
import PermissionModal from '../../../../components/PermissionModal';

interface RoleDefinitionCardProps {
  role: Role;
}

const RoleDefinitionCard = ({ role }: RoleDefinitionCardProps) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      {isModalVisible && (
        <PermissionModal visible={isModalVisible} onHide={handleCloseModal} role={role} />
      )}
      <div className='flex justify-center items-center mb-3 mt-2'>
        <div className='card flex items-center justify-between w-full max-w-[34rem] mx-6'>
          <div className='flex flex-col gap-4 w-full'>
            <div className='flex items-center justify-between'>
              <div className='flex gap-2 items-center'>
                <UserAvatar size={30} className='text-primary-disabled' />
                <div className='text-lg-bold text-neutral-1'>
                  <Translate value={role.roleName} />
                </div>
              </div>
            </div>
            <div className='text-sm-regular text-neutral-6 w-[28rem] ml-1'>
              <Translate value={role.description} />
            </div>
            <Button
              severity='info'
              className='flex justify-center py-2'
              onClick={() => handleOpenModal()}
            >
              <Translate value='view_permissions' />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleDefinitionCard;
