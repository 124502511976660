import { CaseStatus } from '../../types';

const CASE_STATUS_OPTIONS = [
  {
    label: 'Open',
    value: CaseStatus.OPEN,
  },
  {
    label: 'Closed',
    value: CaseStatus.CLOSED,
  },
  {
    label: 'Reopend',
    value: CaseStatus.REOPENED,
  },
];

const MAX_FILE_UPLOAD_LENGTH = 5;

export { CASE_STATUS_OPTIONS, MAX_FILE_UPLOAD_LENGTH };
