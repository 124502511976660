import { useSelector } from 'react-redux';
import InfoTemplate from '../../../../components/InfoTemplate';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import { selectLoading } from '../../../../store/slices/main/mainSlice';
import { selectCustomRoles, selectPrimaryRoles } from '../../../../store/slices/role/roleSlice';
import RolesTable from './RolesTable';

const PrimaryRoles = () => {
  const primaryRoles = useSelector(selectPrimaryRoles);
  const customRoles = useSelector(selectCustomRoles);
  const loading = useSelector(selectLoading);

  if (!loading && !primaryRoles.length && !customRoles.length) {
    return (
      <>
        <NoDataPlaceholder title='roles.noRoles.title' desc='roles.noRoles.desc'>
          <InfoTemplate
            titleKey='roles.noRoles.info.title'
            descriptionKey='roles.noRoles.info.desc'
            buttonKey='roles.noRoles.info.button.title'
            btnAction={() => console.log('TODO')}
          />
        </NoDataPlaceholder>
      </>
    );
  }

  return (
    <div>
      <RolesTable
        title='roles.primary.title'
        description='roles.primary.description'
        data={primaryRoles}
      />
    </div>
  );
};
export default PrimaryRoles;
