import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Translate } from '../../../../i18n/translate';
import { PageHeadContext } from '../../../../providers/PageHead';
import PaymentsTable from './BulkPaymentsTable';

const Dashboard = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    setPageHeadData({
      className: 'py-[8px]',
      title: 'bulk_payment.title',
      description: 'bulk_payment.description',
      button: (
        <div>
          <Button
            severity='info'
            onClick={() => {
              navigate('/payment/bulk-payments/add');
            }}
          >
            <Translate value='create_bulk_payment' />
          </Button>
        </div>
      ),
    });
  }, []);

  return (
    <>
      <PaymentsTable totalRecords={totalRecords} setTotalRecords={setTotalRecords} />
    </>
  );
};

export default Dashboard;
