import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import SearchBar from '../../../../components/SearchInput/SearchInput';
import UserPermissionModal from '../../../../components/UserPermissionModal';
import useRole from '../../../../hooks/useRoles';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import rolePermission from '../rolePermission';
import RoleUserCard from './roleUserCard';

interface RoleUserProps {
  users: User[];
  roleName: string;
}

const RoleUsers: React.FC<RoleUserProps> = ({ users, roleName }) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [finalUsers, setFinalUsers] = useState<User[]>([]);
  const { hasRole } = useRole();
  const hasEditRole = hasRole(rolePermission.UpdateRole);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFinalUsers(users.slice());
  }, [users]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const filteredUsers = useMemo(() => {
    return users
      .filter(
        (user) =>
          user.userIdentity.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.userIdentity.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.userIdentity.email.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      .sort((a, b) => a.userIdentity.firstName.localeCompare(b.userIdentity.firstName));
  }, [searchQuery, finalUsers]);

  // Manual horizontal scroll handler
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const handleCloseModal = () => {
    setShowUsersModal(false);
  };

  const onRemoveClick = (userId: string | number) => {
    setFinalUsers((prevUsers) => prevUsers.filter((u) => u.id !== userId));
  };

  const returnToRolesScreen = () => {
    navigate('/manage/role');
  };

  return (
    <>
      <div className='card m-5'>
        <div className='text-xs-bold text-neutral-1'>
          <Translate value='primaryUsersTitle' />
        </div>
        <div className='text-neutral-3 text-md-regular'>
          <Translate value='primaryUsersDesc' />
        </div>

        <div className='flex justify-between mt-8'>
          <div className='flex items-center space-x-2 p-2 rounded-full bg-primary-surface-lighter'>
            <span className='text-primary font-bold'>Total Users:</span>
            <span className='text-primary font-bold'>{finalUsers.length}</span>
          </div>
          <div className='w-[40%]'>
            <SearchBar onSearch={handleSearch} />
          </div>
        </div>

        <div className='flex justify-between items-center mt-8'>
          <Button
            icon={<ChevronLeft size='24' />}
            onClick={scrollLeft}
            disabled={users.length < 10}
            rounded
            text
            aria-label='Scroll Left'
          />
          <div
            ref={scrollContainerRef}
            className='grid grid-rows-3 gap-4 overflow-hidden p-1'
            style={{
              gridAutoFlow: 'column',
              scrollBehavior: 'smooth',
            }}
          >
            {filteredUsers.map((user) => (
              <RoleUserCard
                key={user.id}
                user={user}
                onRemoveClick={onRemoveClick}
                hasEditRole={hasEditRole}
                isBeingRemovedUser={!finalUsers.some((u) => u.id === user.id)}
              />
            ))}
          </div>
          <Button
            icon={<ChevronRight size='24' />}
            onClick={scrollRight}
            disabled={users.length < 10}
            rounded
            text
            aria-label='Scroll Right'
          />
        </div>
        {showUsersModal && (
          <UserPermissionModal
            visible={showUsersModal}
            onHide={handleCloseModal}
            title={translateWithValues('user_modal_header') + roleName}
          />
        )}
      </div>

      <div className='flex items-center justify-center gap-2 mt-12'>
        <Button className='w-1/5 justify-center' severity='contrast' onClick={returnToRolesScreen}>
          <Translate value='return' />
        </Button>
      </div>
    </>
  );
};

export default RoleUsers;
