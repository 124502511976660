import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { PageHeadContext } from '../../../../providers/PageHead';
import { AddOrEditOrView } from '../../../../types';
import PaymentService from '../../paymentService';
import { BulkPaymentTable } from '../PaymentTable';
import { BulkOverview } from './BulkOverview';
import { BulkSummary } from './BulkSummary';

export const ReviewBulkPayment = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const { orderId } = useParams();
  const sellCurrency = {
    id: '',
    isocode: 'USD',
    active: 'true',
    ratePrecision: 2,
    amountPrecision: 2,
    minAmount: { currencyCode: 'USD', value: 2 },
    maxAmount: null,
    disabled: true,
    status: 'STATUS',
    homeCountries: [],
    intermediaries: [],
  };

  useEffect(() => {
    setPageHeadData({
      title: 'review_bulk_payment',
      description: 'bulk_payment_review.description',
    });
  }, [setPageHeadData]);

  const { data: payment } = useQuery({
    queryKey: ['payment_details_in_case', orderId],
    queryFn: async () => {
      if (orderId) {
        const data = PaymentService.getOrderById(parseInt(orderId));
        return data;
      }
    },
    enabled: !!orderId,
  });

  return (
    <div>
      <div className='flex gap-4'>
        <BulkOverview payment={payment} />
        <BulkSummary payment={payment} />
      </div>
      <BulkPaymentTable
        sellCurrency={sellCurrency}
        orderId={orderId}
        mode={AddOrEditOrView.View}
        payment={payment}
      />
    </div>
  );
};
