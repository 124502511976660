import { createAsyncThunk } from '@reduxjs/toolkit';
import UserprincipalService from '../../../modules/UserPrinciple/userPrinicple';

export const fethCurrentUser = createAsyncThunk<RolesResponse | null>(
  'mainData/fetchCurrentUser',
  async (): Promise<RolesResponse | null> => {
    const response = await UserprincipalService.getCurrentUser();
    return response;
  },
);

export const fetchCurrentCustomerSettings = createAsyncThunk<SettingsData | null, number>(
  'mainData/fetchCustomerSettings',
  async (id?: number): Promise<SettingsData | null> => {
    const response = await UserprincipalService.getCustomerSettings(id);
    return response;
  },
);


