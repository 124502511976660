import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import CaseService from '../caseService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useEffect, useState } from 'react';
import PaymentService from '../../Payment/paymentService';
import { PageHeadContext } from '../../../providers/PageHead';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { Status } from '../../../components';
import CaseDetails from './CaseDetails';
import CommentBox from './CommentBox';
import { Button } from 'primereact/button';
import { CaseStatus } from '../../../types';
import { sendToast } from '../../../store/slices/main/mainSlice';
import { useAppDispatch } from '../../../store';
import PaymentDetails from '../Create/PaymentDetails';
import useRole from '../../../hooks/useRoles';
import caseRoles from '../caseRoles';

const Details = () => {
  const { caseId } = useParams();
  const [paymentId, setPaymentId] = useState<string>();
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const caseTitle = translateWithValues('overview_case_id', {
    caseId,
  });
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { hasRole } = useRole();

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const caseDetailsQuery = useQuery({
    queryKey: ['case_details', caseId],
    queryFn: async () => {
      const data = await CaseService.getCaseById(caseId as string);
      if (data.fxId) {
        setPaymentId(data.fxId);
      }
      return data;
    },
    enabled: !!caseId,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const paymentDataQuery = useQuery({
    queryKey: ['payment_details', paymentId],
    queryFn: async () => {
      const payload = {
        searchText: paymentId ?? '',
      };
      const data = await PaymentService.getRateRequests(0, 10, payload);
      return data?.content[0];
    },
    enabled: !!paymentId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setPageHeadData({
      title: caseTitle,
      description: 'overview_case.subtitle',
      actionUI: caseDetailsQuery.data && (
        <div className='flex flex-col items-end'>
          <div className='text-neutral-3 text-md-regular'>
            <Translate value='created_by' />: {caseDetailsQuery.data?.createdBy}
          </div>
          <div>
            <Status status={caseDetailsQuery.data?.status} />
          </div>
        </div>
      ),
      className: 'flex justify-between',
    });
  }, [setPageHeadData, caseDetailsQuery.data]);

  const closeCaseMutation = useMutation({
    mutationFn: async () => {
      const response = await CaseService.closeCase(caseId as string);
      return response;
    },
    onSuccess: (response) => {
      if (response.status === 200) {
        showToast('success', 'case_closed', '');
        queryClient.invalidateQueries({
          queryKey: ['case_list'],
        });
        navigate('/get-help');
      }
    },
  });

  if (caseDetailsQuery.isLoading || paymentDataQuery.isLoading) {
    return (
      <div className='absolute z-10 inset-0 bg-white/50 grid place-content-center'>
        <ProgressSpinner className='w-10 h-10' />
      </div>
    );
  }
  return (
    <div className='dashboard-padding mt-6'>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
        <CaseDetails caseData={caseDetailsQuery.data} paymentData={paymentDataQuery.data} />
        <CommentBox
          caseId={caseDetailsQuery.data?.supportTicketId}
          messages={caseDetailsQuery.data?.messages.slice(1)}
          createdBy={caseDetailsQuery.data?.createdBy}
        />
      </div>
      {paymentDataQuery.data && <PaymentDetails payment={paymentDataQuery.data} detailsView />}
      {caseDetailsQuery.data?.status !== CaseStatus.CLOSED && hasRole(caseRoles.UpdateCase) && (
        <div className='flex justify-center mt-6'>
          <Button
            loading={closeCaseMutation.isPending}
            severity='info'
            type='button'
            onClick={() => {
              closeCaseMutation.mutate();
            }}
          >
            <Translate value='close_case' />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Details;
