import { Dropdown } from 'primereact/dropdown';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { useEffect, useState } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { translateWithValues } from '../../i18n/translate';
import { selectCountries } from '../../store/slices/refdata/refdataSlice';
import ErrorIcon from '../ErrorIcon';
import MultiSelectInput from '../MultiSelect';
import { getCountryISO2 } from '../../utils/isoncCodeMapping';
import './CountrySelect.css';

interface CountryDropdownProps {
  id?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  className?: string;
  value?: Country;
  name?: string;
  control?: Control<any>;
  error?: any;
  rules?: RegisterOptions;
  countryList?: Country[];
  onChange?: (value: any) => void;
  isMulti?: boolean;
  multiSelectValue?: string[];
}

const CountrySelect = ({
  label,
  disabled,
  id,
  isRequired,
  ariaDescribedBy,
  placeholder,
  className,
  value,
  rules,
  name,
  error,
  control,
  countryList,
  onChange,
  isMulti = false,
  multiSelectValue,
}: CountryDropdownProps) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [multiSelectCountires, setMultiSelectCountires] = useState<CountryOption[]>([]);
  const selectedCountries = useSelector(selectCountries);

  const selectedCountryTemplate = (option: Country) => {
    if (option) {
      return (
        <div className='flex align-items-center'>
          <img
            alt={option.name}
            src={`/images/country_flags/${getCountryISO2(option.code).toLowerCase()}.svg`}
            className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{translateWithValues(placeholder)}</span>;
  };

  const countryOptionTemplate = (option: Country) => {
    return (
      <div className='flex align-items-center'>
        <img
          alt={option.name}
          src={`/images/country_flags/${getCountryISO2(option.code).toLowerCase()}.svg`}
          className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
        />
        <div className='overflow-hidden overflow-ellipsis'>{option.name}</div>
      </div>
    );
  };

  useEffect(() => {
    if (isMulti) {
      setMultiSelectCountires(
        selectedCountries.map((country) => ({
          label: country.name,
          value: country.code,
          code: country.code,
          name: country.name,
        })),
      );
    } else {
      setCountries(
        (countryList ? [...countryList] : [...selectedCountries]).sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      );
    }
  }, [selectedCountries, countryList]);

  const errorMessage =
    error &&
    (error.type === 'required'
      ? `${translateWithValues(label)} ${translateWithValues('is_required')}`
      : error.message);

  return (
    <div className={`flex flex-col gap-1 ${className || ''}`}>
      <label htmlFor={id} className='w-fit overflow-hidden grid gap-1 grid-cols-[1fr_auto]'>
        <span className='truncate'>{translateWithValues(label)}</span>
        {isRequired ? <span className='text-error-1'>*</span> : null}
      </label>
      {!isMulti && (
        <IconField iconPosition='right'>
          {control && name ? (
            <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field }) => (
                <Dropdown
                  className='w-full'
                  id={id}
                  tooltip={error && errorMessage}
                  value={field?.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                    onChange?.(e.value);
                  }}
                  options={countries}
                  disabled={disabled}
                  optionLabel='name'
                  placeholder={placeholder && translateWithValues(placeholder)}
                  filter
                  valueTemplate={(option: Country) => selectedCountryTemplate(option)}
                  itemTemplate={countryOptionTemplate}
                  invalid={!!error}
                />
              )}
            />
          ) : (
            <Dropdown
              id={id}
              name={name}
              value={value}
              aria-describedby={ariaDescribedBy}
              placeholder={placeholder && translateWithValues(placeholder)}
              options={countries}
              optionLabel='name'
              filter
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
              className='w-full md:w-14rem'
              invalid={!!error}
              onChange={(e) => (onChange ? onChange(e.value) : undefined)}
              disabled={disabled}
            />
          )}

          {!!error && (
            <InputIcon className='mr-[23px]'>
              <ErrorIcon isFilled={false} size={16} withBackground={false} />
            </InputIcon>
          )}
        </IconField>
      )}
      {isMulti && (
        <MultiSelectInput
          value={multiSelectValue}
          options={multiSelectCountires}
          disabled={disabled}
          itemTemplate={countryOptionTemplate}
          placeholder={placeholder && translateWithValues(placeholder)}
          className='w-full'
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default CountrySelect;
