import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useParams } from 'react-router';
import { DropdownInput } from '../../../components';
import { Translate } from '../../../i18n/translate';
import { useAppDispatch } from '../../../store';
import { sendToast } from '../../../store/slices/main/mainSlice';
import { AddOrEditOrView, ClientForms } from '../../../types';
import { REVENUE_MODEL_OPTIONS } from '../clientConstants';
import ClientService from '../clientService';
import Table from './Table';
import settingsConfig from './settingsConfig';

interface SettingsProps {
  activeStep?: string;
  mode: AddOrEditOrView;
  jumpToEdit?: (step: ClientForms, client?: Client) => void;
  visited?: Set<string>;
  visitPermitted?: Set<string>;
  onSaveAndProceed?: () => void;
  inheritedClientId?: number;
}

const Settings = ({
  activeStep,
  mode,
  jumpToEdit,
  visited,
  visitPermitted,
  onSaveAndProceed,
  inheritedClientId,
}: SettingsProps) => {
  const { customerId } = useParams();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { data, isFetching } = useQuery({
    queryKey: ['client_settings_data', inheritedClientId, customerId],
    queryFn: async (): Promise<SettingsData | null> => {
      const id = inheritedClientId ?? customerId;
      const response = await ClientService.getClientSettings(id);
      return response;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const handleSettingChange = (e: InputSwitchChangeEvent | DropdownChangeEvent, name: string) => {
    queryClient.setQueryData(
      ['client_settings_data', inheritedClientId, customerId],
      (prev: SettingsData | null) => {
        if (!prev) return prev;
        return {
          ...prev,
          [name]: e.target.value,
        };
      },
    );
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const mutation = useMutation({
    mutationFn: async (settingsData: SettingsData) => {
      const response = await ClientService.updateClientSettings(settingsData);
      return response;
    },
    onSuccess: (response) => {
      if (response.data.success) {
        showToast('success', 'success', 'settings_updated_succesfully');
      } else {
        showToast('error', 'error', 'settings_failed_to_update');
      }
    },
    onError: () => {
      showToast('error', 'error', 'settings_failed_to_update');
    },
  });

  const saveSettings = () => {
    if (data) {
      mutation.mutate(data);
    }
    if (onSaveAndProceed) {
      onSaveAndProceed();
    }
  };

  if (isFetching) {
    return (
      <div className='flex items-center'>
        <ProgressSpinner className='w-10 h-10' />
      </div>
    );
  }

  return (
    <div className='rounded-c8 shadow-c bg-white p-8'>
      <div className='pb-4 flex justify-between'>
        <div>
          <div className='text-sm-bold'>
            <Translate value='settings.title' />
          </div>
          <div className='text-neutral-3'>
            <Translate value='subheading_placeholder' />
          </div>
        </div>
      </div>
      {activeStep !== ClientForms.Settings && mode != AddOrEditOrView.View ? (
        <div className='mt-4'>
          <Button
            severity='contrast'
            className='!px-14'
            disabled={mode === AddOrEditOrView.Add && !visitPermitted?.has(ClientForms.Settings)}
            onClick={() => {
              if (jumpToEdit) {
                jumpToEdit(ClientForms.Settings);
              }
            }}
          >
            <Translate value={visited?.has(ClientForms.Settings) ? 'edit' : 'start'} />
          </Button>
        </div>
      ) : (
        <div className='flex flex-col gap-10 pt-8 border-t border-t-[1px] pb-3 border-neutral-border-2'>
          <div>
            <DropdownInput
              name='revenueShare'
              value={data?.revenueShare}
              onChange={handleSettingChange}
              className='mt-5 w-4/5'
              label='revenue_model'
              placeholder='revenue_model'
              options={REVENUE_MODEL_OPTIONS}
            />
          </div>
          {Object.keys(settingsConfig).map((key) => (
            <Table
              mode={mode}
              title={key}
              key={key}
              settings={settingsConfig[key as keyof SettingsConfig]}
              settingsData={data}
              handleSettingChange={handleSettingChange}
            />
          ))}
        </div>
      )}
      {!(activeStep !== ClientForms.Settings) && mode != AddOrEditOrView.View && (
        <div className='flex items-center justify-center mt-10 gap-5'>
          <Button severity='info' onClick={saveSettings}>
            <Translate value='save' />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Settings;
