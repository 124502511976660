import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CurrencySelect, DropdownInput, Status } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { AddAlt } from '../../../../icons';
import { PageHeadContext } from '../../../../providers/PageHead';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import { CustomerType, Status as PaymentStatus } from '../../../../types/enum';
import ClientService from '../../../Client/clientService';
import PaymentService from '../../paymentService';
import CustomerCreationModal from '../../RateRequest/CustomerDetails/CustomerCreationModal';
import { BulkPaymentTable } from '../PaymentTable';

const AddBulkPayment = () => {
  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<any>();

  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const [options, setOptions] = useState<Option[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sellCurrency, setSellCurrency] = useState<CurrencyConfiguration>();
  const currencies = useSelector(selectCustomerCurrencies);
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const sellCurrencyValue = watch('sellCurrency');
  const [bulkId, setBulkId] = useState<string>('');
  // const dispatch = useAppDispatch();

  useEffect(() => {
    setSellCurrency(
      currencies.find((val) => {
        return sellCurrencyValue == val.isocode;
      }),
    );
  }, [sellCurrencyValue]);

  // const showToast = (
  //   severity: 'success' | 'error',
  //   summary: string,
  //   detail: string,
  //   params?: { [key: string]: string },
  // ) => {
  //   dispatch(sendToast({ severity, summary, detail, params }));
  // };

  useEffect(() => {
    setPageHeadData({
      title: 'create_bulk_payment',
      description: 'editClient.description',
    });
  }, [setPageHeadData]);

  const createOrderMutation = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        debtorId: data.selectedCustomer,
        sellCurrency: data.sellCurrency,
      };
      const response = await PaymentService.initiateBulkPayment(payload);
      setBulkId(response.data.data.id);
    },
  });

  const createPayment = (data: any) => {
    createOrderMutation.mutate(data);
  };

  useEffect(() => {
    getCustomers();
  }, [selectedCustomer]);

  const handleNewCustomerCreation = (customer: Client) => {
    const newCustomerOption = {
      value: `${customer.id}`,
      label: customer.customerLegalName,
    };
    setOptions([...options, newCustomerOption]);
    reset({ selectedCustomer: `${customer.id}` });
  };

  const getCustomers = async () => {
    setIsLoading(true);
    const customerId = selectedCustomer?.id;
    if (customerId) {
      const payload = {
        customerTypes: [CustomerType.ORDERING_CUSTOMER],
      };
      const response = await ClientService.getChildClients(
        customerId,
        0,
        0,
        payload,
        '',
        null,
        true,
      );
      if (response?.content) {
        const filterCustomers = response.content.filter(
          (c) =>
            (c.status == PaymentStatus.ACTIVE ||
              c.approvalStatus == PaymentStatus.PENDING_APPROVAL ||
              c.approvalStatus == PaymentStatus.PENDING_CREATION) &&
            c.bankInfos[0]?.accountNumber != null,
        );
        const customerOptions = filterCustomers
          .map(({ id, customerLegalName, customerShortName }) => ({
            value: `${id}`,
            label: customerShortName ?? customerLegalName,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setOptions(customerOptions);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <CustomerCreationModal
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        handleNewCustomer={handleNewCustomerCreation}
      />
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(createPayment)}>
        <div className='card mt-8'>
          <div className='flex justify-between'>
            <div>
              <p className='text-xs-semibold'>
                <Translate value='create_bulk_payment.card.title' />
              </p>
              <p className='text-md-regular text-neutral-3'>
                <Translate value='create_bulk_payment.card.description' />
              </p>
            </div>
            <div>
              <Status status=''></Status>
            </div>
          </div>
          <div className='flex mt-8 gap-4'>
            <div className='w-[150px]'>
              <CurrencySelect
                label='sell_currency'
                placeholder='currency'
                name='sellCurrency'
                error={errors['sellCurrency']}
                isRequired
                control={control}
                rules={{ required: true }}
                currencySource='sell'
              />
            </div>

            <DropdownInput
              className='lg:min-w-[250px]'
              placeholder='change_customer'
              options={options}
              label='select_existing_customer'
              isRequired
              name='selectedCustomer'
              filter
              control={control}
              error={errors['selectedCustomer']}
              rules={{ required: true }}
            />
            <div className='self-end'>
              <Button
                severity='contrast'
                className='h-[43px] w-60'
                onClick={() => {
                  setIsModalVisible(true);
                }}
                type='button'
              >
                <AddAlt />
                <Translate value='add_new_customer.header' className='ml-1' />
              </Button>
            </div>
          </div>
          <div className='flex justify-center mt-16'>
            {bulkId === '' && (
              <Button
                severity='info'
                type='submit'
                loading={isLoading}
                disabled={!sellCurrency || !watch('selectedCustomer')}
              >
                <Translate value='proceed_payments' />
              </Button>
            )}
          </div>
        </div>
      </form>
      {bulkId !== '' && sellCurrency && (
        <BulkPaymentTable sellCurrency={sellCurrency} orderId={bulkId} />
      )}
    </>
  );
};

export default AddBulkPayment;
