/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ApprovalConfirm, DropdownInput, TextInput } from '../../../../../components';
import { Translate } from '../../../../../i18n/translate';
import { selectLoading, sendToast } from '../../../../../store/slices/main/mainSlice';
import { AddOrEditOrView, Status } from '../../../../../types';
import { EMAIL_VALIDATION_REGEX, USER_STATUS_OPTIONS } from '../../../../Client/clientConstants';
import UserService from '../../../userService';

const UserDetails = ({
  onNext,
  mode,
  userObjet,
}: {
  onNext: (user: User, switchTab: boolean) => void;
  mode: AddOrEditOrView;
  userObjet?: User;
}) => {
  const dispatch = useDispatch();
  const [approved, setApproved] = useState(false);
  const isLoading = useSelector(selectLoading);
  const navigate = useNavigate();
  const {
    control,
    unregister,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<UserFormData>();

  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [apporveOrject, setApporveOrject] = useState<'approve' | 'reject'>();

  useEffect(() => {
    if (![AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)) {
      unregister('status');
    }
  }, [mode]);

  useEffect(() => {
    if (!userObjet) {
      return;
    }
    const formData: UserFormData = {
      emailId: userObjet.userIdentity?.email,
      firstName: userObjet.userIdentity?.firstName,
      lastName: userObjet.userIdentity?.lastName,
      phone: userObjet.userIdentity?.mobilePhone,
      status: userObjet.status,
    };
    reset(formData);
  }, [userObjet]);

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const validateEmailMutation = useMutation({
    mutationFn: (userData: User) => UserService.validateUser(userData ? [userData] : []),
    onSuccess: (response, _userObject) => {
      const { status, data } = response;
      if (status != 200) {
        return;
      }
      const { result } = data[0];
      if (!result.success) {
        const { errors } = result;
        showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
          ...errors[0],
          email: _userObject.userIdentity?.email,
        });
      } else {
        onNext(_userObject, true);
      }
    },
    onError: (error: any) => {
      showToast(
        'error',
        'email.validation.error',
        error.message || 'email.validation.error.detail',
      );
    },
  });

  const handleNext = (userformData: UserFormData, switchTab = true) => {
    if (!isValid) {
      return;
    }

    const submitData: User = {
      userIdentity: {
        firstName: userformData.firstName,
        lastName: userformData.lastName,
        mobilePhone: userformData.phone,
        email: userformData.emailId,
      },
    };

    if (userObjet) submitData['id'] = userObjet.id;
    if (mode !== AddOrEditOrView.Edit) {
      if (userObjet?.owningCustomerId) submitData['owningCustomerId'] = userObjet.owningCustomerId;
      validateEmailMutation.mutate(submitData);
      return;
    }
    onNext(submitData, switchTab);
  };

  useEffect(() => {
    return () => {
      handleNext(getValues(), false);
    };
  }, []);

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <>
      <ApprovalConfirm
        showApproveConfirm={showApproveConfirm}
        setShowApproveConfirm={(v: boolean, _approved: boolean) => {
          setApproved(_approved);
          setShowApproveConfirm(v);
          navigate(-1);
        }}
        apporveOrject={apporveOrject}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        approveFn={UserService.approveOrRject}
        id={userObjet?.id}
        type='user'
      />

      <>
        <form onSubmit={handleSubmit((data) => handleNext(data))}>
          <div className='!p-8 card'>
            <div className='flex justify-between flex-wrap'>
              <div>
                <div className='text-sm-bold text-secondary-text'>
                  <Translate
                    value={`${[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode) ? 'user.view.title' : 'add_user.user_details.title'}`}
                  />
                </div>
                <div className='text-neutral-3'>
                  <Translate value='add_user.user_details.description' />
                </div>
              </div>
              {[AddOrEditOrView.Approve].includes(mode) &&
                userObjet &&
                !approved &&
                userObjet.canBeApprovedByCurrentUser &&
                (userObjet.approvalStatus == Status.PENDING_APPROVAL ||
                  userObjet.approvalStatus == Status.PENDING_CREATION) && (
                  <div>
                    <Button
                      disabled={isLoading}
                      severity='secondary'
                      onClick={(event) => {
                        event.preventDefault();
                        setShowApproveConfirm(true);
                        setApporveOrject('reject');
                      }}
                    >
                      <Translate value='reject' className='ml-1' />
                    </Button>

                    <Button
                      disabled={isLoading}
                      severity='info'
                      className='ml-2'
                      onClick={(event) => {
                        event.preventDefault();
                        setShowApproveConfirm(true);
                        setApporveOrject('approve');
                      }}
                    >
                      <Translate value='approve' className='ml-1' />
                    </Button>
                  </div>
                )}
            </div>
            <div className='lg:mx-5 mt-12 mb-6 grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
              <TextInput
                formRegister={register('emailId', {
                  required: true,
                  pattern: {
                    value: EMAIL_VALIDATION_REGEX,
                    message: 'invalid_email_address',
                  },
                })}
                error={errors.emailId}
                label='email_id'
                placeholder='email_id'
                disabled={[
                  AddOrEditOrView.View,
                  AddOrEditOrView.Approve,
                  AddOrEditOrView.Edit,
                ].includes(mode)}
                isRequired
              />

              <TextInput
                formRegister={register('firstName', { required: true })}
                error={errors.firstName}
                label='first_name'
                placeholder='first_name'
                isRequired
                disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
              />

              <TextInput
                formRegister={register('lastName', { required: true })}
                error={errors.lastName}
                label='last_name'
                placeholder='last_name'
                isRequired
                disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
              />

              <TextInput
                formRegister={register('phone', { required: true })}
                error={errors.phone}
                id='phone'
                label='phone'
                placeholder='phone_placeholder'
                isRequired
                disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
              />

              {[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode) && (
                <DropdownInput
                  name='status'
                  label='status'
                  placeholder='status'
                  control={control}
                  options={USER_STATUS_OPTIONS}
                  error={errors.status}
                  disabled={true}
                />
              )}
            </div>
          </div>
          {![AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode) && (
            <div className='flex items-center justify-center mt-10 gap-5'>
              <Button type='button' severity='contrast' className='!px-7' onClick={handleCancel}>
                <Translate value='cancel' />
              </Button>
              <Button
                loading={validateEmailMutation.isPending}
                type='submit'
                severity='info'
                className='!px-7'
                onClick={() => {
                  if (!isValid) {
                    showToast(
                      'error',
                      'client.field.validation.error',
                      'user.field.validation.error.detail',
                    );
                    return;
                  }
                }}
              >
                <Translate value='user.create.next' />
              </Button>
            </div>
          )}
        </form>
      </>
    </>
  );
};

export default UserDetails;
