import { DocumentDownload, Filter } from '@carbon/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTablePageEvent, DataTableSortEvent, SortOrder } from 'primereact/datatable';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { PaginatorTemplate, TableSearch } from '../../../components';
import FilterModal from '../../../components/FilterModal';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { PageHeadContext } from '../../../providers/PageHead';
import {
  selectCurrentCustomer,
  selectFilters,
  sendToast,
  updateFilter,
} from '../../../store/slices/main/mainSlice';
import { enumToCamelCase, formatDateRelativeTime } from '../../../utils/helper';
import ReportsService from '../reportsService';

export default function Dashboard() {
  const filters = useSelector(selectFilters)['reports'];
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedCustomer = useSelector(selectCurrentCustomer);

  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState<SortOrder>();
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [globalSearch, setGlobalSearch] = useState('');
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const [filterValues, setFilterValues] = useState<any>();

  const onSort = (event: DataTableSortEvent) => {
    if (event.sortField && event.sortOrder) {
      setSortField(event.sortField);
      setSortOrder(event.sortOrder);
    }
  };

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const handleGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    setGlobalSearch(e.target.value);
  };

  const handleApplyFilters = (formdata: { [key: string]: string[] | string }) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    //Update filter
    dispatch(updateFilter({ filterKey: 'reports', value: formdata }));
  };

  useEffect(() => {
    setPageHeadData({
      title: 'reports',
      description: 'reports.description',
      className: 'pb-4 pr-4',
      button: (
        <>
          {
            <Button
              severity='info'
              className='h-[45px]'
              onClick={() => {
                navigate('/reports/generate');
              }}
            >
              <Translate value='generateReports' />
            </Button>
          }
        </>
      ),
    });
  }, [setPageHeadData]);

  const reportNames = useQuery<ReportType[], Error>({
    queryKey: ['reports'],
    queryFn: async () => {
      const data: ReportType[] = await ReportsService.getReportNames();
      return data;
    },
  });

  useEffect(() => {
    const filterMetaData = generateFilterMetaData();
    setFilterValues(filterMetaData);
  }, [reportNames.data]);

  const generateFilterMetaData = () => {
    const reportName: any[] =
      reportNames?.data?.map((report) => {
        return {
          label: enumToCamelCase(report.reportName),
          value: report.reportName,
        };
      }) ?? [];

    return [
      {
        header: 'By Report',
        fields: [
          {
            name: 'report',
            type: 'report',
            placeholder: 'Select Report',
            value: filters?.report || [],
            options: reportName,
          },
        ],
      },
    ];
  };

  const reportsQuery = useQuery({
    queryKey: [
      'reports',
      selectedCustomer?.id,
      sortField,
      sortOrder,
      pageParams.rows,
      pageParams.page,
      filters?.report,
      globalSearch,
    ],
    queryFn: async () => {
      const response = await ReportsService.getReports(
        sortField,
        sortOrder,
        pageParams.page,
        pageParams.rows,
        (filters?.report || []) as Array<string>,
        globalSearch,
      );
      return response;
    },
  });

  const downloadReportMutation = useMutation({
    mutationFn: async ({ reportId, fileName }: { reportId: string; fileName: string }) =>
      await ReportsService.downloadReport(reportId, fileName),
    onSuccess: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'report_downloaded_successfully',
        }),
      );
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'failed_to_download_report',
          detail: error.message,
        }),
      );
    },
  });

  if (reportsQuery.isLoading) {
    return (
      <div className='text-center text-neutral-3'>
        <Translate value='loading' />
      </div>
    );
  }

  if (reportsQuery.isError) {
    return (
      <div className='text-center text-neutral-3'>
        <Translate value='failed_to_fetch_reports' />
      </div>
    );
  }

  const header = (
    <div className='flex justify-between'>
      <div>
        <div className='text-neutral-1 text-lg-semibold'>
          <Translate value='total' />
          <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
            {reportsQuery.data?.totalElements ?? 0}
          </span>
        </div>
        <div className='mt-1'>
          <Translate value='reports_table_description' className='text-neutral-3 text-sm-regular' />
        </div>
      </div>

      <div className='flex justify-around'>
        <div>
          <TableSearch globalFilterValue={globalSearch} onGlobalFilterChange={handleGlobalSearch} />
        </div>

        <>
          <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
            <Button
              severity='secondary'
              className='p-button-text h-12'
              onClick={() => {
                setModalVisible(true);
              }}
            >
              <Filter size={20} />
              <Translate value='filterLabel' className='ml-1 hidden lg:block' />
            </Button>
            {!!activeFilterCount && (
              <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
            )}
          </div>
        </>
      </div>
    </div>
  );

  return (
    <>
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='reportFilter'
        onApply={handleApplyFilters}
      />
      <DataTable
        paginator={true}
        lazy
        paginatorTemplate={PaginatorTemplate}
        first={pageParams.first}
        rows={pageParams.rows}
        totalRecords={reportsQuery.data?.totalElements ?? 0}
        loading={reportsQuery.isFetching}
        onPage={onPage}
        header={header}
        value={reportsQuery.data?.content}
        size={'large'}
        dataKey='reportId'
        onSort={onSort}
        sortField={sortField}
        sortOrder={sortOrder}
        className='mt-8'
      >
        <Column field='reportId' header={translateWithValues('report_id')} sortable />
        <Column field='reportName' header={translateWithValues('report_name')} />
        <Column
          field='creationDate'
          header={translateWithValues('date')}
          sortable
          body={(item: ReportType) => {
            return formatDateRelativeTime(item.creationDate);
          }}
        />
        <Column field='createdBy' header={translateWithValues('createdBy')} />
        <Column
          field='reportType'
          header={translateWithValues('reportType')}
          body={(item) => item.reportTypes.join(', ')}
        />
        <Column
          header={translateWithValues('attachment')}
          body={(item: ReportType) => (
            <DocumentDownload
              className='cursor-pointer'
              onClick={() =>
                void downloadReportMutation.mutate({
                  reportId: item.reportId,
                  fileName: item.fileName,
                })
              }
            />
          )}
        />
      </DataTable>
    </>
  );
}
