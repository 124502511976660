import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { EditorTextChangeEvent } from 'primereact/editor';
import { useState } from 'react';
import { DropdownInput, RichEditor } from '../../../../components';
import { useAppSelector } from '../../../../hooks/store';
import useRole from '../../../../hooks/useRoles';
import { Translate } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { Disclaimers, DisclosureType } from '../../../../types';
import configurationRole from '../../configurationRole';
import { DISCLOSURE_TYPE_OPTIONS } from '../../configurationsConstants';
import { fetchDisclaimer, updateDisclaimer } from '../../configurationService';

const Dashboard = () => {
  const [disclaimerData, setDisclaimerData] = useState<DisclaimerData>({});
  const [disclosureType, setDisclosureType] = useState<Disclosure>(
    DisclosureType.PREPAYMENT_DISCLOSURE,
  );
  const { hasRole } = useRole();
  const currentCustoemr = useAppSelector(selectCurrentCustomer);
  const dispatch = useAppDispatch();

  const { isLoading } = useQuery({
    queryKey: ['disclaimerData', currentCustoemr?.id],
    queryFn: async (): Promise<void> => {
      if (!currentCustoemr?.id) {
        throw new Error('Customer ID is undefined');
      }
      const data = await fetchDisclaimer(currentCustoemr.id);
      const formData: DisclaimerData = {};
      data?.forEach((disclaimerEntity) => {
        formData[disclaimerEntity.type] = disclaimerEntity.content;
      });
      setDisclaimerData(formData);
    },
  });

  const saveDisclaimers = useMutation({
    mutationFn: async () => {
      const payload = Object.keys(disclaimerData).map((type) => ({
        type,
        content: disclaimerData[type as keyof DisclaimerData],
      }));
      return await updateDisclaimer(payload, currentCustoemr?.id as number);
    },
    onSuccess: (response) => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: 'disclousure.sucess',
        }),
      );
      console.log('Disclaimers saved successfully:', response);
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: 'disclousure.error',
        }),
      );
    },
  });

  const handleChange = (e: EditorTextChangeEvent, name: string) => {
    setDisclaimerData({ ...disclaimerData, [name]: e.htmlValue });
  };

  return !isLoading ? (
    <div className='mx-8 px-6 py-2 bg-white rounded-c8 border border-neutral-surface-dark'>
      <div className='flex justify-between py-4 border-b border-neutral-border-2'>
        <div>
          <div className='text-lg-semibold'>
            <Translate value='disclaimer.title' />
          </div>
          <div className='text-neutral-3 mt-1'>
            <Translate value='disclaimer.subtitle' />
          </div>
        </div>
        {hasRole(
          `${configurationRole.editConfiguration}&${configurationRole.viewConfiguration}`,
        ) && (
          <div>
            <Button
              severity='info'
              loading={saveDisclaimers.isPending}
              onClick={() => {
                saveDisclaimers.mutate();
              }}
            >
              <Translate value='save' />
            </Button>
          </div>
        )}
      </div>
      <div className='py-6 border-b border-neutral-border-2'>
        <div className='flex justify-between'>
          <div className='text-neutral-1 text-sm-medium'>
            <Translate value='licensing' />
          </div>
          <div className='w-3/5'>
            <RichEditor
              value={disclaimerData[Disclaimers.LICENSING]}
              onChange={handleChange}
              name={Disclaimers.LICENSING}
            />
          </div>
        </div>
      </div>
      <div className='py-6 border-b border-neutral-border-2'>
        <div className='flex justify-between'>
          <div className='text-neutral-1 text-sm-medium'>
            <Translate value='disclaimer.title' />
          </div>
          <div className='w-3/5'>
            <RichEditor
              value={disclaimerData[Disclaimers.DISCLAIMER]}
              onChange={handleChange}
              name={Disclaimers.DISCLAIMER}
            />
          </div>
        </div>
      </div>
      <div className='py-6 border-b border-neutral-border-2'>
        <div className='flex justify-between'>
          <div className='text-neutral-1 text-sm-medium w-2/5'>
            <Translate value='disclosure' />
            <DropdownInput
              className='mt-5 w-4/5'
              label='disclosure_type'
              placeholder='disclosure_type'
              value={disclosureType}
              onChange={(e) => {
                setDisclosureType(e.target.value as Disclosure);
              }}
              options={DISCLOSURE_TYPE_OPTIONS}
            />
          </div>
          <div className='w-3/5'>
            <RichEditor
              value={disclaimerData[disclosureType]}
              onChange={handleChange}
              name={disclosureType}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Dashboard;
