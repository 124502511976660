import {
  CheckmarkOutline,
  Edit,
  Error,
  Filter,
  View,
  WorkflowAutomation,
} from '@carbon/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  DataTable,
  type DataTablePageEvent,
  type DataTableSortEvent,
  type SortOrder,
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';
import {
  CurrencyFlag,
  InheritedMenu,
  MoreActionMobile,
  PageHead,
  PaginatorTemplate,
  Status as StatusComponent,
  TableSearch,
  WarningIcon,
} from '../../../components';
import CountryFlag from '../../../components/CountryFlag';
import FilterModal from '../../../components/FilterModal';
import useRole from '../../../hooks/useRoles';
import { Translate, TranslateWithValues, translateWithValues } from '../../../i18n/translate';
import { MoreAction } from '../../../icons';
import NextButton from '../../../icons/NextButton';
import PrevButton from '../../../icons/PrevButton';
import { PageHeadContext } from '../../../providers/PageHead';
import { selectFilters, sendToast, updateFilter } from '../../../store/slices/main/mainSlice';
import { Status } from '../../../types';
import { getCountryISO2 } from '../../../utils/isoncCodeMapping';
import { BENEFICIARY_STATUS_OPTIONS } from '../beneficiariesConstants';
import beneficiaryRoles from '../beneficiaryRoles';
import BeneficiaryService from '../beneficiaryService';

export default function ManageBeneficiaries() {
  const dispatch = useDispatch();

  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [debouncedSearch] = useDebounce(globalSearch, 300);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [sortField, setSortField] = useState<string>('id');
  const [sortOrder, setSortOrder] = useState<SortOrder>(-1);
  const filters = useSelector(selectFilters)['beneficiaries_filter'];
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const { hasRole } = useRole();

  const [filterValues, setFilterValues] = useState<any>([
    {
      header: 'By Status',
      fields: [
        {
          name: 'status',
          placeholder: 'Select Status',
          value: filters?.status || [],
          options: BENEFICIARY_STATUS_OPTIONS,
        },
      ],
    },
    {
      header: 'By Buy Currency',
      fields: [
        {
          name: 'currency',
          type: 'currency',
          placeholder: 'Select Currency',
          value: filters?.currency || [],
        },
      ],
    },
    {
      header: 'By Country',
      fields: [
        {
          name: 'country',
          type: 'country',
          placeholder: 'Select Country',
          value: filters?.country || [],
        },
      ],
    },
  ]);
  const [deactivateModalId, setDeactivateModalId] = useState<number>();

  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  const navigate = useNavigate();

  const menu = useRef<Menu>(null);

  const onSort = (event: DataTableSortEvent) => {
    if (event.sortField && event.sortOrder) {
      setSortField(event.sortField);
      setSortOrder(event.sortOrder);
    }
  };

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const handleApplyFilters = (formdata: { [key: string]: string[] | string }) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    dispatch(updateFilter({ filterKey: 'beneficiaries_filter', value: formdata }));
  };

  const beneficiaryListQuery = useQuery({
    queryKey: ['beneficiary_list', pageParams, sortField, sortOrder, filters, debouncedSearch],
    queryFn: async () => {
      const { page, rows } = pageParams;
      const copiedFilters = { ...filters };
      if (copiedFilters.country) {
        copiedFilters.country = (filters.country as string[]).map((c: string) => getCountryISO2(c));
      }
      const payload = {
        ...copiedFilters,
      };
      if (debouncedSearch) {
        payload.searchText = debouncedSearch;
      }
      const data = await BeneficiaryService.getAllBeneficiaries(
        payload,
        page,
        rows,
        sortField,
        sortOrder,
      );
      setTotalRecords(data?.totalElements ?? 0);
      return data?.content;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setPageHeadData({
      title: '',
      description: '',
    });
  }, [setPageHeadData]);

  const queryClient = useQueryClient();

  const changeStatusMutation = useMutation({
    mutationFn: async (beneficiaryId: number) => {
      const currentStatus = beneficiaryListQuery.data!.find(
        (beneficiary) => beneficiary.id === beneficiaryId,
      )?.status;
      const response = await BeneficiaryService.updateBeneficiaryStatus(
        beneficiaryId,
        currentStatus === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE,
      );
      return response;
    },
    onSuccess: () => {
      setDeactivateModalId(undefined);
      queryClient.invalidateQueries({ queryKey: ['beneficiary_list'] });
    },
    onError: (error) => {
      dispatch(
        sendToast({
          summary: 'error',
          severity: 'error',
          detail: error.message,
        }),
      );
    },
  });

  if (beneficiaryListQuery.isError) {
    return <div className='text-center text-neutral-3'>Error fetching beneficiary list</div>;
  }

  const actionBodyTemplate = (beneficiary: BeneficiaryTableItemType) => {
    const actions: MenuItem[] = [
      {
        roles: beneficiaryRoles.ViewBeneficiary,
        label: 'viewDetails',
        icon: <View />,
        onClick: () => {
          navigate(`/beneficiaries/${beneficiary.id}`);
        },
      },
      {
        roles: beneficiaryRoles.UpdateBeneficiary,
        label: 'editDetails',
        disabled:
          beneficiary.status === Status.PENDING || beneficiary.status === Status.REJECTED_CREATION,
        icon: <Edit />,
        onClick: () => {
          navigate(`/beneficiaries/${beneficiary.id}/edit`);
        },
      },
    ];
    if (beneficiary.status === Status.ACTIVE) {
      actions.push({
        roles: beneficiaryRoles.UpdateBeneficiary,
        label: 'deactivate',
        icon: <Error />,
        disabled: changeStatusMutation.isPending,
        onClick: () => setDeactivateModalId(beneficiary.id),
      });
    } else if (beneficiary.status === Status.INACTIVE) {
      actions.push({
        roles: beneficiaryRoles.UpdateBeneficiary,
        label: 'activate',
        icon: <WorkflowAutomation />,
        disabled: changeStatusMutation.isPending,
        onClick: () => changeStatusMutation.mutate(beneficiary.id),
      });
    }

    if ([Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(beneficiary.approvalStatus)) {
      if (beneficiary.canBeApprovedByCurrentUser) {
        actions.unshift({
          roles: beneficiaryRoles.ApproveBeneficiary,
          label: 'bene.approve',
          icon: <CheckmarkOutline />,
          onClick: () => {
            navigate(`/beneficiaries/${beneficiary.id}/approve`);
          },
        });
      }
    }

    return (
      <>
        <div>
          <div
            className='hidden sm:block'
            onClick={(event) => {
              menu.current?.toggle(event);
              setCurrentActions(actions);
            }}
          >
            <MoreAction />
          </div>
          <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
        </div>

        <div>
          <MoreActionMobile actions={actions} />
        </div>
      </>
    );
  };

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div className='text-neutral-1 text-lg-semibold'>
        <Translate value='total_beneficiary_requests' />
        <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
          {totalRecords}
        </span>
      </div>
      <div className='flex justify-around gap-2 items-center'>
        <TableSearch
          globalFilterValue={globalSearch}
          onGlobalFilterChange={(e) => {
            setGlobalSearch(e.target.value);
          }}
        />
        <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
          <Button
            severity='secondary'
            className='p-button-text h-12'
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <Filter size={20} />
            <Translate value='filterLabel' className='ml-1 hidden md:hidden lg:block' />{' '}
          </Button>
          {!!activeFilterCount && (
            <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className='px-[20px] lg:px-[49px] py-[16px] bg-white border-b border-neutral-border'>
        <PageHead
          title='beneficiaries.title'
          description='beneficiaries.description'
          button={
            hasRole(beneficiaryRoles.CreateBeneficiary) && (
              <Button
                severity='info'
                className='h-[40px]'
                onClick={() => {
                  navigate('/beneficiaries/create');
                }}
              >
                <Translate value='create_beneficiary' />
              </Button>
            )
          }
        />
      </div>
      <div className='dashboard-padding'>
        <DataTable
          paginator={true}
          lazy
          paginatorTemplate={PaginatorTemplate}
          first={pageParams.first}
          rows={pageParams.rows}
          totalRecords={totalRecords}
          loading={beneficiaryListQuery.isFetching}
          onPage={onPage}
          header={header}
          value={beneficiaryListQuery.data?.map((beneficiary) => ({
            ...beneficiary,
            accountNumber: beneficiary.accountNumber || beneficiary.iban,
          }))}
          size={'large'}
          dataKey='reportId'
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
          className='mt-8 hidden sm:block'
        >
          <Column sortable field='id' header={<Translate value='beneficiary_id' />} />
          <Column sortable field='nickName' header={<Translate value='beneficiary_nick_name' />} />
          <Column sortable field='accountName' header={<Translate value='beneficiary_name' />} />
          <Column field='accountNumber' header={<Translate value='beneficiary_account_number' />} />
          <Column
            sortable
            field='buyCurrency'
            header={<Translate value='buy_currency' />}
            body={(beneficiary: BeneficiaryTableItemType) => (
              <div className='text-sm-medium text-neutral-2'>
                <CurrencyFlag currencyCode={beneficiary.buyCurrency} />
              </div>
            )}
          />
          <Column
            sortable
            field='country'
            header={<Translate value='country' />}
            body={(beneficiary: BeneficiaryTableItemType) => (
              <div className='text-sm-medium text-neutral-2'>
                <CountryFlag countryCode={beneficiary.beneAddress.country} />
              </div>
            )}
          />

          <Column
            sortable
            field='status'
            header={<Translate value='status' />}
            body={(beneficiary: BeneficiaryTableItemType) => (
              <StatusComponent
                status={
                  beneficiary.approvalStatus == Status.PENDING_APPROVAL ||
                  beneficiary.approvalStatus == Status.PENDING_CREATION
                    ? beneficiary.approvalStatus
                    : beneficiary.status
                }
              />
            )}
          ></Column>
          <Column header='Actions' body={actionBodyTemplate}></Column>
        </DataTable>

        <div className='sm:hidden mt-4'>
          <div className='relative'>
            <TableSearch
              globalFilterValue={globalSearch}
              onGlobalFilterChange={(e) => {
                setGlobalSearch(e.target.value);
              }}
            />
            <div className='absolute sm:relative right-1 top-2 sm:right-auto sm:top-auto'>
              <Button
                severity='secondary'
                className='p-button-text h-8 sm:h-12 border-none sm:border-unset !px-1 sm:px-4'
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                <Filter size={20} />
                <Translate value='filterLabel' className='ml-1 hidden lg:block' />
              </Button>
              {!!activeFilterCount && (
                <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
              )}
            </div>
          </div>
          {!beneficiaryListQuery.isFetching ? (
            beneficiaryListQuery.data?.length ? (
              <>
                {beneficiaryListQuery.data?.map((beneficiary) => (
                  <div
                    key={beneficiary.id}
                    className='border-[1px] border-neutral-surface-dark rounded-[12px] p-4 mt-4 mb-4 shadow-c'
                  >
                    <div className='flex justify-between items-center'>
                      <div className='text-neutral-2'>
                        {translateWithValues('beneficiary_id')}: {beneficiary.id}
                      </div>
                      <div>
                        <StatusComponent status={beneficiary.status} />
                      </div>
                    </div>
                    <div className='border-b-[1px] border-neutral-border mt-4 mb-4'></div>
                    <div className='flex justify-between'>
                      <div className='text-neutral-2 text-lg-semibold'>
                        {beneficiary.accountName}
                      </div>
                      <div className='mt-4'>{actionBodyTemplate(beneficiary)}</div>
                    </div>
                    <div className='text-neutral-2'>{`${beneficiary.nickName} | ${beneficiary.accountNumber}`}</div>
                    <div className='mt-4'>
                      <div className='text-sm-medium text-neutral-2 flex justify-between'>
                        <div>
                          <Translate value='currency' />
                          <span className='text-error-1 pl-1'>*</span>
                        </div>
                        <CurrencyFlag currencyCode={beneficiary.buyCurrency} />
                      </div>
                      <div className='text-sm-medium text-neutral-2 flex justify-between'>
                        <div>
                          <Translate value='beneficiary_country' />
                          <span className='text-error-1 pl-1'>*</span>
                        </div>
                        <CountryFlag countryCode={beneficiary.beneAddress.country} />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <Translate value='no_available_options' className='pb-4 block' />
            )
          ) : (
            <div className='absolute z-10 inset-0 bg-white/50 grid place-content-center'>
              <ProgressSpinner className='w-10 h-10' />
            </div>
          )}

          <div className='flex justify-between items-center mt-4'>
            <Button
              disabled={pageParams.page === 0}
              className='cursor-pointer'
              onClick={() =>
                onPage({
                  first: pageParams.first - pageParams.rows,
                  rows: pageParams.rows,
                  page: pageParams.page - 1,
                })
              }
            >
              <PrevButton />
            </Button>
            <div>
              {translateWithValues('pageOf', {
                current: pageParams.page + 1,
                total: Math.ceil(totalRecords / pageParams.rows),
              })}
            </div>
            <Button
              disabled={pageParams.page >= Math.ceil(totalRecords / pageParams.rows) - 1}
              className='cursor-pointer'
              onClick={() =>
                onPage({
                  first: pageParams.first + pageParams.rows,
                  rows: pageParams.rows,
                  page: pageParams.page + 1,
                })
              }
            >
              <NextButton />
            </Button>
          </div>
        </div>
      </div>
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='paymentFilter'
        onApply={handleApplyFilters}
      />
      <Dialog
        draggable={false}
        visible={!!deactivateModalId}
        onHide={() => setDeactivateModalId(undefined)}
        className='rounded-xl confirm-dialog w-full max-w-[400px]'
        header={
          <div className='grid gap-4 px-5 py-5'>
            <div className='w-fit'>
              <WarningIcon />
            </div>
            <div className='grid gap-2'>
              <h2 className='text-midnightBlue text-sm-bold'>
                <TranslateWithValues value='beneficiary_deactivate.header' />
              </h2>
              <p className='text-sm-medium text-unselected'>
                <Translate value='beneficiary_deactivate.description' />
              </p>
            </div>
          </div>
        }
      >
        <div className='grid gap-4'>
          <Button
            severity='info'
            className='flex gap-2 justify-center'
            onClick={() => changeStatusMutation.mutate(deactivateModalId!)}
            loading={changeStatusMutation.isPending}
          >
            <Translate value='confirm_deactivation' />
          </Button>
          <Button
            severity='secondary'
            className='grid place-items-center'
            onClick={() => setDeactivateModalId(undefined)}
          >
            <Translate value='cancel_deactivation' />
          </Button>
        </div>
      </Dialog>
    </>
  );
}
