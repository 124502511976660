import { Dialog } from 'primereact/dialog';
import { Translate } from '../../../../i18n/translate';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import BeneficiaryService from '../../../Beneficiaries/beneficiaryService';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectCountries } from '../../../../store/slices/refdata/refdataSlice';
import { getCountryISO3 } from '../../../../utils/isoncCodeMapping';
import BeneficiaryForm from '../../../CommonComponents/BeneficiaryForm';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  beneficiaryId: number;
};

export default function ViewBeneficiaryModal({ visible, setVisible, beneficiaryId }: Props) {
  return (
    <Dialog
      className='w-full md:w-3/5 max-h-[100%] m-0 !rounded-none full-screen-dialog'
      header={
        <div className='text-lg font-semibold text-neutral-1'>
          <Translate value='beneficiary.title' />
        </div>
      }
      visible={visible}
      style={{
        height: '100vh',
        position: 'fixed',
        top: 0,
        right: 0,
      }}
      onHide={() => setVisible(false)}
      draggable={false}
    >
      <ViewBeneficiary beneficiaryId={beneficiaryId} />
    </Dialog>
  );
}

function ViewBeneficiary({ beneficiaryId }: { beneficiaryId: number }) {
  const [routingCodeDetails, setRoutingCodeDetails] = useState<{
    intermediary: any;
    credit: any;
  }>();
  const [iban, setIban] = useState('');
  const [swiftBicCode, setSwiftBicCode] = useState('');

  const selectedCountries = useSelector(selectCountries);

  const beneficiaryDetailsQuery = useQuery({
    queryKey: ['beneficiary_details', beneficiaryId],
    queryFn: async () => {
      const response = await BeneficiaryService.getBeneficiaryTemplate(beneficiaryId);
      return response;
    },
    refetchOnWindowFocus: false,
  });

  const form = useForm<any>({
    values: {
      ...beneficiaryDetailsQuery.data?.fieldMap,
      country: selectedCountries.find(
        (item) => item.code === getCountryISO3(beneficiaryDetailsQuery.data?.fieldMap.country),
      ),
      BeneficiaryAddressCountry: selectedCountries.find(
        (item) =>
          item.code ===
          getCountryISO3(beneficiaryDetailsQuery.data?.fieldMap.BeneficiaryAddressCountry),
      ),
    },
    disabled: true,
  });

  if (beneficiaryDetailsQuery.isLoading) {
    return <div className='grid place-items-center'>Loading...</div>;
  }

  if (beneficiaryDetailsQuery.isError) {
    return <div className='grid place-items-center'>Failed to load beneficiary</div>;
  }

  if (!beneficiaryDetailsQuery.data) {
    return <div className='grid place-items-center'>Beneficiary not found</div>;
  }

  if (!form.watch('country')) {
    return <div className='grid place-items-center'>No country selected</div>;
  }

  return (
    <fieldset disabled className='pb-8'>
      <BeneficiaryForm
        buyCurrency={form.watch('buyCurrency')}
        beneficiaryCountry={form.watch('country')}
        form={form}
        isFormDisabled
        routingCodeDetails={routingCodeDetails}
        setRoutingCodeDetails={setRoutingCodeDetails}
        swiftBicCode={swiftBicCode}
        setSwiftBicCode={setSwiftBicCode}
        iban={iban}
        setIban={setIban}
      />
    </fieldset>
  );
}
