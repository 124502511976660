import { useState } from 'react';
import { useAppDispatch } from '../../store';
import { sendToast } from '../../store/slices/main/mainSlice';
import Confirm from '../Confirm';

const ApprovalConfirm = ({
  showApproveConfirm,
  setShowApproveConfirm,
  apporveOrject,
  approveFn,
  id,
  type,
}: {
  apporveOrject: 'approve' | 'reject' | undefined;
  showApproveConfirm: boolean;
  setShowApproveConfirm: (show: boolean, approved: boolean, data?: Client) => void;
  approveFn: (this: void, id: number, requestBody: any) => Promise<any>;
  id?: number | null;
  type: 'customer' | 'user' | 'approval' | 'payment' | 'bene';
}) => {
  const [userComment, setUserComment] = useState('');
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const approve = async () => {
    if (!id) {
      return;
    }
    const requestBody = {
      action: apporveOrject === 'approve' ? 'APPROVED' : 'REJECTED',
      userComment,
    };
    try {
      setIsLoading(true);
      const { data, status } = await approveFn(id, requestBody);
      if (status !== 200 || !data.success) {
        showToast(
          'error',
          'error',
          data?.result?.errors && data.result.errors.length > 0
            ? data.result.errors[0].errorTextCode
            : apporveOrject === 'approve'
              ? `approve.${type}.error`
              : `reject.${type}.error`,
        );
        setShowApproveConfirm(false, false);
      } else {
        showToast(
          'success',
          'success',
          apporveOrject === 'approve' ? `approve.${type}.success` : `reject.${type}.success`,
        );
        setShowApproveConfirm(false, true, data?.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Confirm
      onCancel={() => setShowApproveConfirm(false, false)}
      title={apporveOrject === 'approve' ? 'approve.comment' : 'reject.comment'}
      message={apporveOrject === 'approve' ? 'approve.comment.message' : 'reject.comment.message'}
      onConfirm={approve}
      confirmLabel={apporveOrject == 'approve' ? 'approve' : 'reject'}
      visible={showApproveConfirm}
      onCommentChange={(comment) => {
        setUserComment(comment);
      }}
      loading={isLoading}
    />
  );
};

export default ApprovalConfirm;
