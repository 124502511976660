import { Edit } from '@carbon/icons-react';
import { useState } from 'react';
import { translateWithValues } from '../../i18n/translate';
import { QuoteStatus } from '../../types';
import TextInput, { TextInputProps } from '../TextInput';
import FormatAmount from '../formatAmount';

function QuoteAmountInput(props: TextInputProps & { isEditMode: boolean; precision?: number }) {
  const [startEditing, setStartEditing] = useState(props.isEditMode);

  return (
    <div className='group w-full flex flex-col gap-1'>
      {props.isEditable ? (
        <>
          <label className='whitespace-nowrap overflow-hidden text-ellipsis inline-block'>
            {translateWithValues(props.label)}
            {props.isRequired ? <span className='text-error-1'>*</span> : ''}
          </label>

          <div className='styled-text-display flex gap-4 mt-[10px] items-center'>
            <span className='text-md-semibold'>
              <FormatAmount
                number={parseFloat(props.value || '0')}
                maximumFractionDigits={props.precision}
              />
            </span>
            <Edit
              className='cursor-pointer'
              onClick={() => props.setQuoteStatus(QuoteStatus.AddQuote)}
            />
          </div>
        </>
      ) : (
        <TextInput
          searchIcon={
            startEditing || props.disabled ? null : (
              <Edit className='cursor-pointer' onClick={() => setStartEditing(true)} />
            )
          }
          disabled={!startEditing || props.disabled}
          {...props}
        >
          {props.value ? (
            <span className='inset-0 absolute pointer-events-none overflow-hidden'>
              <span className='h-full w-full pl-3 text-neutral-3 rounded-xl border border-neutral-5 group-focus-within:hidden bg-white flex items-center'>
                {Intl.NumberFormat(navigator.language || 'en-US', {
                  style: 'decimal',
                  minimumFractionDigits: props.precision,
                  maximumFractionDigits: props.precision,
                  useGrouping: true,
                }).format(props.value as any)}
              </span>
            </span>
          ) : null}
        </TextInput>
      )}
    </div>
  );
}

export default QuoteAmountInput;
