import { Filter, View } from '@carbon/icons-react';
import { useQuery } from '@tanstack/react-query';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTablePageEvent, DataTableSortEvent, SortOrder } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CurrencyFlag,
  FormatAmount,
  InheritedMenu,
  MoreActionMobile,
  PaginatorTemplate,
  Status,
  TableSearch,
} from '../../../../components';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { MoreAction } from '../../../../icons';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import paymentRole from '../../paymentRole';
import PaymentService from '../../paymentService';

import { useDebounce } from 'use-debounce';
import CountryFlag from '../../../../components/CountryFlag';
import FilterModal from '../../../../components/FilterModal';
import {
  selectCurrentCustomer,
  selectFilters,
  updateFilter,
} from '../../../../store/slices/main/mainSlice';
import { CustomerType } from '../../../../types';
interface BulkPaymentsTableProps {
  filterCategory?: 'PENDING' | 'IN_PROCESSING' | 'PAYMENT_COMPLETED';
  totalRecords: number;
  setTotalRecords: (totalRecords: number) => void;
}

const BulkPaymentsTable = ({
  filterCategory,
  totalRecords,
  setTotalRecords,
}: BulkPaymentsTableProps) => {
  const [globalSearch, setGlobalSearch] = useState('');
  const [debouncedSearch] = useDebounce(globalSearch, 300);
  const [selectedRates, setSelectedRates] = useState<Order[] | null>(null);
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [actionItems, setActionItems] = useState<MenuItem[]>([]);
  const menu = useRef<Menu>(null);
  const currencies = useSelector(selectCustomerCurrencies);
  const filters = useSelector(selectFilters)['bulk_payments_filter'];

  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<SortOrder>();
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useDispatch();

  const [filterValues, setFilterValues] = useState<any>([
    {
      header: 'By Status',
      fields: [
        {
          name: 'statuses',
          placeholder: 'Select Status',
          value: filters?.statuses || [],
          type: 'multiple',
          // options: PENDING_STATUS_OPTIONS,
        },
      ],
    },
  ]);

  const rateRequestQuery = useQuery({
    queryKey: [
      'rate_requests_query',
      pageParams,
      filters,
      filterCategory,
      selectedCustomer?.id,
      debouncedSearch,
    ],
    queryFn: async () => {
      const { page, rows } = pageParams;
      const payload: {
        statuses: (typeof filters)['statuses'];
        filterCategory?: typeof filterCategory;
        searchText?: string;
        bulkOrder?: boolean;
      } = {
        statuses: filters?.statuses || [],
        filterCategory: filters?.categories as typeof filterCategory,
      };
      if (debouncedSearch) {
        payload.searchText = debouncedSearch;
      }
      if (filterCategory) {
        payload.filterCategory = filterCategory;
      }
      payload.bulkOrder = true;
      const data = await PaymentService.getRateRequests(page, rows, payload);
      setTotalRecords(data?.totalElements ?? 0);
      return data?.content;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const actionBodyTemplate = () => {
    const actions: MenuItem[] = [
      {
        roles: paymentRole.viewPayments,
        label: 'viewDetails',
        icon: <View />,
        // onClick: () => {},
      },
    ];

    return (
      <>
        <div>
          <div
            className='hidden sm:block'
            onClick={(event) => {
              setActionItems(actions);
              menu.current?.toggle(event);
            }}
          >
            <MoreAction />
          </div>
          <InheritedMenu items={actionItems} ref={menu} popupAlign='left' />
        </div>

        <div
          onClick={() => {
            setActionItems(actions);
          }}
        >
          <MoreActionMobile actions={actions} />
        </div>
      </>
    );
  };

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div className='text-neutral-1 text-lg-semibold'>
        <Translate value='payment_requests' />
        <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
          {totalRecords}
        </span>
      </div>
      <div className='flex justify-around gap-2 items-center'>
        <TableSearch
          globalFilterValue={globalSearch}
          onGlobalFilterChange={(e) => {
            setGlobalSearch(e.target.value);
          }}
        />
        <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
          <Button
            severity='secondary'
            className='p-button-text h-12'
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <Filter size={20} />
            <Translate value='filterLabel' className='ml-1 hidden lg:block' />
          </Button>
          {!!activeFilterCount && (
            <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
          )}
        </div>
      </div>
    </div>
  );

  const handleApplyFilters = (formdata: { [key: string]: string[] | string }) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    dispatch(
      updateFilter({
        filterKey: 'bulk_payments_filter',
        value: formdata,
      }),
    );
  };

  const onSort = (event: DataTableSortEvent) => {
    if (event.sortField && event.sortOrder) {
      setSortField(event.sortField);
      setSortOrder(event.sortOrder);
    }
  };

  return (
    <>
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='paymentFilter'
        onApply={handleApplyFilters}
      />
      <DataTable
        className='mt-5 hidden sm:block'
        header={header}
        selectAll={true}
        selectionMode={'checkbox'}
        selection={selectedRates!}
        onSelectionChange={(e: { value: Order[] }) => setSelectedRates(e.value)}
        dataKey='id'
        first={pageParams.first}
        rows={pageParams.rows}
        loading={rateRequestQuery.isFetching}
        onPage={onPage}
        onSort={onSort}
        sortField={sortField}
        sortOrder={sortOrder}
        value={rateRequestQuery.data}
        paginator={true}
        lazy
        paginatorTemplate={PaginatorTemplate}
        totalRecords={totalRecords}
      >
        {/* <Column selectionMode='multiple' headerStyle={{ width: '1rem' }}></Column> */}
        <Column field='id' sortable header={translateWithValues('orderId')} />
        <Column
          field='paymentId'
          header={translateWithValues('payment_id')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              {rateRequest.payments?.[0]?.quote?.fxId}
            </div>
          )}
        />
        {selectedCustomer &&
          (selectedCustomer.customerType == CustomerType.SERVICE_PROVIDER ||
            selectedCustomer.customerType == CustomerType.INSTRUCTING_INSTITUTION) && (
            <Column
              field='owningCustomer'
              header={translateWithValues('owning_customer')}
              body={(rateRequest: Order) => (
                <div className='text-sm-medium text-neutral-2'>
                  {rateRequest.owningCustomerShortName ?? rateRequest.owningCustomerShortName}
                </div>
              )}
            />
          )}
        <Column
          field='orderingCustomer'
          header={translateWithValues('ordering_customer')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              {rateRequest.payments?.[0]?.debitor?.customerShortName ??
                rateRequest.payments?.[0]?.debitor?.customerLegalName}
            </div>
          )}
        />
        <Column
          field='buyCurrency'
          header={translateWithValues('buy_currency')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              <CurrencyFlag currencyCode={rateRequest.payments[0]?.buyCurrency} />
            </div>
          )}
        />
        <Column
          field='buyAmount'
          header={translateWithValues('buy_amount')}
          className='text-right !pr-8'
          body={(rateRequest: Order) => {
            const buyCurrencyValue = rateRequest.payments[0]?.buyCurrency;
            const currency = currencies.find((val) => buyCurrencyValue === val.isocode);
            const precision = currency?.amountPrecision;
            return rateRequest.payments[0]?.buyAmount ? (
              <FormatAmount
                number={parseFloat(rateRequest.payments[0]?.buyAmount.toString())}
                maximumFractionDigits={precision}
              />
            ) : (
              <></>
            );
          }}
        />
        <Column
          field='sellCurrency'
          header={translateWithValues('sell_currency')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              <CurrencyFlag currencyCode={rateRequest.sellCurrency} />
            </div>
          )}
        />
        <Column
          field='sellAmount'
          className='text-right !pr-8'
          sortable
          header={translateWithValues('sell_amount')}
          body={(rateRequest: Order) => {
            const sellCurrencyValue = rateRequest.sellCurrency;
            const currency = currencies.find((val) => sellCurrencyValue === val.isocode);
            const precision = currency?.amountPrecision;
            return rateRequest.totalAmount ? (
              <FormatAmount
                number={parseFloat(rateRequest.totalAmount)}
                maximumFractionDigits={precision}
              />
            ) : (
              <></>
            );
          }}
        />
        <Column
          field='beneficiaryName'
          header={translateWithValues('beneficiary_name')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              {rateRequest.payments?.[0]?.creditor?.accountName}
            </div>
          )}
        />
        {selectedCustomer &&
          (selectedCustomer?.customerType == CustomerType.ORDERING_CUSTOMER ||
            selectedCustomer?.customerType == CustomerType.ORDERING_INSTITUTION) && (
            <Column
              field='beneficiaryCountry'
              header={translateWithValues('beneficiary_country')}
              body={(rateRequest: Order) => (
                <CountryFlag
                  countryCode={rateRequest.payments?.[0]?.creditor?.beneAddress?.country}
                />
              )}
            />
          )}

        <Column
          field='status'
          sortable
          header={translateWithValues('status')}
          body={(rateRequest: Order) => <Status status={rateRequest.status} />}
        />
        <Column header='Actions' body={actionBodyTemplate}></Column>
      </DataTable>
    </>
  );
};

export default BulkPaymentsTable;
