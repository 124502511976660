import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { PageHead } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { sendToast } from '../../../../store/slices/main/mainSlice';
import CountdownTimer from '../../RateRequest/CountdownTimer';

export const BulkSummary = ({ payment }: { payment?: Order }) => {
  const dispatch = useAppDispatch();
  let totalPayments = 0;
  let totalSellAmount = 0;

  const groupedPayments = payment?.payments.reduce((acc: any, curr) => {
    const { buyCurrency, sellAmount, buyAmount } = curr;
    if (!acc[buyCurrency]) {
      acc[buyCurrency] = {
        buyCurrency,
        no_of_payments: 0,
        sell_amount: 0,
        buy_amount: 0,
      };
    }
    acc[buyCurrency].no_of_payments += 1;
    acc[buyCurrency].sell_amount += sellAmount;
    acc[buyCurrency].buy_amount += buyAmount;
    totalPayments += 1;
    totalSellAmount += sellAmount;
    return acc;
  }, {});

  const groupedPaymentsArray: Array<{
    buyCurrency: string;
    no_of_payments: number;
    sell_amount: number;
    buy_amount: number;
  }> = groupedPayments ? Object.values(groupedPayments) : [];

  return (
    <div className='card w-[60%] mt-8 p-8'>
      <PageHead title='bulk_summary' />
      <DataTable value={groupedPaymentsArray} lazy>
        <Column
          field='buyCurrency'
          header='Currency'
          body={(item) => {
            return (
              <div className='flex text-sm-semibold text-neutral-2'>
                <img
                  alt={item?.buyCurrency ?? ''}
                  src={`/images/c_flags_svg/${item?.buyCurrency?.toLocaleLowerCase()}.svg`}
                  className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
                />
                <span>{item?.buyCurrency}</span>
              </div>
            );
          }}
        />
        <Column field='no_of_payments' header='No. of Payments' />
        <Column field='sell_amount' header='Sell Amount' />
        <Column field='buy_amount' header='Buy Amount' />
      </DataTable>
      <div className='flex justify-evenly items-center bg-primary-surface-light rounded-[8px] px-4 py-5 mt-2'>
        <div>
          <div className='text-primary-blue-surface-dark text-sm-medium'>
            <Translate value='totalPayments' />
          </div>
          <div className='text-primary-blue-surface-dark text-mobile-header-bold mt-2'>
            {totalPayments}
          </div>
        </div>
        <div>
          <div className='text-primary-blue-surface-dark text-sm-medium'>
            <Translate value='totalSellAmount' />
          </div>
          <div className='text-primary-blue-surface-dark text-mobile-header-bold mt-2'>
            {totalSellAmount} {payment?.sellCurrency}
          </div>
        </div>
        <CountdownTimer
          createdTime={payment?.createdAt || ''}
          onTimeOut={() => {
            dispatch(
              sendToast({
                severity: 'error',
                summary: 'alert',
                detail: 'quoteExpired',
              }),
            );
          }}
        />
      </div>
    </div>
  );
};
