import { useSelector } from 'react-redux';
import {
  selectCurrentCustomer,
  selectCustomerSettings,
  selectRoles,
} from '../store/slices/main/mainSlice';
import { CustomerType } from '../types';

const useRole = () => {
  const roles = useSelector(selectRoles);

  const hasRole = (allowedRoles: string): boolean => {
    if (allowedRoles.includes('&')) {
      const requiredRoles = allowedRoles.split('&').map((role) => role.trim());
      return requiredRoles.every((role) => roles.includes(role));
    } else {
      const requiredRoles = allowedRoles.split(',').map((role) => role.trim());
      return requiredRoles.some((role) => roles.includes(role));
    }
  };

  return { hasRole };
};

export const useSettingEnabled = () => {
  const customerSettings = useSelector(selectCustomerSettings);

  const isSettingEnabled = (configKey?: string): boolean => {
    if (!configKey) return true;
    return configKey
      .split(',')
      .some((key) => customerSettings?.[key.trim() as keyof typeof customerSettings] !== false);
  };

  return { isSettingEnabled };
};

export const useOrderingCustomerMenu = () => {
  const currentCustomer = useSelector(selectCurrentCustomer);

  const shouldShowMenu = (key: string): boolean => {
    return !(
      currentCustomer?.customerType === CustomerType.ORDERING_CUSTOMER &&
      ['clients', 'reports', 'get_help'].includes(key)
    );
  };

  return { shouldShowMenu };
};

export const useUserMenu = () => {
  const currentCustomer = useSelector(selectCurrentCustomer);

  const shouldShowUserMenu = (key: string): boolean => {
    return !(
      currentCustomer?.customerType === CustomerType.ORDERING_CUSTOMER &&
      !currentCustomer?.enableForOnline &&
      key === 'users'
    );
  };

  return { shouldShowUserMenu };
};


export default useRole;
