import { AccessToken, OktaAuth } from '@okta/okta-auth-js';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { setLoading } from '../store/slices/main/mainSlice';

const apiClient = axios.create({
  baseURL: window.location.origin,
  withCredentials: false,
});

let customerId: number | null = null;
let aoboCustomerId: number | null = null;
export const injectCustomerID = (cId: number) => {
  customerId = cId;
};

export const injectAoboCustoemrID = (cId: number | null) => {
  aoboCustomerId = cId;
};

let store: any;
export const injectStore = (_store: any) => {
  store = _store;
};

let oktaClient: OktaAuth | undefined;
export const injectOktaClient = (t: OktaAuth) => {
  oktaClient = t;
};

// Variable to track the number of active requests
let activeRequests = 0;
apiClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>) => {
    // Increment the request count and set loading to true if this is the first request
    if (config.headers['skipLoader'] !== 'true') {
      activeRequests += 1;
    }
    if (activeRequests === 1) {
      store.dispatch(setLoading(true));
    }
    const token = await oktaClient?.tokenManager.get('accessToken');
    if ((token as AccessToken)?.accessToken) {
      const accessToken = (token as AccessToken)?.accessToken;
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    if (customerId) {
      config.headers['X-Owning-Customer-Id'] = customerId;
      config.headers['X-Org-Owning-Customer-Id'] = undefined;
    }
    if (aoboCustomerId) {
      config.headers['X-Owning-Customer-Id'] = aoboCustomerId;
      config.headers['X-Org-Owning-Customer-Id'] = customerId;
    }

    return config;
  },
  (error) => {
    // Decrement the request count and stop loading if no active requests remain
    activeRequests -= 1;
    if (activeRequests === 0) {
      store.dispatch(setLoading(false));
    }

    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    if (response.config.headers['skipLoader'] != 'true') {
      // Decrement the request count and stop loading if no active requests remain
      activeRequests -= 1;
    }
    if (activeRequests === 0) {
      store.dispatch(setLoading(false));
    }
    return response;
  },
  (error) => {
    // Decrement the request count and stop loading if no active requests remain
    activeRequests -= 1;
    if (activeRequests === 0) {
      store.dispatch(setLoading(false));
    }

    console.debug('API Error:', error);
    return error;
  },
);

export default apiClient;
