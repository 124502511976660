import { ChevronDown, ChevronRight, Close } from '@carbon/icons-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import useRole, {
  useOrderingCustomerMenu,
  useSettingEnabled,
  useUserMenu,
} from '../../hooks/useRoles';
import { Translate } from '../../i18n/translate';

type SibeBarMobileProps = {
  actions: SideBarMenuItem[];
  onClose: () => void;
};

const SibeBarMobile: React.FC<SibeBarMobileProps> = ({ actions, onClose }) => {
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const { hasRole } = useRole();
  const { isSettingEnabled } = useSettingEnabled();
  const { shouldShowMenu } = useOrderingCustomerMenu();
  const { shouldShowUserMenu } = useUserMenu();

  const handleActionClick = (action: SideBarMenuItem) => {
    if (action.children) {
      setOpenSubMenu(openSubMenu === action.key ? null : action.key);
    } else if (action.link) {
      navigate(action.link);
      onClose();
    }
  };

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='bg-white rounded-tr-lg rounded-tl-lg w-full max-w-lg p-4 shadow-lg absolute bottom-0'>
        <div className='flex justify-end items-center mb-4'>
          <button className='text-gray-500 hover:text-gray-700 text-xl font-bold' onClick={onClose}>
            <Close size={20} />
          </button>
        </div>
        <ul className='divide-y divide-neutral-border'>
          {actions.map(
            (action, index) =>
              (!action.roles ||
                (action.roles &&
                  hasRole(action.roles) &&
                  isSettingEnabled(action.configKey) &&
                  shouldShowMenu(action.key))) && (
                <li key={index} className='py-3 px-4'>
                  <div
                    className='flex justify-between items-center cursor-pointer hover:bg-gray-100'
                    onClick={() => handleActionClick(action)}
                  >
                    <div className='flex items-center space-x-3'>
                      <div className='text-neutral-3'>{action.icon}</div>
                      <span className='text-neutral-2 text-md-semibold'>
                        <Translate value={action.key} />
                      </span>
                    </div>
                    <div>
                      {action.children &&
                        (openSubMenu === action.key ? (
                          <ChevronDown size={20} />
                        ) : (
                          <ChevronRight size={20} />
                        ))}
                    </div>
                  </div>
                  {openSubMenu === action.key && action.children && (
                    <ul className='pl-8 mt-2 divide-y divide-neutral-border'>
                      {action.children.map(
                        (child, childIndex) =>
                          hasRole(child.roles || '') &&
                          isSettingEnabled(child.configKey) &&
                          shouldShowUserMenu(child.key) && (
                            <li
                              key={childIndex}
                              className='flex justify-between items-center cursor-pointer hover:bg-gray-100 ml-11 py-2'
                              onClick={() => {
                                navigate(child.link || '#');
                                onClose();
                              }}
                            >
                              <span className='text-neutral-2 text-md-semibold'>
                                <Translate value={child.key} />
                              </span>
                            </li>
                          ),
                      )}
                    </ul>
                  )}
                </li>
              ),
          )}
        </ul>
      </div>
    </div>
  );
};

export default SibeBarMobile;
