import { TrashCan } from '@carbon/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable, DataTablePageEvent } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  ApprovalConfirm,
  DropdownInput,
  InheritedMenu,
  PaginatorTemplate,
  TextInput,
} from '../../../components';
import QuoteAmountInput from '../../../components/QuoteAmount';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { MoreAction } from '../../../icons';
import { useAppDispatch } from '../../../store';
import { selectCurrentCustomer, sendToast } from '../../../store/slices/main/mainSlice';
import { selectCustomerCurrencies } from '../../../store/slices/refdata/refdataSlice';
import { AddOrEditOrView } from '../../../types';
import { getCountryISO2 } from '../../../utils/isoncCodeMapping';
import BeneficiaryService from '../../Beneficiaries/beneficiaryService';
import PaymentService from '../paymentService';

interface BulkPaymentTableProps {
  sellCurrency: CurrencyConfiguration;
  orderId?: string;
  mode?: AddOrEditOrView;
  payment?: Order;
}

export const BulkPaymentTable = ({
  sellCurrency,
  orderId,
  mode = AddOrEditOrView.Add,
  payment,
}: BulkPaymentTableProps) => {
  const [payments, setPayments] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const currencies = useSelector(selectCustomerCurrencies);
  const [buyCurrency, setBuyCurrency] = useState<CurrencyConfiguration[]>([]);
  const [restrictedList, setRestrictedList] = useState<any[]>([]);
  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const menu = useRef<Menu>(null);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<any>(null);
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const customerId = selectedCustomer?.id;
  const queryClient = useQueryClient();
  const [customerOptIn, setCustomerOptIn] = useState(true);
  const [requesterOptIn, setRequesterOptIn] = useState(true);
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    unregister,
    formState: { errors, isValid },
  } = useForm<any>();
  const formValues = watch();

  const handleFormSubmit = (data: any) => {
    if (mode === AddOrEditOrView.Add || mode === AddOrEditOrView.Edit) {
      saveBulkPayments(data);
    } else {
      initiateOrderApprovalMutation.mutate();
    }
  };

  const initiateOrderApprovalMutation = useMutation({
    mutationFn: async () => {
      const response = await PaymentService.initiateOrderApproval(
        Number(orderId)!,
        customerOptIn,
        requesterOptIn,
      );
      return response;
    },
    onSuccess: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'order_approval.success',
        }),
      );
      navigate('/payment/rate-requests');
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'order_approval.failed',
          detail: error.message,
        }),
      );
    },
  });

  const saveBulkPayments = (data: any) => {
    const payload = data.payments.map((payment: any, index: number) => {
      return {
        orderId: orderId,
        creditorId: payment.beneficiaryName?.id,
        popCode: payment.pop || undefined,
        purpose: payment.purposeOfPayment || undefined,
        sellAmount: parseFloat(payment.sellAmount) || undefined,
        buyAmount: parseFloat(payment.buyAmount) || undefined,
        index: index,
      };
    });
    generateQuoteMutation.mutate(payload);
  };

  const generateQuoteMutation = useMutation({
    mutationFn: async (payload: any) => {
      const response = await PaymentService.generateBulkPayment(payload);
      if (response.data.success) {
        const payload = {
          orderId: orderId,
          customerId: customerId,
        };
        generateQuickQuoteMutation.mutate(payload);
      }
    },
  });

  const generateQuickQuoteMutation = useMutation({
    mutationFn: async (payload: any) => {
      const response = await PaymentService.generateQuickQuote(payload);
      if (response.data.success) {
        navigate('/payment/bulk-payments/summary/' + orderId);
      }
    },
  });

  const addRows = (e: any) => {
    e.preventDefault();
    setPayments([
      ...payments,
      {
        beneficiaryName: '',
        buyCurrency: '',
        buyAmount: '',
        sellAmount: '',
        purposeOfPayment: '',
      },
    ]);
  };

  const removeRow = (index: number) => {
    const updatedPayments = [...payments];
    updatedPayments.splice(index, 1);
    setPayments(updatedPayments);

    const updatedBuyCurrency = [...buyCurrency];
    updatedBuyCurrency.splice(index, 1);
    setBuyCurrency(updatedBuyCurrency);

    const updatedRestrictedList = [...restrictedList];
    updatedRestrictedList.splice(index, 1);
    setRestrictedList(updatedRestrictedList);
  };

  const handleInputChange = (index: number, field: string, value: any) => {
    if (field === 'buyAmount') {
      unregister(`payments.${index}.sellAmount`);
    } else if (field === 'sellAmount') {
      unregister(`payments.${index}.buyAmount`);
    }
    const updatedPayments = [...payments];
    updatedPayments[index][field] = value;
    setPayments(updatedPayments);
  };

  const handleBeneficiaryChange = (index: number, beneficiary: any) => {
    const updatedPayments = [...payments];
    const buyCurrencies = [...buyCurrency];
    const val = currencies.find((val) => {
      return beneficiary.buyCurrency == val.isocode;
    });
    updatedPayments[index].buyCurrency = beneficiary.buyCurrency;
    updatedPayments[index].purposeOfPayment = beneficiary.paymentPurpose;
    updatedPayments[index].pop = beneficiary?.paymentCode;
    updatedPayments[index].beneficiaryName = beneficiary;

    if (val) {
      buyCurrencies[index] = val;
    }

    setBuyCurrency(buyCurrencies);
    setPayments(updatedPayments);
    setSelectedBeneficiary({ ...beneficiary, index });

    if (beneficiary?.paymentCode) {
      queryClient.invalidateQueries({
        queryKey: [
          'request_quote_beneficiary',
          beneficiary.countryCode,
          beneficiary.buyCurrency,
          beneficiary.paymentCode,
        ],
      });
    }
  };

  useEffect(() => {
    payments?.forEach((payment, index) => {
      Object.keys(payment).forEach((key) => {
        setValue(`payments.${index}.${key}`, payment[key]);
      });
    });
  }, [payments, setValue]);

  useEffect(() => {
    setTotalRecords(0);
    if (mode === AddOrEditOrView.Add) {
      setPayments([
        {
          beneficiaryName: '',
          buyCurrency: '',
          buyAmount: '',
          sellAmount: '',
          purposeOfPayment: '',
        },
        {
          beneficiaryName: '',
          buyCurrency: '',
          buyAmount: '',
          sellAmount: '',
          purposeOfPayment: '',
        },
      ]);
    } else {
      setPayments(payment?.payments || []);
    }
  }, [payment, mode]);

  const beneficiaryListQuery = useQuery({
    queryKey: ['beneficiary_list', pageParams],
    queryFn: async () => {
      const data = await BeneficiaryService.getAllBeneficiaries({}, 0, 10);
      setTotalRecords(data?.totalElements ?? 0);
      return data?.content;
    },
  });

  const handleDecimalInput = (e: React.KeyboardEvent<HTMLInputElement>, precesion?: number) => {
    const input = e.currentTarget.value;
    const key = e.key;
    if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key)) {
      return;
    }
    if (!/^[0-9.]$/.test(key)) {
      e.preventDefault();
    }
    if (key === '.' && input.includes('.')) {
      e.preventDefault();
    }
    const [, decimals] = input.split('.');
    if (decimals && precesion && decimals.length >= precesion) {
      e.preventDefault();
    }
  };

  useQuery({
    queryKey: [
      'request_quote_beneficiary',
      selectedBeneficiary?.countryCode,
      selectedBeneficiary?.buyCurrency,
      selectedBeneficiary?.paymentCode,
    ],
    queryFn: async () => {
      const data = await PaymentService.getBeneficiaryFormByCountry(
        getCountryISO2(selectedBeneficiary.countryCode),
        selectedBeneficiary?.buyCurrency,
      );
      const listValues = data
        .flatMap(({ rules = [] }) => rules)
        .filter(({ ruleName }) => ruleName === 'RESTRICTED TO SET LIST OF VALUES');

      setRestrictedList((prevList) => {
        const newList = [...prevList];
        newList[selectedBeneficiary.index] = listValues;
        return newList;
      });
      return data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled:
      !!selectedBeneficiary?.countryCode && !!buyCurrency && !!selectedBeneficiary?.paymentCode,
  });

  const actionBodyTemplate = (payment: any, index: number) => {
    const actions: MenuItem[] = [
      {
        label: 'delete',
        icon: <TrashCan />,
        disabled: payments.length <= 2,
        className: 'delete-menu-item',
        onClick: () => {
          removeRow(index);
        },
      },
    ];
    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActions(actions);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div className='text-neutral-1 text-lg-semibold'>
        <Translate value='total_payment_rows' />
        <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
          {totalRecords}
        </span>
      </div>
      {/* <div className='flex justify-around gap-2 items-center'>
          <TableSearch globalFilterValue={globalSearch} onGlobalFilterChange={handleGlobalSearch} />
          <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
            <Button
              severity='secondary'
              className='p-button-text h-12'
              onClick={() => {
                setModalVisible(true);
              }}
            >
              <Filter size={20} />
              <Translate value='filterLabel' className='ml-1 hidden md:hidden lg:block' />
            </Button>
            {!!activeFilterCount && (
              <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
            )}
          </div>
          <div>
            <Button
              severity='contrast'
              onClick={() => {
                downloadMargins();
              }}
              className='h-12'
            >
              <Download /> <Translate value='downloadLabel' className='ml-1 hidden lg:block' />
            </Button>
          </div>
        </div> */}
    </div>
  );

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className='card mt-8'>
        <div>
          <p className='text-xs-semibold'>
            <Translate value='enter_payment_row.title' />
          </p>
          <p className='text-md-regular text-neutral-3'>
            <Translate value='enter_payment_row.description' />
          </p>
        </div>
        <div className='table-card'>
          <DataTable
            value={payments}
            // paginator={true}
            lazy
            paginatorTemplate={PaginatorTemplate}
            first={pageParams.first}
            rows={pageParams.rows}
            // loading={loading}
            totalRecords={totalRecords}
            header={header}
            footer={
              <Button severity='info' onClick={addRows} disabled={payments?.length >= 50}>
                <Translate value='add_row' />
              </Button>
            }
            onPage={onPage}
          >
            {mode === AddOrEditOrView.View ||
              (mode === AddOrEditOrView.Approve && (
                <Column
                  field='payId'
                  header='Pay ID'
                  body={(payment) => <div>{payment?.payId}</div>}
                />
              ))}

            <Column
              field='beneficiaryName'
              header='Beneficiary Name'
              body={(payment, { rowIndex }) => (
                <DropdownInput
                  name={`payments.${rowIndex}.beneficiaryName`}
                  options={
                    Array.isArray(beneficiaryListQuery.data)
                      ? beneficiaryListQuery.data.map((beneficiary) => ({
                          label: beneficiary.nickName,
                          value: {
                            name: beneficiary.nickName,
                            id: beneficiary.id,
                            paymentPurpose: beneficiary.paymentPurpose,
                            buyCurrency: beneficiary.buyCurrency,
                            paymentCode: beneficiary?.fieldMap.PaymentCode,
                            countryCode: beneficiary?.beneAddress.country,
                          },
                        }))
                      : []
                  }
                  placeholder={translateWithValues('selectRecipient')}
                  className='w-full !gap-0 p-0 p-inputtext p-component shadow-input'
                  rules={{ required: true }}
                  control={control}
                  error={(errors.payments as any)?.[rowIndex]?.beneficiaryName}
                  filter={true}
                  handleFilter={(e) => {
                    BeneficiaryService.getAllBeneficiaries(
                      {
                        searchTerm: e.filter,
                      },
                      0,
                      10,
                    ).then((data) => {
                      queryClient.setQueryData(['beneficiary_list'], data?.content);
                    });
                  }}
                  onChange={(e) => handleBeneficiaryChange(rowIndex, e.value)}
                />
              )}
            />

            <Column
              field='buyCurrency'
              header={translateWithValues('buyCurrency')}
              body={(payment, { rowIndex }) =>
                payments[rowIndex].buyCurrency && (
                  <div className='flex text-neutral-2'>
                    <img
                      alt={payments[rowIndex].buyCurrency ?? ''}
                      src={`/images/c_flags_svg/${payments[rowIndex].buyCurrency?.toLocaleLowerCase()}.svg`}
                      className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
                    />
                    <span>{payments[rowIndex].buyCurrency}</span>
                  </div>
                )
              }
            />

            {mode === AddOrEditOrView.View ||
              (mode === AddOrEditOrView.Approve && (
                <Column
                  field='fxRate'
                  header='FX Rate'
                  body={(payment) => <div>{payment?.fxRate}</div>}
                />
              ))}

            <Column
              field='buyAmount'
              header={translateWithValues('buyAmount')}
              body={(payment, { rowIndex }) =>
                mode === AddOrEditOrView.Add || mode === AddOrEditOrView.Edit ? (
                  <QuoteAmountInput
                    isEditMode={true}
                    placeholder={translateWithValues('buyAmount')}
                    formRegister={register(`payments.${rowIndex}.buyAmount`, {
                      required: !formValues?.payments?.[rowIndex]?.sellAmount,
                      onChange: (e) => handleInputChange(rowIndex, 'buyAmount', e.target.value),
                      min: {
                        value: buyCurrency[rowIndex]?.minAmount?.value || 0,
                        message: translateWithValues('sellAmountMin', {
                          minAmount:
                            buyCurrency[rowIndex]?.minAmount?.value +
                            ' ' +
                            buyCurrency[rowIndex]?.isocode,
                        }),
                      },
                      ...(buyCurrency[rowIndex]?.maxAmount?.value &&
                        (buyCurrency[rowIndex]?.maxAmount as any)?.value > 0 && {
                          max: {
                            value: (buyCurrency[rowIndex].maxAmount as any).value,
                            message: translateWithValues('sellAmountMax', {
                              maxAmount:
                                (buyCurrency[rowIndex]?.maxAmount as any).value +
                                ' ' +
                                buyCurrency[rowIndex]?.isocode,
                            }),
                          },
                        }),
                      pattern: {
                        value: RegExp(
                          `^\\d+(\\.\\d{${buyCurrency[rowIndex]?.amountPrecision && buyCurrency[rowIndex]?.amountPrecision > 0 ? 1 : 0},${buyCurrency[rowIndex]?.amountPrecision}})?$`,
                        ),
                        message: translateWithValues('amountValidation', {
                          validationValue: buyCurrency[rowIndex]?.amountPrecision,
                        }),
                      },
                    })}
                    onKeyDown={(e) => handleDecimalInput(e, buyCurrency[rowIndex]?.amountPrecision)}
                    disabled={Boolean(formValues?.payments?.[rowIndex]?.sellAmount)}
                    error={(errors.payments as any)?.[rowIndex]?.buyAmount}
                    value={formValues?.payments?.[rowIndex]?.buyAmount}
                    precision={buyCurrency[rowIndex]?.amountPrecision}
                  />
                ) : (
                  <div>{payment?.buyAmount}</div>
                )
              }
            />

            {
              <Column
                field='sellAmount'
                header={translateWithValues('sellAmount')}
                body={(payment, { rowIndex }) =>
                  mode === AddOrEditOrView.Add || mode === AddOrEditOrView.Edit ? (
                    <QuoteAmountInput
                      isEditMode={true}
                      placeholder={translateWithValues('sellAmount')}
                      formRegister={register(`payments.${rowIndex}.sellAmount`, {
                        required: !formValues?.payments?.[rowIndex]?.buyAmount,
                        onChange: (e) => handleInputChange(rowIndex, 'sellAmount', e.target.value),
                        min: {
                          value: sellCurrency?.minAmount?.value || 0,
                          message: translateWithValues('sellAmountMin', {
                            minAmount: sellCurrency?.minAmount?.value + ' ' + sellCurrency?.isocode,
                          }),
                        },
                        ...(sellCurrency?.maxAmount?.value &&
                          sellCurrency?.maxAmount?.value > 0 && {
                            max: {
                              value: sellCurrency.maxAmount.value,
                              message: translateWithValues('sellAmountMax', {
                                maxAmount:
                                  sellCurrency?.maxAmount.value + ' ' + sellCurrency?.isocode,
                              }),
                            },
                          }),
                        pattern: {
                          value: RegExp(
                            `^\\d+(\\.\\d{${sellCurrency?.amountPrecision && sellCurrency?.amountPrecision > 0 ? 1 : 0},${sellCurrency?.amountPrecision}})?$`,
                          ),
                          message: translateWithValues('amountValidation', {
                            validationValue: sellCurrency?.amountPrecision,
                          }),
                        },
                      })}
                      disabled={Boolean(formValues?.payments?.[rowIndex]?.buyAmount)}
                      error={(errors.payments as any)?.[rowIndex]?.sellAmount}
                      value={formValues?.payments?.[rowIndex]?.sellAmount}
                      precision={sellCurrency?.amountPrecision}
                    />
                  ) : (
                    <div>{payment?.sellAmount}</div>
                  )
                }
              />
            }

            <Column
              field='purposeOfPayment'
              header={translateWithValues('purposeOfPayment')}
              body={(_payment, { rowIndex }) =>
                mode === AddOrEditOrView.Add || mode === AddOrEditOrView.Edit ? (
                  <TextInput
                    placeholder={translateWithValues('purposeOfPayment')}
                    isRequired
                    formRegister={register(`payments.${rowIndex}.purposeOfPayment`, {
                      required: true,
                      onChange: (e) =>
                        handleInputChange(rowIndex, 'purposeOfPayment', e.target.value),
                    })}
                    error={(errors.payments as any)?.[rowIndex]?.purposeOfPayment}
                  />
                ) : (
                  <div>{_payment?.purpose}</div>
                )
              }
            />

            <Column
              field='pop'
              header={translateWithValues('pop')}
              body={(payment, { rowIndex }) =>
                (restrictedList[rowIndex]?.length > 0 && mode === AddOrEditOrView.Add) ||
                mode === AddOrEditOrView.Edit ? (
                  <Controller
                    name={`payments.${rowIndex}.pop`}
                    control={control}
                    rules={{ required: payments[rowIndex].pop ? false : true }}
                    // disabled={isFormDisabled}
                    render={({ field }) => (
                      <Dropdown
                        id={`payments.${rowIndex}.pop`}
                        virtualScrollerOptions={{ itemSize: 40 }}
                        className='max-w-[150px]'
                        // tooltip={errorMessage}
                        options={restrictedList[rowIndex][0]?.ruleValues.map((item: any) => ({
                          value: item,
                          label: `${item}-${translateWithValues(item)}`,
                        }))}
                        placeholder={'POP'}
                        value={field.value}
                        filter
                        onChange={(e) => field.onChange(e.value)}
                        // invalid={!!error}
                        // disabled={isFormDisabled}
                      />
                    )}
                  />
                ) : (
                  <div>{payment?.pop}</div>
                )
              }
            />

            {mode === AddOrEditOrView.View ||
              (mode === AddOrEditOrView.Approve && (
                <Column
                  field='country'
                  header='Country'
                  body={(payment) => <div>{payment?.quote?.sellCurrency}</div>}
                />
              ))}

            {mode === AddOrEditOrView.View ||
              (mode === AddOrEditOrView.Approve && (
                <Column
                  field='fees'
                  header='Fees'
                  body={(payment) => <div>{payment?.quote?.fee}</div>}
                />
              ))}

            {mode === AddOrEditOrView.View ||
              (mode === AddOrEditOrView.Approve && (
                <Column
                  field='quoteMargin'
                  header='Quote Margin'
                  body={(payment) => <div>{payment?.quote?.appliedMargin}</div>}
                />
              ))}

            <Column
              header='Actions'
              body={(payment, { rowIndex }) => actionBodyTemplate(payment, rowIndex)}
            />
          </DataTable>
        </div>
      </div>
      <div className='grid gap-6 mt-6 place-items-center'>
        <div className='border border-primary-light rounded-lg bg-primary-surface-light p-5 flex items-center gap-4 w-fit'>
          <Checkbox
            onChange={(e) => setCustomerOptIn(Boolean(e.checked))}
            checked={customerOptIn}
          />
          <div className='text-sm-semibold text-primary-dark-content'>
            <Translate value='order_approval.customer_opt_in' />
          </div>
        </div>
        <div className='border border-primary-light rounded-lg bg-primary-surface-light p-5 flex items-center gap-4 w-fit'>
          <Checkbox
            onChange={(e) => setRequesterOptIn(Boolean(e.checked))}
            checked={requesterOptIn}
          />
          <div className='text-sm-semibold text-primary-dark-content'>
            <Translate value='order_approval.requester_opt_in' />
          </div>
        </div>

        <div className='flex justify-center gap-4'>
          {mode === AddOrEditOrView.View ||
            (mode === AddOrEditOrView.Approve && (
              <Button
                severity='contrast'
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Translate value='cancel' />
              </Button>
            ))}
          {mode !== AddOrEditOrView.Approve && (
            <Button severity='info' type='submit' disabled={!isValid}>
              <Translate
                value={
                  mode === AddOrEditOrView.View ? 'submit_for_approval' : 'proceed_bulk_payment'
                }
              />
            </Button>
          )}
          {mode === AddOrEditOrView.Approve && <ApprovalButtons quoteExpired={false} />}
        </div>
      </div>
    </form>
  );
};

function ApprovalButtons({ quoteExpired }: { quoteExpired: boolean }) {
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [apporveOrject, setApporveOrject] = useState<'approve' | 'reject'>();
  const [approved, setApproved] = useState(false);
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId') as number | null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (approved) {
    return <></>;
  }

  return (
    <>
      <ApprovalConfirm
        showApproveConfirm={showApproveConfirm}
        setShowApproveConfirm={(v, _approved) => {
          setShowApproveConfirm(v);
          setApproved(_approved);
          navigate('/payment/bulk-payments');
        }}
        apporveOrject={apporveOrject}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        approveFn={PaymentService.approveOrRject}
        id={orderId}
        type='payment'
      />

      <div className='flex justify-center gap-6'>
        <Button
          severity='secondary'
          className={`!px-12 disabled ${quoteExpired ? 'opacity-50' : ''}`}
          onClick={() => {
            if (quoteExpired) {
              dispatch(
                sendToast({
                  severity: 'error',
                  summary: 'error',
                  detail: 'approve.quote_expired',
                }),
              );
              return;
            }
            setApporveOrject('reject');
            setShowApproveConfirm(true);
          }}
        >
          <Translate value='reject' />
        </Button>

        <Button
          severity='info'
          className={`ml-[5px] !px-12 ${quoteExpired ? 'opacity-50' : ''}`}
          onClick={() => {
            if (quoteExpired) {
              dispatch(
                sendToast({
                  severity: 'error',
                  summary: 'error',
                  detail: 'approve.quote_expired',
                }),
              );
              return;
            }
            setApporveOrject('approve');
            setShowApproveConfirm(true);
          }}
        >
          <Translate value='approve' />
        </Button>
      </div>
    </>
  );
}
