import { CheckmarkOutline, CloseOutline, Edit, Filter, TrashCan, View } from '@carbon/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Confirm, InheritedMenu, Status, TableSearch } from '../../../components';
import FilterModal from '../../../components/FilterModal';
import InfoTemplate from '../../../components/InfoTemplate';
import useRole from '../../../hooks/useRoles';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { MoreAction } from '../../../icons';
import { PageHeadContext } from '../../../providers/PageHead';
import { useAppDispatch } from '../../../store';
import { selectFilters, sendToast, updateFilter } from '../../../store/slices/main/mainSlice';
import { enumToCamelCase, formatDateRelativeTime } from '../../../utils/helper';
import reportRoles from '../reportRoles';
import ReportsService from '../reportsService';
import AddSchedule from './AddSchedule';

export default function ScheduleReports() {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [addScheduleToggle, setAddScheduleToggle] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const menu = useRef<Menu>(null);
  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const [updateStatusConfirm, setUpdateStatusConfirm] = useState<
    'DELETED' | 'ACTIVE' | 'INACTIVE' | ''
  >('');
  const { hasRole } = useRole();
  const dispatch = useAppDispatch();
  const filters = useSelector(selectFilters)['scheduleReports'];
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [globalSearch, setGlobalSearch] = useState('');
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const [filterValues, setFilterValues] = useState<any>();

  useEffect(() => {
    setPageHeadData({
      title: 'scheduledReportsConf',
      description: 'scheduledReportsConfDesc',
      className: 'pb-4 pr-4',
      actionUI: hasRole(reportRoles.CREARE_REPORT) && (
        <InfoTemplate
          titleKey='scheduleReports.addnew.title'
          descriptionKey='scheduleReports.addnew.description'
          buttonKey='scheduleReports.addnew.button'
          btnAction={() => {
            setAddScheduleToggle(true);
            setSelectedSchedule(null);
            setIsViewMode(false);
          }}
          // roles={clientRole.AddClient}
        />
      ),
    });
  }, [setPageHeadData, navigate, searchParams, dispatch]);

  const schedulesQuery = useQuery({
    queryKey: ['schedules'],
    queryFn: async () => {
      const data = await ReportsService.getSchedules();
      return data;
    },
  });

  const reportNames = useQuery<ReportType[], Error>({
    queryKey: ['reports'],
    queryFn: async () => {
      const data: ReportType[] = await ReportsService.getReportNames();
      return data;
    },
  });

  useEffect(() => {
    const filterMetaData = generateFilterMetaData();
    setFilterValues(filterMetaData);
  }, [reportNames.data]);

  const generateFilterMetaData = () => {
    const reportName: any[] =
      reportNames?.data?.map((report) => {
        return {
          label: enumToCamelCase(report.reportName),
          value: report.reportName,
        };
      }) ?? [];

    return [
      {
        header: 'By Report',
        fields: [
          {
            name: 'report',
            type: 'report',
            placeholder: 'Select Report',
            value: filters?.report || [],
            options: reportName,
          },
        ],
      },
    ];
  };

  const handleGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    setGlobalSearch(e.target.value);
  };

  const handleApplyFilters = (formdata: { [key: string]: string[] | string }) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    //Update filter
    dispatch(updateFilter({ filterKey: 'scheduleReports', value: formdata }));
  };

  const queryClient = useQueryClient();

  const updateStatusMutation = useMutation({
    mutationFn: async () => {
      if (!selectedSchedule?.scheduleId) return;

      const response = await ReportsService.updateReportScheduleStatus(
        selectedSchedule.scheduleId,
        updateStatusConfirm,
      );
      return response;
    },
    onSuccess: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary:
            updateStatusConfirm === 'DELETED'
              ? 'schedule_deleted_successfully'
              : updateStatusConfirm === 'ACTIVE'
                ? 'schedule_activated_successfully'
                : 'schedule_deactivated_successfully',
        }),
      );
      setUpdateStatusConfirm('');
      queryClient.invalidateQueries({ queryKey: ['schedules'] });
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary:
            updateStatusConfirm === 'DELETED'
              ? 'failed_to_delete_schedule'
              : updateStatusConfirm === 'ACTIVE'
                ? 'failed_to_activate_schedule'
                : 'failed_to_deactivate_schedule',
          detail: error.message,
        }),
      );
    },
  });

  const header = (
    <div className='flex justify-between'>
      <div>
        <div className='text-neutral-1 text-lg-semibold'>
          <Translate value='total' />
          <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
            {schedulesQuery.data?.length ?? 0}
          </span>
        </div>
        <div className='mt-1'>
          <Translate value='reports_table_description' className='text-neutral-3 text-sm-regular' />
        </div>
      </div>

      <div className='flex justify-around'>
        <div>
          <TableSearch globalFilterValue={globalSearch} onGlobalFilterChange={handleGlobalSearch} />
        </div>

        <>
          <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
            <Button
              severity='secondary'
              className='p-button-text h-12'
              onClick={() => {
                setModalVisible(true);
              }}
            >
              <Filter size={20} />
              <Translate value='filterLabel' className='ml-1 hidden lg:block' />
            </Button>
            {!!activeFilterCount && (
              <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
            )}
          </div>
        </>
      </div>
    </div>
  );

  const filteredSchedules = (schedulesQuery.data || []).filter((schedule) => {
    // Apply filters
    if (filters?.report?.length && !filters.report.includes(schedule.reportName)) {
      return false;
    }
    // Apply global search
    if (globalSearch && !schedule.reportName.toLowerCase().includes(globalSearch.toLowerCase())) {
      return false;
    }
    return true;
  });

  const actionBodyTemplate = (schedule: any) => {
    const actionFilter: MenuItem[] = [
      {
        label: 'view',
        icon: <View />,
        roles: reportRoles.VIEW_REPORT,
        onClick: () => {
          setSelectedSchedule(schedule);
          setIsViewMode(true);
          setAddScheduleToggle(true);
        },
      },
      {
        label: 'edit',
        icon: <Edit />,
        onClick: () => {
          setSelectedSchedule(schedule);
          setIsViewMode(false);
          setAddScheduleToggle(true);
        },
      },
      schedule.status === 'ACTIVE'
        ? {
            label: 'deactivate',
            icon: <CloseOutline />,
            roles: reportRoles.UPDATE_REPORT,
            onClick: () => {
              setSelectedSchedule(schedule);
              setUpdateStatusConfirm('INACTIVE');
            },
          }
        : {
            label: 'activate',
            roles: reportRoles.UPDATE_REPORT,
            icon: <CheckmarkOutline />,
            onClick: () => {
              setSelectedSchedule(schedule);
              setUpdateStatusConfirm('ACTIVE');
            },
          },
      {
        label: 'delete',
        roles: reportRoles.UPDATE_REPORT,
        icon: <TrashCan />,
        onClick: () => {
          setSelectedSchedule(schedule);
          setUpdateStatusConfirm('DELETED');
        },
        className: 'delete-menu-item',
      },
    ];

    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActions(actionFilter);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  return (
    <>
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='scheduleReportFilter'
        onApply={handleApplyFilters}
      />
      <Confirm
        visible={!!updateStatusConfirm}
        confirmButtonSeverify={updateStatusConfirm === 'DELETED' ? 'danger' : 'info'}
        onCancel={() => setUpdateStatusConfirm('')}
        onConfirm={() => updateStatusMutation.mutate()}
        title={
          updateStatusConfirm === 'DELETED'
            ? 'delete_schedule_title'
            : updateStatusConfirm === 'ACTIVE'
              ? 'activate_schedule_title'
              : 'deactivate_schedule_title'
        }
        message={
          updateStatusConfirm === 'DELETED'
            ? 'delete_schedule_message'
            : updateStatusConfirm === 'ACTIVE'
              ? 'activate_schedule_message'
              : 'deactivate_schedule_message'
        }
        loading={updateStatusMutation.isPending}
      />
      {addScheduleToggle && (
        <AddSchedule
          visible={addScheduleToggle}
          setAddScheduleToggle={setAddScheduleToggle}
          schedule={selectedSchedule}
          isViewMode={isViewMode}
        />
      )}
      <div className='py-4'>
        <DataTable
          value={filteredSchedules}
          paginator
          rows={10}
          header={header}
          className='p-datatable-gridlines'
          loading={schedulesQuery.isLoading}
          sortField='scheduleId'
          sortOrder={1}
        >
          <Column field='scheduleId' header='Schedule ID' sortable />
          {/* <Column field='owningCustomerId' header='Customer ID' sortable /> */}
          <Column field='recurringPeriod' header='Recurring Period' />
          {/* <Column field='hourOfDay' header='Hour of Day' sortable /> */}
          <Column field='timeZone' header='Time Zone' />
          <Column
            field='reportName'
            header='Report Name'
            sortable
            body={(rowData) => enumToCamelCase(rowData.reportName)}
          />
          <Column
            field='reportTypes'
            header='Report Types'
            body={(rowData) => rowData.reportTypes.join(', ')}
          />
          <Column
            field='deliveryChannels'
            header='Delivery Channels'
            body={(rowData) => rowData.deliveryChannels.join(', ')}
          />
          <Column
            field='creationDate'
            header={translateWithValues('date')}
            sortable
            body={(rowData: ReportType) => {
              return formatDateRelativeTime(rowData.creationDate || '');
            }}
          />
          <Column field='createdBy' header={translateWithValues('createdBy')} />
          <Column
            field='status'
            header='Status'
            body={(rowData: Schedule) => <Status status={rowData.status} />}
          />

          <Column body={actionBodyTemplate} header='Actions' />
        </DataTable>
      </div>
    </>
  );
}
