import { TicketType } from '../../types';
import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

const ROOT = '/api/order-management/cases';

const CaseService = {
  async getAllCases(): Promise<Case[] | null> {
    try {
      const response = await apiClient.get(`${ROOT}/all`);
      return handleApiResponse(response);
    } catch (err) {
      console.error('Error fetching cases', err);
      return null;
    }
  },
  async getTopics(ticketType: TicketType): Promise<Topic[] | null> {
    try {
      const response = await apiClient.get(`${ROOT}/topics?ticketType=${ticketType}`);
      return handleApiResponse(response);
    } catch (error) {
      console.error('cannot fetch topics', error);
      return null;
    }
  },
  async createcase(payload: CreateCasePayload): Promise<CreateCaseResponse | null> {
    try {
      const response = await apiClient.post(`${ROOT}`, payload);
      return handleApiResponse(response);
    } catch (error) {
      console.error('Error creating case', error);
      return null;
    }
  },

  async uploadCaseFile(file: File, caseId: string) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await apiClient.post(`${ROOT}/${caseId}/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  },

  async getCaseById(caseId: string): Promise<Case> {
    try {
      const response = await apiClient.get(`${ROOT}/${caseId}`);
      return handleApiResponse(response);
    } catch (error) {
      console.log('Error fetching case data', error);
      throw error;
    }
  },

  async closeCase(caseId: string) {
    const response = await apiClient.put(`${ROOT}/${caseId}/close`, {});
    return response;
  },

  async downloadFile(documentId = '', caseId = '') {
    const response = await apiClient.get(`${ROOT}/${caseId}/file/${documentId}`);
    return response;
  },

  async commentOnCase(comment: string, caseId: string) {
    const response = await apiClient.put(`${ROOT}/${caseId}`, comment, {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
    return response;
  },
};

export default CaseService;
